import http from '@/infra/api'

let createProfileThermo = (data) => {
  return http.post(`/thermal-profile`, data)
}
let index = () => {
  return http.get(`/thermal-profile`)
}

let getProfile = (id) => {
  return http.get(`/thermal-profile/${id}`)
}

let destroy = (id) => {
  return http.delete(`/thermal-profile/${id}`)
}

export default {
  createProfileThermo,
  getProfile,
  index,
  destroy
}
