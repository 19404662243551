<template>
  <div v-if="hover" class="edit-modulo">
    <v-hover v-slot="{ hover }">
      <v-icon @click="onMove" :class="{ 'hover-icon': hover, icon: true }">
        mdi-cursor-move
      </v-icon>
    </v-hover>
    <v-hover v-slot="{ hover }">
      <v-icon @click="onConfig" :class="{ 'hover-icon': hover, icon: true }">
        mdi-hammer-wrench
      </v-icon>
    </v-hover>
    <v-hover v-slot="{ hover }">
      <v-icon @click="onCopy" :class="{ 'hover-icon': hover, icon: true }">
        mdi-content-copy
      </v-icon>
    </v-hover>
    <v-hover v-slot="{ hover }">
      <v-icon @click="onDelete" :class="{ 'hover-icon': hover, icon: true }">
        mdi-delete
      </v-icon>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: 'EditModuloTools',

  props: {
    hover: Boolean
  },

  methods: {
    onMove: function () {
      this.$emit('onClickMove')
    },
    onConfig: function () {
      this.$emit('onClickConfig')
    },
    onCopy: function () {
      this.$emit('onClickCopy')
    },
    onDelete: function () {
      this.$emit('onClickDelete')
    }
  }
}
</script>

<style scoped>
.edit-modulo {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
  background: gray;
  z-index: 1;
  line-height: 0.8;
}
.icon {
  font-size: 14px;
  color: white;
}
.hover-icon {
  color: #2196f3;
}
</style>
