import http from '@/infra/api'

let getSubjectThermos = (subjectId) => {
  return http.get(`/reports/subject-thermos/${subjectId}`)
}

let getSingleThermo = (thermoId, data = {}) => {
  return http.get(`/thermo/${thermoId}`, { params: data })
}

let getGeneralStats = () => {
  return http.get(`/report/general`)
}

let getGroupThermos = (groupId, tagsId, startDate, endDate) => {
  return http.get(
    `/thermo/group?groupId=${groupId}&tagsId=${tagsId}&startDate=${startDate}&endDate=${endDate}`
  )
}

let getLastAsymmetries = () => {
  return http.get(`/thermo/get-last-asymmetries`)
}

export default {
  getSubjectThermos,
  getSingleThermo,
  getGroupThermos,
  getLastAsymmetries,
  getGeneralStats
}
