<template>
  <v-col v-if="error">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadStatistics') }} </v-col>
        <v-col class="py-0">
          <v-btn @click="getData" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col v-else-if="loading">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col v-else>
    <v-row class="ma-3" justify="center">
      <v-col cols="3">
        <v-autocomplete
          v-if="groups.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedGroups"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="groups"
          :label="$t('Groups')"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="tags.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedTags"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="tags"
          :label="$t('Tags')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="createdAt"
          type="datetime-local"
          :label="$t('Date')"
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          outlined
          dense
          hide-details
          v-model="tempMin"
          :placeholder="`${$t('Minimum')}`"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          outlined
          dense
          hide-details
          v-model="tempMax"
          :placeholder="`${$t('Maximum')}`"
        />
      </v-col>
      <v-col cols="12" class="px-lg-16 px-md-8">
        <PeopleSearchList
          :enableFilters="false"
          customColsLg="3"
          customColsXl="2"
          showOnlytThermoOnModal
          :tempMax="tempMax"
          :tempMin="tempMin"
          :selectedTagsOutside="selectedTags"
          :selectedGroupsOutside="selectedGroups"
          :dateOutside="createdAt"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
// import AssymetryList from '../components/AsymetryList.vue'
import PeopleSearchList from '../components/PeopleSearchList.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  components: { PeopleSearchList, LoadingScreen },
  data() {
    return {
      loading: false,
      selectedGroups: [],
      selectedTags: [],
      createdAt: null,
      tempMin: null,
      tempMax: null,
      error: false
    }
  },
  computed: {
    groups() {
      return this.$store.getters.groups.map((item) => {
        return {
          text: `${item.type}: ${item.name}`,
          value: item.id
        }
      })
    },
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    itemsAlerts() {
      return []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        await Promise.all([
          this.$store.dispatch('fetchSubjects'),
          this.$store.dispatch('fetchGroups'),
          this.$store.dispatch('fetchTags')
        ])
      } catch (error) {
        console.log('Error: ', error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    onClickSubject(id) {
      this.$router.push({
        name: 'PeopleThermos',
        params: { peopleId: id }
      })
    }
  }
}
</script>

<style scoped>
.list-scroll {
  width: auto;
  overflow-y: auto;
  max-height: 400px;
}
.styled-table {
  border-collapse: collapse;
  margin: 20px 0;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 15px gray;
}
.styled-vcard {
  border-color: red;
  box-shadow: 0 0 10px gray;
}
</style>
