export default {
  state: {
    snackBar: {
      text: '',
      type: '',
      model: false
    }
  },
  mutations: {
    SET_SNACKBAR(state, payload) {
      state.snackBar = payload
    }
  },
  actions: {
    setSnackBar({ commit }, payload) {
      commit('SET_SNACKBAR', payload)
    }
  },
  getters: {
    snackBar: (state) => state.snackBar
  }
}
