<template>
  <page-editor />
</template>

<script>
import PageEditor from '../components/PageEditor'

export default {
  name: 'NewTemplateReport',
  components: { PageEditor }
}
</script>
