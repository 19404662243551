<template>
  <div
    :style="{
      textAlign: 'center',
      border: 'dotted 2px gray',
      padding: '8px',
      width: '100%'
    }"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'MoveModuloTemplate',
  props: {
    text: String
  }
}
</script>
