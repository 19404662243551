import http from '@/infra/api'

let index = () => {
  return http.get('groups')
}

let show = (id) => {
  return http.get(`groups/${id}`)
}

let store = (data) => {
  return http.post('groups', data)
}

let update = (data) => {
  return http.put(`groups/${data.id}`, data)
}

let destroy = (id) => {
  return http.delete(`groups/${id}`)
}

export default {
  index,
  show,
  store,
  update,
  destroy
}
