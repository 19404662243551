<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col cols="12" v-else-if="errors.fetch">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col>{{ $t('ErrorLoadingPeople') }}.</v-col>
        <v-col class="py-0">
          <v-btn @click="fetchAll" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <v-card outlined>
      <v-col id="filters-profiles">
        <v-row class="pa-3" justify="space-between">
          <span class="text-caption text--secondary">{{ $t('Filters') }}</span>
          <v-btn
            @click="$router.push({ name: 'NewProfile' })"
            rounded
            color="primary"
            ><v-icon>mdi-plus</v-icon> {{ $t('NewProfile') }}</v-btn
          >
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="search"
              :placeholder="`${$t('SearchName')} ...`"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
    <v-card outlined class="mt-2" id="content-profiles">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="filteredItems"
        :sort-by="['name']"
        :sort-desc="[false]"
        must-sort
        outlined
        :items-per-page="items.length"
        :no-data-text="`${$t('NoRecord')} :(`"
        mobile-breakpoint="1"
      >
        <template v-slot:item="{ headers, item }">
          <v-hover v-slot="{ hover }">
            <tr @dblclick="onClickItem(item)">
              <td
                v-for="itemKey in headers"
                :style="
                  itemKey.value === 'actions' || itemKey.value === 'inserted_at'
                    ? 'width: 150px'
                    : ''
                "
                :key="itemKey.value"
              >
                <template v-if="itemKey.value === 'actions'">
                  <v-btn
                    icon
                    small
                    color="blue"
                    class="ml-2"
                    @click="onClickItem(item)"
                  >
                    <v-icon small>mdi-eye</v-icon></v-btn
                  >

                  <template v-if="hover">
                    <v-btn
                      icon
                      small
                      color="primary"
                      class="ml-2"
                      @click="handleEditProfile(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      small
                      class="ml-2"
                      color="red"
                      :loading="loadings.remove"
                      :disabled="loadings.remove"
                      @click="handleOpenRemoveModal(item)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </template>
                <template v-else-if="itemKey.value === 'name'">
                  <span>
                    {{ item[itemKey.value] }}
                  </span>
                </template>
                <template v-else-if="itemKey.value === 'subjects'">
                  <v-chip
                    x-small
                    v-for="subject in item.subjects"
                    :key="subject.id"
                  >
                    {{ subject.name }}
                  </v-chip>
                </template>
                <template v-else-if="itemKey.value === 'series_qtd'">
                  {{ item.thermographys.length }}
                </template>
                <template v-else-if="itemKey.value === 'group_names'">
                  <v-chip
                    class="ma-1"
                    x-small
                    v-for="group in item.groups"
                    :key="group.id"
                  >
                    {{ group.type }}: {{ group.name }}
                  </v-chip>
                </template>
                <template v-else>
                  {{ item[itemKey.value] }}
                </template>
              </td>
            </tr>
          </v-hover>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="removeModal.open" max-width="400px">
      <RemoveFormModal
        :title="`${$t('RemoveProfile')}?`"
        :onCloseCallback="() => (removeModal.open = false)"
        :onRemoveCallback="() => handleRemoveItem(removeModal.item)"
        :isLoading="loadings.remove"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import RemoveFormModal from '../components/RemoveFormModal.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  name: 'People',
  components: {
    RemoveFormModal,
    LoadingScreen
  },
  data: function () {
    return {
      search: '',
      loadings: {
        fetch: false,
        addPeople: false,
        remove: false
      },
      removeModal: {
        open: false,
        item: null
      },
      errors: {
        fetch: false
      }
    }
  },
  methods: {
    async fetchProfiles() {
      await this.$store.dispatch('fetchProfiles')
    },
    async fetchAll() {
      this.loadings.fetch = true
      try {
        await this.fetchProfiles()
        this.errors.fetch = false
      } catch (error) {
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    },
    async handleRemoveItem(item) {
      this.loadings.remove = true
      try {
        await this.$store.dispatch('removeProfile', item.id)
        this.removeModal.item = null
        this.removeModal.open = false

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ProfileSuccessfullyRemoved')}!`,
          type: 'success',
          model: true
        })

        await this.fetchProfiles()
      } catch (e) {
        console.log(e)

        this.$store.dispatch('setSnackBar', {
          text:
            e?.response?.data?.message != ''
              ? e.response.data.message
              : `${this.$t('AnErrorOccurredWhileTryingToRemoveTheProfile')}!`,
          type: 'error',
          model: true
        })
      }
      this.loadings.remove = undefined
    },
    onClickItem(item) {
      this.$router.push({
        name: 'Profile',
        params: {
          profileId: item.id
        }
      })
    },
    handleEditProfile(item) {
      this.$router.push({
        name: 'ProfileEdit',
        params: {
          profileId: item.id
        }
      })
    },
    handleOpenRemoveModal(item) {
      this.removeModal.item = item
      this.removeModal.open = true
    }
  },
  async mounted() {
    await this.fetchAll()
  },
  computed: {
    headers() {
      return [
        { text: this.$t('Actions'), value: 'actions', width: '150px' },
        { text: this.$t('Name'), value: 'name', width: '250px' },
        { text: this.$t('Series'), value: 'series', width: '220px' },
        { text: this.$t('People'), value: 'subjects', width: '220px' }
      ]
    },
    filteredItems() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    items() {
      return this.$store.getters.profiles
    }
  }
}
</script>

<style></style>
