<template>
  <v-card flat>
    <v-row align="center" class="pa-3 ma-0 mx-5 mt-2">
      <div class="icon-container mr-3">
        <v-icon color="primary" class="">mdi-account-box-multiple</v-icon>
      </div>
      <span class="text-h5">{{ $t('CreateThermographicProfile') }}</span>
      <span class="caption ml-3 text--secondary"
        >|
        {{
          step === 0
            ? $t('SubmitThermographiesForProfileCreation')
            : selectedViewLabel
        }}</span
      >
    </v-row>
    <v-col cols="12" v-if="step === 0" id="select-view-new-profile">
      <v-card flat class="px-6">
        {{ $t('SelectAViewToCreateTheThermographicProfile') }}.
        <v-autocomplete
          :items="views"
          item-text="label"
          item-value="value"
          class="mt-6"
          dense
          v-model="selectedView"
          outlined
          :label="$t('View')"
        ></v-autocomplete>
        <v-row justify="end" class="ma-0">
          <v-btn
            color="primary"
            :disabled="!selectedView"
            @click="handleInit"
            id="button-select-view-new-profile"
            >{{ $t('Begin') }}</v-btn
          >
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" v-else-if="step === 2">
      <v-card flat class="pa-6">
        <v-text-field
          autofocus
          :label="$t('Name')"
          v-model="name"
          dense
          outlined
        ></v-text-field>
        <v-row justify="end" class="ma-0">
          <v-btn @click="step = 1" text color="primary" class="mr-2">{{
            $t('BACK')
          }}</v-btn>
          <v-btn
            color="primary"
            :disabled="name === '' || loadings.create"
            :loading="loadings.create"
            @click="handleCreateProfile"
            >{{ $t('CREATE') }}</v-btn
          >
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" v-else-if="loadings.fetch">
      <v-row justify="center" class="mt-10">
        <loading-screen />
      </v-row>
    </v-col>
    <v-col cols="12" v-else-if="errors.fetch">
      <v-row justify="center" class="mt-10">
        <div>
          <v-col>{{ $t('ErrorLoadingPeople') }}.</v-col>
          <v-col class="py-0">
            <v-btn @click="fetchAll" rounded color="primary">{{
              $t('TRYAGAIN')
            }}</v-btn>
          </v-col>
        </div>
      </v-row>
    </v-col>
    <v-col cols="12" v-else class="px-8">
      <v-card outlined>
        <v-col id="filter-new-profile">
          <v-row class="pa-3" justify="space-between">
            <span class="text--secondary">{{ $t('Filters') }}</span>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                outlined
                dense
                v-model="search"
                :placeholder="`${$t('SearchName')}...`"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                style="font-weight: 300"
                outlined
                dense
                chips
                small-chips
                multiple
                v-model="selectedGroups"
                :items="groups"
                :label="$t('Groups')"
              >
                <template v-slot:item="data">
                  <v-list-item-content
                    v-text="data.item.text"
                  ></v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                style="font-weight: 300"
                outlined
                dense
                chips
                small-chips
                multiple
                v-model="selectedSubjects"
                :items="subjects"
                :label="$t('People')"
              >
                <template v-slot:item="data">
                  <v-list-item-content
                    v-text="data.item.text"
                  ></v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                style="font-weight: 300"
                outlined
                dense
                chips
                small-chips
                multiple
                v-model="selectedTags"
                :items="tags"
                :label="$t('Tags')"
              >
                <template v-slot:item="data">
                  <v-list-item-content
                    v-text="data.item.text"
                  ></v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="end" class="ma-0 mt-4">
            <v-btn @click="step = 0" text color="primary" class="mr-3">{{
              $t('BACK')
            }}</v-btn>
            <v-btn
              color="primary"
              :disabled="selectedImages.length < 2"
              @click="step = 2"
              >{{ $t('NEXT') }}</v-btn
            >
          </v-row>
        </v-col>
      </v-card>
      <v-card outlined class="mt-2" id="all-thermos-new-profile">
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :sort-by="['formatedDate']"
          :sort-desc="[true]"
          :custom-sort="customSort"
          must-sort
          item-key="id"
          show-select
          v-model="selectedImages"
          outlined
          :no-data-text="`${$t('NoRecord')} :(`"
          mobile-breakpoint="1"
        >
          <template v-slot:[`item.tags`]="{ item }">
            <v-row style="max-width: 350px">
              <v-chip
                v-for="chipItem in item.tags"
                :key="chipItem"
                class="ma-1"
                x-small
              >
                {{ chipItem }}
              </v-chip>
            </v-row>
          </template>
          <template v-slot:[`item.group_names`]="{ item }">
            <v-row style="max-width: 350px">
              <v-chip
                v-for="chipItem in item.group_names"
                :key="chipItem"
                class="ma-1"
                x-small
              >
                {{ chipItem }}
              </v-chip>
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-card>
</template>

<script>
import { profileService } from '@/services'
import LoadingScreen from '../components/LoadingScreen.vue'
import { format } from 'date-fns'

export default {
  name: 'People',
  components: {
    LoadingScreen
  },
  data: function () {
    return {
      search: '',
      selectedView: null,
      step: 0,
      name: '',
      selectedImages: [],
      selectedGroups: null,
      selectedSubjects: null,
      selectedTags: null,
      loadings: {
        fetch: false,
        create: false
      },
      errors: {
        fetch: false,
        create: false
      }
    }
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'formatedDate') {
          const iDateA = new Date(a.createdAt).getTime()
          const iDateB = new Date(b.createdAt).getTime()
          if (!isDesc[0]) {
            return iDateA < iDateB ? -1 : 1
          } else {
            return iDateB < iDateA ? -1 : 1
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
    async handleInit() {
      this.step = 1
      await this.fetchAll()
    },
    async handleCreateProfile() {
      this.loadings.create = true
      try {
        const response = await profileService.createProfileThermo({
          name: this.name,
          images: this.selectedImages.map((item) => item.id)
        })

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ProfileCreatedSuccessfully')}!`,
          type: 'success',
          model: true
        })

        console.log(response)
        setTimeout(() => this.$router.push(`/profile/${response.data.id}`), 500)
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('AnErrorOccurredWhileTryingToCreateTheProfile')}!`,
          type: 'error',
          model: true
        })

        console.log(error)
      }
      this.loadings.create = false
    },
    async fetchSubjects() {
      await this.$store.dispatch('fetchSubjects')
    },
    async fetchTags() {
      await this.$store.dispatch('fetchTags')
    },
    async fetchGroups() {
      await this.$store.dispatch('fetchGroups')
    },
    async fetchThermoImages() {
      await this.$store.dispatch('fetchThermoImages', {
        view: this.selectedView
      })
    },
    async fetchAll() {
      this.loadings.fetch = true
      try {
        await Promise.all([
          this.fetchSubjects(),
          this.fetchGroups(),
          this.fetchTags(),
          this.fetchThermoImages()
        ])
        this.errors.fetch = false
      } catch (error) {
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    },
    getLabelView(valueView) {
      let labelVista = valueView

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },
    handleOpenRemoveModal(item) {
      this.removeModal.item = item
      this.removeModal.open = true
    }
  },
  watch: {
    selectedImages(images) {
      const separator = ', '
      if (images.length > 0)
        this.name = `${images.reduce((prevVal, curImg, idx) => {
          if (idx === 1)
            return prevVal.subject === curImg.subject
              ? prevVal.subject
              : `${prevVal.subject}${separator}${curImg.subject}`

          return !prevVal.includes(curImg.subject)
            ? `${prevVal}${separator}${curImg.subject}`
            : prevVal
        })} - ${this.getLabelView(
          images[0].view
        )} - ${new Date().toLocaleString()}`
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('Name'), value: 'thermo_name', width: '200px' },
        { text: this.$t('Groups'), value: 'group_names', width: '200px' },
        { text: this.$t('Person'), value: 'subject', width: '200px' },
        { text: this.$t('Tags'), value: 'tags', width: '150px' },
        { text: this.$t('StudyDate'), value: 'formatedDate', width: '200px' }
      ]
    },
    views() {
      const views = [...this.$t('viewsOptsArr')]
      views.pop()
      return views
    },
    selectedViewLabel() {
      let labelVista = this.selectedView

      this.views.forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },
    filteredItems() {
      return this.items
        .filter((item) => {
          if (item.status !== 'PROCESSED') {
            return false
          }
          if (this.selectedGroups) {
            const existent = this.selectedGroups.find((group) => {
              return !item.thermography.subject.groups.find(
                (itemGroup) => itemGroup.id === group
              )
            })
            if (existent) return false
          }
          if (this.selectedSubjects) {
            const existent = this.selectedSubjects.find((subject) => {
              return item.thermography.subject.id !== subject
            })
            if (existent) {
              return false
            }
          }
          if (this.selectedTags) {
            const existent = this.selectedTags.find((tag) => {
              return !item.thermography.tags.find(
                (itemTag) => itemTag.id === tag
              )
            })
            if (existent) return false
          }
          return item.thermography.name
            .toLowerCase()
            .includes(this.search.toLowerCase())
        })
        .map((item) => ({
          ...item,
          group_names: item.thermography.subject.groups.map(
            (g) => `${g.type}: ${g.name}`
          ),
          subject: item.thermography.subject.name,
          tags: item.thermography.tags.map((t) => t.name),
          formatedDate: format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm'),
          thermo_name: item.thermography.name
        }))
    },
    groups() {
      return this.$store.getters.groups.map((item) => {
        return {
          text: `${item.type}: ${item.name}`,
          value: item.id
        }
      })
    },
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: `${item.name}`,
          value: item.id
        }
      })
    },
    items() {
      return this.$store.getters.thermoImagesGet
    },
    subjects() {
      return this.$store.getters.subjects.map((item) => {
        return {
          text: `${item.name}`,
          value: item.id
        }
      })
    }
  }
}
</script>

<style scoped>
.icon-container {
  background-color: #f6ebea;
  padding: 4px;
  border-radius: 3px;
}
</style>
