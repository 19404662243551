<template>
  <div class="tutorial-wrapper">
    <home v-if="!tutorial && showTutorial === 'Home'" />
    <groups v-if="!tutorial && showTutorial === 'Groups'" />
    <tags v-if="!tutorial && showTutorial === 'Tags'" />
    <people v-if="!tutorial && showTutorial === 'People'" />
    <people-thermos v-if="!tutorial && showTutorial === 'PeopleThermos'" />
    <thermography v-if="!tutorial && showTutorial === 'Thermography'" />
    <thermal-diff v-if="!tutorial && showTutorial === 'ThermalDiff'" />
    <profiles v-if="!tutorial && showTutorial === 'Profiles'" />
    <profile v-if="!tutorial && showTutorial === 'Profile'" />
    <new-profile v-if="!tutorial && showTutorial === 'NewProfile'" />
    <new-thermography v-if="!tutorial && showTutorial === 'NewThermo'" />

    <v-dialog v-model="tutorial" width="400" persistent>
      <div class="pa-6 white">
        <v-row>
          <v-col align="center">
            <h3 class="mb-2">
              {{ $t('WouldYouLikeToSeeATutorialOfThisPartOfTheSystem') }}?
            </h3>
            <v-btn class="mx-2" @click="handleNo()">{{ $t('No') }}</v-btn
            ><v-btn color="primary" class="mx-2" @click="handleYes()">{{
              $t('Yes')
            }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Home from './Home.vue'
import Groups from './Groups.vue'
import Tags from './Tags.vue'
import People from './People.vue'
import PeopleThermos from './PeopleThermos.vue'
import Thermography from './Thermography.vue'
import ThermalDiff from './ThermalDiff.vue'
import Profiles from './Profiles.vue'
import Profile from './Profile.vue'
import NewProfile from './NewProfile.vue'
import NewThermography from './NewThermography.vue'

export default {
  name: 'Tutorial',
  components: {
    Home,
    Groups,
    Tags,
    People,
    PeopleThermos,
    Thermography,
    ThermalDiff,
    Profiles,
    NewProfile,
    NewThermography,
    Profile
  },
  data() {
    return {
      tutorial: false
    }
  },

  methods: {
    handleNo() {
      this.tutorial = false
      this.$store.dispatch('setShowTutorial', '')
      this.$store.dispatch('addPageShown', this.$route.name)
    },
    handleYes() {
      this.tutorial = false
      this.$store.dispatch('setShowTutorial', this.$route.name)
      this.$store.dispatch('addPageShown', this.$route.name)
    },
    beginTutorial() {
      this.tutorial =
        this.tutorialPages.includes(this.$route.name) &&
        !this.pagesAlreadyShown.includes(this.$route.name)
    }
  },

  watch: {
    '$route.name'() {
      this.beginTutorial()
    }
  },

  mounted() {
    this.$store.dispatch('setPagesAlreadyShown')

    this.beginTutorial()
  },

  computed: {
    tutorialPages() {
      return this.$store.getters.tutorialPages
    },
    pagesAlreadyShown() {
      return this.$store.getters.pagesAlreadyShown
    },
    showTutorial() {
      return this.$store.getters.showTutorial
    }
  }
}
</script>

<style scoped>
.tutorial-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 0;
  width: 0;
  z-index: 99999;
}
</style>
