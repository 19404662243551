<template>
  <v-dialog v-model="globalModal.model" width="600">
    <div class="pa-6 white">
      <FormFeedbackThermography
        v-if="globalModal.component === 'FormFeedbackThermography'"
      />
    </div>
  </v-dialog>
</template>

<script>
import FormFeedbackThermography from './FormFeedbackThermography.vue'
export default {
  components: { FormFeedbackThermography },
  computed: {
    globalModal() {
      return this.$store.getters.globalModal
    }
  }
}
</script>
