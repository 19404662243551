import { profileService } from '@/services'

export default {
  state: { profiles: [] },
  mutations: {
    SET_PROFILES(state, payload) {
      state.profiles = payload
    }
  },
  actions: {
    async fetchProfiles({ commit }) {
      const response = await profileService.index()
      if (response.data.length > 0) {
        commit('SET_PROFILES', response.data)
      } else {
        commit('SET_PROFILES', [])
      }
    },

    async removeProfile({ commit, state }, payload) {
      await profileService.destroy(payload)
      commit(
        'SET_PROFILES',
        state.profiles.filter((profile) => profile.id !== payload)
      )
    }
  },
  getters: {
    profiles: (state) => state.profiles
  }
}
