<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-if="modulo.value !== undefined"
        v-model="modulo.value"
        outlined
        dense
        label="Texto"
      />
    </v-col>
    <v-col cols="12">
      <h3>Estilo</h3>
    </v-col>
    <v-col
      cols="12"
      v-for="(config, key) in modulo.configs"
      :key="`modulo-config-${key}`"
    >
      <v-text-field v-model="modulo.configs[key]" outlined dense :label="key" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EditModuloForm',

  props: {
    modulo: Object
  }
}
</script>
