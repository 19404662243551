var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.error)?_c('v-col',[_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('div',[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('ErrorLoadStatistics'))+" ")]),_c('v-col',{staticClass:"py-0"},[_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.getData}},[_vm._v(_vm._s(_vm.$t('TRYAGAIN')))])],1)],1)])],1):(_vm.loading)?_c('v-col',[_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('loading-screen')],1)],1):_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"px-lg-16 px-md-8",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headersAlerts,"items":_vm.subjectsTableAlerts,"sort-by":['date1'],"sort-desc":[true],"loading":_vm.loadingSubjectsTableAlerts,"must-sort":"","outlined":"","items-per-page":-1,"no-data-text":((_vm.$t('NoRecord')) + " :("),"mobile-breakpoint":"1","id":"table-data-people"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToThermo(item.thermo1Id)}}},_vm._l((headers),function(itemKey){return _c('td',{key:itemKey.value},[(
                  itemKey.value === 'date1' ||
                  itemKey.value === 'date2' ||
                  itemKey.value === 'date3'
                )?[_vm._v(" "+_vm._s(item[itemKey.value] != null ? new Date(item[itemKey.value]) .toLocaleDateString() .toString() : null)+" ")]:[_vm._v(" "+_vm._s(item[itemKey.value])+" ")]],2)}),0)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }