<template>
  <div>
    <loading-screen v-if="isLoadingFetch" />
    <div v-else>
      <v-row class="pt-6 px-6 mb-3" align="center">
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="name"
            outlined
            dense
            :label="$t('Name')"
            hide-details
          />
        </v-col>
        <v-col cols="12" lg="4">
          <v-btn
            @click="openModalVariables('create')"
            color="primary"
            class="mr-4 px-2"
            small
          >
            <span class="mx-3">{{ $t('Create') + ' ' + $t('Variables') }}</span>
          </v-btn>
          <v-btn
            @click="openModalVariables('insert')"
            color="primary"
            class="mr-4 px-2"
            small
          >
            <span class="mx-3">{{ $t('Insert') + ' ' + $t('Variables') }}</span>
          </v-btn>
        </v-col>
        <v-col cols="12" lg="4" class="text-right">
          <v-btn
            @click="handleSave"
            color="primary"
            :loading="isLoadingSave"
            :disabled="isLoadingSave"
          >
            <span class="mx-3">{{ $t('SAVE') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <div style="width: 100%">
        <tiny-editor
          :content="content"
          :onChangeContent="onChangeContent"
          :addContent="addContent"
          height="calc(100vh - 154px)"
        />
      </div>
      <v-dialog v-model="modalVariables" max-width="600px">
        <v-card elevation="6" class="pa-6">
          <div v-if="typeModalVariables == 'create'">
            <div class="mb-4">
              <v-btn @click="isNewVariable = !isNewVariable" color="primary">
                <span class="mx-3">{{
                  !isNewVariable ? $t('Add') + ' ' + $t('New') : $t('BACK')
                }}</span>
              </v-btn>
            </div>
            <div v-if="isNewVariable">
              <v-text-field
                v-model="newVariable"
                outlined
                dense
                :label="$t('Variable')"
              />
              <tiny-editor
                :content="newContentVariable"
                :onChangeContent="onChangeNewContentVariable"
                height="200px"
                class="mb-6"
              />
            </div>
            <div v-else>
              <v-select
                v-model="selectedVariable"
                :items="Object.keys(this.metadata)"
                outlined
                dense
                single-line
                :label="$t('Variable')"
              />
              <div class="mb-4">
                {{
                  $t(
                    'ToInsertThisVariableInTheTemplateJustCopyTheFollowingCode'
                  )
                }}:<br />{{ selectedVariableCode }}
                <v-icon
                  @click="copyText(selectedVariableCode)"
                  small
                  color="primary"
                >
                  mdi-content-copy
                </v-icon>
              </div>
              <tiny-editor
                :content="selectedContentVariable"
                :onChangeContent="onChangeSelectedContentVariable"
                height="200px"
                class="mb-6"
              />
            </div>
            <div class="text-right">
              <v-btn
                @click="handleSaveVariables"
                :loading="isLoadingSave"
                :disabled="isLoadingSave"
                color="primary"
              >
                <span class="mx-3">{{ $t('SAVE') }}</span>
              </v-btn>
            </div>
          </div>
          <div v-if="typeModalVariables == 'insert'">
            <label>Selecione o tipo de variável a inserir</label>
            <v-select
              v-model="typeInsert"
              :items="optionsTypeInsert"
              outlined
              dense
              single-line
              :label="$t('Variable')"
            />

            <div v-if="typeInsert == 'thermography'">
              <label>Variáveis de termografia</label>
              <v-select
                v-model="selectedVariableThermography"
                :items="Object.keys(variablesThermographies)"
                outlined
                dense
                single-line
                :label="$t('Variable')"
              />
              <div class="mb-6">
                {{ variablesThermographies[selectedVariableThermography] }}
              </div>
            </div>

            <div v-else-if="typeInsert == 'local'">
              <label>Variáveis locais</label>
              <v-select
                v-model="selectedVariable"
                :items="Object.keys(metadata)"
                outlined
                dense
                single-line
                :label="$t('Variable')"
              />
              <div class="mb-4" v-html="metadata[selectedVariable]" />
            </div>

            <v-btn @click="handleInsertVariable" color="primary">
              <span class="mx-3">{{ $t('INSERIR') }}</span>
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import LoadingScreen from '../components/LoadingScreen'
import TinyEditor from '../components/TinyEditor'
import { reportTemplatesService } from '../services'

export default {
  name: 'ReportTemplateEdit',

  components: { TinyEditor, LoadingScreen },

  data() {
    return {
      content: '',
      addContent: '',
      id: -1,
      name: '',
      variablesThermographies: {
        name: 'Exibe o nome do paciente',
        gender: 'Exibe o sexo do paciente',
        age: 'Exibe a idade do paciente',
        createdAt: 'Exibe a data de criação da termografia',
        dateNow: 'Exibe a data de hoje',
        thermographies: 'Exibe a série de termografias a ser gerada'
      },
      typeInsert: '',
      optionsTypeInsert: [
        {
          value: 'local',
          text: 'Local'
        },
        {
          value: 'thermography',
          text: 'Termografia'
        }
      ],
      metadata: {},
      isLoadingFetch: true,
      isLoadingSave: false,
      selectedVariable: '',
      selectedVariableThermography: '',
      modalVariables: false,
      typeModalVariables: 'create',
      newVariable: '',
      newContentVariable: '',
      isNewVariable: false
    }
  },

  methods: {
    async handleSave() {
      this.isLoadingSave = true
      try {
        const dispatchSave =
          this.id != -1 ? 'updateReportTemplate' : 'saveReportTemplate'

        const datePost = {
          name: this.name,
          content: this.content,
          metadata: this.metadata
        }

        if (this.id != -1) {
          datePost.id = this.id
        }

        await this.$store.dispatch(dispatchSave, datePost)

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ReportTemplateSuccessfullyUpdated')}!`,
          type: 'success',
          model: true
        })
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t(
                  'AnErrorOccurredWhileTryingToUpdateTheReportTemplate'
                )}!`,
          type: 'error',
          model: true
        })
      }
      this.isLoadingSave = false
    },
    openModalVariables(type) {
      this.modalVariables = true
      this.typeModalVariables = type
    },
    async handleSaveVariables() {
      if (!this.isNewVariable) {
        if (this.id != -1) {
          await this.handleSave()
        }
        this.modalVariables = false
        return
      }

      this.metadata[this.newVariable] = this.newContentVariable
      this.selectedVariable = this.newVariable
      this.newVariable = ''
      this.newContentVariable = ''
      this.isNewVariable = false
    },
    onChangeContent(val) {
      this.content = val
    },
    onChangeNewContentVariable(val) {
      this.newContentVariable = val
    },
    onChangeSelectedContentVariable(val) {
      if (Object.keys(this.metadata).includes(this.selectedVariable))
        this.metadata[this.selectedVariable] = val
    },
    copyText(val) {
      navigator.clipboard.writeText(val)
    },
    async handleInsertVariable() {
      if (this.typeInsert == 'local') {
        this.addContent = this.metadata[this.selectedVariable]
      } else if (this.typeInsert == 'thermography') {
        this.addContent = this.selectedVariableThermographyCode
      }

      this.modalVariables = false

      await new Promise((resolve) => setTimeout(resolve, 50))

      this.addContent = ''
    }
  },

  computed: {
    selectedVariableThermographyCode() {
      return '%%' + this.selectedVariableThermography + '%%'
    },
    selectedVariableCode() {
      return '%%variable_' + this.selectedVariable + '%%'
    },
    selectedContentVariable() {
      if (Object.keys(this.metadata).includes(this.selectedVariable))
        return this.metadata[this.selectedVariable]
      return ''
    }
  },

  async mounted() {
    if (this.$route.params.id == undefined) {
      this.isLoadingFetch = false
      return
    }
    try {
      this.id = this.$route.params.id

      const response = await reportTemplatesService.show(this.id)

      this.name = response.data.name
      this.content = response.data.content
      this.metadata = response.data.metadata
      if (Object.keys(this.metadata).length > 0) {
        this.selectedVariable = Object.keys(this.metadata)[0]
      }

      this.isLoadingFetch = false
    } catch (error) {
      this.$store.dispatch('setSnackBar', {
        text:
          error?.response?.data?.message != ''
            ? error.response.data.message
            : `${this.$t('ErrorLoadingReportTemplates')}!`,
        type: 'error',
        model: true
      })

      this.$router.push({ name: 'ReportTemplates' })
    }
  }
}
</script>
