var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.canLoad &&
    _vm.$auth &&
    !_vm.$auth.loading &&
    _vm.$auth.isAuthenticated &&
    _vm.planType != undefined &&
    _vm.planType != 'TRIAL-CANCELED'
  )?_c('v-app',{class:("custom-theme-" + _vm.theme)},[_c('v-main',[_c('toolbar'),_c('sidebar'),_c('router-view',{attrs:{"id":"app-content"}}),_c('snack-bar-fixed'),(_vm.activeGlobalModal)?_c('global-modal'):_vm._e(),_c('tutorial')],1)],1):(_vm.planType == 'TRIAL-CANCELED')?_c('v-app',{class:("custom-theme-" + _vm.theme)},[_c('toolbar'),_c('v-row',{staticClass:"mt-10 pt-10 text-center mb-0"},[_c('v-col',[_c('h2',[_vm._v("Você está com problemas de acesso!")]),_c('p',[_vm._v(" Entre em contato com nossa equipe para mais informações: "),_c('a',{attrs:{"href":"mailto:contato@rad2.ai"}},[_vm._v("contato@rad2.ai")])])])],1)],1):_c('v-app',{class:("custom-theme-" + _vm.theme)},[_c('loading-screen')],1)}
var staticRenderFns = []

export { render, staticRenderFns }