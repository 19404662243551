var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor',{key:_vm.refTinyKey,attrs:{"api-key":"no-api-key","init":{
      height: _vm.height,
      plugins: [
        'advlist',
        'autolink',
        'lists',
        'link',
        'image',
        'charmap',
        'preview',
        'anchor',
        'searchreplace',
        'visualblocks',
        'code',
        'fullscreen',
        'insertdatetime',
        'media',
        'table',
        'help',
        'wordcount'
      ],
      toolbar:
        'undo redo | formatselect | ' +
        'bold italic backcolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help',
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      language: _vm.$i18n.locale
    }},model:{value:(_vm.contentComp),callback:function ($$v) {_vm.contentComp=$$v},expression:"contentComp"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }