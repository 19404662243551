<template>
  <v-col v-if="error">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadStatistics') }} </v-col>
        <v-col class="py-0">
          <v-btn @click="getData" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col v-else-if="loading">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col v-else>
    <v-row class="ma-3" justify="center" align="end">
      <v-col cols="3">
        <label>{{ $t('Filter') }}</label>
        <v-autocomplete
          v-if="subjects.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedSubjects"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="subjects"
          :label="$t('People')"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="groups.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedGroups"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="groups"
          :label="$t('Groups')"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="tags.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedTags"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="tags"
          :label="$t('Tags')"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="tags.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedViews"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="availableViews"
          :label="$t('Vista')"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="clearLoadedThermos"
          color="secundary"
          style="margin-right: 15px"
          >Limpar Seleção</v-btn
        >
      </v-col>
      <v-col cols="2" class="col-cards-images">
        <v-hover
          v-slot="{ hover }"
          v-for="(thermoImg, key) in filteredThermoImgs"
          :key="key"
        >
          <v-card
            :class="[
              'thermo-card',
              isLoadingThermos ? 'opacity-50' : null,
              hover ? 'on-hover' : null
            ]"
            :elevation="hover ? 12 : 6"
            align="center"
            @click="toggleThermoImage(thermoImg)"
          >
            <div
              class="is-selected"
              v-if="selectedThermoImgs.includes(thermoImg.id)"
            >
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </div>
            <div class="div-thermo-img">
              <img :src="thermoImg.src" />
            </div>
            <h5>
              {{ viewsTranslate[thermoImg.view] }}
            </h5>
            <h5>
              {{ formatDate(thermoImg.createdAt, 'dd/MM/yyyy HH:mm') }}
            </h5>
            <h5>
              {{ thermoImg.subjectName }}
            </h5>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="10">
        <v-row v-if="loadedThermos.length">
          <v-col
            :cols="
              loadedThermos.length == 3 && key == 3 ? 12 : colsLoadedThermos
            "
            v-for="(image, key) in loadedThermos"
            :key="image.id"
          >
            <profile-view
              :data="image.thermoImage.data"
              :metrics="image.metrics.data"
              :contours="image.contours"
              :view="image.view"
              :maxHeight="heightLoadedThermos"
              :type="'image'"
              :temps="{
                min: themeTypeTemps == 'dynamic' ? image.minTemp : themeMinTemp,
                max: themeTypeTemps == 'dynamic' ? image.maxTemp : themeMaxTemp
              }"
              :srcImage="image.src"
              :partsPainSelected="image.pain"
              :flipRegisters="image.flip_registers"
              :segments="image.segments"
              showSemaforica
              :defaultPaletteName="themeDefaultPaletteName"
              :thermoImageId="image.thermoImage.thermographyId"
              :hideControls="true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import LoadingScreen from '../components/LoadingScreen.vue'
import ProfileView from '../components/ProfileView.vue'
import { format } from 'date-fns'
import { reportsService } from '@/services'

export default {
  components: { LoadingScreen, ProfileView },
  data() {
    return {
      isLoadingThermos: false,
      loading: false,
      idxCarousel: 0,
      iCarouselItemsPerPage: 5,
      selectedSubjects: [],
      selectedGroups: [],
      selectedTags: [],
      selectedViews: [],
      thermoImages: [],
      selectedThermoImgs: [],
      loadedThermos: [],
      viewsTranslate: {
        superiorAnterior: 'Superior Anterior',
        superiorPosterior: 'Superior Posterior',
        lateral_right: 'Lateral Direita',
        lateral_left: 'Lateral Esquerda',
        inferiorAnterior: 'Inferior Anterior',
        inferiorPosterior: 'Inferior Posterior'
      },
      availableViews: [
        {
          text: 'Superior Anterior',
          value: 'superiorAnterior'
        },
        {
          text: 'Superior Posterior',
          value: 'superiorPosterior'
        },
        {
          text: 'Lateral Direita',
          value: 'lateral_right'
        },
        {
          text: 'Lateral Esquerda',
          value: 'lateral_left'
        },
        {
          text: 'Inferior Anterior',
          value: 'inferiorAnterior'
        },
        {
          text: 'Inferior Posterior',
          value: 'inferiorPosterior'
        }
      ],
      error: false
    }
  },
  computed: {
    colsLoadedThermos() {
      switch (this.loadedThermos.length) {
        case 1:
          return 12
        case 2:
          return 6
        case 3:
          return 6
        case 4:
          return 6
        default:
          return 12
      }
    },
    heightLoadedThermos() {
      switch (this.loadedThermos.length) {
        case 1:
          return 600
        case 2:
          return 600
        case 3:
          return 300
        case 4:
          return 300
        default:
          return 600
      }
    },
    themeTypeTemps() {
      return window.localStorage.getItem('themeTypeTemps')
    },
    themeDefaultPaletteName() {
      return window.localStorage.getItem('themeDefaultPaletteName')
    },
    themeMinTemp() {
      return window.localStorage.getItem('themeMinTemp')
    },
    themeMaxTemp() {
      return window.localStorage.getItem('themeMaxTemp')
    },
    selectedThermos() {
      const aThermoImgs = this.selectedThermoImgs
      let aThermoIds = this.filteredThermoImgs
        .filter((thermoImg) => {
          return aThermoImgs.includes(thermoImg.id)
        })
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        )
        .map((thermoImg) => {
          return {
            thermographyId: thermoImg.thermographyId,
            id: thermoImg.id
          }
        })

      return aThermoIds
    },
    filteredThermoImgs() {
      const thermos = this.filteredThermos
      return this.thermoImages
        .filter((thermoImg) => {
          for (let iThermo = 0; iThermo < thermos.length; iThermo++) {
            const thermo = thermos[iThermo]
            if (thermo.id == thermoImg.thermographyId) {
              return true
            }
          }

          return false
        })
        .filter((thermoImg) => {
          if (this.selectedViews.length) {
            return this.selectedViews.includes(thermoImg.view)
          }
          return true
        })
    },
    filteredThermos() {
      return this.$store.getters.subjects
        .filter((subject) => {
          if (this.selectedSubjects.length) {
            const existent = this.selectedSubjects.find((selectedSubject) => {
              return selectedSubject == subject.id
            })
            if (!existent) return false
          }

          if (this.selectedGroups.length) {
            const existent = this.selectedGroups.find((group) => {
              return !subject.groups.find((itemGroup) => itemGroup.id === group)
            })
            if (!existent) return false
          }

          if (this.selectedTags.length) {
            const existent = this.selectedTags.find((tag) => {
              let haveTag = false
              for (
                let iThermo = 0;
                iThermo < subject.thermographies.length;
                iThermo++
              ) {
                const thermo = subject.thermographies[iThermo]
                if (thermo.tags.find((itemTag) => itemTag.id === tag)) {
                  haveTag = true
                }
              }
              return !haveTag
            })
            if (!existent) return false
          }
          return true
        })
        .map((subject) => {
          subject.thermographies = subject.thermographies
            .sort(function (v1, v2) {
              if (v1.createdAt > v2.createdAt) {
                return 1
              } else if (v1.createdAt < v2.createdAt) {
                return -1
              }
              return 0
            })
            .filter((thermo) => thermo.status == 'PROCESSED')

          // if (subject.thermographies.length > 3) {
          //   subject.thermographies.length = 3
          // }

          return subject.thermographies
        })
        .flat()
    },
    subjects() {
      return this.$store.getters.subjects.map((subject) => {
        return {
          text: subject.name,
          value: subject.id
        }
      })
    },
    groups() {
      return this.$store.getters.groups.map((item) => {
        return {
          text: `${item.type}: ${item.name}`,
          value: item.id
        }
      })
    },
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    itemsAlerts() {
      return []
    }
  },
  created() {
    this.getData()
  },
  watch: {
    selectedSubjects(val) {
      if (val.length > 0) {
        this.getImagesCarousel()
      }
    },
    async selectedThermoImgs() {
      await this.$nextTick()
      this.loadThermos()
    }
  },
  methods: {
    clearLoadedThermos() {
      this.loadedThermos = []
      this.selectedThermoImgs = []
    },
    async loadThermoImages(thermo) {
      try {
        const response = await reportsService.getSingleThermo(
          thermo.thermographyId,
          {
            qtyLastImages: 0
          }
        )

        const data_images = response.data.thermographyImages
          .filter((image) => {
            if (image.id == thermo.id) {
              return true
            }
            return false
          })
          .map((image) => {
            const contours =
              typeof image?.contours?.data === typeof Object
                ? Object.keys(image.contours.data).filter(
                    (contour) =>
                      !image.contours.data[contour] ||
                      image.contours.data[contour] === 'bg'
                  )
                : []
            for (const contour of contours) {
              delete image.contours.data[contour]
            }
            const metrics =
              typeof image?.metrics?.data === typeof Object
                ? Object.keys(image.metrics.data).filter(
                    (metric) =>
                      !image.metrics.data[metric] ||
                      image.metrics.data[metric] === 'bg'
                  )
                : []
            for (const metric of metrics) {
              delete image.metrics.data[metric]
            }
            return image
          })

        for (
          let idxDataImgs = 0;
          idxDataImgs < data_images.length;
          idxDataImgs++
        ) {
          const data_image = data_images[idxDataImgs]
          this.loadedThermos.push(data_image)
        }
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
          type: 'error',
          model: true
        })
      }
    },
    async loadThermos() {
      const selectedThermos = this.selectedThermos
      const selectedThermosIds = this.selectedThermos.map((thermo) => thermo.id)
      const loadedThermosIds = this.loadedThermos.map((thermo) => thermo.id)
      const canLoadThermoImg = []

      this.isLoadingThermos = true

      for (
        let iSelectedThermo = 0;
        iSelectedThermo < selectedThermos.length;
        iSelectedThermo++
      ) {
        const selectedThermo = selectedThermos[iSelectedThermo]
        if (!loadedThermosIds.includes(selectedThermo.id)) {
          canLoadThermoImg.push(selectedThermo)
        }
      }

      for (
        let iloadedThermo = 0;
        iloadedThermo < this.loadedThermos.length;
        iloadedThermo++
      ) {
        const loadedThermo = this.loadedThermos[iloadedThermo]
        if (!selectedThermosIds.includes(loadedThermo.id)) {
          this.loadedThermos.splice(iloadedThermo, 1)
        }
      }

      for (
        let iThermoIds = 0;
        iThermoIds < canLoadThermoImg.length;
        iThermoIds++
      ) {
        const thermo = canLoadThermoImg[iThermoIds]
        if (thermo.id) {
          await this.loadThermoImages(thermo)
        }
      }

      this.isLoadingThermos = false
    },
    toggleThermoImage(thermoImg) {
      if (this.isLoadingThermos) {
        return
      }
      const idxThermoImage = this.selectedThermoImgs.indexOf(thermoImg.id)

      if (this.selectedThermoImgs.length >= 4 && idxThermoImage === -1) {
        this.selectedThermoImgs.length = 3
        this.selectedThermoImgs.push(thermoImg.id)
        return
      }

      if (idxThermoImage === -1) this.selectedThermoImgs.push(thermoImg.id)
      else this.selectedThermoImgs.splice(idxThermoImage, 1)
    },
    formatDate(date, mask) {
      return format(new Date(date), mask)
    },
    pushDataThermoImgs() {
      const thermoImgs = this.$store.getters.thermoImagesGet
      for (let iThermoImg = 0; iThermoImg < thermoImgs.length; iThermoImg++) {
        const thermoImg = thermoImgs[iThermoImg]
        this.thermoImages.push({
          id: thermoImg.id,
          src: thermoImg.src,
          createdAt: thermoImg.createdAt,
          thermographyId: thermoImg.thermographyId,
          tags: thermoImg.tags,
          view: thermoImg.view,
          subjectName: thermoImg.thermography.subject.name
        })
      }
    },
    async getImagesCarousel() {
      this.thermoImages = []

      await this.$store.dispatch('fetchThermoImages', {
        view: 'superiorPosterior',
        subjectIds: this.selectedSubjects.join(',')
      })
      this.pushDataThermoImgs()
      await this.$store.dispatch('fetchThermoImages', {
        view: 'superiorAnterior',
        subjectIds: this.selectedSubjects.join(',')
      })
      this.pushDataThermoImgs()
      await this.$store.dispatch('fetchThermoImages', {
        view: 'inferiorPosterior',
        subjectIds: this.selectedSubjects.join(',')
      })
      this.pushDataThermoImgs()
      await this.$store.dispatch('fetchThermoImages', {
        view: 'inferiorAnterior',
        subjectIds: this.selectedSubjects.join(',')
      })
      this.pushDataThermoImgs()
      await this.$store.dispatch('fetchThermoImages', {
        view: 'lateral_left',
        subjectIds: this.selectedSubjects.join(',')
      })
      this.pushDataThermoImgs()
      await this.$store.dispatch('fetchThermoImages', {
        view: 'lateral_right',
        subjectIds: this.selectedSubjects.join(',')
      })
      this.pushDataThermoImgs()
    },
    async getData() {
      this.loading = true
      try {
        await Promise.all([
          this.$store.dispatch('fetchSubjects'),
          this.$store.dispatch('fetchGroups'),
          this.$store.dispatch('fetchTags')
        ])
        if (this.subjects.length >= 1) {
          this.selectedSubjects = [this.subjects[0].value]
        }
        if (this.$route.params.peopleid != undefined) {
          this.selectedSubjects = [this.$route.params.peopleid]
        }
        await this.getImagesCarousel()
      } catch (error) {
        console.log('Error: ', error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.h-full {
  height: 100%;
}
.thermo-card {
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  top: 0;
  height: 100%;
  margin: 15px;
  padding-bottom: 10px;
}
.thermo-card.on-hover {
  top: -5px;
}
.thermo-card .is-selected {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.2);
  border-radius: 6px;
  border: 2px solid red;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thermo-card .is-selected .v-icon {
  font-size: 42px;
  color: red;
}
.div-thermo-img img {
  width: 100%;
  height: 100px;
  object-position: center;
  object-fit: contain;
  border-radius: 6px 6px 0 0;
}
.col-cards-images {
  max-height: 600px;
  overflow-y: auto;
}
.opacity-50 {
  opacity: 0.5;
}
</style>
