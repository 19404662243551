<template>
  <div>
    <editor
      api-key="no-api-key"
      :key="refTinyKey"
      :init="{
        height: height,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'help',
          'wordcount'
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        language: $i18n.locale
      }"
      v-model="contentComp"
    />
  </div>
</template>

<script>
/* Import TinyMCE */
import tinymce from 'tinymce'

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default'

/* Required TinyMCE components */
import 'tinymce/themes/silver'
import 'tinymce/models/dom'

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/tinymce-5/skin.css'

/* Import plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'

/* Translations */
import tinyPtBR from '../localesExtra/tinymce/pt_BR.json'
tinymce.addI18n('pt_BR', tinyPtBR)

import Editor from '@tinymce/tinymce-vue'

export default {
  components: { Editor },

  props: {
    content: String,
    addContent: String,
    height: {
      type: String,
      default: '500px'
    },
    onChangeContent: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      contentComp: '',
      refTinyKey: 0
    }
  },

  watch: {
    content(val) {
      this.contentComp = val
    },
    addContent(val) {
      tinymce.activeEditor.execCommand('mceInsertContent', false, val)
    },
    contentComp(val) {
      if (this.onChangeContent !== undefined) {
        this.onChangeContent(val)
      }
    },
    '$i18n.locale'() {
      this.refTinyKey += 1
    }
  },

  mounted() {
    this.contentComp = this.content
    tinymce.init({})
  }
}
</script>
