<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col cols="12" v-else-if="errors.fetch">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col>{{ $t('ErrorLoadingData') }}.</v-col>
        <v-col class="py-0">
          <v-btn @click="fetchAll" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <v-card outlined>
      <v-col id="filters-thermal-diff">
        <v-row class="pa-3" justify="space-between">
          <span>{{ $t('Filters') }}</span>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="startDate"
                  type="date"
                  :label="$t('StartDate')"
                  dense
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="endDate"
                  type="date"
                  :label="$t('EndDate')"
                  dense
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  outlined
                  dense
                  hide-details
                  @change="handleSelectGroup"
                  v-model="filterModel"
                  :items="groups"
                  :label="$t('SelectGroup')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  prepend-inner-icon="mdi-tag"
                  dense
                  hide-details
                  multiple
                  chips
                  v-model="selectedTags"
                  @change="changeTags"
                  :items="tags"
                  auto-select-first
                  small-chips
                  outlined
                  :label="$t('Tags')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedComparType"
                  dense
                  outlined
                  hide-details
                  :label="$t('Method')"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: $t('Mean'), value: 'mean' },
                    { name: $t('Minimum'), value: 'min' },
                    { name: $t('Maximum'), value: 'max' }
                  ]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8">
                <vue-full-body-part-selector
                  :contoursType="true"
                  :selection.sync="selectedComparItem"
                  :disabled="false"
                  :multiple="false"
                  :parts-colour="{}"
                  :body-colour="painColors[0]"
                  :selection-colour="painColors[0]"
                  :viewType="'others'"
                  :contourJson="fullBody"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <div id="content-thermal-diff">
              <v-card outlined class="mt-3" v-if="charts.length > 0">
                <v-col>
                  <span class="text-body-2"
                    >{{ $t('Summary') }} - {{ $t('TotalPeople') }}:
                  </span>
                  <v-card flat style="width: 300px">
                    <apexchart
                      type="donut"
                      :series="generalChart.series"
                      :options="generalChart.options"
                    ></apexchart>
                  </v-card>
                </v-col>
              </v-card>
              <v-col v-if="loadings.report">
                <v-row justify="center" class="mt-10">
                  <loading-screen />
                </v-row>
              </v-col>
              <v-col v-else-if="charts.length === 0">
                <v-row class="mt-3" justify="center">
                  <span class="text--secondary text-body-2">{{
                    $t('NoItemsFoundWithTheSpecifiedFilters')
                  }}</span>
                </v-row>
              </v-col>
              <v-col class="px-2 py-3 mt-3" v-else>
                <v-row v-if="infoType === 'Gráfico'">
                  <v-card
                    outlined
                    flat
                    :class="$vuetify.breakpoint.xsOnly ? 'my-1' : 'ma-1'"
                    :style="
                      $vuetify.breakpoint.xsOnly
                        ? 'width: 100%'
                        : 'width: 300px'
                    "
                    v-for="item in charts"
                    :key="item.id"
                  >
                    <span class="text-caption pl-3">{{ item.name }}</span>
                    <div style="width: 300px; display: flex">
                      <apexchart
                        type="donut"
                        width="150"
                        inline
                        :series="item.series"
                        :options="item.options"
                      ></apexchart>
                      <apexchart
                        type="donut"
                        width="150"
                        inline
                        :series="item.painDatas.series"
                        :options="item.painDatas.options"
                      ></apexchart>
                    </div>
                  </v-card>
                </v-row>
                <v-row v-else>
                  <div
                    style="width: 300px; padding: 7px"
                    v-for="item in charts"
                    :key="item.id"
                  >
                    <img src="@/assets/bernardo_semaforo.png" alt="" />
                  </div>
                </v-row>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import { reportsService } from '@/services'
import translateDict from '@/translations/dict.json'
import LoadingScreen from '../components/LoadingScreen.vue'
import DataProfileView from '@/assets/arr-0-320-240.json'
import VueFullBodyPartSelector from '../components/VueFullBodyPartSelector.vue'
import ContourSuperiorInferiorPosterior from '@/assets/contour-superiorInferiorPosterior.json'
import ContourSuperiorInferiorAnterior from '@/assets/contour-superiorInferiorAnterior.json'

export default {
  components: { LoadingScreen, VueFullBodyPartSelector },
  data: () => ({
    selectedTags: [],
    startDate: '',
    endDate: '',
    DataProfileView,
    painColors: {
      0: '#C1EAFF',
      1: '#ABD7FF',
      2: '#7BB1EE',
      3: '#1A63B1',
      4: '#064890',
      5: '#222C51',
      6: '#451515',
      7: '#65130F',
      8: '#8A1713',
      9: '#CA1D0F',
      10: '#FD2000'
    },
    fullBody: {
      superiorInferiorPosterior: ContourSuperiorInferiorPosterior,
      superiorInferiorAnterior: ContourSuperiorInferiorAnterior
    },
    view: '',
    selectedContours: [],
    filterModel: null,
    infoType: 'Gráfico',
    subjects: [],
    selectedComparItem: null,
    selectedComparType: 'mean',
    searchKeys: {
      Ombro: 'shoulder_left',
      Braço: 'arm'
    },
    loadings: {
      fetch: false,
      report: false
    },
    errors: {
      fetch: false,
      report: false
    }
  }),
  async mounted() {
    await this.fetchAll()
  },
  watch: {
    startDate() {
      this.changeTags()
    },
    endDate() {
      this.changeTags()
    }
  },
  methods: {
    changeTags() {
      if (this.filterModel != null && this.filterModel != '') {
        this.handleSelectGroup()
      }
    },
    onChangeSelected(val) {
      if (val.length > 0) {
        this.selectedComparItem = val[val.length - 1]
        this.selectedContours = [val[val.length - 1]]
      } else {
        this.selectedComparItem = ''
        this.selectedContours = []
      }
    },
    async fetchGroups() {
      await this.$store.dispatch('fetchGroups')
    },
    async fetchAll() {
      this.loadings.fetch = true
      try {
        if (this.$store.getters.groups.length === 0) {
          await this.fetchGroups()
        }
        if (this.$store.getters.tags.length === 0) {
          await this.$store.dispatch('fetchTags')
        }
        this.errors.fetch = false
      } catch (e) {
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    },
    async handleSelectGroup() {
      this.loadings.report = true
      try {
        const response = await reportsService.getGroupThermos(
          this.filterModel,
          this.selectedTags.join(','),
          this.startDate,
          this.endDate
        )
        this.subjects = response.data
        this.errors.report = false
      } catch (error) {
        console.log(error)
        this.errors.report = true
      }
      this.loadings.report = false
    }
  },
  computed: {
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    filterItems() {
      return Object.keys(translateDict).map((item) => ({
        name: translateDict[item].full,
        value: item
      }))
    },
    charts() {
      if (!this.selectedComparType || !this.selectedComparItem) return []
      const formattedSubjects = []
      for (let iSubject = 0; iSubject < this.subjects.length; iSubject++) {
        const item = this.subjects[iSubject]

        const thermographyImages = item.thermographyImages
        data = {
          red: 0,
          yellow: 0,
          green: 0
        }
        for (const thermo of thermographyImages) {
          const itemName = this.selectedComparItem
          if (!itemName) continue
          if (thermo && thermo[itemName]) {
            const comparValue =
              thermo.pain && thermo.pain[itemName] ? thermo.pain[itemName] : 0
            if (comparValue > 7) data.red++
            else if (comparValue > 4) data.yellow++
            else data.green++
          }
        }
        let painSubject = {
          id: item.id,
          name: item.name + ' - ' + this.$t('Pain'),
          data
        }
        let data = {
          red: 0,
          yellow: 0,
          green: 0
        }
        for (const thermo of thermographyImages) {
          const itemName = this.selectedComparItem
          if (!itemName) continue
          const comparType = this.selectedComparType
          if (thermo && thermo[itemName]) {
            const comparValue = Math.abs(thermo[itemName][comparType])
            if (comparValue > 1) data.red++
            else if (comparValue > 0.3) data.yellow++
            else data.green++
          }
        }
        formattedSubjects.push({
          id: item.id,
          name: item.name,
          pain: painSubject,
          data
        })
      }

      return formattedSubjects.map((item) => ({
        id: item.id,
        name: item.name,
        series: [item.data.red, item.data.yellow, item.data.green],
        options: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    fontSize: '15px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    color: '#000000',
                    offsetY: -10,
                    formatter: () =>
                      item.data.red + item.data.green + item.data.yellow
                  }
                }
              }
            }
          },
          colors: ['#B00020', '#FB8C00', '#4CAF50'],
          labels: [this.$t('High'), this.$t('Medium'), this.$t('Low')],
          legend: false
        },
        painDatas: {
          id: 'pain' + item.pain.id,
          name: '',
          series: [
            item.pain.data.red,
            item.pain.data.yellow,
            item.pain.data.green
          ],
          options: {
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      fontSize: '15px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      color: '#000000',
                      offsetY: -10,
                      formatter: () =>
                        item.pain.data.red +
                        item.pain.data.green +
                        item.pain.data.yellow
                    }
                  }
                }
              }
            },
            colors: ['#B00020', '#FB8C00', '#4CAF50'],
            labels: [this.$t('High'), this.$t('Medium'), this.$t('Low')],
            legend: false
          }
        }
      }))
    },
    generalChart() {
      const data = {
        red: 0,
        yellow: 0,
        green: 0
      }
      this.charts.forEach((item) => {
        const [red, yellow, green] = item.series
        if (red === 0 && yellow === 0 && green === 0) return
        if (green > red && green > yellow) data.green++
        else if (yellow > red && yellow > green) data.yellow++
        else data.red++
      })
      return {
        id: 'generalChart',
        series: [data.red, data.yellow, data.green],
        options: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    fontSize: '22px',
                    color: '#000000',
                    offsetY: -10,
                    formatter: () => data.red + data.green + data.yellow
                  }
                }
              }
            }
          },
          dataLabels: {
            textAnchor: 'start',
            style: {
              fontSize: '12px',
              colors: ['#333']
            },
            offsetX: 150,
            dropShadow: {
              enabled: false
            },
            background: {
              enabled: true,
              opacity: 0.3,
              foreColor: '#fff',
              borderRadius: 1,
              padding: 4
            },
            position: 'top'
          },
          colors: ['#B00020', '#FB8C00', '#4CAF50'],
          labels: [this.$t('High'), this.$t('Medium'), this.$t('Low')]
        }
      }
    },
    groups() {
      return this.$store.getters.groups.map((group) => ({
        text: `${group.type}: ${group.name}`,
        value: group.id
      }))
    },
    allViews() {
      return this.$t('viewsOptsArr').filter((view) => {
        return view.value !== 'others'
      })
    }
  }
}
</script>

<style></style>
