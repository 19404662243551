<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col cols="12" v-else-if="errors.fetch">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col>{{ $t('ErrorLoadingReportTemplates') }}.</v-col>
        <v-col class="py-0">
          <v-btn @click="fetchAll" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <v-card id="filters-report-templates" outlined>
      <v-col>
        <v-row class="pa-3" justify="space-between">
          <span class="text-caption text--secondary">{{ $t('Filters') }}</span>
          <v-btn :to="{ name: 'ReportTemplatesNew' }" rounded color="primary"
            ><v-icon>mdi-plus</v-icon>{{ $t('NEWREPORTTEMPLATE') }}</v-btn
          >
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="search"
              :placeholder="`${$t('SearchName')}...`"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
    <v-card outlined class="mt-6">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="filteredItems"
        must-sort
        outlined
        :items-per-page="items.length"
        :no-data-text="`${$t('NoRecord')} :(`"
        mobile-breakpoint="1"
        id="table-data-report-templates"
      >
        <template v-slot:item="{ headers, item }">
          <v-hover v-slot="{ hover }">
            <tr>
              <td
                v-for="itemKey in headers"
                :style="
                  itemKey.value === 'actions' || itemKey.value === 'inserted_at'
                    ? 'width: 150px'
                    : ''
                "
                :key="itemKey.value"
              >
                <template v-if="itemKey.value === 'actions'">
                  <template v-if="hover">
                    <v-btn
                      icon
                      small
                      color="primary"
                      :to="{
                        name: 'ReportTemplatesEdit',
                        params: { id: item.id }
                      }"
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      small
                      :loading="loadings.remove"
                      :disabled="loadings.remove"
                      class="ml-2"
                      color="red"
                      @click="handleOpenRemoveModal(item)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </template>
                <template v-else>
                  {{ item[itemKey.value] }}
                </template>
              </td>
            </tr>
          </v-hover>
        </template>
      </v-data-table>
      <v-dialog v-model="removeModal.open" max-width="400px">
        <RemoveFormModal
          :title="`${$t('RemoveReportTemplate')}?`"
          :onCloseCallback="() => (removeModal.open = false)"
          :onRemoveCallback="() => handleRemoveItem(removeModal.item)"
          :isLoading="loadings.remove"
        />
      </v-dialog>
    </v-card>
  </v-col>
</template>

<script>
import RemoveFormModal from '../components/RemoveFormModal.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  components: {
    RemoveFormModal,
    LoadingScreen
  },
  data() {
    return {
      search: '',
      loadings: {
        fetch: false,
        remove: false
      },
      removeModal: {
        open: false,
        item: null
      },
      errors: {
        fetch: false
      }
    }
  },
  methods: {
    async fetchReportTemplates() {
      await this.$store.dispatch('fetchReportTemplates')
    },
    async handleRemoveItem(item) {
      this.loadings.remove = true
      try {
        await this.$store.dispatch('removeReportTemplate', item.id)
        this.removeModal.open = false
        this.removeModal.item = null

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ReportTemplateRemovedSuccessfully')}!`,
          type: 'success',
          model: true
        })
      } catch (e) {
        console.log(e)

        this.$store.dispatch('setSnackBar', {
          text:
            e?.response?.data?.message != ''
              ? e.response.data.message
              : `${this.$t('ErrorTryingToRemoveReportTemplate')}!`,
          type: 'error',
          model: true
        })
      }
      this.loadings.remove = undefined
    },
    handleOpenRemoveModal(item) {
      this.removeModal.item = item
      this.removeModal.open = true
    },
    async fetchAll() {
      this.loadings.fetch = true
      try {
        await Promise.all([this.fetchReportTemplates()])
        this.errors.fetch = false
      } catch (e) {
        console.log('error', e)
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    }
  },
  async mounted() {
    await this.fetchAll()
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    items() {
      return this.$store.getters.reportTemplates
    },
    headers() {
      return [
        { text: this.$t('Name'), value: 'name', width: '180px' },
        { text: this.$t('Actions'), value: 'actions', width: '180px' }
      ]
    }
  }
}
</script>

<style></style>
