<template>
  <div>
    <loading-screen v-if="isLoading || isLoadingContent" />
    <h3 class="text-center mt-4" v-else-if="images.length <= 0">
      {{ $t('UnexpectedErrorWhileProcessingThermography') }}
    </h3>
    <div class="d-flex" v-else>
      <div class="report-tiny-editor">
        <tiny-editor
          :content="content"
          :onChangeContent="onChangeContent"
          :addContent="addContent"
          height="calc(100vh - 65px)"
        />
      </div>
      <div class="menu-report-tiny-editor">
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(tool, i) in menuTools"
              :key="i"
              @click="handleMenuToolClick(tool)"
            >
              <v-list-item-icon>
                <v-icon v-text="tool.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-html="$t(tool.text)"> </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>

    <v-dialog
      v-model="showModal"
      :width="
        toolAction == 'addThermographyDelta' ||
        toolAction == 'addThermographiesWithDelta'
          ? '1200px'
          : '600px'
      "
    >
      <v-card elevation="12" class="pa-4">
        <div
          v-if="
            thermography &&
            (toolAction == 'addThermographyDelta' ||
              toolAction == 'addThermographiesWithDelta')
          "
        >
          <v-row>
            <v-col cols="12">
              <label
                >Selecione duas termografias com mesma vista para formar o
                Delta.</label
              >
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="thermographyId"
                :items="optionsImages"
                label="Selecione o periodo"
              />

              <div v-if="isLoadingThermographies">
                <loading-screen-small />
              </div>
              <div
                v-else-if="
                  thermography &&
                  !isLoadingThermographies &&
                  stepDelta == 'selectThermos'
                "
              >
                <v-carousel
                  v-model="idxThermoDelta1"
                  hide-delimiter-background
                  height="400"
                  class="carousel-profile-view"
                >
                  <v-carousel-item
                    v-for="image in images"
                    :key="'thermo-to-delta-modal-1-' + image.id"
                    :onChangeSelected="() => onChangeSelection([])"
                  >
                    <div class="carousel-image">
                      <profile-view
                        v-if="image"
                        :refCanvasKelvin="`canvasKelvinReportDelta${image.id}`"
                        :printCanvas="isAddingThermographyImage"
                        :data="image.thermoImage.data"
                        :topLeftLabel="`${image.labelView}`"
                        :metrics="image.metrics.data"
                        :contours="image.contours"
                        :view="image.view"
                        :maxHeight="400"
                        :type="'image'"
                        :temps="{
                          min:
                            themeTypeTemps == 'dynamic'
                              ? image.minTemp
                              : themeMinTemp,
                          max:
                            themeTypeTemps == 'dynamic'
                              ? image.maxTemp
                              : themeMaxTemp
                        }"
                        :srcImage="image.src"
                        activateEditDrawings
                        :flipRegisters="image.flip_registers"
                        :segments="image.segments"
                        showSemaforica
                        :defaultPaletteName="themeDefaultPaletteName"
                        :thermoImageId="image.thermoImage.thermographyId"
                        :hideControls="true"
                      />
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="thermographyIdDelta2"
                :items="optionsImages"
                label="Selecione o periodo"
              />

              <div v-if="isLoadingThermographiesDelta2">
                <loading-screen-small />
              </div>
              <div
                v-else-if="
                  thermography &&
                  !isLoadingThermographiesDelta2 &&
                  stepDelta == 'selectThermos'
                "
              >
                <v-carousel
                  v-model="idxThermoDelta2"
                  hide-delimiter-background
                  height="400"
                  class="carousel-profile-view"
                >
                  <v-carousel-item
                    v-for="image in imagesDelta2"
                    :key="'thermo-to-delta-modal-2-' + image.id"
                    :onChangeSelected="() => onChangeSelection([])"
                  >
                    <div class="carousel-image">
                      <profile-view
                        v-if="image"
                        :refCanvasKelvin="`canvasKelvinReportDelta${image.id}`"
                        :printCanvas="isAddingThermographyImage"
                        :data="image.thermoImage.data"
                        :topLeftLabel="`${image.labelView}`"
                        :metrics="image.metrics.data"
                        :contours="image.contours"
                        :view="image.view"
                        :maxHeight="400"
                        :type="'image'"
                        :temps="{
                          min:
                            themeTypeTemps == 'dynamic'
                              ? image.minTemp
                              : themeMinTemp,
                          max:
                            themeTypeTemps == 'dynamic'
                              ? image.maxTemp
                              : themeMaxTemp
                        }"
                        :srcImage="image.src"
                        activateEditDrawings
                        :flipRegisters="image.flip_registers"
                        :segments="image.segments"
                        showSemaforica
                        :defaultPaletteName="themeDefaultPaletteName"
                        :thermoImageId="image.thermoImage.thermographyId"
                        :hideControls="true"
                      />
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>

            <v-col cols="12">
              <div v-if="imagesDelta2.length > 0 && stepDelta == 'viewDelta'">
                <div class="carousel-image">
                  <profile-view
                    :refCanvasKelvin="`canvasKelvinReportDelta`"
                    :topLeftLabel="`${$t('DELTA')} - ${getLabelView(
                      images[idxThermoDelta1].view
                    )}`"
                    :data="thermoDeltaDatasArr"
                    :metrics="metricsDelta"
                    :contours="images[idxThermoDelta1].contours"
                    :index="0"
                    :indexTwo="1"
                    :view="images[idxThermoDelta1].view"
                    :temps="{
                      min: themeDeltaMinTemp,
                      max: themeDeltaMaxTemp
                    }"
                    defaultPaletteName="Artic"
                    type="deltaTwoThermos"
                    :maxHeight="400"
                    :removeAsymmetryTemps="true"
                    :hideControls="true"
                  />
                </div>
              </div>
            </v-col>

            <v-col>
              <v-btn
                v-if="
                  stepDelta == 'selectThermos' &&
                  !(toolAction == 'addThermographiesWithDelta')
                "
                color="primary"
                class="mb-4 mr-4"
                @click="changeStepDelta('viewDelta')"
                >Avançar</v-btn
              >
              <v-btn
                v-if="
                  stepDelta == 'viewDelta' &&
                  !(toolAction == 'addThermographiesWithDelta')
                "
                color="primary"
                class="mb-4 mr-4"
                @click="changeStepDelta('selectThermos')"
                >Voltar</v-btn
              >
              <v-btn
                v-if="
                  stepDelta == 'viewDelta' ||
                  toolAction == 'addThermographiesWithDelta'
                "
                color="primary"
                class="mb-4 mr-4"
                @click="addThermographyDeltaToContent"
                >Adicionar</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <div
          v-else-if="
            thermography &&
            (toolAction == 'addThermography' ||
              toolAction == 'addThermographyImage')
          "
        >
          <v-select
            v-model="thermographyId"
            :items="optionsImages"
            label="Selecione o periodo"
          />

          <div v-if="isLoadingThermographies">
            <loading-screen-small />
          </div>
          <div v-else-if="thermography && !isLoadingThermographies">
            <v-carousel
              v-model="idxThermo"
              hide-delimiter-background
              height="400"
              class="carousel-profile-view"
            >
              <v-carousel-item
                v-for="image in images"
                :key="'thermo-modal-' + image.id"
                :onChangeSelected="() => onChangeSelection([])"
              >
                <div class="carousel-image">
                  <profile-view
                    v-if="image"
                    :refCanvasKelvin="`canvasKelvinReport${image.id}`"
                    :printCanvas="isAddingThermographyImage"
                    :data="image.thermoImage.data"
                    :topLeftLabel="`${image.labelView}`"
                    :metrics="image.metrics.data"
                    :contours="image.contours"
                    :view="image.view"
                    :maxHeight="400"
                    :type="'image'"
                    :temps="{
                      min:
                        themeTypeTemps == 'dynamic'
                          ? image.minTemp
                          : themeMinTemp,
                      max:
                        themeTypeTemps == 'dynamic'
                          ? image.maxTemp
                          : themeMaxTemp
                    }"
                    :srcImage="image.src"
                    activateEditDrawings
                    :flipRegisters="image.flip_registers"
                    :segments="image.segments"
                    showSemaforica
                    :defaultPaletteName="themeDefaultPaletteName"
                    :thermoImageId="image.thermoImage.thermographyId"
                    :hideControls="true"
                  />
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>
          <v-btn color="primary" class="my-4" @click="addThermographyToContent"
            >Adicionar</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <div
      :style="{
        height: 0,
        width: 0,
        opacity: 0,
        position: 'fixed',
        right: 0,
        bottom: 0
      }"
    >
      <profile-view
        v-for="image in images"
        :key="image.id"
        :refCanvasKelvin="`canvasKelvinReportHidden${image.id}`"
        :printCanvas="true"
        :data="image.thermoImage.data"
        :topLeftLabel="`${image.labelView}`"
        :metrics="image.metrics.data"
        :contours="image.contours"
        :view="image.view"
        :maxHeight="700"
        :type="'image'"
        :temps="{
          min: themeTypeTemps == 'dynamic' ? image.minTemp : themeMinTemp,
          max: themeTypeTemps == 'dynamic' ? image.maxTemp : themeMaxTemp
        }"
        :srcImage="image.src"
        activateEditDrawings
        :flipRegisters="image.flip_registers"
        :segments="image.segments"
        showSemaforica
        :defaultPaletteName="image.palette.replace('Palette', '')"
        :thermoImageId="image.thermoImage.thermographyId"
        :hideControls="true"
      />
    </div>
  </div>
</template>

<script>
import {
  reportsService,
  reportTemplatesService,
  thermoService
} from '@/services'
import TinyEditor from '../components/TinyEditor'
import LoadingScreen from '../components/LoadingScreen'
import LoadingScreenSmall from '../components/LoadingScreenSmall'
import ProfileView from '../components/ProfileView'
import dictSegmentation from '@/translations/dict.json'

export default {
  components: { TinyEditor, LoadingScreen, LoadingScreenSmall, ProfileView },

  data() {
    return {
      optionsImages: [],
      thermographyId: this.$route.params.thermographyId,
      thermographyIdDelta2: this.$route.params.thermographyId,
      stepDelta: 'selectThermos',
      isLoadingThermographies: false,
      isLoadingThermographiesDelta2: false,
      content: '',
      addContent: '',
      fixedTags: {
        name: '%%name%%',
        gender: '%%gender%%',
        age: '%%age%%',
        createdAt: '%%createdAt%%',
        dateNow: '%%dateNow%%',
        thermographies: '%%thermographies%%'
      },

      isLoading: true,
      isLoadingContent: true,
      images: [],
      imagesDelta2: [],
      name: '',
      gender: '',
      age: '',
      dateNow: new Date().toLocaleDateString(),
      createdAt: '',
      toolAction: 'addThermography',
      menuTools: [
        {
          icon: 'mdi-image-plus',
          text: 'Adicionar termografia',
          action: 'addThermography'
        },
        {
          icon: 'mdi-image-plus',
          text: 'Adicionar imagem termográfica',
          action: 'addThermographyImage'
        },
        {
          icon: 'mdi-image-plus',
          text: 'Adicionar termografias delta',
          action: 'addThermographiesWithDelta'
        },
        {
          icon: 'mdi-content-save',
          text: 'Salvar',
          action: 'saveThermographyReport'
        }
        // {
        //   icon: 'mdi-image-plus',
        //   text: 'Adicionar delta',
        //   action: 'addThermographyDelta'
        // }
      ],
      idxThermo: 0,
      idxThermoDelta1: 0,
      idxThermoDelta2: 0,
      showModal: false,
      isAddingThermographyImage: false,
      thermoMetadata: null,
      nameThermo: '',
      tagsThermo: []
    }
  },

  methods: {
    changeStepDelta: function (val) {
      if (
        this.images[this.idxThermoDelta1].view !=
        this.imagesDelta2[this.idxThermoDelta2].view
      ) {
        this.$store.dispatch('setSnackBar', {
          text: `Você deve selecionar duas termografias com vistas iguais!`,
          type: 'error',
          model: true
        })
        return
      }
      this.stepDelta = val
    },
    setOptionsImages: function () {
      if (this.images.length <= 0) return []

      const optionsImages = [
        {
          text:
            this.name +
            ' - ' +
            new Date(this.images[0].createdAt).toLocaleString().toString(),
          value: this.$route.params.thermographyId
        }
      ]

      this.images.forEach((image) => {
        image.lastImages.forEach((lastImage) => {
          optionsImages.push({
            text:
              this.name +
              ' - ' +
              new Date(lastImage.createdAt).toLocaleString().toString(),
            value: lastImage.thermographyId
          })
        })
      })

      this.optionsImages = optionsImages
    },
    async waitCanvasLoad(selector) {
      let canvasSize = document.querySelector(selector).toDataURL().length
      while (canvasSize <= 15000) {
        await new Promise((resolve) => setTimeout(resolve, 300))
        canvasSize = document.querySelector(selector).toDataURL().length
      }
      return true
    },
    getAge(dateString) {
      var today = new Date()
      var birthDate = new Date(dateString)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    async addThermographyToContent() {
      this.isAddingThermographyImage = true
      await new Promise((resolve) => setTimeout(resolve, 50))
      if (this.toolAction == 'addThermography') {
        this.addContent = this.tableHtmlStringThermo(
          document
            .querySelector(`#canvasKelvinReport${this.thermography.id} canvas`)
            .toDataURL(),
          this.thermography
        )
      } else if (this.toolAction == 'addThermographyImage') {
        this.addContent = `<img src="${document
          .querySelector(`#canvasKelvinReport${this.thermography.id} canvas`)
          .toDataURL()}" style="width: auto; height: 400px;" />`
      }
      this.isAddingThermographyImage = false
      await new Promise((resolve) => setTimeout(resolve, 50))
      this.addContent = ''
      this.showModal = false
    },
    async addThermographyDeltaToContent() {
      if (
        this.images[this.idxThermoDelta1].view !=
        this.imagesDelta2[this.idxThermoDelta2].view
      ) {
        this.$store.dispatch('setSnackBar', {
          text: `Você deve selecionar duas termografias com vistas iguais!`,
          type: 'error',
          model: true
        })
        return
      }

      const thermography = JSON.parse(
        JSON.stringify(this.images[this.idxThermoDelta1])
      )
      thermography.metrics.data = this.metricsDelta
      this.isAddingThermographyImage = true
      await new Promise((resolve) => setTimeout(resolve, 50))
      if (this.toolAction == 'addThermographiesWithDelta') {
        this.addContent = await this.contentToAddDelta()
      } else {
        this.addContent = this.tableHtmlStringThermo(
          document.querySelector(`#canvasKelvinReportDelta canvas`).toDataURL(),
          thermography
        )
      }
      this.isAddingThermographyImage = false
      await new Promise((resolve) => setTimeout(resolve, 50))
      this.addContent = ''
      this.showModal = false
    },
    handleMenuToolClick(tool) {
      if (tool.action == 'saveThermographyReport') {
        this.saveThermographyReport()
        return
      }
      this.toolAction = tool.action
      this.showModal = true
    },
    async saveThermographyReport() {
      this.$store.dispatch('setSnackBar', {
        text: `Salvando relatório...`,
        type: 'success',
        model: true
      })

      try {
        await thermoService.updateThermo(this.$route.params.thermographyId, {
          metadata: this.thermoMetadata,
          name: this.nameThermo,
          tags: this.tagsThermo
        })

        this.$store.dispatch('setSnackBar', {
          text: `Relatório salvo com sucesso!`,
          type: 'success',
          model: true
        })
      } catch (e) {
        console.log(e)

        this.$store.dispatch('setSnackBar', {
          text: `Erro ao tentar salvar o relatório!`,
          type: 'error',
          model: true
        })
      }
    },
    onChangeContent(val) {
      this.content = val

      if (this.thermoMetadata == null) this.thermoMetadata = {}
      this.thermoMetadata.report = val
    },
    getLabelView(valueView) {
      let labelVista = valueView

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },

    async fetchTemplate() {
      if (this.$route.params.reportTemplateId == null) return
      try {
        const response = await reportTemplatesService.show(
          this.$route.params.reportTemplateId
        )
        this.content = response.data.content
      } catch (error) {
        console.log(error)

        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('ErrorLoadingReportTemplate')}!`,
          type: 'error',
          model: true
        })
      }
    },

    async fetchImages(thermographyId, imageKey) {
      if (this[imageKey].length === 0) this.isLoading = true
      try {
        const response = await reportsService.getSingleThermo(thermographyId)
        this.name = response.data.subject.name
        this.tagsThermo = response.data.tags.map((tag) => tag.id)
        this.nameThermo = response.data.name
        this.gender = response.data.subject.gender
        this.thermoMetadata = response.data.metadata
        this.age = this.getAge(response.data.subject.birthDate).toString()
        this.createdAt = new Date(response.data.createdAt).toLocaleDateString()

        if (
          response.data.metadata != null &&
          response.data.metadata.report != null
        ) {
          this.content = response.data.metadata.report
        }

        const data_images = response.data.thermographyImages.map((image) => {
          if (image.status === 'PROCESSED') {
            const contours =
              typeof image?.contours?.data === typeof Object
                ? Object.keys(image.contours.data).filter(
                    (contour) =>
                      !image.contours.data[contour] ||
                      image.contours.data[contour] === 'bg'
                  )
                : []
            for (const contour of contours) {
              delete image.contours.data[contour]
            }
            const metrics =
              typeof image?.metrics?.data === typeof Object
                ? Object.keys(image.metrics.data).filter(
                    (metric) =>
                      !image.metrics.data[metric] ||
                      image.metrics.data[metric] === 'bg'
                  )
                : []
            for (const metric of metrics) {
              delete image.metrics.data[metric]
            }

            return {
              id: image.id,
              src: image.src,
              status: image.status,
              asymmetries: image?.asymmetries || {},
              lastImages: image.lastImages,
              view: image.view,
              labelView: this.getLabelView(image.view),
              maxTemp: image.maxTemp,
              minTemp: image.minTemp,
              palette: image.palette,
              thermoImage: image.thermoImage,
              flip_registers: image?.flip_registers || {},
              segments: image?.segments || {},
              contours: {
                ...image.contours
              },
              metrics: image.metrics || {
                data: {}
              },
              createdAt: image.createdAt,
              showModal: false
            }
          }
          return image
        })
        this[imageKey] = data_images

        if (this.optionsImages.length <= 0) this.setOptionsImages()

        if (
          response.data.status === 'CREATED' ||
          response.data.status === 'PROCESSING' ||
          response.data.status === 'QUEUE'
        ) {
          this.$store.dispatch('setSnackBar', {
            text: `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
            type: 'error',
            model: true
          })
        }
      } catch (error) {
        console.log(error)

        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
          type: 'error',
          model: true
        })
      } finally {
        this.isLoading = false
      }
    },

    tableHtmlStringThermo(imageSrc, thermography, type = 'thermography') {
      const metrics = thermography.metrics.data
      const cssBorder = 'border: 1px solid black; border-collapse: collapse;'
      let stringHtmlTemperatures = ''

      Object.keys(metrics).forEach((metricKey) => {
        if (metricKey.includes('left')) {
          const tdAsymmetry = `
            <td style="${cssBorder}" align="center">
              ${(
                metrics[metricKey.replace('left', 'right')].mean -
                metrics[metricKey].mean
              ).toFixed(2)}
            </td>
          `
          stringHtmlTemperatures += `
            <tr>
              <td style="${cssBorder}" align="center">
                ${dictSegmentation[metricKey].full.replace(' Esquerdo', '')}
              </td>
              <td style="${cssBorder}" align="center">
                ${metrics[metricKey.replace('left', 'right')].mean.toFixed(2)}
              </td>
              <td style="${cssBorder}" align="center">
                ${metrics[metricKey].mean.toFixed(2)}
              </td>
              ${type == 'delta' ? tdAsymmetry : ''}
            </tr>
          `
        }
      })

      const colspan = type == 'delta' ? 4 : 3
      const tdAsymmetryHeader = `
        <td style="${cssBorder}" align="center">
          <b>ASSIMETRIA</b>
        </td>
      `

      let stringHtml = `
        <table style="width: 100%;${cssBorder} margin-bottom: 8px;">
          <tr>
            <td colspan="2" style="${cssBorder}" align="center">
              <img src="${imageSrc}" style="width: auto; height: 400px;" />
            </td>
          </tr>
          <tr>
            <td style="${cssBorder}" align="center">
              <span>
                ${this.getLabelView(thermography.view)}
              </span>
            </td>
            <td style="${cssBorder}" align="center">
              <span>${new Date(thermography.createdAt)
                .toLocaleDateString()
                .toString()}</span>
            </td>
          </tr>
        </table>
        <table style="width: 100%;${cssBorder}">
          <tr>
            <td colspan="${colspan}" style="${cssBorder}" align="center">
              <b>TEMPERATURA °C MÉDIA</b>
            </td>
          </tr>
          <tr>
            <td style="${cssBorder}" align="center">
              <b>ÁREA</b>
            </td>
            <td style="${cssBorder}" align="center">
              <b>DIR</b>
            </td>
            <td style="${cssBorder}" align="center">
              <b>ESQ</b>
            </td>
            ${type == 'delta' ? tdAsymmetryHeader : ''}
          </tr>
          ${stringHtmlTemperatures}
        </table>
      `

      return stringHtml
    },

    async replaceThermographies() {
      const cssRow = 'width: 100%; margin-top: 10px; margin-bottom: 10px;'
      let stringHtml = `<table style="${cssRow}">`
      for (let i = 0; i < this.images.length; i++) {
        if (i == 0 || i % 2 == 0) stringHtml += '<tr>'
        stringHtml += '<td style="vertical-align: top; width: 50%;">'

        const canvasSelector = `#canvasKelvinReportHidden${this.images[i].id} canvas`
        await this.waitCanvasLoad(canvasSelector)
        await new Promise((resolve) => setTimeout(resolve, 50))
        stringHtml += this.tableHtmlStringThermo(
          document.querySelector(canvasSelector).toDataURL(),
          this.images[i]
        )
        await new Promise((resolve) => setTimeout(resolve, 50))

        stringHtml += '<p></p></td>'
        if (i == this.images.length - 1 || i % 2 == 1) stringHtml += '</tr>'
      }
      stringHtml += '</table>'

      this.content = this.content.replace(
        this.fixedTags.thermographies,
        stringHtml
      )
    },

    async contentToAddDelta() {
      const thermographyDelta1 = this.images[this.idxThermoDelta1]
      const thermographyDelta2 = this.imagesDelta2[this.idxThermoDelta2]
      const cssRow = 'width: 100%; margin-top: 10px; margin-bottom: 10px;'
      let stringHtml = `
      <table style="${cssRow}">
        <tr>
      `

      await new Promise((resolve) => setTimeout(resolve, 50))
      stringHtml += '<td>'
      stringHtml += this.tableHtmlStringThermo(
        document
          .querySelector(
            `#canvasKelvinReportDelta${thermographyDelta1.id} canvas`
          )
          .toDataURL(),
        this.images[this.idxThermoDelta1],
        'delta'
      )
      stringHtml += '</td>'
      await new Promise((resolve) => setTimeout(resolve, 50))

      await new Promise((resolve) => setTimeout(resolve, 50))
      stringHtml += '<td>'
      stringHtml += this.tableHtmlStringThermo(
        document
          .querySelector(
            `#canvasKelvinReportDelta${thermographyDelta2.id} canvas`
          )
          .toDataURL(),
        this.imagesDelta2[this.idxThermoDelta2],
        'delta'
      )
      stringHtml += '</td>'
      await new Promise((resolve) => setTimeout(resolve, 50))

      stringHtml += `
        </tr>
      </table>
      `

      const cssBorder = 'border: 1px solid black; border-collapse: collapse;'

      let stringHtmlTemperatures = ''

      Object.keys(thermographyDelta1.metrics.data).forEach((metricKey) => {
        if (metricKey.includes('left')) {
          stringHtmlTemperatures += `
            <tr>
              <td style="${cssBorder}" align="center">
                ${dictSegmentation[metricKey].full.replace(' Esquerdo', '')}
              </td>
              <td style="${cssBorder}" align="center">
                ${(
                  thermographyDelta2.metrics.data[
                    metricKey.replace('left', 'right')
                  ].mean -
                  thermographyDelta1.metrics.data[
                    metricKey.replace('left', 'right')
                  ].mean
                ).toFixed(2)}
              </td>
              <td style="${cssBorder}" align="center">
                ${(
                  thermographyDelta2.metrics.data[metricKey].mean -
                  thermographyDelta1.metrics.data[metricKey].mean
                ).toFixed(2)}
              </td>
            </tr>
          `
        }
      })

      stringHtml += `
        <table style="width: 100%;${cssBorder}">
          <tr>
            <td colspan="3" style="${cssBorder}" align="center">
              <b>DELTA</b>
            </td>
          </tr>
          <tr>
            <td style="${cssBorder}" align="center">
              <b>ÁREA</b>
            </td>
            <td style="${cssBorder}" align="center">
              <b>DIR</b>
            </td>
            <td style="${cssBorder}" align="center">
              <b>ESQ</b>
            </td>
          </tr>
          ${stringHtmlTemperatures}
        </table>
      `

      return stringHtml
    },

    async replaceTags() {
      Object.keys(this.fixedTags).forEach((tag) => {
        if (typeof this[tag] == 'string' && this[tag] != '')
          this.content = this.content.replace(this.fixedTags[tag], this[tag])
      })
      await this.replaceThermographies()
    }
  },

  computed: {
    themeTypeTemps() {
      return window.localStorage.getItem('themeTypeTemps')
    },
    themeDefaultPaletteName() {
      return window.localStorage.getItem('themeDefaultPaletteName')
    },
    themeMinTemp() {
      return window.localStorage.getItem('themeMinTemp')
    },
    themeMaxTemp() {
      return window.localStorage.getItem('themeMaxTemp')
    },
    themeScoreZMinTemp() {
      return window.localStorage.getItem('themeScoreZMinTemp')
    },
    themeScoreZMaxTemp() {
      return window.localStorage.getItem('themeScoreZMaxTemp')
    },
    themeDeltaMinTemp() {
      return window.localStorage.getItem('themeDeltaMinTemp')
    },
    themeDeltaMaxTemp() {
      return window.localStorage.getItem('themeDeltaMaxTemp')
    },
    thermography: function () {
      if (this.images.length <= 0) return false

      return this.images[this.idxThermo]
    },
    metricsDelta() {
      const metricsDelta = {}
      console.log(this.images[this.idxThermoDelta1])
      console.log(this.imagesDelta2[this.idxThermoDelta2])
      Object.keys(this.images[this.idxThermoDelta1].metrics.data).forEach(
        (key) => {
          if (
            this.imagesDelta2[this.idxThermoDelta2].metrics.data[key] !=
              undefined &&
            this.images[this.idxThermoDelta1].metrics.data[key] != undefined
          ) {
            metricsDelta[key] = {
              max:
                this.images[this.idxThermoDelta1].metrics.data[key].max -
                this.imagesDelta2[this.idxThermoDelta2].metrics.data[key].max,
              min:
                this.images[this.idxThermoDelta1].metrics.data[key].min -
                this.imagesDelta2[this.idxThermoDelta2].metrics.data[key].min,
              mean:
                this.images[this.idxThermoDelta1].metrics.data[key].mean -
                this.imagesDelta2[this.idxThermoDelta2].metrics.data[key].mean
            }
          }
        }
      )
      return metricsDelta
    },
    thermoDeltaDatasArr: function () {
      return [
        this.images[this.idxThermoDelta1].segments.data,
        this.imagesDelta2[this.idxThermoDelta2].segments.data
      ]
    }
  },

  watch: {
    async thermographyId(val) {
      this.isLoadingThermographies = true
      this.idxThermo = 0
      this.idxThermoDelta1 = 0
      await this.fetchImages(val, 'images')
      this.isLoadingThermographies = false
    },
    async thermographyIdDelta2(val) {
      this.isLoadingThermographiesDelta2 = true
      this.idxThermoDelta2 = 0
      await this.fetchImages(val, 'imagesDelta2')
      this.isLoadingThermographiesDelta2 = false
    }
  },

  async mounted() {
    await Promise.all([
      this.fetchImages(this.$route.params.thermographyId, 'images'),
      this.fetchImages(this.$route.params.thermographyId, 'imagesDelta2')
    ])
    await this.fetchTemplate()
    await this.replaceTags()
    this.isLoadingContent = false
  }
}
</script>

<style>
.carousel-profile-view .v-carousel__controls {
  width: calc(100% - 120px);
  margin-left: 60px;
}
</style>
<style scoped>
.carousel-image {
  width: 100%;
  max-height: 400px;
  height: 100%;
}
.report-tiny-editor {
  width: calc(100% - 300px);
}
.menu-report-tiny-editor {
  width: 300px;
}
</style>
