import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
if (locale === 'en') locale = 'en_US'

export default new VueI18n({
  locale: locale.trim().length && locale != '/' ? locale : 'pt_BR',
  fallbackLocale: 'pt_BR',
  messages: loadLocaleMessages()
})
