<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col cols="12" v-else-if="errors.fetch">
    <span>{{ $t('ErrorSearchingForThermography') }}</span>
  </v-col>
  <v-col cols="12" class="pa-5" v-else>
    <v-row align="center" class="pa-3 mx-0">
      <div class="icon-container mr-3">
        <v-icon color="primary" class="">mdi-camera</v-icon>
      </div>
      <span class="text-h5">{{ $t('Thermography') }}</span>
      <span class="caption ml-3 text--secondary"
        >| {{ $t('EditThermography') }}</span
      >
    </v-row>
    <v-col>
      <v-row align="center" class="pa-2"> </v-row>
      <v-row class="mt-3">
        <v-col cols="12" sm="4" class="py-1">
          <v-row class="ma-0">
            <v-text-field
              prepend-inner-icon="mdi-account"
              outlined
              dense
              disabled
              v-model="subject"
              item-text="label"
              item-value="value"
              autofocus
              :label="$t('Person')"
            />
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" class="py-1">
          <v-autocomplete
            prepend-inner-icon="mdi-tag"
            dense
            multiple
            chips
            v-model="selectedTags"
            :items="tags"
            auto-select-first
            small-chips
            outlined
            :label="$t('Tag')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="py-1">
          <v-text-field
            v-model="name"
            :label="$t('Description')"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="py-1">
          <v-text-field
            v-model="anamnese"
            :label="$t('Anamnese')"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <template v-if="subject">
        <v-row align="center" class="pa-3">
          <span class="text--secondary text-caption pr-2">{{
            $t('Thermographs')
          }}</span>
          <v-divider></v-divider>
        </v-row>
        <v-row class="px-3">
          <v-card
            width="120"
            height="160"
            class="mr-2"
            v-for="(thermoItem, key) in $store.getters.thermoImages"
            :key="thermoItem.view"
            style="position: relative"
          >
            <img class="current-image" :src="thermoItem.imgPreview" />
            <div class="view-overlay">
              <span class="text-body-2 white--text">{{
                getViewName(thermoItem.view)
              }}</span>
              <v-btn icon small>
                <v-icon
                  v-if="key !== loadingRemoveThermoIdx"
                  @click="removeThermoImage(thermoItem, key)"
                  color="white"
                  small
                  >mdi-delete
                </v-icon>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn>
            </div>
          </v-card>

          <v-card
            @click="showAddImage = true"
            width="120"
            height="160"
            class="card-hover"
          >
            <v-row style="height: 100%" justify="center" align="center">
              <v-icon>mdi-plus</v-icon>
            </v-row>
          </v-card>
        </v-row>
        <v-row class="py-3 pt-8" v-if="showAddImage">
          <v-col cols="12" sm="6">
            <input
              type="file"
              @change="handleFileChange"
              ref="inputFile"
              style="display: none"
            />
            <v-card v-if="liveCapture"> </v-card>
            <v-card v-if="liveCapture">
              <live-capture :onChangeImage="handleLiveCapture" />
            </v-card>
            <v-card
              v-else
              :class="[isDroppingFile ? 'blue dropping-file' : null]"
              @click="handleInputFile('click')"
              @dragover.prevent="dragOverFile"
              @dragleave.prevent="dragLeaveFile"
              @drop.prevent="handleInputFile('drop')"
              height="300"
            >
              <img
                class="current-image"
                :src="newThermo.imgPreview"
                v-if="newThermo.img"
              />
              <v-row
                v-else
                style="height: 100%"
                justify="center"
                align="center"
              >
                <v-col align="center">
                  <p><v-icon>mdi-camera-plus</v-icon></p>
                  <p>{{ $t('DragAndDropTheImageOrClickHere') }}.</p>
                </v-col>
              </v-row>
            </v-card>

            <v-row class="pa-3">
              <v-spacer></v-spacer>
              <v-switch label="Live capture" v-model="liveCapture"></v-switch>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <span class="text-caption text--secondary"
              >{{ $t('TemperatureImage') }}:</span
            >
            <v-row class="pa-3">
              <v-text-field
                dense
                style="max-width: calc(50% - 10px)"
                outlined
                prepend-inner-icon="mdi-thermometer-minus"
                :label="$t('Min')"
                type="number"
                v-model="newThermo.minTemp"
              ></v-text-field>
              <v-text-field
                dense
                class="ml-5"
                prepend-inner-icon="mdi-thermometer-plus"
                style="max-width: calc(50% - 10px)"
                outlined
                type="number"
                v-model="newThermo.maxTemp"
                :label="$t('Max')"
              ></v-text-field>
            </v-row>
            <v-select
              :items="palettesKeys"
              class="mt-3"
              v-model="paletteName"
              outlined
              :label="$t('Palette')"
              color="primary"
              dense
            ></v-select>
            <v-select
              prepend-inner-icon="mdi-account-eye"
              class="mt-3"
              :label="$t('Protocol')"
              v-model="newThermo.view"
              dense
              :items="avalibleViews"
              item-text="label"
              item-value="value"
              outlined
            ></v-select>
            <v-row class="pa-3" justify="end">
              <v-btn text @click="showAddImage = false">{{
                $t('Cancel')
              }}</v-btn>
              <v-btn
                outlined
                color="primary"
                @click="handleAddThermoImage"
                :loading="loadings.addThermo"
                :disabled="loadings.addThermo"
                >{{ $t('Add') }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pa-3" justify="end" v-if="!showAddImage">
          <v-btn class="mr-3" text @click="$router.push({ name: 'People' })">{{
            $t('Cancel')
          }}</v-btn>
          <v-btn
            color="primary"
            style="width: 150px"
            @click="handleSendThermo"
            :loading="loadings.sendThermo"
            :disabled="!canSend || loadings.sendThermo"
            >{{ $t('Send') }}</v-btn
          >
        </v-row>
      </template>
    </v-col>
  </v-col>
</template>

<script>
import { reportsService } from '@/services'
import LoadingScreen from '../components/LoadingScreen.vue'
import LiveCapture from '../components/LiveCapture.vue'
import palettes from '@/assets/palettes.json'

export default {
  name: 'ProfileEdit',
  components: { LoadingScreen, LiveCapture },
  data: () => {
    return {
      paletteName: 'RainbowLC',
      createdAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16),
      newThermo: {
        id: -1,
        createdAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16),
        img: null,
        imgPreview: null,
        minTemp: null,
        maxTemp: null,
        paletteName: 'RainbowLC',
        view: ''
      },
      camCapture: null,
      liveCapture: false,
      isDroppingFile: false,
      reponseDataStatus: '',
      errors: {
        fetch: false
      },
      loadings: {
        fetch: false,
        sendThermo: false,
        addThermo: false
      },
      loadingRemoveThermoIdx: -1,
      showAddImage: false,
      images: [],
      name: '',
      anamnese: '',
      selectedTags: [],
      subject: '',
      subjectId: -1
    }
  },

  methods: {
    basename(path) {
      return path.split('/').reverse()[0]
    },
    handleLiveCapture(img) {
      this.newThermo.img = img
      this.newThermo.imgPreview = URL.createObjectURL(img)
    },
    dragOverFile() {
      this.isDroppingFile = true
    },
    dragLeaveFile() {
      this.isDroppingFile = false
    },
    handleInputFile(type) {
      if (type == 'drop') {
        this.isDroppingFile = false
        this.handleFileChange(event)
      } else if (type == 'click') {
        this.$refs.inputFile.click()
      }
    },
    handleFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.newThermo.img = files[0]
      this.newThermo.imgPreview = URL.createObjectURL(files[0])
    },
    getLabelView(valueView) {
      let labelVista = valueView

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },
    getViewName(key) {
      return this.allViews.find((item) => item.value === key)?.label
    },
    async removeThermoImage(thermo, key) {
      this.loadingRemoveThermoIdx = key
      try {
        await this.$store.dispatch('deleteImage', thermo.id)
        this.$store.dispatch('removeThermoImage', key)
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t(
                  'AnErrorOccurredWhileTryingToEditTheThermography'
                )}!`,
          type: 'error',
          model: true
        })
      }
      this.loadingRemoveThermoIdx = -1
    },
    async handleAddThermoImage() {
      this.loadings.addThermo = true
      try {
        await this.$store.dispatch('addImage', {
          ...this.newThermo,
          thermoId: this.$route.params.thermographyId
        })
        await this.fetchImages(true)
        this.newThermo = {
          id: -1,
          createdAt: this.createdAt,
          img: null,
          imgPreview: null,
          minTemp: this.newThermo.minTemp,
          maxTemp: this.newThermo.maxTemp,
          paletteName: this.newThermo.paletteName,
          view: ''
        }
        this.showAddImage = false
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t(
                  'AnErrorOccurredWhileTryingToAddTheThermographicImage'
                )}!`,
          type: 'error',
          model: true
        })
      }
      this.loadings.addThermo = false
    },
    async handleSendThermo() {
      this.loadings.sendThermo = true
      try {
        this.$store.dispatch('updateThermoName', this.name)
        this.$store.dispatch('updateThermoAnamnese', this.anamnese)
        this.$store.dispatch('updateThermoTags', this.selectedTags)
        await this.$store.dispatch('updateThermo', {
          id: this.$route.params.thermographyId
        })

        this.$router.push(`/thermography/${this.$route.params.thermographyId}`)

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ThermographySuccessfullyEdited')}!`,
          type: 'success',
          model: true
        })
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t(
                  'AnErrorOccurredWhileTryingToEditTheThermography'
                )}!`,
          type: 'error',
          model: true
        })

        this.errors.sendThermo = true
      }
      this.loadings.sendThermo = false
    },
    async fetchImages(updateOnlyThermos = false) {
      if (this.images.length === 0) this.loadings.fetch = true
      try {
        const response = await reportsService.getSingleThermo(
          this.$route.params.thermographyId
        )

        if (!updateOnlyThermos) {
          this.subject = response.data.subject.name
          this.subjectId = response.data.subject.id
          this.selectedTags = response.data.tags.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
          this.name = response.data.name
          this.anamnese = response.data.anamnese
          this.createdAt = response.data.createdAt
        } else this.$store.dispatch('clearThermo')

        const data_images = response.data.thermographyImages.map(
          async (image) => {
            const contours =
              typeof image?.contours?.data === typeof Object
                ? Object.keys(image.contours.data).filter(
                    (contour) =>
                      !image.contours.data[contour] ||
                      image.contours.data[contour] === 'bg'
                  )
                : []
            for (const contour of contours) {
              delete image.contours.data[contour]
            }
            const metrics =
              typeof image?.metrics?.data === typeof Object
                ? Object.keys(image.metrics.data).filter(
                    (metric) =>
                      !image.metrics.data[metric] ||
                      image.metrics.data[metric] === 'bg'
                  )
                : []
            for (const metric of metrics) {
              delete image.metrics.data[metric]
            }

            this.$store.dispatch('addThermoImage', {
              id: image.id,
              createdAt: image.createdAt,
              img: image.src,
              imgPreview: image.src,
              minTemp: image.minTemp,
              maxTemp: image.maxTemp,
              paletteName: image.palette.replace('Palette', ''),
              view: image.view
            })

            return {
              id: image.id,
              src: image.src,
              status: image.status,
              asymmetries: image?.asymmetries || {},
              lastImages: image.lastImages,
              view: image.view,
              labelView: this.getLabelView(image.view),
              maxTemp: image.maxTemp,
              minTemp: image.minTemp,
              palette: image.palette,
              thermoImage: image.thermoImage,
              flip_registers: image?.flip_registers || {},
              segments: image?.segments || {},
              contours: {
                ...image.contours
              },
              metrics: image.metrics || {
                data: {}
              },
              createdAt: image.createdAt,
              showModal: false
            }
          }
        )
        this.images = data_images
        this.errors.fetch = false

        this.reponseDataStatus = response.data.status
      } catch (error) {
        this.errors.fetch = true
        this.reponseDataStatus = 'ERROR'

        console.log(error)

        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
          type: 'error',
          model: true
        })
      } finally {
        this.loadings.fetch = false
      }
    }
  },

  computed: {
    palettesKeys() {
      return Object.keys(palettes)
    },
    allViews() {
      return this.$t('viewsOptsArr')
    },
    canAddImage() {
      return (
        !!this.newThermo.img &&
        !!this.newThermo.minTemp &&
        !!this.newThermo.maxTemp &&
        this.newThermo.view !== ''
      )
    },
    canSend() {
      return this.$store.getters.thermoImages.length > 0
    },
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    avalibleViews() {
      return this.allViews.filter(
        (view) =>
          !this.$store.getters.thermoImages.find((img) => {
            return img.view === view.value
          })
      )
    }
  },

  async mounted() {
    this.$store.dispatch('clearThermo')
    this.$store.dispatch('fetchTags')
    await this.fetchImages()
  },

  watch: {
    paletteName() {
      this.newThermo.paletteName = this.paletteName
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  background-color: #f6ebea;
  padding: 4px;
  border-radius: 3px;
}
.card-hover {
  cursor: pointer;
  transition: background 0.5s;
  &:hover {
    background: #f6ebea;
  }
}
.current-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.view-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropping-file {
  opacity: 0.6;
}
</style>
