<template>
  <v-navigation-drawer
    app
    permanent
    clipped
    expand-on-hover
    mini-variant-width="60px"
    width="214"
  >
    <v-list class="v-list-sidebar">
      <v-list-item-group v-model="selectedItem" color="primary" mandatory>
        <v-list-item
          v-for="(item, i) in items"
          :key="`item-${i}`"
          exact
          router
          @click="handleNavigationRoute(item.route)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item disabled class="text--primary">
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Cadastros </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="(item_cad, i) in items_cad"
          :key="`itemCad-${i}`"
          exact
          router
          @click="handleNavigationRoute(item_cad.route)"
        >
          <v-list-item-icon>
            <v-icon> {{ item_cad.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item_cad.text }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-col class="button-thermo">
      <v-row align="center" justify="center">
        <v-btn
          color="primary"
          rounded
          @click="$router.push({ name: 'NewThermo' })"
        >
          <v-icon>mdi-camera</v-icon>
          <span class="pl-2 text-button-thermo">
            {{ $t('NewThermography') }}
          </span>
        </v-btn>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      selectedItem: 0
    }
  },
  mounted() {
    this.selectedItem = this.items.findIndex((item) => {
      return item.route === this.$route.name
    })
  },
  computed: {
    items() {
      return [
        {
          text: 'Home',
          icon: 'mdi-home-circle',
          route: 'Home'
        },
        {
          text: this.$t('AlertToday'),
          icon: 'mdi-alert-circle-outline',
          route: 'AlertsToday'
        },
        {
          text: this.$t('Map'),
          icon: 'mdi-account-multiple-plus',
          route: 'DashboardMap'
        },
        {
          text: this.$t('Tira Teima'),
          icon: 'mdi-account',
          route: 'TiraTeima'
        },
        {
          text: this.$t('Visualization'),
          icon: 'mdi-account',
          route: 'People'
        },
        {
          text: this.$t('Asymmetry'),
          icon: 'mdi-monitor-dashboard',
          route: 'ThermalDiff'
        }
        // {
        //   text: this.$t('Profiles'),
        //   icon: 'mdi-account-box-multiple-outline',
        //   route: 'Profiles'
        // },
        // {
        //   text: this.$t('Report'),
        //   icon: 'mdi-clipboard-text-search-outline'
        //   //route: 'Profiles'
        // }
      ]
    },
    items_cad() {
      return [
        {
          text: this.$t('Groups'),
          icon: 'mdi-layers-triple',
          route: 'Groups'
        },
        {
          text: this.$t('Tags'),
          icon: 'mdi-tag',
          route: 'Tags'
        },
        {
          text: this.$t('Templates'),
          icon: 'mdi-text-box-edit-outline',
          route: 'ReportTemplates'
        },
        // {
        //   text: this.$t('Notes'),
        //   icon: 'mdi-comment-edit-outline'
        //   //route: 'Tags'
        // },
        {
          text: 'Config',
          icon: 'mdi-cog-outline',
          route: 'MenuConfig'
        }
      ]
    }
  },
  methods: {
    handleNavigationRoute(route) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer--mini-variant .button-thermo .text-button-thermo {
  display: none;
}
.v-navigation-drawer--mini-variant .button-thermo .v-btn {
  min-width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
}
.button-thermo {
  position: absolute;
  bottom: 30px;
  font-size: 11px;
  span {
    font-size: 11px;
  }
}
.v-list-sidebar {
  padding-bottom: 66px;
  .v-list-item {
    min-height: 30px !important;
    .v-list-item__icon {
      margin: 8px 12px 8px 0 !important;
    }
    .v-list-item__content {
      padding: 8px 0 !important;
    }
  }
}
.v-navigation-drawer--mini-variant .v-list-item .v-list-item__icon {
  margin-right: 0 !important;
}
</style>
