<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col cols="12" v-else-if="errors.fetch">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadingPeople') }}. </v-col>
        <v-col class="py-0">
          <v-btn @click="fetchAll" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <v-card id="filters-people" outlined>
      <v-col>
        <v-row class="pa-3" justify="space-between">
          <span class="text-caption text--secondary">{{ $t('Filters') }}</span>
          <v-btn to="people-inactives" rounded color="primary">{{
            $t('Inactive')
          }}</v-btn>
          <v-btn @click="handleCreatePeople()" rounded color="primary"
            ><v-icon>mdi-plus</v-icon> {{ $t('NewPerson') }}</v-btn
          >
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="search"
              :placeholder="`${$t('SearchName')}...`"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              style="font-weight: 300"
              outlined
              dense
              chips
              small-chips
              multiple
              v-model="selectedGroups"
              :items="groups"
              :label="$t('Groups')"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
    <v-card outlined class="mt-2">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="filteredItems"
        :sort-by="['name']"
        :sort-desc="[false]"
        must-sort
        outlined
        :items-per-page="-1"
        :no-data-text="`${$t('NoRecord')} :(`"
        mobile-breakpoint="1"
        id="table-data-people"
      >
        <template v-slot:item="{ headers, item }">
          <v-hover v-slot="{ hover }">
            <tr @dblclick="onClickItem(item)">
              <td
                v-for="itemKey in headers"
                :style="
                  itemKey.value === 'actions' || itemKey.value === 'inserted_at'
                    ? 'width: 150px'
                    : ''
                "
                :key="itemKey.value"
              >
                <template v-if="itemKey.value === 'actions'">
                  <v-btn
                    icon
                    small
                    color="blue"
                    class="ml-2"
                    @click="onClickItem(item)"
                  >
                    <v-icon small>mdi-eye</v-icon></v-btn
                  >

                  <template v-if="hover">
                    <v-btn
                      icon
                      small
                      color="primary"
                      class="ml-2"
                      @click="handleEditPeople(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon></v-btn
                    >

                    <v-btn
                      icon
                      small
                      class="ml-2"
                      color="red"
                      :loading="loadings.remove"
                      :disabled="loadings.remove"
                      @click="handleOpenRemoveModal(item)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </template>
                <template v-else-if="itemKey.value === 'name'">
                  <v-row align="center">
                    <v-avatar color="primary" size="36">
                      <img
                        v-if="item.profilePicture !== ''"
                        :src="item.profilePicture"
                        alt="Profile picture"
                      />
                      <span v-else class="white--text text-body-1">
                        {{ item[itemKey.value].charAt(0) }}
                      </span>
                    </v-avatar>
                    <span class="ml-3">
                      {{ item[itemKey.value] }}
                    </span>
                  </v-row>
                </template>
                <template v-else-if="itemKey.value === 'thermographies'">
                  {{
                    item && item.thermographies ? item.thermographies.length : 0
                  }}
                </template>
                <template v-else-if="itemKey.value === 'groups'">
                  <v-chip
                    class="ma-1"
                    x-small
                    v-for="group in item.groups"
                    :key="group.id"
                  >
                    {{ group.type }}: {{ group.name }}
                  </v-chip>
                </template>
                <template v-else>
                  {{ item[itemKey.value] }}
                </template>
              </td>
            </tr>
          </v-hover>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="removeModal.open" max-width="400px">
      <RemoveFormModal
        :title="`${$t('RemovePerson')}?`"
        :onCloseCallback="() => (removeModal.open = false)"
        :onRemoveCallback="() => handleRemoveItem()"
        :isLoading="loadings.remove"
      />
    </v-dialog>
    <v-dialog v-model="createPeopleModal" max-width="550px">
      <CreatePeopleFormModal
        :closeModalCallback="() => (createPeopleModal = false)"
        :type="typeCreatePeopleModal"
        :peopleInfo="editPeople"
        :onErrorCallback="handleOnErrorCreatePeople"
        :onSuccessCallback="handleOnSuccessCreatePeople"
        v-if="createPeopleModal"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import CreatePeopleFormModal from '../components/CreatePeopleFormModal.vue'
import RemoveFormModal from '../components/RemoveFormModal.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  name: 'People',
  components: { CreatePeopleFormModal, RemoveFormModal, LoadingScreen },
  data: function () {
    return {
      search: '',
      createPeopleModal: false,
      typeCreatePeopleModal: 'add',
      selectedGroups: null,
      loadings: {
        fetch: false,
        remove: false
      },
      editPeople: {},
      removeModal: {
        open: false,
        itemId: -1
      },
      errors: {
        fetch: false
      },
      showAddPeople: false
    }
  },
  methods: {
    async fetchSubjects() {
      await this.$store.dispatch('fetchSubjects')
    },
    async fetchGroups() {
      await this.$store.dispatch('fetchGroups')
    },
    async fetchAll() {
      this.loadings.fetch = true
      try {
        await Promise.all([this.fetchSubjects(), this.fetchGroups()])
        this.errors.fetch = false
      } catch (error) {
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    },
    async handleRemoveItem() {
      this.loadings.remove = true
      try {
        await this.$store.dispatch('removeSubject', this.removeModal.itemId)
        this.removeModal.itemId = -1
        this.removeModal.open = false

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('PersonSuccessfullyRemoved')}!`,
          type: 'success',
          model: true
        })
      } catch (e) {
        console.log(e)

        this.$store.dispatch('setSnackBar', {
          text:
            e?.response?.data?.message != ''
              ? e.response.data.message
              : this.$t('ErrorTryingToRemoveThePerson'),
          type: 'error',
          model: true
        })
      }
      this.loadings.remove = undefined
    },
    onClickItem(item) {
      this.$router.push({
        name: 'PeopleThermos',
        params: {
          peopleId: item.id
        }
      })
    },
    handleCreatePeople() {
      this.typeCreatePeopleModal = 'add'
      this.createPeopleModal = true
    },
    handleEditPeople(item) {
      this.editPeople = {
        ...item,
        profilePictureBase64: item.profilePicture,
        birthDate: item.birthDate?.substr(0, 10),
        groups: item.groups?.map((g) => g.id)
      }
      this.typeCreatePeopleModal = 'edit'
      this.createPeopleModal = true
    },
    handleOpenRemoveModal(item) {
      this.removeModal.itemId = item.id
      this.removeModal.open = true
    },
    async handleOnSuccessCreatePeople() {
      const createPeopleSuccess =
        this.typeCreatePeopleModal === 'add'
          ? this.$t('SuccessfullyRegisteredPerson')
          : this.$t('SuccessfullyUpdatedPerson')

      await this.$store.dispatch('fetchSubjects')

      this.$store.dispatch('setSnackBar', {
        text: createPeopleSuccess,
        type: 'success',
        model: true
      })
    },
    handleOnErrorCreatePeople() {
      const createPeopleError =
        this.typeCreatePeopleModal === 'add'
          ? this.$t('ErrorTryingToRegisterThePerson')
          : this.$t('ErrorTryingToUpdatePerson')

      this.$store.dispatch('setSnackBar', {
        text: createPeopleError,
        type: 'error',
        model: true
      })
    }
  },
  async mounted() {
    await this.fetchAll()
  },
  computed: {
    headers() {
      return [
        { text: this.$t('Actions'), value: 'actions', width: '150px' },
        { text: this.$t('Name'), value: 'name', width: '400px' },
        {
          text: this.$t('Series'),
          value: 'thermographies',
          width: '96px'
        },
        { text: this.$t('Groups'), value: 'groups', width: '350px' }
      ]
    },
    filteredItems() {
      return this.items.filter((item) => {
        if (this.selectedGroups) {
          const existent = this.selectedGroups.find((group) => {
            return !item.groups.find((itemGroup) => itemGroup.id === group)
          })
          if (existent) return false
        }
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    groups() {
      return this.$store.getters.groups.map((item) => {
        return {
          text: `${item.type}: ${item.name}`,
          value: item.id
        }
      })
    },
    items() {
      return this.$store.getters.subjects
    }
  }
}
</script>

<style></style>
