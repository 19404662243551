<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-toolbar-title class="text--subtitle">
        <v-icon color="primary" style="padding-bottom: 2px">mdi-delete</v-icon>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon @click="onCloseCallback()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-actions class="pb-6 mt-4">
      <v-spacer></v-spacer>
      <v-btn text class="px-10" @click="onCloseCallback()">
        <span class="mx-3">{{ $t('Cancel') }}</span>
      </v-btn>
      <v-btn
        @click="onRemoveCallback()"
        color="primary"
        class="px-4"
        :loading="isLoading"
        :disabled="isLoading"
      >
        <span class="mx-3">{{ $t('REMOVE') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'RemoveFormModal',
  props: {
    title: String,
    onCloseCallback: Function,
    onRemoveCallback: Function,
    isLoading: Boolean
  }
}
</script>
