import { reportsService } from '@/services'

export default {
  state: {
    stats: {
      success_thermos: 0,
      error_thermos: 0,
      total_thermos: 0,
      groups: 0,
      subjects: []
    }
  },
  mutations: {
    UPDATE_STATS(state, payload) {
      state.stats = payload
    }
  },
  actions: {
    async fetchGeneralStats({ commit }) {
      const response = await reportsService.getGeneralStats()
      commit('UPDATE_STATS', response.data)
    }
  },
  getters: {
    stats(state) {
      return state.stats
    }
  }
}
