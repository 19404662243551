<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center">
      <loading-screen />
    </v-row>
  </v-col>
  <v-card
    v-else-if="
      reponseDataStatus === 'PROCESSING' ||
      reponseDataStatus === 'CREATED' ||
      reponseDataStatus === 'QUEUE'
    "
  >
    <v-col>
      <v-col>
        <span>
          {{
            reponseDataStatus === 'QUEUE'
              ? $t('ProfileReprocessing')
              : $t('ProfileProcessing')
          }}...</span
        >
        <loading-screen-small />
      </v-col>
      <v-col class="pt-0 mt-n3">
        <span class="text-caption text--secondary"
          >{{ $t('ThisCanTakeSomeTime') }}...
        </span>
      </v-col>
    </v-col>
  </v-card>
  <v-col cols="12" v-else-if="errors.fetch">
    <span>{{ $t('ErrorFetchingProfile') }}</span>
  </v-col>
  <v-col cols="12" class="pa-0" v-else-if="selectedView !== ''">
    <v-card flat>
      <v-row align="center" class="pa-3 ma-0 mx-5 mt-2">
        <div class="icon-container mr-3">
          <v-icon color="primary" class="">mdi-account-box-multiple</v-icon>
        </div>
        <span class="text-h5">{{ $t('EditThermographicProfile') }}</span>
        <span class="caption ml-3 text--secondary"
          >| {{ selectedViewLabel }}</span
        >
      </v-row>
    </v-card>
    <v-card class="mt-4">
      <v-row class="mx-5">
        <v-col cols="12" lg="6" class="pb-0">
          <v-text-field
            autofocus
            :label="$t('Name')"
            v-model="currentNameProfile"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-btn
            color="primary"
            :disabled="
              currentNameProfile === '' ||
              loadings.update ||
              selectedThermosImages.length < 2
            "
            :loading="loadings.update"
            @click="handleUpdateProfile"
            >{{ $t('Update') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pt-6">
      <v-row v-if="paginatedThermos.length > 0" class="px-6 mt-0 mx-0">
        <v-col
          cols="12"
          lg="2"
          md="4"
          v-for="item in paginatedThermos"
          :key="item.id"
        >
          <div class="h-full">
            <v-hover v-slot="{ hover }">
              <v-card
                :class="['pb-4', 'thermo-card', hover ? 'on-hover' : null]"
                :elevation="hover ? 12 : 6"
                align="center"
                @click="toggleThermoImage(item)"
              >
                <div class="is-selected" v-if="item.isSelected">
                  <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                </div>
                <div class="div-thermo-img">
                  <img :src="item.src" />
                </div>
                <h5>{{ item.thermography.subject.name }}</h5>
                <h5>{{ item.thermography.name }}</h5>
                <h5>{{ formatDate(item.createdAt, 'dd/MM/yyyy HH:mm') }}</h5>
                <v-chip
                  x-small
                  label
                  v-for="tag in item.thermography.tags"
                  :key="tag.id"
                >
                  {{ tag.name }}
                </v-chip>
              </v-card>
            </v-hover>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="pa-3">
            <v-pagination
              v-model="page"
              :length="pageLength"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { profileService } from '@/services'
import LoadingScreen from '../components/LoadingScreen.vue'
import LoadingScreenSmall from '../components/LoadingScreenSmall.vue'
import { format } from 'date-fns'

export default {
  name: 'ProfileEdit',
  components: { LoadingScreen, LoadingScreenSmall },
  data: function () {
    return {
      selectedView: null,
      reponseDataStatus: '',
      loadings: {
        fetch: false,
        update: false
      },
      errors: {
        fetch: false
      },
      currentNameProfile: '',
      selectedThermosImages: [],
      thermosPerPage: 12,
      page: 1
    }
  },
  methods: {
    formatDate(date, mask) {
      return format(new Date(date), mask)
    },
    toggleThermoImage(item) {
      const idxThermoImage = this.selectedThermosImagesIds.indexOf(item.id)
      if (idxThermoImage === -1) this.selectedThermosImages.push(item)
      else this.selectedThermosImages.splice(idxThermoImage, 1)
    },
    async fetchAll() {
      await this.fetchProfile(this.$route.params.profileId)
      await this.fetchThermoImages()
    },
    async fetchThermoImages() {
      await this.$store.dispatch('fetchThermoImages', {
        view: this.selectedView
      })
    },
    async fetchProfile(id) {
      if (this.selectedThermosImages.length === 0) this.loadings.fetch = true
      try {
        const response = await profileService.getProfile(id)
        const profileData = response.data.data
        this.currentNameProfile = response.data.name

        this.selectedView = response.data.thermographyImage[0].view

        this.profileContours = profileData?.contours

        this.selectedThermosImages = response.data.thermographyImage
        this.errors.fetch = false

        this.reponseDataStatus = response.data.status
        if (
          response.data.status === 'CREATED' ||
          response.data.status === 'PROCESSING' ||
          response.data.status === 'QUEUE'
        ) {
          setTimeout(() => {
            this.fetchProfile(this.$route.params.profileId)
          }, 5000)
        }
      } catch (error) {
        console.log('ERRO', error)
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    },
    async handleUpdateProfile() {
      this.loadings.update = true
      try {
        await this.$store.dispatch(
          'removeProfile',
          this.$route.params.profileId
        )

        const response = await profileService.createProfileThermo({
          name: this.currentNameProfile,
          images: this.selectedThermosImagesIds
        })

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ProfileUpdatedSuccessfully')}!`,
          type: 'success',
          model: true
        })

        setTimeout(
          () => this.$router.replace(`/profile/${response.data.id}`),
          500
        )
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('AnErrorOccurredWhileTryingToUpdateTheProfile')}!`,
          type: 'error',
          model: true
        })

        console.log(error)
      }
      this.loadings.create = false
    }
  },
  async mounted() {
    await this.fetchAll()
  },
  computed: {
    selectedThermosImagesIds() {
      return this.selectedThermosImages.map((item) => item.id)
    },
    pageLength() {
      return Math.ceil(this.thermoImages.length / this.thermosPerPage)
    },
    paginatedThermos() {
      return this.thermoImages.slice(
        (this.page - 1) * this.thermosPerPage,
        this.page * this.thermosPerPage
      )
    },
    thermoImages() {
      return this.$store.getters.thermoImagesGet
        .filter((item) => {
          if (item.status !== 'PROCESSED') return false
          return true
        })
        .map((item) => {
          item.isSelected = this.selectedThermosImagesIds.includes(item.id)
          return item
        })
    },
    views() {
      const views = [...this.$t('viewsOptsArr')]
      views.pop()
      return views
    },
    selectedViewLabel() {
      let labelVista = this.selectedView

      this.views.forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    }
  }
}
</script>

<style scoped>
.h-full {
  height: 100%;
}
.thermo-card {
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  top: 0;
  height: 100%;
}
.thermo-card.on-hover {
  top: -5px;
}
.thermo-card .is-selected {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.2);
  border-radius: 6px;
  border: 2px solid red;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thermo-card .is-selected .v-icon {
  font-size: 42px;
  color: red;
}
.div-thermo-img img {
  width: 100%;
  height: auto;
  border-radius: 6px 6px 0 0;
}
</style>
