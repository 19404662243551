<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col cols="12" v-else-if="errors.fetch">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadingGroups') }}. </v-col>
        <v-col class="py-0">
          <v-btn @click="fetchAll" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <v-card id="filters-groups" outlined>
      <v-col>
        <v-row class="pa-3" justify="space-between">
          <span class="text-caption text--secondary">{{ $t('Filters') }}</span>
          <v-btn @click="addGroup.open = true" rounded color="primary"
            ><v-icon>mdi-plus</v-icon> {{ $t('NEWGROUP') }}</v-btn
          >
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="search"
              :placeholder="`${$t('SearchName')}...`"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              style="font-weight: 300"
              outlined
              dense
              v-model="selectedType"
              :items="groupTypes"
              :label="$t('Type')"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
    <v-card outlined class="mt-6">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="filteredItems"
        must-sort
        outlined
        :items-per-page="items.length"
        :no-data-text="`${$t('NoRecord')} :(`"
        mobile-breakpoint="1"
        id="table-data-groups"
      >
        <template v-slot:item="{ headers, item }">
          <v-hover v-slot="{ hover }">
            <tr>
              <td
                v-for="itemKey in headers"
                :style="
                  itemKey.value === 'actions' || itemKey.value === 'inserted_at'
                    ? 'width: 150px'
                    : ''
                "
                :key="itemKey.value"
              >
                <template v-if="itemKey.value === 'actions'">
                  <span class="d-none">{{ $t('Actions') }}</span>
                  <template v-if="hover">
                    <v-btn
                      icon
                      small
                      color="primary"
                      @click="handleEditGroup(item)"
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      small
                      :loading="loadings.remove"
                      :disabled="loadings.remove"
                      class="ml-2"
                      color="red"
                      @click="handleOpenRemoveModal(item)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </template>
                <template v-else>
                  {{ item[itemKey.value] }}
                </template>
              </td>
            </tr>
          </v-hover>
        </template>
      </v-data-table>
      <v-dialog v-model="addGroup.open" max-width="550px">
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title class="text--subtitle">
              <v-icon color="primary" style="padding-bottom: 2px"
                >mdi-plus</v-icon
              >
              {{ $t('NewGroup') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="handleCloseAddModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-col class="px-4">
            <v-text-field
              v-model="addGroup.name"
              outlined
              dense
              :label="$t('Name')"
            >
            </v-text-field>
            <v-autocomplete
              style="font-weight: 300"
              outlined
              dense
              v-model="addGroup.type"
              auto-select-first
              :items="addGroupTypes"
              @update:search-input="onChangeAddType"
              :label="$t('Type')"
            >
            </v-autocomplete>
          </v-col>

          <v-card-actions class="pb-6 mt-4">
            <v-spacer></v-spacer>
            <v-btn text class="px-10" @click="handleCloseAddModal">
              <span class="mx-3">{{ $t('Cancel') }}</span>
            </v-btn>
            <v-btn
              @click="handleSave"
              color="primary"
              :loading="loadings.addGroup"
              :disabled="loadings.addGroup"
              class="px-4"
            >
              <span class="mx-3">{{ $t('SAVE') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editGroup.open" max-width="550px">
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title class="text--subtitle">
              {{ $t('EditGroup') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="handleCloseEditModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-col class="px-4">
            <v-text-field
              v-model="editGroup.item.name"
              outlined
              dense
              :label="$t('Name')"
            >
            </v-text-field>
            <v-autocomplete
              style="font-weight: 300"
              outlined
              dense
              v-model="editGroup.item.type"
              auto-select-first
              :items="addGroupTypes"
              @update:search-input="onChangeAddType"
              :label="$t('Type')"
            >
            </v-autocomplete>
          </v-col>

          <v-card-actions class="pb-6 mt-4">
            <v-spacer></v-spacer>
            <v-btn text class="px-10" @click="handleCloseEditModal">
              <span class="mx-3">{{ $t('Cancel') }}</span>
            </v-btn>
            <v-btn
              @click="handleSaveEdit"
              color="primary"
              :loading="loadings.editGroup"
              :disabled="loadings.editGroup"
              class="px-4"
            >
              <span class="mx-3">{{ $t('SAVE') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeModal.open" max-width="400px">
        <RemoveFormModal
          :title="`${$t('RemoveGroup')}?`"
          :onCloseCallback="() => (removeModal.open = false)"
          :onRemoveCallback="() => handleRemoveItem(removeModal.item)"
          :isLoading="loadings.remove"
        />
      </v-dialog>
    </v-card>
  </v-col>
</template>

<script>
import RemoveFormModal from '../components/RemoveFormModal.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  components: {
    RemoveFormModal,
    LoadingScreen
  },
  data() {
    return {
      search: '',
      selectedType: null,
      loadings: {
        addGroup: false,
        fetch: false,
        remove: false,
        editGroup: false
      },
      removeModal: {
        open: false,
        item: null
      },
      errors: {
        addGroup: false,
        fetch: false,
        editGroup: false
      },
      editGroup: {
        open: false,
        item: {
          name: '',
          type: ''
        }
      },
      addGroup: {
        open: false,
        type: null,
        name: '',
        typeInput: ''
      }
    }
  },
  methods: {
    async fetchGroups() {
      this.loadings.fetch = true
      try {
        await this.$store.dispatch('fetchGroups')
        this.errors.fetch = false
      } catch (e) {
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    },
    async fetchGroupTypes() {
      await this.$store.dispatch('fetchGroupTypes')
    },
    async handleRemoveItem(item) {
      this.loadings.remove = true
      try {
        await this.$store.dispatch('removeGroup', item.id)
        this.removeModal.open = false
        this.removeModal.item = null

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('GroupSuccessfullyRemoved')}!`,
          type: 'success',
          model: true
        })
      } catch (e) {
        console.log(e)

        this.$store.dispatch('setSnackBar', {
          text:
            e?.response?.data?.message != ''
              ? e.response.data.message
              : `${this.$t('AnErrorOccurredWhileTryingToRemoveTheGroup')}!`,
          type: 'error',
          model: true
        })
      }
      this.loadings.remove = undefined
    },
    handleOpenRemoveModal(item) {
      this.removeModal.item = item
      this.removeModal.open = true
    },
    handleCloseEditModal() {
      this.editGroup.open = false
      this.editGroup.item = {
        name: '',
        type: ''
      }
    },
    handleEditGroup(item) {
      this.editGroup.item = { ...item }
      this.editGroup.open = true
    },
    handleCloseAddModal() {
      this.addGroup = {
        open: false,
        type: null,
        name: '',
        typeInput: '',
        metadata: [
          {
            key: '',
            value: ''
          }
        ]
      }
      this.loadings.addGroup = false
      this.errors.addGroup = false
      this.addGroup.open = false
    },
    onChangeAddType(e) {
      this.addGroup.typeInput = e
    },
    removeMeta(index) {
      this.addGroup.metadata.splice(index, 1)
    },
    addMeta() {
      this.addGroup.metadata.push({
        key: '',
        value: ''
      })
    },
    async handleSave() {
      this.loadings.addGroup = true
      try {
        await this.$store.dispatch('saveGroup', {
          name: this.addGroup.name,
          type: this.addGroup.typeInput
        })
        this.errors.addGroup = false
        this.handleCloseAddModal()

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('GroupRegisteredSuccessfully')}!`,
          type: 'success',
          model: true
        })
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('AnErrorOccurredWhenTryingToRegisterTheGroup')}!`,
          type: 'error',
          model: true
        })

        this.errors.addGroup = true
      }
      this.loadings.addGroup = false
    },
    async handleSaveEdit() {
      this.loadings.editGroup = true
      try {
        await this.$store.dispatch('updateGroup', this.editGroup.item)
        this.errors.editGroup = false
        this.handleCloseEditModal()

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('GroupSuccessfullyUpdated')}!`,
          type: 'success',
          model: true
        })
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('AnErrorOccurredWhileTryingToUpdateTheGroup')}!`,
          type: 'error',
          model: true
        })

        this.errors.editGroup = true
      }
      this.loadings.editGroup = false
    },
    async fetchAll() {
      this.loadings.fetch = true
      try {
        await Promise.all([this.fetchGroupTypes(), this.fetchGroups()])
        this.errors.fetch = false
      } catch (e) {
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    }
  },
  async mounted() {
    await this.fetchAll()
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        if (this.selectedType && item.type !== this.selectedType) {
          return false
        }
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    items() {
      return this.$store.getters.groups
    },
    groupTypes() {
      return this.$store.getters.groupTypes
    },
    addGroupTypes() {
      if (this.addGroup.typeInput !== '') {
        if (!this.$store.getters.groupTypes.includes(this.addGroup.typeInput)) {
          return [...this.$store.getters.groupTypes, this.addGroup.typeInput]
        }
      }
      return this.$store.getters.groupTypes
    },
    headers() {
      return [
        { text: this.$t('Name'), value: 'name', width: '150px' },
        { text: this.$t('Type'), value: 'type', width: '150px' },
        { text: this.$t('Actions'), value: 'actions', width: '150px' }
      ]
    }
  }
}
</script>

<style></style>
