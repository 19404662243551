var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.contoursType == true && _vm.viewType != 'others')?_c('v-col',{attrs:{"cols":"12"}},[_c('svg',{class:{ noClick: _vm.disabled },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":'0 0 ' +
        this.contourJson.imageWidth +
        ' ' +
        this.contourJson.imageHeight +
        ''},on:{"click":_vm.part_clicked}},_vm._l((_vm.partsJsonContourType),function(value,key){return _c('path',{key:("" + key),class:[key, _vm.hoverName == key ? 'hovering' : ''],style:(_vm.getStyle(key)),attrs:{"stroke":"whitesmoke","stroke-width":"2","d":_vm.transformPolyToSvg(value)},on:{"click":function($event){return _vm.$emit('click', key)},"mouseover":function($event){_vm.hoverName = key},"mouseleave":function($event){_vm.hoverName = ''}}})}),0)]):_vm._e(),(_vm.contoursType == true && _vm.viewType == 'others')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('svg',{class:{ noClick: _vm.disabled },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":'0 0 ' +
            _vm.contourJson.superiorInferiorAnterior.imageWidth +
            ' ' +
            _vm.contourJson.superiorInferiorAnterior.imageHeight +
            ''},on:{"click":_vm.part_clicked}},_vm._l((_vm.contourJson.superiorInferiorAnterior.data),function(value,key){return _c('path',{key:("" + key),class:[key, _vm.hoverName == key ? 'hovering' : ''],style:(_vm.getStyle(key)),attrs:{"stroke":"whitesmoke","stroke-width":"2","d":_vm.transformPolyToSvg(value)},on:{"click":function($event){return _vm.$emit('click', key)},"mouseover":function($event){_vm.hoverName = key},"mouseleave":function($event){_vm.hoverName = ''}}})}),0)]),_c('v-col',{attrs:{"cols":"6"}},[_c('svg',{class:{ noClick: _vm.disabled },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":'0 0 ' +
            _vm.contourJson.superiorInferiorPosterior.imageWidth +
            ' ' +
            _vm.contourJson.superiorInferiorPosterior.imageHeight +
            ''},on:{"click":_vm.part_clicked}},_vm._l((_vm.contourJson.superiorInferiorPosterior.data),function(value,key){return _c('path',{key:("" + key),class:[key, _vm.hoverName == key ? 'hovering' : ''],style:(_vm.getStyle(key)),attrs:{"stroke":"whitesmoke","stroke-width":"2","d":_vm.transformPolyToSvg(value)},on:{"click":function($event){return _vm.$emit('click', key)},"mouseover":function($event){_vm.hoverName = key},"mouseleave":function($event){_vm.hoverName = ''}}})}),0)])],1)],1):_vm._e(),(_vm.contoursType == false)?_c('v-col',{attrs:{"cols":"6"}},[_c('svg',{class:{ noClick: _vm.disabled },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 805.12 1583.17"},on:{"click":_vm.part_clicked}},_vm._l((_vm.contourJson.fullBodyAnterior.data.parts),function(value,key){return _c('path',{key:(key + "_" + (value.partName)),class:[
          value.partName,
          _vm.hoverName == value.partName ? 'hovering' : ''
        ],style:(_vm.getStyle(value.partName)),attrs:{"d":value.svgData},on:{"click":function($event){return _vm.$emit('click', value.partName)},"mouseover":function($event){_vm.hoverName = value.partName},"mouseleave":function($event){_vm.hoverName = ''}}})}),0)]):_vm._e(),(_vm.contoursType == false)?_c('v-col',{attrs:{"cols":"6"}},[_c('svg',{class:{ noClick: _vm.disabled },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 805.12 1583.17"},on:{"click":_vm.part_clicked}},_vm._l((_vm.contourJson.fullBodyPosterior.data.parts),function(value,key){return _c('path',{key:(key + "_" + (value.partName)),class:[
          value.partName,
          _vm.hoverName == value.partName ? 'hovering' : ''
        ],style:(_vm.getStyle(value.partName)),attrs:{"d":value.svgData},on:{"click":function($event){return _vm.$emit('click', value.partName)},"mouseover":function($event){_vm.hoverName = value.partName},"mouseleave":function($event){_vm.hoverName = ''}}})}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }