<template>
  <div
    :style="{
      textAlign: 'center',
      border: 'dotted 2px gray',
      padding: '16px'
    }"
  />
</template>

<script>
export default {
  name: 'EmptyModuloTemplate'
}
</script>
