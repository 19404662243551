export default {
  state: {
    tutorialPages: [
      'Home',
      'Groups',
      'Tags',
      'People',
      'PeopleThermos',
      'ThermalDiff',
      'Profiles',
      'Profile',
      'NewProfile',
      'NewThermo',
      'Thermography'
    ],
    pagesAlreadyShown: [],
    showTutorial: '',

    step: 0,
    maxSteps: 0,
    highlightCss: {
      top: '0px',
      left: '0px',
      width: '0px',
      height: '0px',
      border: '0px solid transparent'
    },
    textHighlightCss: {
      top: 'calc(50% - 150px)',
      left: 'calc(50% - 60px)',
      width: '300px',
      height: '120px'
    },
    textHighlight:
      'Para prosseguir no tutorial clique com o botão esquerdo do mouse.'
  },
  mutations: {
    ADD_PAGE_SHOWN(state, payload) {
      state.pagesAlreadyShown.push(payload)
      state.pagesAlreadyShown = state.pagesAlreadyShown.filter(
        (v, i, a) => a.indexOf(v) === i
      )

      localStorage.setItem(
        'pagesAlreadyShown',
        JSON.stringify(state.pagesAlreadyShown)
      )
    },
    SET_PAGES_ALREADY_SHOWN(state) {
      const pagesAlreadyShown = localStorage.getItem('pagesAlreadyShown')
      state.pagesAlreadyShown =
        pagesAlreadyShown !== null
          ? JSON.parse(localStorage.getItem('pagesAlreadyShown'))
          : []
    },
    SET_SHOW_TUTORIAL(state, payload) {
      state.showTutorial = payload
    },

    NEXT_STEP(state) {
      let canAddStep = true

      if (state.step === state.maxSteps) {
        document.getElementsByTagName('html')[0].style.overflow = ''
        state.highlightCss = {
          top: '0px',
          left: '0px',
          width: '0px',
          height: '0px',
          border: '0px solid transparent'
        }
        state.textHighlightCss = {
          top: 'calc(50% - 150px)',
          left: 'calc(50% - 60px)',
          width: '300px',
          height: '120px'
        }
        state.textHighlight =
          'Para prosseguir no tutorial clique com o botão esquerdo do mouse.'
        state.showTutorial = ''

        canAddStep = false
        state.step = 0
      }

      if (state.maxSteps > state.step && canAddStep) state.step++
    },
    SET_MAX_STEPS(state, payload) {
      state.maxSteps = payload
    },
    SET_HIGHLIGHT_CSS(state, payload) {
      state.highlightCss = payload
    },
    SET_TEXT_HIGHLIGHT_CSS(state, payload) {
      state.textHighlightCss = payload.textHighlightCss
      this.commit('SET_POSITION_TEXT_HIGHLIGHT', payload.positionInfos)
    },
    SET_TEXT_HIGHLIGHT(state, payload) {
      state.textHighlight = payload
    },

    SET_POSITION_TEXT_HIGHLIGHT(state, payload) {
      const offsetsSelectedEl = payload.offsetsSelectedEl
      const selectedEl = payload.selectedEl
      const widthTextHighlight = payload.widthTextHighlight
      const heightTextHighlight = payload.heightTextHighlight
      const tipPosition = payload.tipPosition

      const positionsTextHighlight = {
        bottom: {
          top: offsetsSelectedEl.top + selectedEl.offsetHeight + 15,
          left:
            offsetsSelectedEl.left +
            selectedEl.offsetWidth / 2 -
            widthTextHighlight / 2
        },
        top: {
          top: offsetsSelectedEl.top - heightTextHighlight - 15,
          left:
            offsetsSelectedEl.left +
            selectedEl.offsetWidth / 2 -
            widthTextHighlight / 2
        },
        left: {
          top:
            offsetsSelectedEl.top +
            selectedEl.offsetHeight / 2 -
            heightTextHighlight / 2,
          left: offsetsSelectedEl.left - widthTextHighlight - 15
        },
        right: {
          top:
            offsetsSelectedEl.top +
            selectedEl.offsetHeight / 2 -
            heightTextHighlight / 2,
          left: offsetsSelectedEl.left + selectedEl.offsetWidth + 15
        },
        center: {
          top:
            offsetsSelectedEl.top +
            selectedEl.offsetHeight / 2 -
            heightTextHighlight / 2,
          left:
            offsetsSelectedEl.left +
            selectedEl.offsetWidth / 2 -
            widthTextHighlight / 2
        }
      }

      if (positionsTextHighlight[tipPosition] !== undefined) {
        state.textHighlightCss.top = `${positionsTextHighlight[tipPosition].top}px`
        state.textHighlightCss.left = `${positionsTextHighlight[tipPosition].left}px`
      }
    }
  },
  actions: {
    addPageShown({ commit }, payload) {
      commit('ADD_PAGE_SHOWN', payload)
    },
    setPagesAlreadyShown({ commit }) {
      commit('SET_PAGES_ALREADY_SHOWN')
    },
    setShowTutorial({ commit }, payload) {
      commit('SET_SHOW_TUTORIAL', payload)
    },

    nextStep({ commit }) {
      commit('NEXT_STEP')
    },
    setMaxSteps({ commit }, payload) {
      commit('SET_MAX_STEPS', payload)
    },
    setHighlightCss({ commit }, payload) {
      commit('SET_HIGHLIGHT_CSS', payload)
    },
    setTextHighlightCss({ commit }, payload) {
      commit('SET_TEXT_HIGHLIGHT_CSS', payload)
    },
    setTextHighlight({ commit }, payload) {
      commit('SET_TEXT_HIGHLIGHT', payload)
    }
  },
  getters: {
    tutorialPages: (state) => state.tutorialPages,
    pagesAlreadyShown: (state) => state.pagesAlreadyShown,
    showTutorial: (state) => state.showTutorial,

    step: (state) => state.step,
    maxSteps: (state) => state.maxSteps,
    highlightCss: (state) => state.highlightCss,
    textHighlightCss: (state) => state.textHighlightCss,
    textHighlight: (state) => state.textHighlight
  }
}
