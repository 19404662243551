<template>
  <v-col cols="12">
    <v-card class="pa-4" outlined>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <h2 class="mb-3">Configurações:</h2>
          <v-row>
            <v-col cols="12">
              <label>Idioma:</label>
              <locale-changer />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label>Cor do tema:</label>
              <v-select
                v-model="theme"
                @change="changeTheme"
                :items="[
                  {
                    text: 'Branco',
                    value: 'white'
                  },
                  {
                    text: 'Preto',
                    value: 'black'
                  }
                ]"
                outlined
                dense
                single-line
                hide-details
                :label="$t('Tema')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label>Padrão temperaturas:</label>
              <v-select
                v-model="themeTypeTemps"
                @change="changeThemeTypeTemps"
                :items="[
                  {
                    text: 'Fixo',
                    value: 'static'
                  },
                  {
                    text: 'Dinâmico',
                    value: 'dynamic'
                  }
                ]"
                outlined
                dense
                single-line
                hide-details
                :label="$t('Padrão temperaturas')"
              />
            </v-col>
            <v-col cols="12">
              <label>Padrão Paleta de Cor:</label>
              <v-select
                v-model="themeDefaultPaletteName"
                @change="changeThemeDefaultPaletteName"
                :items="palettesKeys"
                outlined
                dense
                single-line
                hide-details
                :label="$t('Padrão Paleta de Cor')"
              />
            </v-col>
            <v-col cols="12" v-if="themeTypeTemps == 'static'">
              <label for="">Temperatura Fixa termografia</label>
              <v-text-field
                label="Temperatura mínima"
                v-model="themeMinTemp"
                @change="changeThemeMinTemp"
                type="number"
              ></v-text-field>
              <v-text-field
                label="Temperatura máxima"
                v-model="themeMaxTemp"
                @change="changeThemeMaxTemp"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label for="">Temperatura fixa ScoreZ</label>
              <v-text-field
                label="Temperatura mínima"
                v-model="themeScoreZMinTemp"
                @change="changeThemeScoreZMinTemp"
                type="number"
                step="0.01"
              ></v-text-field>
              <v-text-field
                label="Temperatura máxima"
                v-model="themeScoreZMaxTemp"
                @change="changeThemeScoreZMaxTemp"
                type="number"
                step="0.01"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label for="">Temperatura fixa Delta</label>
              <v-text-field
                label="Temperatura mínima"
                v-model="themeDeltaMinTemp"
                @change="changeThemeDeltaMinTemp"
                type="number"
              ></v-text-field>
              <v-text-field
                label="Temperatura máxima"
                v-model="themeDeltaMaxTemp"
                @change="changeThemeDeltaMaxTemp"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label for="">Temperatura nova termografia</label>
              <v-text-field
                label="Temperatura mínima"
                v-model="themeNewThermoMinTemp"
                @change="changeThemeNewThermoMinTemp"
                type="number"
              ></v-text-field>
              <v-text-field
                label="Temperatura máxima"
                v-model="themeNewThermoMaxTemp"
                @change="changeThemeNewThermoMaxTemp"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label for="">Ferramenta ROI Temperaturas</label>
              <v-text-field
                label="Temperatura mínima"
                v-model="themeRoiTempMinPerc"
                @change="changeThemeRoiTempMinPerc"
                type="number"
                suffix="%"
              ></v-text-field>
              <v-text-field
                label="Temperatura máxima"
                v-model="themeRoiTempMaxPerc"
                @change="changeThemeRoiTempMaxPerc"
                type="number"
                suffix="%"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="cpf"
                :rules="rulesCpf"
                @change="changeCpf"
                v-mask="'###.###.###-##'"
                outlined
                dense
                :label="$t('CPF')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import LocaleChanger from '../components/LocaleChanger.vue'
import validaCpf from '../utils/validaCpf'
import palettes from '@/assets/palettes.json'

export default {
  components: { LocaleChanger },
  data() {
    return {
      cpf: null,
      rulesCpf: [(value) => validaCpf(value) || 'CPF inválido']
    }
  },
  methods: {
    changeTheme(theme) {
      this.$store.dispatch('setTheme', { theme })
    },
    changeCpf(cpf) {
      if (validaCpf(cpf) && cpf != '') {
        this.$store.dispatch('setCpf', { cpf })
      }
    },
    changeMethod(method) {
      this.$store.dispatch('setMethod', { method })
    },
    changeThemeTypeTemps(themeTypeTemps) {
      this.$store.dispatch('setThemeTypeTemps', { themeTypeTemps })
    },
    changeThemeDefaultPaletteName(themeDefaultPaletteName) {
      this.$store.dispatch('setThemeDefaultPaletteName', {
        themeDefaultPaletteName
      })
    },
    changeThemeMinTemp(themeMinTemp) {
      this.$store.dispatch('setThemeMinTemp', { themeMinTemp })
    },
    changeThemeMaxTemp(themeMaxTemp) {
      this.$store.dispatch('setThemeMaxTemp', { themeMaxTemp })
    },
    changeThemeScoreZMinTemp(themeScoreZMinTemp) {
      this.$store.dispatch('setThemeScoreZMinTemp', { themeScoreZMinTemp })
    },
    changeThemeScoreZMaxTemp(themeScoreZMaxTemp) {
      this.$store.dispatch('setThemeScoreZMaxTemp', { themeScoreZMaxTemp })
    },
    changeThemeDeltaMinTemp(themeDeltaMinTemp) {
      this.$store.dispatch('setThemeDeltaMinTemp', { themeDeltaMinTemp })
    },
    changeThemeDeltaMaxTemp(themeDeltaMaxTemp) {
      this.$store.dispatch('setThemeDeltaMaxTemp', { themeDeltaMaxTemp })
    },
    changeThemeNewThermoMinTemp(themeNewThermoMinTemp) {
      this.$store.dispatch('setThemeNewThermoMinTemp', {
        themeNewThermoMinTemp
      })
    },
    changeThemeNewThermoMaxTemp(themeNewThermoMaxTemp) {
      this.$store.dispatch('setThemeNewThermoMaxTemp', {
        themeNewThermoMaxTemp
      })
    },
    changeThemeRoiTempMinPerc(themeRoiTempMinPerc) {
      this.$store.dispatch('setThemeRoiTempMinPerc', { themeRoiTempMinPerc })
    },
    changeThemeRoiTempMaxPerc(themeRoiTempMaxPerc) {
      this.$store.dispatch('setThemeRoiTempMaxPerc', { themeRoiTempMaxPerc })
    },
    changeThemeRoiTempMeanPerc(themeRoiTempMeanPerc) {
      this.$store.dispatch('setThemeRoiTempMeanPerc', { themeRoiTempMeanPerc })
    }
  },
  computed: {
    palettesKeys() {
      return Object.keys(palettes)
    },
    theme() {
      return this.$store.getters.theme
    },
    themeRoiTempMinPerc() {
      return this.$store.getters.themeRoiTempMinPerc
    },
    themeRoiTempMaxPerc() {
      return this.$store.getters.themeRoiTempMaxPerc
    },
    themeRoiTempMeanPerc() {
      return this.$store.getters.themeRoiTempMeanPerc
    },
    methodsOptions() {
      return this.$store.getters.methodsOptions
    },
    themeTypeTemps() {
      return this.$store.getters.themeTypeTemps
    },
    themeDefaultPaletteName() {
      return this.$store.getters.themeDefaultPaletteName
    },
    themeMinTemp() {
      return this.$store.getters.themeMinTemp
    },
    themeMaxTemp() {
      return this.$store.getters.themeMaxTemp
    },
    themeScoreZMinTemp() {
      return this.$store.getters.themeScoreZMinTemp
    },
    themeScoreZMaxTemp() {
      return this.$store.getters.themeScoreZMaxTemp
    },
    themeDeltaMinTemp() {
      return this.$store.getters.themeDeltaMinTemp
    },
    themeDeltaMaxTemp() {
      return this.$store.getters.themeDeltaMaxTemp
    },
    themeNewThermoMinTemp() {
      return this.$store.getters.themeNewThermoMinTemp
    },
    themeNewThermoMaxTemp() {
      return this.$store.getters.themeNewThermoMaxTemp
    }
  },
  async mounted() {
    const theme = window.localStorage.getItem('theme')
    if (theme != undefined) this.$store.dispatch('setTheme', { theme: theme })
    const themeTypeTemps = window.localStorage.getItem('themeTypeTemps')
    const themeDefaultPaletteName = window.localStorage.getItem(
      'themeDefaultPaletteName'
    )

    const cpf = window.localStorage.getItem('cpf')
    if (cpf != undefined) this.$store.dispatch('setCpf', { cpf: cpf })
    this.cpf = cpf

    if (themeTypeTemps != undefined)
      this.$store.dispatch('setThemeTypeTemps', {
        themeTypeTemps: themeTypeTemps
      })
    if (themeDefaultPaletteName != undefined)
      this.$store.dispatch('setThemeDefaultPthemeDefaultPaletteName', {
        themeDefaultPaletteName: themeDefaultPaletteName
      })
    const themeMinTemp = window.localStorage.getItem('themeMinTemp')
    if (themeMinTemp != undefined)
      this.$store.dispatch('setThemeMinTemp', { themeMinTemp: themeMinTemp })
    const themeMaxTemp = window.localStorage.getItem('themeMaxTemp')
    if (themeMaxTemp != undefined)
      this.$store.dispatch('setThemeMaxTemp', { themeMaxTemp: themeMaxTemp })

    const themeScorezMinTemp = window.localStorage.getItem('themeScorezMinTemp')
    if (themeScorezMinTemp != undefined)
      this.$store.dispatch('setThemeScorezMinTemp', {
        themeScorezMinTemp: themeScorezMinTemp
      })
    const themeScorezMaxTemp = window.localStorage.getItem('themeScorezMaxTemp')
    if (themeScorezMaxTemp != undefined)
      this.$store.dispatch('setThemeScorezMaxTemp', {
        themeScorezMaxTemp: themeScorezMaxTemp
      })

    const themeDeltaMinTemp = window.localStorage.getItem('themeDeltaMinTemp')
    if (themeDeltaMinTemp != undefined)
      this.$store.dispatch('setThemeDeltaMinTemp', {
        themeDeltaMinTemp: themeDeltaMinTemp
      })
    const themeDeltaMaxTemp = window.localStorage.getItem('themeDeltaMaxTemp')
    if (themeDeltaMaxTemp != undefined)
      this.$store.dispatch('setThemeDeltaMaxTemp', {
        themeDeltaMaxTemp: themeDeltaMaxTemp
      })

    const themeNewThermoMinTemp = window.localStorage.getItem(
      'themeNewThermoMinTemp'
    )
    if (themeNewThermoMinTemp != undefined)
      this.$store.dispatch('setThemeNewThermoMinTemp', {
        themeNewThermoMinTemp: themeNewThermoMinTemp
      })
    const themeNewThermoMaxTemp = window.localStorage.getItem(
      'themeNewThermoMaxTemp'
    )
    if (themeNewThermoMaxTemp != undefined)
      this.$store.dispatch('setThemeNewThermoMaxTemp', {
        themeNewThermoMaxTemp: themeNewThermoMaxTemp
      })

    const themeRoiTempMaxPerc = window.localStorage.getItem(
      'themeRoiTempMaxPerc'
    )
    if (themeRoiTempMaxPerc != undefined)
      this.$store.dispatch('setThemeRoiTempMaxPerc', {
        themeRoiTempMaxPerc: themeRoiTempMaxPerc
      })
    const themeRoiTempMinPerc = window.localStorage.getItem(
      'themeRoiTempMinPerc'
    )
    if (themeRoiTempMinPerc != undefined)
      this.$store.dispatch('setThemeRoiTempMinPerc', {
        themeRoiTempMinPerc: themeRoiTempMinPerc
      })
    const themeRoiTempMeanPerc = window.localStorage.getItem(
      'themeRoiTempMeanPerc'
    )
    if (themeRoiTempMeanPerc != undefined)
      this.$store.dispatch('setThemeRoiTempMeanPerc', {
        themeRoiTempMeanPerc: themeRoiTempMeanPerc
      })
  }
}
</script>
