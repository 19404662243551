<template>
  <div id="app" class="web-camera-container">
    <div v-show="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      ></v-progress-circular>
    </div>

    <div
      v-show="!isLoading"
      class="camera-box"
      :class="camAngle % 180 === 0 ? '' : 'mt-14'"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video
        v-show="!photoTaken"
        ref="camera"
        :width="340"
        :height="240"
        :style="`transform: rotate(${camAngle}deg);`"
        autoplay
      ></video>
      <v-img v-if="photoTaken" :src="photoTaken"></v-img>
      <canvas
        v-show="false"
        id="photoTaken"
        :width="320"
        :height="240"
      ></canvas>
    </div>

    <div v-if="!isLoading" class="camera-shoot py-4">
      <v-row v-if="photoTaken" align="center">
        <v-btn color="primary" fab text @click="deletePhoto">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-row>
      <v-row v-else :class="camAngle % 180 === 0 ? '' : 'mt-6'">
        <v-btn color="primary" fab text @click="takePhoto">
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-btn color="primary" fab text @click="rotateCam">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      camAngle: 0,
      photoTaken: null,
      isShotPhoto: false,
      isLoading: false,
      link: '#'
    }
  },
  props: {
    onChangeImage: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.createCameraElement()
  },

  methods: {
    createCameraElement() {
      this.isLoading = true

      const constraints = (window.constraints = {
        audio: false,
        video: true
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false
          this.$refs.camera.srcObject = stream
        })
        .catch((error) => {
          console.log('error', error)
          this.isLoading = false
          alert("May the browser didn't support or there is some errors.")
        })
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach((track) => {
        track.stop()
      })
    },

    async rotateReturnImage() {
      const canvas = document.getElementById('photoTaken')
      const ctx = canvas.getContext('2d')
      const img = new Image()
      img.src = canvas.toDataURL()
      const _this = this
      return new Promise((resolve) => {
        img.onload = function () {
          if (_this.camAngle === 90 || _this.camAngle === 270) {
            canvas.width = img.height
            canvas.height = img.width
          }
          ctx.rotate((_this.camAngle * Math.PI) / 180)
          if (_this.camAngle === 270) {
            ctx.drawImage(img, -canvas.height, 0)
          }
          if (_this.camAngle === 90) {
            ctx.drawImage(img, 0, -canvas.width)
          }
          if (_this.camAngle === 180) {
            ctx.drawImage(img, -canvas.width, -canvas.height)
          }

          resolve(canvas.toDataURL('image/jpeg'))
        }
      })
    },
    async takePhoto() {
      const canvas = document.getElementById('photoTaken')
      canvas.width = 320
      canvas.height = 240
      const context = canvas.getContext('2d')
      context.restore()
      context.drawImage(this.$refs.camera, 0, 0, 320, 240)
      this.photoTaken = await this.rotateReturnImage()
      this.onChangeImage(await this.base64ToFile(this.photoTaken))
    },
    async base64ToFile(url) {
      return new Promise((resolve) =>
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            resolve(
              new File([blob], 'Thermography File', { type: 'image/jpeg' })
            )
          })
      )
    },
    async deletePhoto() {
      this.photoTaken = null
      this.onChangeImage(null)
    },
    async rotateCam() {
      this.camAngle += 90
      if (this.camAngle === 360) this.camAngle = 0
    },
    async downloadImage() {
      const download = document.getElementById('downloadPhoto')
      const base64Image = await this.rotateReturnImage()
      const canvas = base64Image.replace('image/jpeg', 'image/octet-stream')

      download.setAttribute('href', canvas)
      download.click()
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  display: flex;
  justify-content: center;
}

.web-camera-container {
  margin: auto;
  min-height: 350px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
