<template>
  <v-col cols="12">
    <v-col cols="12" v-if="loadings.fetch">
      <v-row justify="center">
        <loading-screen />
      </v-row>
    </v-col>
    <v-card
      v-else-if="
        reponseDataStatus === 'PROCESSING' ||
        reponseDataStatus === 'CREATED' ||
        reponseDataStatus === 'QUEUE'
      "
    >
      <v-col>
        <v-col>
          <span
            >{{
              reponseDataStatus === 'QUEUE'
                ? $t('ProfileReprocessing')
                : $t('ProfileProcessing')
            }}...</span
          >
          <loading-screen-small />
        </v-col>
        <v-col class="pt-0 mt-n3">
          <span class="text-caption text--secondary"
            >{{ $t('ThisCanTakeSomeTime') }}...
          </span>
        </v-col>
      </v-col>
    </v-card>
    <v-col cols="12" v-else-if="errors.fetch">
      <span>{{ $t('ErrorFetchingProfile') }}</span>
    </v-col>
    <v-col cols="12" class="pa-0" v-else-if="view !== ''">
      <v-row>
        <v-col cols="12" id="infos-profile">
          <h2>{{ $t('Profile') }}: {{ nameProfile }}{{ vista }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" id="canvas-perfil">
          <profile-view
            :data="thermoImagesArr"
            :topLeftLabel="$t('PROFILE')"
            :metrics="profileMetrics"
            :contours="profileContours"
            :view="view"
            :onChangeSelection="onChangeSelection"
            :propComparType="chartsSelectedCompareType"
            :onChangeComparType="onChangeComparType"
            type="profile"
            :selectedContours="selectedContours"
            :maxHeight="400"
            :flipRegisters="profileFlipRegisters"
            :segments="profileSegments"
            showSemaforica
            :hideControls="true"
          />
        </v-col>
        <v-col cols="12" lg="6" id="canvas-thermos">
          <v-carousel
            v-model="carouselTermos"
            hide-delimiter-background
            height="400"
            class="carousel-profile-view"
          >
            <v-carousel-item
              v-for="image in images"
              :key="image.id"
              :onChangeSelected="() => onChangeSelection([])"
            >
              <div class="carousel-image">
                <profile-view
                  :data="image.thermoImage.data"
                  :topLeftLabel="image.subject.name"
                  :topLeftLabelB="
                    new Date(image.createdAt).toLocaleDateString().toString()
                  "
                  :metrics="image.data.metrics.data"
                  :contours="image.contours"
                  :view="image.view"
                  :maxHeight="400"
                  :type="'image'"
                  :srcImage="image.src"
                  :onChangeSelection="onChangeSelection"
                  :propComparType="chartsSelectedCompareType"
                  :onChangeComparType="onChangeComparType"
                  :selectedContours="selectedContours"
                  :flipRegisters="image.flip_registers"
                  :segments="image.segments"
                  showSemaforica
                  :defaultPaletteName="image.palette.replace('Palette', '')"
                  :thermoImageId="image.thermoImage.thermographyId"
                  :hideControls="true"
                />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row>
        <v-col cols="12" lg="6" id="canvas-delta-scorez">
          <v-carousel
            v-model="carouselSpecials"
            hide-delimiter-background
            height="400"
            class="carousel-profile-view"
          >
            <v-carousel-item>
              <div class="carousel-image">
                <profile-view
                  :topLeftLabel="$t('DELTA')"
                  :data="thermoImagesArr"
                  :metrics="metricsDelta"
                  :contours="profileContours"
                  :index="carouselTermos"
                  :view="view"
                  :temps="{
                    min: themeDeltaMinTemp,
                    max: themeDeltaMaxTemp
                  }"
                  defaultPaletteName="Artic"
                  :onChangeSelection="onChangeSelection"
                  :propComparType="chartsSelectedCompareType"
                  :onChangeComparType="onChangeComparType"
                  :selectedContours="selectedContours"
                  type="delta"
                  :maxHeight="400"
                  :removeAsymmetryTemps="true"
                  :hideControls="true"
                />
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="carousel-image">
                <profile-view
                  :topLeftLabel="$t('SCOREZ')"
                  :data="thermoImagesArr"
                  :metrics="metricsScoreZ"
                  :contours="profileContours"
                  :index="carouselTermos"
                  :view="view"
                  :temps="{
                    min: themeScoreZMinTemp,
                    max: themeScoreZMaxTemp
                  }"
                  defaultPaletteName="Artic"
                  :onChangeSelection="onChangeSelection"
                  :propComparType="chartsSelectedCompareType"
                  :onChangeComparType="onChangeComparType"
                  :selectedContours="selectedContours"
                  type="scoreZ"
                  :maxHeight="400"
                  :removeAsymmetryTemps="true"
                  :hideControls="true"
                />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" lg="6" id="infos-temperatures-profile">
          <v-row class="ma-0">
            <v-col cols="6">
              <v-select
                :items="dataTypes"
                v-model="chartsSelectedType"
                :label="$t('Type')"
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="compareTypes"
                v-model="chartsSelectedCompareType"
                outlined
                :label="$t('Comparative')"
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-card flat>
            <apexchart
              type="bar"
              height="300"
              :options="charts.chartData.options"
              :series="getChartSeries"
              :data="charts.chartData.data"
              v-if="chartsSelectedType === 'Chart'"
            />
            <v-simple-table
              fixed-header
              dense
              class="pa-0"
              v-if="chartsSelectedType === 'Table'"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="item in asymmetryItemsTable.headers"
                      :key="item"
                      class="text-left"
                      :title="translateItem(item, 'full')"
                    >
                      {{ translateItem(item, 'short') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      thermoItems, idxThermoItems
                    ) in asymmetryItemsTable.items"
                    :key="idxThermoItems"
                  >
                    <td
                      v-for="(asymetryInfo, idxAsymetryInfo) in thermoItems"
                      :key="idxAsymetryInfo"
                      :class="
                        idxThermoItems == 3
                          ? getAsymmetryClass(asymetryInfo)
                          : ''
                      "
                    >
                      {{
                        asymetryInfo && idxAsymetryInfo > 0
                          ? asymetryInfo.toFixed(2)
                          : asymetryInfo
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
import { profileService } from '@/services'
import ProfileView from '../components/ProfileView.vue'
import dictSegmentation from '@/translations/dict.json'
import LoadingScreen from '../components/LoadingScreen.vue'
import LoadingScreenSmall from '../components/LoadingScreenSmall.vue'
import * as tf from '@tensorflow/tfjs'

export default {
  components: { ProfileView, LoadingScreen, LoadingScreenSmall },
  data: () => ({
    reponseDataStatus: '',
    nameProfile: '',
    carouselTermos: 0,
    carouselSpecials: 0,
    images: [],
    thermoImagesArr: [],
    view: '',
    profileContours: {},
    profileMetrics: {},
    profileAsymmetries: {},
    selectedContours: [],
    chartsSelectedType: 'Chart',
    chartsSelectedCompareType: 'mean',
    charts: {
      chartData: {
        options: {
          legend: {
            show: false
          },
          bar: {
            distributed: true
          },
          dataLabels: {
            enabled: false
          },
          yaxis: {
            min: (min) => (min > 10 && min < 40 ? Math.floor(min) : 22),
            max: (max) => (max > 10 && max < 40 ? Math.ceil(max) : 36),
            tickAmount: 4
          },
          chart: {
            toolbar: {
              show: false
            },
            id: 'analyse-chart'
          }
        },
        series: []
      }
    },
    loadings: {
      fetch: false
    },
    errors: {
      fetch: false
    }
  }),
  methods: {
    getAsymmetryFromMetrics(metrics) {
      const asymmetry = {}

      Object.keys(metrics).forEach((keyMetric) => {
        if (keyMetric.includes('left')) {
          const keyMetricRight = keyMetric.replace('left', 'right')

          if (Object.keys(metrics).includes(keyMetricRight)) {
            asymmetry[keyMetric] = { ...metrics[keyMetric] }
            asymmetry[keyMetricRight] = { ...metrics[keyMetricRight] }

            Object.keys(asymmetry[keyMetric]).forEach((keyRange) => {
              asymmetry[keyMetric][keyRange] -=
                metrics[keyMetricRight][keyRange]
              asymmetry[keyMetricRight][keyRange] -=
                metrics[keyMetric][keyRange]
            })
          }
        }
      })

      return asymmetry
    },
    getAsymmetryClass(value) {
      let colorType = 'green_success'

      if (value > 2.59) colorType = 'red_danger'
      else if (value > 1.96) colorType = 'orange_danger'
      else if (value > 1.65) colorType = 'yellow_danger'
      else if (value < -2.59) colorType = 'purple_danger'
      else if (value < -1.96) colorType = 'blue_danger'
      else if (value < -1.65) colorType = 'gray_blue_danger'

      return colorType
    },
    async fetchAll() {
      await this.fetchProfile(this.$route.params.profileId)
    },
    onChangeSelection(contours) {
      this.selectedContours = contours
    },
    onChangeComparType(val) {
      this.chartsSelectedCompareType = val
    },
    translateItem(item, type) {
      if (!dictSegmentation[item]) return item
      return dictSegmentation[item][type] || item
    },
    getLabelView(valueView) {
      let labelVista = valueView

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },
    async fetchProfile(id) {
      if (this.images.length === 0) this.loadings.fetch = true
      try {
        const response = await profileService.getProfile(id)
        const profileData = response.data.data
        this.nameProfile = response.data.name

        this.profileAsymmetries = profileData?.asymmetry
        // this.profileMetrics = profileData?.metrics
        this.thermoImagesArr = response.data?.thermographyImage.map((item) => {
          const data_index = profileData?.ids.indexOf(item.id)
          return profileData?.data[data_index]
        })

        this.view = response.data.thermographyImage[0].view

        this.profileContours = profileData?.contours

        this.images = response.data.thermographyImage
        this.errors.fetch = false

        this.profileMetrics = {}
        if (this.images.length > 0) {
          this.images.forEach((image) => {
            Object.keys(image.data.metrics.data).forEach((metric) => {
              if (this.profileMetrics[metric] != undefined) {
                this.profileMetrics[metric].min +=
                  image.data.metrics.data[metric].min
                this.profileMetrics[metric].max +=
                  image.data.metrics.data[metric].max
                this.profileMetrics[metric].mean +=
                  image.data.metrics.data[metric].mean
              }

              if (this.profileMetrics[metric] == undefined) {
                this.profileMetrics[metric] = {
                  ...image.data.metrics.data[metric]
                }
              }
            })
          })

          Object.keys(this.profileMetrics).forEach((metric) => {
            this.profileMetrics[metric].min /= this.images.length
            this.profileMetrics[metric].max /= this.images.length
            this.profileMetrics[metric].mean /= this.images.length
          })
        }

        this.reponseDataStatus = response.data.status
        if (
          response.data.status === 'CREATED' ||
          response.data.status === 'PROCESSING' ||
          response.data.status === 'QUEUE'
        ) {
          setTimeout(() => {
            this.fetchProfile(this.$route.params.profileId)
          }, 5000)
        }
      } catch (error) {
        console.log('ERRO', error)
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    }
  },
  async mounted() {
    await this.fetchAll()
  },

  computed: {
    themeScoreZMinTemp() {
      return window.localStorage.getItem('themeScoreZMinTemp')
    },
    themeScoreZMaxTemp() {
      return window.localStorage.getItem('themeScoreZMaxTemp')
    },
    themeDeltaMinTemp() {
      return window.localStorage.getItem('themeDeltaMinTemp')
    },
    themeDeltaMaxTemp() {
      return window.localStorage.getItem('themeDeltaMaxTemp')
    },
    dataTypes() {
      return [
        { text: this.$t('Table'), value: 'Table' },
        { text: this.$t('Chart'), value: 'Chart' }
      ]
    },
    compareTypes() {
      return [
        { text: this.$t('Mean'), value: 'mean' },
        { text: this.$t('Minimum'), value: 'min' },
        { text: this.$t('Maximum'), value: 'max' }
      ]
    },
    vista() {
      let labelVista =
        this.images != undefined &&
        this.images.length > 0 &&
        this.images[0].view != undefined
          ? this.images[0].view
          : null

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = ' - ' + view.label
      })

      return labelVista
    },
    profileSegments() {
      if (this.images.length <= 0) return null
      if (this.images[0].segments === undefined) return null

      if (this.images.length) return null

      const segments = this.images[0].segments
      const tfSegData = tf.tensor(segments.data)

      this.images.forEach((img) => {
        tfSegData.add(tf.tensor(img.segments.data))
      })

      tfSegData.scalar(this.images.length)

      const taSegData = tfSegData.dataSync()
      const aSegData = Array.from(taSegData)

      segments.data = aSegData

      return segments
    },
    profileFlipRegisters() {
      if (this.images.length <= 0) return null
      if (this.images[0].flip_registers === undefined) return null

      if (this.images.length) return null

      const flipRegisters = this.images[0].flip_registers
      const tfFlipRegData = tf.tensor(flipRegisters.data)

      this.images.forEach((img) => {
        tfFlipRegData.add(tf.tensor(img.flip_registers.data))
      })

      tfFlipRegData.scalar(this.images.length)

      const taFlipRegData = tfFlipRegData.dataSync()
      const aFlipRegData = Array.from(taFlipRegData)

      flipRegisters.data = aFlipRegData

      return flipRegisters
    },
    metricsDelta() {
      const metricsDelta = {}
      Object.keys(this.profileMetrics).forEach((key) => {
        if (
          this.profileMetrics[key] != undefined &&
          this.images[this.carouselTermos].data.metrics.data[key] !=
            undefined &&
          this.profileMetrics[key] != null &&
          this.images[this.carouselTermos].data.metrics.data[key] != null
        ) {
          metricsDelta[key] = {
            max:
              this.images[this.carouselTermos].data.metrics.data[key].max -
              this.profileMetrics[key].max,
            min:
              this.images[this.carouselTermos].data.metrics.data[key].min -
              this.profileMetrics[key].min,
            mean:
              this.images[this.carouselTermos].data.metrics.data[key].mean -
              this.profileMetrics[key].mean
          }
        }
      })
      return metricsDelta
    },
    metricsScoreZ() {
      const metricsScoreZ = {}
      Object.keys(this.metricsDelta).forEach((sKeyMetric) => {
        const curImg =
          this.images[this.carouselTermos].data.metrics.data[sKeyMetric]
        metricsScoreZ[sKeyMetric] = {}

        if (this.metricsDelta[sKeyMetric] != undefined && curImg != undefined) {
          Object.keys(curImg).forEach((type) => {
            let stdDeviation = 0
            const profile = this.profileMetrics[sKeyMetric][type]
            const curDelta = curImg[type] - profile

            if (curImg[type] !== 0) {
              this.images.forEach((val, keyImg) => {
                if (
                  this.images[keyImg].data.metrics.data[sKeyMetric] !== null
                ) {
                  const img =
                    this.images[keyImg].data.metrics.data[sKeyMetric][type]
                  stdDeviation += Math.pow(img - profile, 2)
                }
              })

              stdDeviation = Math.sqrt(stdDeviation / this.images.length)

              metricsScoreZ[sKeyMetric][type] = curDelta / stdDeviation
            }
          })
        }
      })

      return metricsScoreZ
    },
    asymmetryItemsTable() {
      const returnData = {
        headers: [''],
        items: [
          [this.$t('Thermography') + ' (ºC)'],
          [this.$t('Profile') + ' (ºC)'],
          [this.$t('Delta') + ' (ºC)'],
          [this.$t('ScoreZ')]
        ]
      }

      const selectedContoursCopy = [...this.selectedContours]

      selectedContoursCopy.sort().forEach((contour) => {
        returnData.headers.push(contour)
        returnData.items[0].push(
          this.images[this.carouselTermos].data.metrics.data[contour][
            this.chartsSelectedCompareType
          ]
        )
        returnData.items[1].push(
          this.profileMetrics[contour][this.chartsSelectedCompareType]
        )
        returnData.items[2].push(
          this.metricsDelta[contour][this.chartsSelectedCompareType]
        )
        returnData.items[3].push(
          this.metricsScoreZ[contour][this.chartsSelectedCompareType]
        )
      })

      return returnData
    },
    profileItems() {
      return this.$store.getters.profiles.map((profile) => {
        return {
          text: profile.name,
          value: profile.id
        }
      })
    },
    getChartSeries() {
      let selectedContoursCopy = [...this.selectedContours]
      selectedContoursCopy = selectedContoursCopy.sort()
      console.log('selectedContoursCopy', selectedContoursCopy)
      console.log('this.profileMetrics', this.profileMetrics)
      console.log('this.images', this.images)

      const profile = selectedContoursCopy.map((contour) => {
        const fromProfile = parseFloat(
          this.profileMetrics[contour][this.chartsSelectedCompareType].toFixed(
            2
          )
        )
        return { y: fromProfile, x: this.translateItem(contour, 'full') }
      })
      const image = selectedContoursCopy.map((contour) => {
        const fromProfile = parseFloat(
          this.images[this.carouselTermos].data.metrics.data[contour][
            this.chartsSelectedCompareType
          ].toFixed(2)
        )
        return { y: fromProfile, x: this.translateItem(contour, 'full') }
      })
      return [
        {
          name: 'Profile',
          data: profile
        },
        {
          name: 'Image',
          data: image
        }
      ]
    }
  }
}
</script>

<style>
.carousel-profile-view .v-carousel__controls {
  width: calc(100% - 120px);
  margin-left: 60px;
}
</style>
<style scoped lang="scss">
.carousel-image {
  width: 100%;
  max-height: 400px;
  height: 100%;
}
.img-preview {
  width: 120px;
}
.selected-row {
  background: #ccc;
}
.red_danger {
  background: #fe4844;
  color: #fff;
}
.orange_danger {
  background: #f57b39;
  color: #fff;
}
.yellow_danger {
  background: #fbda68;
  color: #fff;
}
.gray_blue_danger {
  background: #b7c5ea;
  color: #fff;
}
.blue_danger {
  background: #3919ef;
  color: #fff;
}
.purple_danger {
  background: #7f2bb6;
  color: #fff;
}
</style>
