var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hover)?_c('div',{staticClass:"edit-modulo"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{class:{ 'hover-icon': hover, icon: true },on:{"click":_vm.onMove}},[_vm._v(" mdi-cursor-move ")])]}}],null,false,2219731388)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{class:{ 'hover-icon': hover, icon: true },on:{"click":_vm.onConfig}},[_vm._v(" mdi-hammer-wrench ")])]}}],null,false,2652431047)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{class:{ 'hover-icon': hover, icon: true },on:{"click":_vm.onCopy}},[_vm._v(" mdi-content-copy ")])]}}],null,false,2226289311)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{class:{ 'hover-icon': hover, icon: true },on:{"click":_vm.onDelete}},[_vm._v(" mdi-delete ")])]}}],null,false,3568395035)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }