import { thermoService } from '@/services'

export default {
  state: {
    images: [],
    createdAt: new Date().toISOString(),
    name: '',
    anamnese: '',
    tags: [],
    subject_id: null
  },
  mutations: {
    CLEAR_THERMO(state) {
      state.images = []
      state.createdAt = new Date().toISOString()
      state.name = ''
      state.anamnese = ''
      state.tags = []
      state.subject_id = null
    },
    UPDATE_TAGS(state, payload) {
      state.tags = payload
    },
    ADD_THERMOGRAPHY(state, payload) {
      state.images.push(payload)
    },
    REMOVE_THERMOGRAPHY_IMAGE(state, payload) {
      state.images.splice(payload, 1)
    },
    UPDATE_SUBJECT_ID(state, payload) {
      state.subject_id = payload
    },
    UPDATE_NAME(state, payload) {
      state.name = payload
    },
    UPDATE_ANAMNESE(state, payload) {
      state.anamnese = payload
    },
    UPDATE_CREATEDAT(state, payload) {
      state.createdAt = new Date(payload).toISOString()
    }
  },
  actions: {
    addThermoImage({ commit }, payload) {
      commit('ADD_THERMOGRAPHY', payload)
    },
    removeThermoImage({ commit }, payload) {
      commit('REMOVE_THERMOGRAPHY_IMAGE', payload)
    },
    updateThermoSubjectId({ commit }, payload) {
      commit('UPDATE_SUBJECT_ID', payload)
    },
    updateThermoName({ commit }, payload) {
      commit('UPDATE_NAME', payload)
    },
    updateThermoAnamnese({ commit }, payload) {
      commit('UPDATE_ANAMNESE', payload)
    },
    updateThermoCreatedAt({ commit }, payload) {
      commit('UPDATE_CREATEDAT', payload)
    },
    updateThermoTags({ commit }, payload) {
      commit('UPDATE_TAGS', payload)
    },
    clearThermo({ commit }) {
      commit('CLEAR_THERMO')
    },
    async updateThermo({ state }, payload) {
      const response = await thermoService.updateThermo(payload.id, {
        name: state.name,
        anamnese: state.anamnese,
        tags: state.tags
      })

      return response.data
    },
    // eslint-disable-next-line no-empty-pattern
    async removeThermo({}, payload) {
      await thermoService.destroy(payload)
    },
    // eslint-disable-next-line no-empty-pattern
    async addImage({}, payload) {
      const formData = new FormData()
      formData.append('thermographyId', payload.thermoId)
      formData.append('image', payload.img)
      if (payload.view === 'lateral_right' || payload.view === 'lateral_left') {
        formData.append('view', 'superiorLateral')
      } else {
        formData.append('view', payload.view)
      }
      formData.append('metadata', '{}')
      formData.append('createdAt', new Date(payload.createdAt).toISOString())
      formData.append('minTemp', payload.minTemp)
      formData.append('maxTemp', payload.maxTemp)
      formData.append('palette', 'Palette' + payload.paletteName)
      return await thermoService.addImage(formData)
    },
    // eslint-disable-next-line no-empty-pattern
    async deleteImage({}, payload) {
      return await thermoService.deleteImage(payload)
    },
    // eslint-disable-next-line no-empty-pattern
    async reprocessThermo({}, payload) {
      await thermoService.reprocess(payload)
    },
    async analyseThermo({ commit, state }) {
      const thermoResponse = await thermoService.createThermo({
        createdAt: new Date(state.createdAt).toISOString(),
        name: state.name,
        anamnese: state.anamnese,
        subjectId: state.subject_id,
        tags: state.tags,
        metadata: {}
      })
      const addImages = []
      for (const image of state.images) {
        const formData = new FormData()
        formData.append('thermographyId', thermoResponse.data.id)
        formData.append('image', image.img)
        if (image.view === 'lateral_right' || image.view === 'lateral_left') {
          formData.append('view', 'superiorLateral')
        } else {
          formData.append('view', image.view)
        }
        formData.append('metadata', '{}')
        if (Object.keys(image.pain).length > 0) {
          formData.append('pain', JSON.stringify(image.pain))
        }
        formData.append('createdAt', new Date(image.createdAt).toISOString())
        formData.append('minTemp', image.minTemp)
        formData.append('maxTemp', image.maxTemp)
        formData.append('palette', 'Palette' + image.paletteName)
        addImages.push(thermoService.addImage(formData))
      }
      await Promise.all(addImages)
      commit('CLEAR_THERMO')
      return thermoResponse.data
    }
  },
  getters: {
    thermoImages(state) {
      return state.images
    }
  }
}
