import http from '@/infra/api'

let index = () => {
  return http.get('report-template')
}

let show = (id) => {
  return http.get(`report-template/${id}`)
}

let store = (data) => {
  return http.post('report-template', data)
}

let update = (data) => {
  return http.put(`report-template/${data.id}`, data)
}

let destroy = (id) => {
  return http.delete(`report-template/${id}`)
}

export default {
  index,
  show,
  store,
  update,
  destroy
}
