var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-page-editor"},[_c('v-card',{staticClass:"content-page-editor",attrs:{"elevation":"8"}},[(_vm.rows.length == 0)?_c('empty-row-template'):_vm._e(),_vm._l((_vm.rows),function(row,idxRow){return _c('v-hover',{key:("row-" + idxRow),scopedSlots:_vm._u([{key:"default",fn:function(hoverRow){return [_c('v-row',{class:{
          'hover-element': hoverRow.hover
        },style:(Object.assign({}, {margin: 0},
          row.configs,
          {minHeight: '1px',
          position: 'relative'}))},[(row.cols.length == 0)?_c('empty-col-template'):_vm._e(),(_vm.isMovingNewModulo && hoverRow.hover && row.cols.length == 0)?_c('move-modulo-template',{attrs:{"text":"Módulo"}}):_vm._e(),_c('edit-modulo-tools',{attrs:{"hover":hoverRow.hover},on:{"onClickMove":_vm.onMove,"onClickConfig":function($event){return _vm.onConfig(idxRow)},"onClickCopy":function($event){return _vm.onCopy(idxRow)},"onClickDelete":function($event){return _vm.onDelete(idxRow)}}}),_vm._l((row.cols),function(col,idxCol){return _c('v-hover',{key:("col-" + idxCol),scopedSlots:_vm._u([{key:"default",fn:function(hoverCol){return [_c('v-col',{class:{ 'hover-element': hoverCol.hover },style:(Object.assign({}, col.configs,
              {minHeight: '1px',
              position: 'relative'}))},[(col.modulos.length == 0)?_c('empty-modulo-template'):_vm._e(),(
                _vm.isMovingNewModulo && hoverCol.hover && col.modulos.length == 0
              )?_c('move-modulo-template',{attrs:{"text":"Módulo"}}):_vm._e(),_c('edit-modulo-tools',{attrs:{"hover":hoverCol.hover},on:{"onClickMove":_vm.onMove,"onClickConfig":function($event){return _vm.onConfig(idxRow, idxCol)},"onClickCopy":function($event){return _vm.onCopy(idxRow, idxCol)},"onClickDelete":function($event){return _vm.onDelete(idxRow, idxCol)}}}),_vm._l((col.modulos),function(modulo,idxModulo){return _c('v-hover',{key:("modulo-" + idxModulo),scopedSlots:_vm._u([{key:"default",fn:function(hoverModulo){return [_c('div',{class:{ 'hover-element': hoverModulo.hover },style:(Object.assign({}, modulo.configs,
                  {minHeight: '1px',
                  width: '100%',
                  position: 'relative'}))},[_c('edit-modulo-tools',{attrs:{"hover":hoverModulo.hover},on:{"onClickMove":_vm.onMove,"onClickConfig":function($event){return _vm.onConfig(idxRow, idxCol, idxModulo)},"onClickCopy":function($event){return _vm.onCopy(idxRow, idxCol, idxModulo)},"onClickDelete":function($event){return _vm.onDelete(idxRow, idxCol, idxModulo)}}}),_vm._v(" "+_vm._s(modulo.value)+" "),(_vm.isMovingNewModulo && hoverModulo.hover)?_c('move-modulo-template',{attrs:{"text":"Módulo"}}):_vm._e()],1)]}}],null,true)})})],2)]}}],null,true)})})],2)]}}],null,true)})})],2),_c('v-card',{staticClass:"menu-page-editor",attrs:{"elevation":"8"}},[_c('menu-tools-editor',{on:{"onMouseDown":_vm.mouseDownSelectTool}})],1),_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.dialogConfigs),callback:function ($$v) {_vm.dialogConfigs=$$v},expression:"dialogConfigs"}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"text--subtitle"},[_c('v-icon',{staticStyle:{"padding-bottom":"2px"},attrs:{"color":"primary"}},[_vm._v("mdi-hammer-wrench")]),_vm._v(" "+_vm._s(_vm.textModulo)+" ")],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogConfigs = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-col',[_c('edit-modulo-form',{attrs:{"modulo":_vm.selectedModulo}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }