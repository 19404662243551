import { tagsService } from '@/services'

export default {
  state: { tags: [] },
  mutations: {
    SET_TAGS(state, payload) {
      state.tags = payload
    }
  },
  actions: {
    async fetchTags({ commit }) {
      const response = await tagsService.index()
      if (response.data.length > 0) {
        commit('SET_TAGS', response.data)
      } else {
        commit('SET_TAGS', [])
      }
    },
    async saveTag({ commit, state }, payload) {
      const response = await tagsService.store(payload)
      commit('SET_TAGS', [...state.tags, response.data])
    },
    async updateTag({ commit, state }, payload) {
      const response = await tagsService.update(payload)
      commit(
        'SET_TAGS',
        state.tags.map((tag) =>
          tag.id === response.data.id ? response.data : tag
        )
      )
    },

    async removeTag({ commit, state }, payload) {
      await tagsService.destroy(payload)
      commit(
        'SET_TAGS',
        state.tags.filter((tag) => tag.id !== payload)
      )
    }
  },
  getters: {
    tags: (state) => state.tags
  }
}
