import http from '@/infra/api'

let index = () => {
  return http.get('subjects')
}

let indexInactive = () => {
  return http.get('subjects/inactives')
}

let show = (id) => {
  return http.get(`subjects/${id}`)
}

let store = (data) => {
  return http.post('subjects', data)
}

let update = (data, id) => {
  return http.put(`subjects/${id}`, data)
}

let destroy = (id) => {
  return http.delete(`subjects/${id}`)
}

export default {
  index,
  indexInactive,
  show,
  store,
  update,
  destroy
}
