<template>
  <v-list dense>
    <v-list-item-group>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        @mousedown="onMouseDown(item.type)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="item.text"> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'MenuToolsEditor',

  data() {
    return {
      items: [
        {
          text: 'Cabeçalho',
          icon: 'mdi-format-text',
          type: 'header'
        },
        {
          text: 'Editor de Texto',
          icon: 'mdi-card-text',
          type: 'editor'
        },
        {
          text: 'Shortchode',
          icon: 'mdi-text-short',
          type: 'shortcode'
        },
        {
          text: 'Imagem',
          icon: 'mdi-image',
          type: 'image'
        }
      ]
    }
  },

  methods: {
    onMouseDown: function (type) {
      this.$emit('onMouseDown', type)
    }
  }
}
</script>
