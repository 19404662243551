var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadings.fetch)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('loading-screen')],1)],1):(
    _vm.reponseDataStatus === 'PROCESSING' ||
    _vm.reponseDataStatus === 'CREATED' ||
    _vm.reponseDataStatus === 'QUEUE'
  )?_c('v-card',[_c('v-col',[_c('v-col',[_c('span',[_vm._v(" "+_vm._s(_vm.reponseDataStatus === 'QUEUE' ? _vm.$t('ProfileReprocessing') : _vm.$t('ProfileProcessing'))+"...")]),_c('loading-screen-small')],1),_c('v-col',{staticClass:"pt-0 mt-n3"},[_c('span',{staticClass:"text-caption text--secondary"},[_vm._v(_vm._s(_vm.$t('ThisCanTakeSomeTime'))+"... ")])])],1)],1):(_vm.errors.fetch)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t('ErrorFetchingProfile')))])]):(_vm.selectedView !== '')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pa-3 ma-0 mx-5 mt-2",attrs:{"align":"center"}},[_c('div',{staticClass:"icon-container mr-3"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-box-multiple")])],1),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('EditThermographicProfile')))]),_c('span',{staticClass:"caption ml-3 text--secondary"},[_vm._v("| "+_vm._s(_vm.selectedViewLabel))])])],1),_c('v-card',{staticClass:"mt-4"},[_c('v-row',{staticClass:"mx-5"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"autofocus":"","label":_vm.$t('Name'),"dense":"","outlined":""},model:{value:(_vm.currentNameProfile),callback:function ($$v) {_vm.currentNameProfile=$$v},expression:"currentNameProfile"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.currentNameProfile === '' ||
            _vm.loadings.update ||
            _vm.selectedThermosImages.length < 2,"loading":_vm.loadings.update},on:{"click":_vm.handleUpdateProfile}},[_vm._v(_vm._s(_vm.$t('Update')))])],1)],1)],1),_c('v-card',{staticClass:"pt-6",attrs:{"flat":""}},[(_vm.paginatedThermos.length > 0)?_c('v-row',{staticClass:"px-6 mt-0 mx-0"},[_vm._l((_vm.paginatedThermos),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","lg":"2","md":"4"}},[_c('div',{staticClass:"h-full"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{class:['pb-4', 'thermo-card', hover ? 'on-hover' : null],attrs:{"elevation":hover ? 12 : 6,"align":"center"},on:{"click":function($event){return _vm.toggleThermoImage(item)}}},[(item.isSelected)?_c('div',{staticClass:"is-selected"},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")])],1):_vm._e(),_c('div',{staticClass:"div-thermo-img"},[_c('img',{attrs:{"src":item.src}})]),_c('h5',[_vm._v(_vm._s(item.thermography.subject.name))]),_c('h5',[_vm._v(_vm._s(item.thermography.name))]),_c('h5',[_vm._v(_vm._s(_vm.formatDate(item.createdAt, 'dd/MM/yyyy HH:mm')))]),_vm._l((item.thermography.tags),function(tag){return _c('v-chip',{key:tag.id,attrs:{"x-small":"","label":""}},[_vm._v(" "+_vm._s(tag.name)+" ")])})],2)]}}],null,true)})],1)])}),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pa-3"},[_c('v-pagination',{attrs:{"length":_vm.pageLength,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],2):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }