import { groupService, groupTypesService } from '@/services'

export default {
  state: { groups: [], groupTypes: [] },
  mutations: {
    SET_GROUPS(state, payload) {
      state.groups = payload
    },
    SET_GROUP_TYPES(state, payload) {
      state.groupTypes = payload
    }
  },
  actions: {
    async fetchGroups({ commit }) {
      const response = await groupService.index()
      if (response.data.length > 0) {
        commit('SET_GROUPS', response.data)
      } else {
        commit('SET_GROUPS', [])
      }
    },
    async saveGroup({ commit, state }, payload) {
      const response = await groupService.store(payload)
      commit('SET_GROUPS', [...state.groups, response.data])
      if (!state.groupTypes.includes(response.data.type)) {
        commit('SET_GROUP_TYPES', [...state.groupTypes, response.data.type])
      }
    },
    async updateGroup({ commit, state }, payload) {
      const response = await groupService.update(payload)
      commit(
        'SET_GROUPS',
        state.groups.map((group) =>
          group.id === response.data.id ? response.data : group
        )
      )
    },

    async removeGroup({ commit, state }, payload) {
      await groupService.destroy(payload)
      commit(
        'SET_GROUPS',
        state.groups.filter((group) => group.id !== payload)
      )
    },
    async fetchGroupTypes({ commit }) {
      const responseGroupTypes = await groupTypesService.index()
      if (responseGroupTypes.data.length > 0) {
        commit('SET_GROUP_TYPES', responseGroupTypes.data)
      } else {
        commit('SET_GROUP_TYPES', [])
      }
    }
  },
  getters: {
    groups: (state) => state.groups,
    groupTypes: (state) => state.groupTypes
  }
}
