<template>
  <div>
    <h3 align="center" class="mb-3">
      Nesta última série de termografias houve alguma ocorrência?
    </h3>
    <div class="feedback-options">
      <v-btn
        color="primary"
        width="100%"
        class="my-2"
        @click="handlerClickOption(option.value)"
        v-for="(option, key) in optionsFeedback"
        :key="key"
        >{{ option.label }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOptionFeedback: '',
      optionsFeedback: [
        {
          label: 'Não',
          value: ''
        },
        {
          label: 'Encaminhado para o ortopedista',
          value: ''
        },
        {
          label: 'Leves dores',
          value: ''
        }
      ]
    }
  },

  methods: {
    handlerClickOption(val) {
      console.log(val)
      this.$store.dispatch('setGlobalModal', {
        component: '',
        showNextPage: false,
        model: false
      })
    }
  }
}
</script>
