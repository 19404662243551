// axios
import axios from 'axios'
import store from '@/store'

let baseURL = process.env.VUE_APP_URL

const instance = axios.create({
  baseURL
})

instance.interceptors.request.use((config) => {
  if (store.getters.token) {
    config.headers.common.Authorization = `Bearer ${store.getters.token}`
  }

  return config
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      //ls.clear()
      store.dispatch('signOut')
    }
    return Promise.reject(error)
  }
)

export default instance
