<template>
  <v-row
    :style="{
      justifyContent: 'center',
      margin: 0,
      padding: '8px',
      border: 'dotted 2px gray'
    }"
  >
    Arraste um elemento para cá
  </v-row>
</template>

<script>
export default {
  name: 'EmptyRowTemplate'
}
</script>
