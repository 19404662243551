<template>
  <div>
    <div class="home-wrapper" @click="handleClick" />
    <div class="highlight-part" :style="highlightCss" />
    <div class="text-highlight-part" :style="textHighlightCss">
      {{ textHighlight }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultTemplate',

  props: {
    handleClick: Function
  },

  computed: {
    highlightCss() {
      return this.$store.getters.highlightCss
    },
    textHighlightCss() {
      return this.$store.getters.textHighlightCss
    },
    textHighlight() {
      return this.$store.getters.textHighlight
    }
  },

  mounted() {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden'
  }
}
</script>

<style scoped>
.home-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.highlight-part {
  transition: all 0.3s;
  position: fixed;
  border-radius: 4px;
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
}
.text-highlight-part {
  transition: all 0.3s;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--v-info-darken1);
  border-radius: 10px;
  padding: 16px;
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
}
</style>
