<template>
  <v-col class="pa-0" ref="container">
    <v-row justify="center" class="ma-0">
      <v-stage
        :config="configKonva"
        :id="refCanvasKelvin"
        :ref="refCanvasKelvin"
        @click="handlerOnClick"
        @touchstart="handlerOnClick"
        @touchend="handlerOnClick"
        @touchmove="handlerOnMouseMove"
        @mousemove="handlerOnMouseMove"
      >
        <v-layer>
          <v-group>
            <v-image :config="configImg" />
          </v-group>

          <v-group
            v-if="
              showContours &&
              filtredPolygons.length > 0 &&
              this.temperaturesMetrics[this.activeTemperaturesMetrics].type !==
                'none'
            "
          >
            <v-line
              v-for="(line, index) in filtredPolygons"
              :key="index"
              @click="!activeDrawings ? clickCanvas(line.name) : null"
              @touchend="!activeDrawings ? clickCanvas(line.name) : null"
              @touchmove="!activeDrawings ? (hoverContour = line.name) : null"
              @mousemove="!activeDrawings ? (hoverContour = line.name) : null"
              @mouseout="hoverContour = ''"
              :config="{
                x: 0,
                y: 0,
                points: line.vector,
                tension: 0,
                stroke: line.strokeColor,
                strokeWidth: 1,
                closed: true,
                fill: line.fillColor,
                scaleX: resizeRatio,
                scaleY: resizeRatio
              }"
            />
          </v-group>

          <v-group
            v-if="Object.keys(calculatedMetrics).length > 0 && !activeDrawings"
          >
            <v-text
              v-for="(position, key) in positionsMetrics"
              :key="key"
              @click="!activeDrawings ? clickCanvas(key) : null"
              @touchstart="!activeDrawings ? clickCanvas(key) : null"
              @touchend="!activeDrawings ? clickCanvas(key) : null"
              @touchmove="!activeDrawings ? (hoverContour = key) : null"
              @mousemove="!activeDrawings ? (hoverContour = key) : null"
              @mouseout="hoverContour = ''"
              :config="{
                x: isNaN(position.x) ? -100 : position.x,
                y: isNaN(position.y) ? -100 : position.y,
                text:
                  calculatedMetrics !== undefined &&
                  calculatedMetrics[key] !== undefined &&
                  calculatedMetrics[key][comparType] !== undefined &&
                  calculatedMetrics[key][comparType] != 0
                    ? calculatedMetrics[key][comparType].toFixed(2)
                    : '',
                fontSize: 12,
                width: 32,
                offsetX: 16,
                offsetY: 3,
                fill: 'white',
                shadowColor: 'black',
                shadowBlur: 0,
                shadowOffset: { x: 1, y: 1 },
                shadowOpacity: 0.5
              }"
            />
          </v-group>

          <v-group>
            <v-group
              v-for="(rect, key) in userDrawing.rects"
              :key="`rect-${key}`"
            >
              <v-rect
                :config="rect"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'rects')"
                @mouseleave="changeCursor('default')"
              />
              <v-text
                v-if="rect && rect.hasTemps"
                :config="rect.tempsDraw"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'rects')"
                @mouseleave="changeCursor('default')"
              />
            </v-group>
            <v-group
              v-for="(ellipse, key) in userDrawing.ellipses"
              :key="`ellipse-${key}`"
            >
              <v-ellipse
                :config="ellipse"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'ellipses')"
                @mouseleave="changeCursor('default')"
              />
              <v-text
                v-if="ellipse && ellipse.hasTemps"
                :config="ellipse.tempsDraw"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'ellipses')"
                @mouseleave="changeCursor('default')"
              />
            </v-group>
            <v-group
              v-for="(arrow, key) in userDrawing.arrows"
              :key="`arrow-${key}`"
            >
              <v-arrow
                :config="arrow"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'arrows')"
                @mouseleave="changeCursor('default')"
              />
            </v-group>
            <v-group
              v-for="(text, key) in userDrawing.texts"
              :key="`text-${key}`"
            >
              <v-rect
                v-if="text !== undefined"
                :config="text.rectConfig"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'texts')"
                @mouseleave="changeCursor('default')"
              />
              <v-text
                :config="text"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'texts')"
                @mouseleave="changeCursor('default')"
              />
            </v-group>
            <v-group
              v-for="(line, key) in userDrawing.lines"
              :key="`lines-${key}`"
            >
              <v-line
                :config="line"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'lines')"
                @mouseleave="changeCursor('default')"
              />
              <v-text
                v-if="line && line.hasTemps"
                :config="line.tempsDraw"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'lines')"
                @mouseleave="changeCursor('default')"
              />
            </v-group>
            <v-group
              v-for="(poly, key) in userDrawing.polys"
              :key="`polys-${key}`"
            >
              <v-line
                :config="poly"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'polys')"
                @mouseleave="changeCursor('default')"
              />
              <v-text
                v-if="poly && poly.hasTemps"
                :config="poly.tempsDraw"
                @mouseenter="handlerMouseEnterMoveEditDraw(key, 'polys')"
                @mouseleave="changeCursor('default')"
              />
            </v-group>
          </v-group>

          <v-group>
            <v-group
              v-if="editDrawConfig.drawIndex >= 0 && selectedUserTool === ''"
            >
              <v-rect
                v-for="(editConfig, idxEditConfig) in editResizePoints"
                :key="idxEditConfig"
                :config="editConfig"
                @mouseenter="changeCursor('crosshair')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
            </v-group>

            <v-group
              v-if="editDrawConfig.drawIndex >= 0 && selectedUserTool === ''"
              :config="removeDrawLayer"
            >
              <v-ellipse
                :config="removeDraw.ellipse"
                @click="removeCurrentDraw()"
                @touchend="removeCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
              <v-rect
                :config="removeDraw.rect"
                @click="removeCurrentDraw()"
                @touchend="removeCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
            </v-group>

            <v-group
              v-if="editDrawConfig.drawIndex >= 0 && selectedUserTool === ''"
              :config="copyDrawLayer"
            >
              <v-ellipse
                :config="copyDraw.ellipse"
                @click="copyCurrentDraw()"
                @touchend="copyCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
              <v-path
                :config="copyDraw.iconContentCopy"
                @click="copyCurrentDraw()"
                @touchend="copyCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
            </v-group>

            <v-group
              v-if="
                editDrawConfig.drawIndex >= 0 &&
                selectedUserTool === '' &&
                (editDrawConfig.drawType === 'lines' ||
                  editDrawConfig.drawType === 'arrows' ||
                  editDrawConfig.drawType === 'polys')
              "
              :config="mirrorDrawLayer"
            >
              <v-ellipse
                :config="mirrorDraw.ellipse"
                @click="mirrorCurrentDraw()"
                @touchend="mirrorCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
              <v-path
                :config="mirrorDraw.iconMirror"
                @click="mirrorCurrentDraw()"
                @touchstart="mirrorCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
            </v-group>

            <v-group
              v-if="
                editDrawConfig.drawIndex >= 0 &&
                selectedUserTool === '' &&
                editDrawConfig.drawType === 'texts'
              "
              :config="textDrawLayer"
            >
              <v-ellipse
                :config="textDraw.ellipse"
                @click="textCurrentDraw()"
                @touchend="textCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
              <v-path
                :config="textDraw.iconText"
                @click="textCurrentDraw()"
                @touchend="textCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
            </v-group>

            <v-group
              v-if="editDrawConfig.drawIndex >= 0 && selectedUserTool === ''"
              :config="colorDrawLayer"
            >
              <v-ellipse
                :config="colorDraw.ellipse"
                @click="colorCurrentDraw()"
                @touchend="colorCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
              <v-path
                :config="colorDraw.iconColor"
                @click="colorCurrentDraw()"
                @touchend="colorCurrentDraw()"
                @mouseenter="changeCursor('pointer')"
                @mouseleave="
                  () =>
                    editDrawConfig.indexResize === -1
                      ? changeCursor('default')
                      : null
                "
              />
            </v-group>
          </v-group>

          <v-group v-if="printCanvas">
            <v-text
              v-if="topLeftLabel != ''"
              :key="`topLeftLabel`"
              :config="{
                text: topLeftLabel,
                x: 5 * resizeRatio,
                y: 5 * resizeRatio,
                width: 800 * resizeRatio,
                height: 20 * resizeRatio,
                fill: 'white',
                fontSize: 13 * resizeRatio
              }"
            />
            <v-text
              v-if="topLeftLabelB != ''"
              :key="`topLeftLabelB`"
              :config="{
                text: topLeftLabelB,
                x: 5 * resizeRatio,
                y: 22 * resizeRatio,
                width: 800 * resizeRatio,
                height: 20 * resizeRatio,
                fill: 'white',
                fontSize: 13 * resizeRatio
              }"
            />
            <v-text
              v-for="(temp, keyTemp) in userDrawTempsLateral"
              :key="`roi-temp-${temp.id}`"
              :config="{
                text: `${temp.id} - ${temp.text}`,
                x: 5 * resizeRatio,
                y: (56 + keyTemp * 22) * resizeRatio,
                width: 400 * resizeRatio,
                height: 20 * resizeRatio,
                fill: 'white',
                fontSize: 13 * resizeRatio
              }"
            />

            <v-text
              v-if="usedTemps.min != null"
              :key="`usedTempsMin`"
              :config="{
                text: usedTemps.min.toFixed(2),
                align: 'right',
                x: configKonva.width - 215 * resizeRatio,
                y: configKonva.height - 40 * resizeRatio,
                width: 200 * resizeRatio,
                height: 20 * resizeRatio,
                fill: 'white',
                fontSize: 13 * resizeRatio
              }"
            />
            <v-text
              v-if="usedTemps.max != null"
              :key="`usedTempsMax`"
              :config="{
                text: usedTemps.max.toFixed(2),
                align: 'right',
                x: configKonva.width - 215 * resizeRatio,
                y: 30 * resizeRatio,
                width: 200 * resizeRatio,
                height: 20 * resizeRatio,
                fill: 'white',
                fontSize: 13 * resizeRatio
              }"
            />
          </v-group>
        </v-layer>
      </v-stage>
      <div class="lateral-roi-temperatures" v-if="!hideControls">
        <div v-for="temp in userDrawTempsLateral" :key="`roi-temp-${temp.id}`">
          <span v-if="temp.text !== undefined">{{
            `${temp.id} - ${temp.text}`
          }}</span>
        </div>
      </div>
      <div class="tools-user-drawings" v-if="!hideControls">
        <div class="btns-thermo-kelvin-canvas">
          <button
            @click="changeTempsMetrics"
            :title="temperaturesMetrics[activeTemperaturesMetrics].title"
          >
            <v-icon
              >mdi-{{
                temperaturesMetrics[activeTemperaturesMetrics].icon
              }}</v-icon
            >
          </button>
        </div>
        <div v-if="activateEditDrawings">
          <div>
            <button
              @click="toggleActiveDrawings"
              class="btn-edit-tools-kelvin-canvas"
            >
              <v-icon>mdi-hammer-screwdriver</v-icon>
            </button>
          </div>
          <div v-if="activeDrawings">
            <div v-for="(drawTool, idx) in userDrawToolsRender" :key="idx">
              <button
                @click="changeDrawTool(drawTool.type)"
                :class="`btn-drawing ${
                  (selectedUserTool.includes(drawTool.type) &&
                    drawTool.type != '') ||
                  selectedUserTool == drawTool.type
                    ? 'selected-drawing'
                    : null
                }`"
              >
                <v-icon>{{ drawTool.icon }}</v-icon>
                <v-icon
                  v-if="
                    selectedUserTool.includes(drawTool.type) &&
                    selectedUserTool.includes('_text') &&
                    drawTool.type != ''
                  "
                  class="suffix-icon-text"
                  >mdi-format-text</v-icon
                >
              </button>
            </div>

            <v-color-picker
              v-if="
                selectedUserTool === 'palette' || editDrawConfig.isEditingColor
              "
              v-model="selectedUserColor"
              dot-size="15"
              swatches-max-height="200"
              hide-inputs
              class="selected-color-palette"
            />

            <div
              class="div-text-user-draw"
              v-if="currentDrawConfig.editingText"
            >
              <v-text-field
                v-model="textUserDraw"
                hide-details="auto"
                ref="ref-text-user-draw"
                @blur="() => endEditingTextUserTools()"
                @keyup="
                  (e) =>
                    e.key === 'Enter' || e.keyCode === 13
                      ? endEditingTextUserTools()
                      : null
                "
              />
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <img :src="this.imageUrl" v-show="false" ref="image" />
    <img
      :src="this.originalImageUrlBlob"
      v-if="this.originalImageUrlBlob != ''"
      v-show="false"
      ref="originalImageBlob"
    />
  </v-col>
</template>

<script>
import VueKonva from 'vue-konva'
import Vue from 'vue'
import { thermoService, reportsService } from '@/services'

Vue.use(VueKonva)
export default {
  data: () => ({
    copyDraw: {
      iconContentCopy: {
        x: -1,
        y: -1,
        scaleX: 0.6,
        scaleY: 0.6,
        data: 'M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z',
        fill: 'white'
      },
      ellipse: {
        x: 6,
        y: 6,
        radiusX: 12,
        radiusY: 12,
        fill: 'red'
      }
    },
    mirrorDraw: {
      iconMirror: {
        x: -1,
        y: -1,
        scaleX: 0.6,
        scaleY: 0.6,
        data: 'M8.29 10.28L11.53 7.03L12.59 8.09L9.35 11.34L8.29 10.28M8.7 14.61L14.36 8.95L15.42 10L9.76 15.67L8.7 14.61M18 3V21H6V3H18M20 1H4V23H20V1Z',
        fill: 'white'
      },
      ellipse: {
        x: 6,
        y: 6,
        radiusX: 12,
        radiusY: 12,
        fill: 'red'
      }
    },
    textDraw: {
      iconText: {
        x: -1,
        y: -1,
        scaleX: 0.6,
        scaleY: 0.6,
        data: 'M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z',
        fill: 'white'
      },
      ellipse: {
        x: 6,
        y: 6,
        radiusX: 12,
        radiusY: 12,
        fill: 'red'
      }
    },
    colorDraw: {
      iconColor: {
        x: -1,
        y: -1,
        scaleX: 0.6,
        scaleY: 0.6,
        data: 'M17.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,9A1.5,1.5 0 0,1 19,10.5A1.5,1.5 0 0,1 17.5,12M14.5,8A1.5,1.5 0 0,1 13,6.5A1.5,1.5 0 0,1 14.5,5A1.5,1.5 0 0,1 16,6.5A1.5,1.5 0 0,1 14.5,8M9.5,8A1.5,1.5 0 0,1 8,6.5A1.5,1.5 0 0,1 9.5,5A1.5,1.5 0 0,1 11,6.5A1.5,1.5 0 0,1 9.5,8M6.5,12A1.5,1.5 0 0,1 5,10.5A1.5,1.5 0 0,1 6.5,9A1.5,1.5 0 0,1 8,10.5A1.5,1.5 0 0,1 6.5,12M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A1.5,1.5 0 0,0 13.5,19.5C13.5,19.11 13.35,18.76 13.11,18.5C12.88,18.23 12.73,17.88 12.73,17.5A1.5,1.5 0 0,1 14.23,16H16A5,5 0 0,0 21,11C21,6.58 16.97,3 12,3Z',
        fill: 'white'
      },
      ellipse: {
        x: 6,
        y: 6,
        radiusX: 12,
        radiusY: 12,
        fill: 'red'
      }
    },
    configKonva: {
      x: 0,
      y: 0,
      width: 400,
      height: 300,
      scaleX: 1,
      scaleY: 1
    },
    image: new Image(),
    baseWidth: 320,
    polygons: [],
    polygonBg: [],
    polygonBg2d: [],
    selectedItems: [],
    verticalImage: false,
    resizeRatio: 1,
    resizeRatioZoom: 1,
    imageWidth: 0,
    rotation: 0,
    widthImage: 400,
    heightImage: 300,
    hoverContour: '',
    activateTexts: false,

    currentIdTemps: 0,
    defaultConfigTextTemps: {
      width: 24,
      height: 14,
      fill: 'white',
      shadowColor: 'black',
      shadowBlur: 0,
      shadowOffset: { x: 1, y: 1 },
      shadowOpacity: 0.5,
      text: '',
      drawIndex: null,
      drawType: null,
      isTempsDraw: true
    },
    timerTempsDrawings: null,

    activeDrawings: false,
    userDrawTools: ['rects', 'arrows', 'ellipses', 'texts', 'lines', 'polys'],
    userDrawToolsRender: [
      {
        type: '',
        icon: 'mdi-cursor-default-outline'
      },
      {
        type: 'palette',
        icon: 'mdi-palette'
      },
      {
        type: 'zoom-in',
        icon: 'mdi-magnify-plus'
      },
      {
        type: 'zoom-out',
        icon: 'mdi-magnify-minus'
      },
      {
        type: 'rects',
        icon: 'mdi-rectangle-outline'
      },
      {
        type: 'ellipses',
        icon: 'mdi-circle-outline'
      },
      {
        type: 'arrows',
        icon: 'mdi-arrow-bottom-right-thin'
      },
      {
        type: 'texts',
        icon: 'mdi-format-text-variant'
      },
      {
        type: 'lines',
        icon: 'mdi-tilde'
      },
      {
        type: 'polys',
        icon: 'mdi-vector-polygon'
      },
      {
        type: 'trash',
        icon: 'mdi-trash-can-outline'
      },
      {
        type: 'save',
        icon: 'mdi-content-save'
      }
    ],
    userDrawing: {
      rects: [],
      arrows: [],
      ellipses: [],
      texts: [],
      lines: [],
      polys: []
    },
    selectedUserTool: '',
    selectedUserColor: '#FF0000FF',
    selectedUserStrokeWidth: 2,
    textUserDraw: '',
    removeDraw: {
      rect: {
        x: 3,
        y: 5,
        width: 6,
        height: 2,
        fill: 'white'
      },
      ellipse: {
        x: 6,
        y: 6,
        radiusX: 6,
        radiusY: 6,
        fill: 'red'
      }
    },
    currentDrawConfig: {
      x: -1,
      y: -1,
      index: -1,
      editingText: false
    },
    editDrawConfig: {
      defaultRender: {
        x: -1,
        y: -1,
        width: 8,
        height: 8,
        fill: 'black',
        strokeWidth: 2,
        stroke: 'white',
        drawConfigCopy: {},
        pointResize: true,
        indexResize: -1
      },
      isMoving: false,
      isEditingColor: false,
      indexResize: -1,
      drawIndex: -1,
      drawType: ''
    },

    activeTemperaturesMetrics: 0
  }),
  props: {
    usedTemps: {
      type: Object
    },
    printCanvas: {
      type: Boolean,
      default: false
    },
    topLeftLabel: {
      type: String,
      default: ''
    },
    topLeftLabelB: {
      type: String,
      default: ''
    },
    refCanvasKelvin: {
      type: String,
      required: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    dataArray: {
      type: Array
    },
    showContours: {
      type: Boolean,
      default: true
    },
    removeAsymmetryTemps: {
      type: Boolean,
      default: false
    },
    activeTempMetrics: {
      type: Number,
      default: 0
    },
    onChangeActiveTempMetrics: {
      type: Function,
      default() {
        return () => null
      }
    },
    onChangeActiveTempMetricsObject: {
      type: Function,
      default() {
        return () => null
      }
    },
    activateEditDrawings: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      required: true
    },
    originalImageUrlBlob: {
      type: String,
      default: '',
      required: false
    },
    data: {
      type: Object
    },
    metrics: {
      type: Object
    },
    meta: {
      type: Object
    },
    selectedContours: {
      type: Array,
      default: () => []
    },
    view: {
      type: String,
      required: true
    },
    onChangeSelected: {
      type: Function
    },
    canResize: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      default: 400
    },
    comparType: {
      type: String,
      default: 'mean'
    },
    thermoImageId: {
      type: String,
      required: false
    }
  },
  computed: {
    themeRoiTempMinPerc() {
      return this.$store.getters.themeRoiTempMinPerc
    },
    themeRoiTempMaxPerc() {
      return this.$store.getters.themeRoiTempMaxPerc
    },
    themeRoiTempMeanPerc() {
      return this.$store.getters.themeRoiTempMeanPerc
    },
    userDrawTempsLateral() {
      const drawingTypes = ['rects', 'ellipses', 'lines', 'polys']
      const userDrawTemps = []

      drawingTypes.forEach((type) => {
        this.userDrawing[type].forEach((item) => {
          if (item && item.hasTemps) {
            userDrawTemps.push({
              id: item.idTemps,
              text: item.tempsDraw.textTemps
            })

            if (
              item.extraData.isMirror != undefined &&
              item.extraData.isMirror
            ) {
              let copiedItem =
                this.userDrawing[type][item.extraData.copiedIndex]
              let minTemp =
                item.tempsDraw.minTemp - copiedItem.tempsDraw.minTemp
              let maxTemp =
                item.tempsDraw.maxTemp - copiedItem.tempsDraw.maxTemp
              let meanTemp =
                item.tempsDraw.meanTemp - copiedItem.tempsDraw.meanTemp
              userDrawTemps.push({
                id: `Δ ${item.idTemps} - ${copiedItem.idTemps}`,
                text: `${this.$t('MIN')}: ${minTemp.toFixed(2)} ${this.$t(
                  'MAX'
                )}: ${maxTemp.toFixed(2)} ${this.$t(
                  'MEAN'
                )}: ${meanTemp.toFixed(2)}`
              })
            }
          }
        })
      })

      userDrawTemps.sort((a, b) => a.id - b.id)

      return userDrawTemps
    },
    temperaturesMetrics() {
      return [
        {
          icon: 'thermometer',
          type: 'metrics',
          title: this.$t('Temperature')
        },
        {
          icon: 'thermometer-minus',
          type: 'asymmetry',
          title: this.$t('Asymmetry')
        },
        {
          icon: 'thermometer-off',
          type: 'none',
          title: this.$t('TemperatureOff')
        }
      ]
    },
    editResizePoints() {
      const editResizePoints = []
      const currentDraw =
        this.userDrawing[this.editDrawConfig.drawType][
          this.editDrawConfig.drawIndex
        ]

      let maxPoints = this.editDrawConfig.drawType === 'arrows' ? 2 : 4
      if (
        this.editDrawConfig.drawType === 'lines' ||
        this.editDrawConfig.drawType === 'polys'
      )
        maxPoints = 0

      for (let idxRender = 0; idxRender < maxPoints; idxRender++) {
        editResizePoints[idxRender] = {
          ...this.editDrawConfig.defaultRender
        }

        editResizePoints[idxRender].indexResize = idxRender

        editResizePoints[idxRender].x =
          idxRender === 1 || idxRender === 3
            ? currentDraw.x -
              editResizePoints[idxRender].width / 2 +
              currentDraw.width
            : currentDraw.x - editResizePoints[idxRender].width / 2
        editResizePoints[idxRender].y =
          idxRender === 2 || idxRender === 3
            ? currentDraw.y -
              editResizePoints[idxRender].height / 2 +
              currentDraw.height
            : currentDraw.y - editResizePoints[idxRender].height / 2

        if (this.editDrawConfig.drawType === 'ellipses') {
          editResizePoints[idxRender].x =
            idxRender === 1 || idxRender === 3
              ? currentDraw.x + currentDraw.radiusX
              : currentDraw.x - currentDraw.radiusX
          editResizePoints[idxRender].y =
            idxRender === 2 || idxRender === 3
              ? currentDraw.y + currentDraw.radiusY
              : currentDraw.y - currentDraw.radiusY
        } else if (this.editDrawConfig.drawType === 'arrows') {
          editResizePoints[idxRender].x =
            idxRender === 1
              ? currentDraw.x +
                currentDraw.points[2] -
                editResizePoints[idxRender].width / 2
              : currentDraw.x - editResizePoints[idxRender].width / 2
          editResizePoints[idxRender].y =
            idxRender === 1
              ? currentDraw.y +
                currentDraw.points[3] -
                editResizePoints[idxRender].width / 2
              : currentDraw.y - editResizePoints[idxRender].width / 2
        } else if (this.editDrawConfig.drawType === 'texts') {
          editResizePoints[idxRender].x =
            idxRender === 1 || idxRender === 3
              ? currentDraw.rectConfig.x -
                editResizePoints[idxRender].width / 2 +
                currentDraw.rectConfig.width
              : currentDraw.rectConfig.x - editResizePoints[idxRender].width / 2
          editResizePoints[idxRender].y =
            idxRender === 2 || idxRender === 3
              ? currentDraw.rectConfig.y -
                editResizePoints[idxRender].height / 2 +
                currentDraw.rectConfig.height
              : currentDraw.rectConfig.y -
                editResizePoints[idxRender].height / 2
        }
      }

      return editResizePoints
    },
    removeDrawLayer() {
      const currentDraw =
        this.userDrawing[this.editDrawConfig.drawType][
          this.editDrawConfig.drawIndex
        ]

      let x =
        currentDraw.x + currentDraw.width / 2 - this.removeDraw.ellipse.radiusX
      let y = currentDraw.y + this.removeDraw.ellipse.radiusX

      if (this.editDrawConfig.drawType === 'ellipses') {
        x = currentDraw.x - this.removeDraw.ellipse.radiusX
        y =
          currentDraw.y - currentDraw.radiusY + this.removeDraw.ellipse.radiusX
      } else if (this.editDrawConfig.drawType === 'arrows') {
        x =
          currentDraw.x +
          currentDraw.points[2] / 2 -
          this.removeDraw.ellipse.radiusX
        y =
          currentDraw.y +
          currentDraw.points[3] / 2 -
          this.removeDraw.ellipse.radiusY
      } else if (
        this.editDrawConfig.drawType === 'lines' ||
        this.editDrawConfig.drawType === 'polys'
      ) {
        x = currentDraw.posCenter.x - this.removeDraw.ellipse.radiusX
        y = currentDraw.posInit.y + 8
      }

      return {
        x,
        y
      }
    },
    copyDrawLayer() {
      const currentDraw =
        this.userDrawing[this.editDrawConfig.drawType][
          this.editDrawConfig.drawIndex
        ]

      let x = currentDraw.x + currentDraw.width - this.copyDraw.ellipse.radiusX
      let y = currentDraw.y + this.copyDraw.ellipse.radiusX

      if (this.editDrawConfig.drawType === 'ellipses') {
        x = currentDraw.x + currentDraw.radiusX - this.copyDraw.ellipse.radiusX
        y = currentDraw.y - currentDraw.radiusY + this.copyDraw.ellipse.radiusX
      } else if (this.editDrawConfig.drawType === 'arrows') {
        x =
          currentDraw.x + currentDraw.points[2] - this.copyDraw.ellipse.radiusX
        y =
          currentDraw.y +
          currentDraw.points[3] / 2 -
          this.copyDraw.ellipse.radiusY
      } else if (
        this.editDrawConfig.drawType === 'lines' ||
        this.editDrawConfig.drawType === 'polys'
      ) {
        x = currentDraw.posEnd.x - this.copyDraw.ellipse.radiusX
        y = currentDraw.posInit.y + 8
      }

      return {
        x,
        y
      }
    },
    mirrorDrawLayer() {
      const currentDraw =
        this.userDrawing[this.editDrawConfig.drawType][
          this.editDrawConfig.drawIndex
        ]

      let x = currentDraw.x
      let y = currentDraw.y + this.mirrorDraw.ellipse.radiusX

      if (this.editDrawConfig.drawType === 'arrows') {
        x = currentDraw.x - this.mirrorDraw.ellipse.radiusX
        y =
          currentDraw.y +
          currentDraw.points[3] / 2 -
          this.mirrorDraw.ellipse.radiusY
      } else if (
        this.editDrawConfig.drawType === 'lines' ||
        this.editDrawConfig.drawType === 'polys'
      ) {
        x = currentDraw.posInit.x - this.mirrorDraw.ellipse.radiusX
        y = currentDraw.posInit.y + 8
      }

      return {
        x,
        y
      }
    },
    textDrawLayer() {
      const currentDraw =
        this.userDrawing[this.editDrawConfig.drawType][
          this.editDrawConfig.drawIndex
        ]

      let x = currentDraw.x
      let y = currentDraw.y + this.textDraw.ellipse.radiusX

      return {
        x,
        y
      }
    },
    colorDrawLayer() {
      const currentDraw =
        this.userDrawing[this.editDrawConfig.drawType][
          this.editDrawConfig.drawIndex
        ]

      let x = currentDraw.x
      let y = currentDraw.y + this.mirrorDraw.ellipse.radiusX

      if (this.editDrawConfig.drawType === 'ellipses') {
        x = currentDraw.x + currentDraw.radiusX - this.copyDraw.ellipse.radiusX
        y = currentDraw.y - currentDraw.radiusY + this.copyDraw.ellipse.radiusX
      } else if (this.editDrawConfig.drawType === 'arrows') {
        x = currentDraw.x - this.mirrorDraw.ellipse.radiusX
        y =
          currentDraw.y +
          currentDraw.points[3] / 2 -
          this.mirrorDraw.ellipse.radiusY
      } else if (
        this.editDrawConfig.drawType === 'lines' ||
        this.editDrawConfig.drawType === 'polys'
      ) {
        x = currentDraw.posInit.x - this.mirrorDraw.ellipse.radiusX
        y = currentDraw.posInit.y + 8
      }

      if (this.editDrawConfig.drawType !== 'rects') {
        y += 6 + this.mirrorDraw.ellipse.radiusY * 2
      }

      return {
        x,
        y
      }
    },
    calculatedMetrics() {
      const metrics = {
        metrics: this.metrics,
        asymmetry: this.asymmetryMetrics,
        none: {}
      }

      if (this.metrics === undefined) return {}

      if (
        !Object.keys(metrics).includes(
          this.temperaturesMetrics[this.activeTemperaturesMetrics].type
        )
      )
        return {}

      return metrics[
        this.temperaturesMetrics[this.activeTemperaturesMetrics].type
      ]
    },
    asymmetryMetrics() {
      const asymmetry = {}

      if (this.metrics === undefined) return asymmetry

      Object.keys(this.metrics).forEach((keyMetric) => {
        if (keyMetric.includes('left')) {
          const keyMetricRight = keyMetric.replace('left', 'right')

          if (
            Object.keys(this.metrics).includes(keyMetricRight) &&
            Object.keys(this.metrics).includes(keyMetric) &&
            this.metrics[keyMetric] != null &&
            this.metrics[keyMetricRight] != null
          ) {
            asymmetry[keyMetric] = { ...this.metrics[keyMetric] }
            asymmetry[keyMetricRight] = { ...this.metrics[keyMetricRight] }

            Object.keys(this.metrics[keyMetric]).forEach((keyRange) => {
              if (
                Object.keys(this.metrics[keyMetric]).includes(keyRange) &&
                Object.keys(this.metrics[keyMetricRight]).includes(keyRange) &&
                this.metrics[keyMetric][keyRange] != null &&
                this.metrics[keyMetricRight][keyRange] != null
              ) {
                asymmetry[keyMetric][keyRange] -=
                  this.metrics[keyMetricRight][keyRange]
                asymmetry[keyMetricRight][keyRange] -=
                  this.metrics[keyMetric][keyRange]
              }
            })
          }
        }
      })

      return asymmetry
    },
    configImg() {
      return {
        x: 0,
        y: 0,
        image: this.image,
        width: this.image.width,
        height: this.image.height
      }
    },
    filtredPolygons() {
      const selectedColor = {
        default: '#0000ff',
        warning: '#ff9d00',
        danger: '#ff0000'
      }

      const returnPolygons = []

      this.polygons.forEach((polygon) => {
        let idxSelected = 'default'

        if (
          this.temperaturesMetrics[this.activeTemperaturesMetrics].type ===
          'asymmetry'
        ) {
          idxSelected = this.getColorType(
            this.calculatedMetrics?.[polygon.name]?.[this.comparType]
          )
        }

        returnPolygons.push({
          ...polygon,
          strokeColor:
            this.selectedItems.includes(polygon.name) &&
            !this.activeDrawings &&
            this.temperaturesMetrics[this.activeTemperaturesMetrics].type !==
              'none'
              ? selectedColor[idxSelected]
              : '#fff',
          fillColor:
            this.selectedItems.includes(polygon.name) &&
            !this.activeDrawings &&
            this.temperaturesMetrics[this.activeTemperaturesMetrics].type !==
              'none'
              ? `${selectedColor[idxSelected]}66`
              : ''
        })
      })

      return returnPolygons
    },
    positionsMetrics() {
      const positions = {}
      if (this.data === undefined) return positions

      Object.keys(this.data).forEach((key) => {
        if (
          this.data[key] !== null &&
          this.data[key] !== undefined &&
          this.data[key] !== null &&
          this.data[key] !== undefined &&
          (this.selectedItems.includes(key) || this.hoverContour === key)
        ) {
          positions[key] = {
            x: 0,
            y: 0
          }
          let i = 0

          this.data[key].forEach((el) => {
            i += 1
            positions[key].x += el[0]
            positions[key].y += el[1]
          })

          positions[key].x = parseInt(positions[key].x / i) * this.resizeRatio
          positions[key].y = parseInt(positions[key].y / i) * this.resizeRatio
        }
      })
      return positions
    }
  },
  async mounted() {
    if (this.selectedContours.length > 0)
      this.selectedItems = this.selectedContours
    if (this.removeAsymmetryTemps) this.temperaturesMetrics.splice(1, 1)
    this.activeTemperaturesMetrics = this.activeTempMetrics

    if (this.canResize) {
      window.addEventListener('resize', async () => {
        if (this.$refs.container) {
          this.configKonva.width = this.$refs.container.clientWidth
          await this.setupImage()
        }
      })
    }
    this.baseWidth =
      this.meta !== undefined &&
      this.meta.imageWidth !== undefined &&
      this.meta.imageWidth > 0
        ? this.meta.imageWidth
        : this.baseWidth

    this.$refs.image.addEventListener('load', () => {
      // if (this.originalImageUrlBlob != '') {
      //   return
      // }
      this.widthImage = this.$refs.image.width
      this.heightImage = this.$refs.image.height

      this.configKonva.width = this.$refs.container.clientWidth
      this.configKonva.height = this.maxHeight

      this.setupImage()
    })
    // this.$refs.originalImageBlob.addEventListener('load', () => {
    //   this.$refs.image.width = this.$refs.originalImageBlob.width
    //   this.$refs.image.height = this.$refs.originalImageBlob.height
    //   this.widthImage = this.$refs.originalImageBlob.width
    //   this.heightImage = this.$refs.originalImageBlob.height

    //   this.configKonva.width = this.$refs.container.clientWidth
    //   this.configKonva.height = this.maxHeight

    //   this.setupImage()
    // })
    this.$refs.image.src = this.imageUrl

    this.loadRoi()
  },
  watch: {
    thermoImageId() {
      this.loadRoi()
    },
    selectedUserColor(color) {
      const mirrorIndex = this.editDrawConfig.drawIndex
      const mirrorType = this.editDrawConfig.drawType

      if (mirrorType !== '' && mirrorIndex >= 0) {
        const currentDraw = this.userDrawing[mirrorType][mirrorIndex]
        currentDraw.stroke = color

        if (mirrorType === 'arrows' || mirrorType === 'texts') {
          currentDraw.fill = color
        }
      }
    },
    selectedContours(val) {
      this.selectedItems = val !== undefined ? val : []
    },
    textUserDraw(val) {
      const idxDraw =
        this.editDrawConfig.drawIndex >= 0
          ? this.editDrawConfig.drawIndex
          : this.currentDrawConfig.index
      const drawType =
        this.editDrawConfig.drawType !== ''
          ? this.editDrawConfig.drawType
          : this.selectedUserTool

      if (this.currentDrawConfig.editingText)
        this.userDrawing[drawType][idxDraw].text = val
    },
    activeTemperaturesMetrics(val) {
      this.onChangeActiveTempMetrics(val)
      this.onChangeActiveTempMetricsObject(this.temperaturesMetrics[val])
    },
    activeTempMetrics(val) {
      this.activeTemperaturesMetrics = val
    },
    data() {
      this.setupImage()
    }
  },
  methods: {
    percentile(arr, q) {
      if (arr.length === 0) return null
      if (q < 0 || q > 100) return null

      let sorted = arr.slice().sort((a, b) => a - b)
      let pos = ((sorted.length - 1) * q) / 100
      let base = Math.floor(pos)
      let rest = pos - base

      if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base])
      } else {
        return sorted[base]
      }
    },
    async saveRoi() {
      if (this.$route.params.thermographyId == null) {
        this.$store.dispatch('setSnackBar', {
          text: `Termografia não encontrada...`,
          type: 'success',
          model: true
        })
        return
      }
      if (this.thermoImageId == null || this.thermoImageId == '') {
        this.$store.dispatch('setSnackBar', {
          text: `Imagem não encontrada...`,
          type: 'success',
          model: true
        })
        return
      }

      this.$store.dispatch('setSnackBar', {
        text: `Salvando ROI...`,
        type: 'success',
        model: true
      })

      try {
        const thermographySeries = await reportsService.getSingleThermo(
          this.$route.params.thermographyId
        )

        let metadata = thermographySeries.data.metadata
        const name = thermographySeries.data.name
        const tags = thermographySeries.data.tags.map((tag) => tag.id)

        if (metadata == null) {
          metadata = {}
        }
        if (metadata.rois == null) {
          metadata.rois = {}
        }

        metadata.rois[this.thermoImageId] = this.userDrawing

        await thermoService.updateThermo(this.$route.params.thermographyId, {
          metadata,
          name,
          tags
        })

        this.$store.dispatch('setSnackBar', {
          text: `ROI salvo com sucesso!`,
          type: 'success',
          model: true
        })
      } catch (e) {
        console.log(e)

        this.$store.dispatch('setSnackBar', {
          text: `Erro ao tentar salvar o ROI!`,
          type: 'error',
          model: true
        })
      }
    },
    async loadRoi() {
      if (!this.activateEditDrawings) {
        return
      }
      if (this.$route.params.thermographyId == null) {
        return
      }
      if (this.thermoImageId == null || this.thermoImageId == '') {
        return
      }

      // if (confirm('Carregar anotações de teste?')) {
      //   await new Promise((resolve) => setTimeout(resolve, 2000))
      //   for (let iPoly = 0; iPoly < this.polygons.length; iPoly++) {
      //     const el = this.polygons[iPoly]
      //     let contourPoly = JSON.parse(JSON.stringify(el.vector))
      //     contourPoly.push(contourPoly[0])
      //     contourPoly.push(contourPoly[1])
      //     for (
      //       let iContourPoly = 0;
      //       iContourPoly < contourPoly.length;
      //       iContourPoly++
      //     ) {
      //       contourPoly[iContourPoly] =
      //         contourPoly[iContourPoly] * this.resizeRatio
      //     }

      //     const poly = {
      //       x: 0,
      //       y: 0,
      //       stroke: '#FF0000FF',
      //       strokeWidth: 2,
      //       hasTemps: true,
      //       tempsDraw: {
      //         width: 24,
      //         height: 14,
      //         fill: 'white',
      //         shadowColor: 'black',
      //         shadowBlur: 0,
      //         shadowOffset: {
      //           x: 1,
      //           y: 1
      //         },
      //         shadowOpacity: 0.5,
      //         text: 2,
      //         drawIndex: 0,
      //         drawType: 'polys',
      //         isTempsDraw: true,
      //         x: 0,
      //         y: 0,
      //         minTemp: 0,
      //         meanTemp: 0,
      //         maxTemp: 0,
      //         textTemps: 'MIN: 0.00 MAX: 0.00 MÉDIA: 0.00'
      //       },
      //       extraData: {
      //         drawIndex: 0,
      //         drawType: 'polys'
      //       },
      //       idTemps: iPoly + 1,
      //       points: contourPoly,
      //       tension: 0,
      //       closed: false,
      //       posCenter: {
      //         x: 0,
      //         y: 0
      //       },
      //       posInit: {
      //         x: 0,
      //         y: 0
      //       },
      //       posEnd: {
      //         x: 0,
      //         y: 0
      //       }
      //     }

      //     this.userDrawing.polys.push(poly)

      //     this.setTempsDrawing('polys', iPoly)
      //   }

      //   return
      // }

      try {
        const thermographySeries = await reportsService.getSingleThermo(
          this.$route.params.thermographyId
        )

        const metadata = thermographySeries.data.metadata

        if (
          metadata == null ||
          (metadata != null && metadata.rois == null) ||
          (metadata != null &&
            metadata.rois != null &&
            metadata.rois[this.thermoImageId] == null)
        ) {
          return
        }

        this.userDrawing = metadata.rois[this.thermoImageId]
      } catch (e) {
        console.log(e)
      }
    },
    changeTempsMetrics() {
      this.temperaturesMetrics.length !== this.activeTemperaturesMetrics + 1
        ? this.activeTemperaturesMetrics++
        : (this.activeTemperaturesMetrics = 0)
    },
    toggleActiveDrawings() {
      this.activeDrawings = !this.activeDrawings
    },
    pointInPolygon(polygon, point) {
      //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
      let odd = false
      //For each edge (In this case for each point of the polygon and the previous one)
      for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
        //If a line from the point into infinity crosses this edge
        if (
          polygon[i][1] > point[1] !== polygon[j][1] > point[1] && // One point needs to be above, one below our y coordinate
          // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
          point[0] <
            ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
              (polygon[j][1] - polygon[i][1]) +
              polygon[i][0]
        ) {
          // Invert odd
          odd = !odd
        }
        j = i
      }
      //If the number of crossings was odd, the point is in the polygon
      return odd
    },
    getObjectPointsInsidePolygon(polygon = [], idx, type = 'lines') {
      const includesArray = (data, arr) => {
        return data.some(
          (e) => Array.isArray(e) && e.every((o, i) => Object.is(arr[i], o))
        )
      }
      const pointsInsidePolygon = []
      const polygon2d = []
      let minX = 999999
      let maxX = 0
      let minY = 999999
      let maxY = 0
      for (let i = 0; i < polygon.length; i += 2) {
        const posX = this.userDrawing[type][idx].x + polygon[i]
        const posY = this.userDrawing[type][idx].y + polygon[i + 1]
        minX = posX < minX ? posX : minX
        maxX = posX > maxX ? posX : maxX
        minY = posY < minY ? posY : minY
        maxY = posY > maxY ? posY : maxY
        polygon2d.push([posX, posY])
      }

      const posCenter = {
        x: minX + (maxX - minX) / 2,
        y: minY + (maxY - minY) / 2
      }

      const posInit = {
        x: minX,
        y: minY
      }

      const posEnd = {
        x: maxX,
        y: maxY
      }

      for (let posXW = minX; posXW < maxX; posXW++) {
        for (let posYH = minY; posYH < maxY; posYH++) {
          if (
            this.pointInPolygon(polygon2d, [posXW, posYH]) &&
            !this.pointInPolygon(this.polygonBg2d, [posXW, posYH]) &&
            !includesArray(pointsInsidePolygon, [
              Math.round(posXW / this.resizeRatio),
              Math.round(posYH / this.resizeRatio)
            ])
          ) {
            pointsInsidePolygon.push([
              Math.round(posXW / this.resizeRatio),
              Math.round(posYH / this.resizeRatio)
            ])
          }
        }
      }

      return {
        pointsInsidePolygon,
        posCenter,
        posInit,
        posEnd
      }
    },
    setTempsDrawing(selectedTool, idx) {
      if (selectedTool === 'rects' && idx >= 0) {
        const draw = this.userDrawing[selectedTool][idx]

        const xAxisInit =
          draw.width < 0
            ? parseInt((draw.x + draw.width) / this.resizeRatio)
            : parseInt(draw.x / this.resizeRatio)
        const xAxisMax =
          draw.width < 0
            ? parseInt(draw.x / this.resizeRatio)
            : parseInt((draw.x + draw.width) / this.resizeRatio)
        const yAxisInit =
          draw.height < 0
            ? parseInt((draw.y + draw.height) / this.resizeRatio)
            : parseInt(draw.y / this.resizeRatio)
        const yAxisMax =
          draw.height < 0
            ? parseInt(draw.y / this.resizeRatio)
            : parseInt((draw.y + draw.height) / this.resizeRatio)
        let minTemp = 0
        let arrayTemp = []
        let maxTemp = 0
        let meanTemp = 0

        for (let xAxis = xAxisInit; xAxis <= xAxisMax; xAxis++) {
          for (let yAxis = yAxisInit; yAxis <= yAxisMax; yAxis++) {
            if (this.dataArray[yAxis][xAxis] > 10) {
              arrayTemp.push(this.dataArray[yAxis][xAxis])
            }
          }
        }

        if (arrayTemp.length > 0) {
          maxTemp = this.percentile(arrayTemp, this.themeRoiTempMaxPerc)

          minTemp = this.percentile(arrayTemp, this.themeRoiTempMinPerc)

          meanTemp =
            arrayTemp.reduce(function (acc, cur) {
              return acc + cur
            }) / arrayTemp.length
        }

        draw.tempsDraw = {
          ...this.defaultConfigTextTemps,
          extraData: draw.extraData,
          x: draw.width < 0 ? draw.x + 5 + draw.width : draw.x + 5,
          y: draw.height < 0 ? draw.y + 5 + draw.height : draw.y + 5,
          drawIndex: idx,
          drawType: selectedTool,
          text: draw.idTemps,
          minTemp,
          meanTemp,
          maxTemp,
          textTemps: `${this.$t('MIN')}: ${minTemp.toFixed(2)} ${this.$t(
            'MAX'
          )}: ${maxTemp.toFixed(2)} ${this.$t('MEAN')}: ${meanTemp.toFixed(2)}`
        }
      } else if (selectedTool === 'ellipses') {
        const draw = this.userDrawing[selectedTool][idx]

        const centerPosX = draw.radiusX
        const centerPosY = draw.radiusY
        const semiCenterPosX = centerPosX - 1
        const semiCenterPosY = centerPosY - 1
        const width = centerPosX * 2
        const height = centerPosY * 2

        let minTemp = 0
        let arrayTemp = []
        let maxTemp = 0
        let meanTemp = 0

        for (let posY = 0; posY < height; posY++) {
          for (let posX = 0; posX < width; posX++) {
            if (
              ((posX - centerPosX) * (posX - centerPosX)) /
                (semiCenterPosX * semiCenterPosX) +
                ((posY - centerPosY) * (posY - centerPosY)) /
                  (semiCenterPosY * semiCenterPosY) <=
              1.0
            ) {
              const xAxis = parseInt(
                (posX + draw.x - centerPosX) / this.resizeRatio
              )
              const yAxis = parseInt(
                (posY + draw.y - centerPosY) / this.resizeRatio
              )
              if (this.dataArray[yAxis][xAxis] > 10) {
                arrayTemp.push(this.dataArray[yAxis][xAxis])
              }
            }
          }
        }

        if (arrayTemp.length > 0) {
          maxTemp = this.percentile(arrayTemp, this.themeRoiTempMaxPerc)

          minTemp = this.percentile(arrayTemp, this.themeRoiTempMinPerc)

          meanTemp =
            arrayTemp.reduce(function (acc, cur) {
              return acc + cur
            }) / arrayTemp.length
        }

        draw.tempsDraw = {
          ...this.defaultConfigTextTemps,
          extraData: draw.extraData,
          x: draw.x - this.defaultConfigTextTemps.width / 2,
          y: draw.y - this.defaultConfigTextTemps.height / 2,
          drawIndex: idx,
          drawType: selectedTool,
          text: draw.idTemps,
          minTemp,
          meanTemp,
          maxTemp,
          textTemps: `${this.$t('MIN')}: ${minTemp.toFixed(2)} ${this.$t(
            'MAX'
          )}: ${maxTemp.toFixed(2)} ${this.$t('MEAN')}: ${meanTemp.toFixed(2)}`
        }
      } else if (selectedTool === 'lines' || selectedTool === 'polys') {
        this.setTempsDrawLines(idx, selectedTool)
      }
    },
    setTempsDrawLines(idx, type = 'lines') {
      const draw = this.userDrawing[type][idx]
      const objectPointsInsidePolygon = this.getObjectPointsInsidePolygon(
        draw.points,
        idx,
        type
      )

      let minTemp = 0
      let arrayTemp = []
      let maxTemp = 0
      let meanTemp = 0

      objectPointsInsidePolygon.pointsInsidePolygon.forEach((point) => {
        if (this.dataArray[point[1]][point[0]] > 10) {
          arrayTemp.push(this.dataArray[point[1]][point[0]])
        }
      })

      if (arrayTemp.length > 0) {
        maxTemp = this.percentile(arrayTemp, this.themeRoiTempMaxPerc)

        minTemp = this.percentile(arrayTemp, this.themeRoiTempMinPerc)

        meanTemp =
          arrayTemp.reduce(function (acc, cur) {
            return acc + cur
          }) / arrayTemp.length
      }

      draw.tempsDraw = {
        ...this.defaultConfigTextTemps,
        x:
          objectPointsInsidePolygon.posCenter.x -
          this.defaultConfigTextTemps.width / 2,
        y:
          objectPointsInsidePolygon.posCenter.y -
          this.defaultConfigTextTemps.height / 2,
        drawIndex: idx,
        drawType: type,
        text: draw.idTemps,
        minTemp,
        meanTemp,
        maxTemp,
        textTemps: `${this.$t('MIN')}: ${minTemp.toFixed(2)} ${this.$t(
          'MAX'
        )}: ${maxTemp.toFixed(2)} ${this.$t('MEAN')}: ${meanTemp.toFixed(2)}`
      }
      draw.posCenter = objectPointsInsidePolygon.posCenter
      draw.posInit = objectPointsInsidePolygon.posInit
      draw.posEnd = objectPointsInsidePolygon.posEnd
    },
    debounceSetTempsDrawing(selectedTool, idx) {
      clearTimeout(this.timerTempsDrawings)

      this.timerTempsDrawings = setTimeout(
        () => this.setTempsDrawing(selectedTool, idx),
        300
      )
    },
    removeCurrentDraw() {
      if (!confirm(this.$t('AreYouSureYouWantToDeleteThisAnnotation'))) {
        return
      }
      const deleteIndex = this.editDrawConfig.drawIndex
      const deleteType = this.editDrawConfig.drawType
      if (deleteIndex >= 0 && deleteType != '') {
        delete this.userDrawing[deleteType][deleteIndex]
        this.clearCurrentDrawConfig()
        this.clearEditDrawConfig()
      }
    },
    copyCurrentDraw() {
      const copyIndex = this.editDrawConfig.drawIndex
      const copyType = this.editDrawConfig.drawType
      if (copyIndex >= 0 && copyType != '') {
        const currentDraw = JSON.parse(
          JSON.stringify(this.userDrawing[copyType][copyIndex])
        )
        const newCurrentIndex = this.userDrawing[copyType].length

        if (['rects', 'ellipses', 'lines', 'polys'].includes(copyType)) {
          this.currentIdTemps++
          currentDraw.idTemps = this.currentIdTemps
        }
        currentDraw.x += 20
        currentDraw.y += 20
        currentDraw.extraData.drawIndex = newCurrentIndex
        currentDraw.extraData.copiedIndex = copyIndex

        if (copyType === 'texts') {
          currentDraw.rectConfig.x += 20
          currentDraw.rectConfig.y += 20
        }

        this.userDrawing[copyType].push(currentDraw)

        this.editDrawConfig.drawIndex = newCurrentIndex

        this.setTempsDrawing(copyType, newCurrentIndex)
      }
    },
    mirrorCurrentDraw() {
      const mirrorIndex = this.editDrawConfig.drawIndex
      const mirrorType = this.editDrawConfig.drawType
      if (mirrorIndex >= 0 && mirrorType != '') {
        const currentDraw = this.userDrawing[mirrorType][mirrorIndex]

        if (mirrorType === 'arrows') {
          const widthDraw = currentDraw.points[2]
          const xDraw = currentDraw.x

          currentDraw.x = xDraw + widthDraw
          currentDraw.points[2] = widthDraw * -1
        } else if (mirrorType === 'lines' || mirrorType === 'polys') {
          for (let i = 0; i < currentDraw.points.length; i += 2) {
            const posX = currentDraw.x + currentDraw.points[i]
            const posCenterX = currentDraw.posCenter.x
            currentDraw.points[i] =
              posCenterX + (posX - posCenterX) * -1 - currentDraw.x
          }

          if (currentDraw.extraData.copiedIndex != undefined) {
            if (currentDraw.extraData.isMirror == undefined) {
              currentDraw.extraData.isMirror = false
            }
            currentDraw.extraData.isMirror = !currentDraw.extraData.isMirror
          }

          this.setTempsDrawing(mirrorType, mirrorIndex)
        }
      }
    },
    async textCurrentDraw() {
      const idxDraw = this.editDrawConfig.drawIndex
      const drawType = this.editDrawConfig.drawType

      const currentDraw = this.userDrawing[drawType][idxDraw]

      this.currentDrawConfig.index = idxDraw
      this.currentDrawConfig.editingText = true
      this.textUserDraw = currentDraw.text

      await this.$nextTick()
      this.$refs['ref-text-user-draw'].$refs.input.focus()
    },
    async colorCurrentDraw() {
      await this.$nextTick()
      this.editDrawConfig.isEditingColor = true
    },
    getColorType(number = 0) {
      let colorType = 'default'

      if (number > 0.99) colorType = 'danger'
      else if (number > 0.49) colorType = 'warning'
      else if (number < -0.99) colorType = 'danger'
      else if (number < -0.49) colorType = 'warning'

      return colorType
    },
    async setupPolygons() {
      const polygons =
        this.data !== undefined
          ? Object.keys(this.data)
              .filter((key) => !this.data?.key && key !== 'bg')
              .map((key) => {
                return {
                  name: key,
                  vector: this.data[key]?.reduce(
                    (acc, curVal) => acc.concat(curVal),
                    []
                  )
                }
              })
          : []
      this.polygons = polygons
      this.polygonBg = this.data?.bg?.reduce((acc, curVal) =>
        acc.concat(curVal)
      )
      let polygonBg2d = []
      await new Promise((resolve) => setTimeout(resolve, 2000))
      if (this.polygonBg != undefined) {
        for (let i = 0; i < this.polygonBg.length; i += 2) {
          polygonBg2d.push([
            this.polygonBg[i] * this.resizeRatio,
            this.polygonBg[i + 1] * this.resizeRatio
          ])
        }
      }
      this.polygonBg2d = polygonBg2d
    },
    async getImageSize(url) {
      return new Promise((resolve) => {
        const tmpImage = new Image()
        tmpImage.src = url
        tmpImage.onload = function () {
          resolve({
            width: tmpImage.width,
            height: tmpImage.height
          })
        }
      })
    },
    clickCanvas(name) {
      if (this.selectedItems.includes(name)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== name)
      } else {
        this.selectedItems.push(name)
      }
      if (this.onChangeSelected !== undefined)
        this.onChangeSelected(this.selectedItems)
    },
    clearCanvas() {
      this.selectedItems = []
    },
    async setupImage() {
      let newHeight = 0
      let newWidth = 0

      newHeight = this.configKonva.height
      newWidth = (this.configKonva.height * this.widthImage) / this.heightImage
      this.configKonva.width = newWidth

      this.resizeRatio = newWidth / this.widthImage

      this.image = new Image(newWidth, newHeight)
      this.image.src = this.imageUrl
      await this.setupPolygons()
    },
    handleZoomingClick(event) {
      if (
        !(
          this.selectedUserTool === 'zoom-in' ||
          this.selectedUserTool === 'zoom-out'
        )
      )
        return

      const oldResize = this.resizeRatioZoom

      if (this.selectedUserTool === 'zoom-in') {
        this.resizeRatioZoom *= 1.5
      } else if (
        this.selectedUserTool === 'zoom-out' &&
        this.resizeRatioZoom > 1
      ) {
        this.resizeRatioZoom /= 1.5
      }

      const cursorPos = this.getCursorPosition(event)

      const pointToX = (cursorPos.x - this.configKonva.x) / oldResize
      const pointToY = (cursorPos.y - this.configKonva.y) / oldResize

      this.configKonva.x =
        this.resizeRatioZoom === 1
          ? 0
          : cursorPos.x - pointToX * this.resizeRatioZoom
      this.configKonva.y =
        this.resizeRatioZoom === 1
          ? 0
          : cursorPos.y - pointToY * this.resizeRatioZoom
      this.configKonva.scaleX = this.resizeRatioZoom
      this.configKonva.scaleY = this.resizeRatioZoom

      this.setupImage()
    },
    handleZoomingMove() {
      if (
        !(
          this.selectedUserTool === 'zoom-in' ||
          this.selectedUserTool === 'zoom-out'
        )
      )
        return

      if (
        this.selectedUserTool === 'zoom-in' ||
        this.selectedUserTool === 'zoom-out'
      ) {
        this.changeCursor(this.selectedUserTool)
      }
    },
    handlerOnClick(event) {
      this.handleZoomingClick(event)
      this.drawOnCanvas(event, event.type)
      this.selectDrawOnCanvas(event, event.type)
    },
    handlerOnMouseMove(event) {
      this.handleZoomingMove()
      this.drawOnCanvas(event, event.type)
      this.selectDrawOnCanvas(event, event.type)
    },
    getInitConfigDrawCanvas(event) {
      const cursorPos = this.getCursorPosition(event)
      const defaultConfig = {
        x: (cursorPos.x - this.configKonva.x) / this.resizeRatioZoom,
        y: (cursorPos.y - this.configKonva.y) / this.resizeRatioZoom,
        stroke: this.selectedUserColor,
        strokeWidth: this.selectedUserStrokeWidth,
        hasTemps: false,
        tempsDraw: { ...this.defaultConfigTextTemps },
        extraData: {
          drawIndex: this.currentDrawConfig.index,
          drawType: this.selectedUserTool
        }
      }

      const config = {
        rects: {
          ...defaultConfig,
          idTemps: this.currentIdTemps,
          hasTemps: true,
          width: 0,
          height: 0
        },
        arrows: {
          ...defaultConfig,
          points: [0, 0, 0, 0],
          pointerLength: 10,
          pointerWidth: 10,
          fill: this.selectedUserColor
        },
        ellipses: {
          ...defaultConfig,
          idTemps: this.currentIdTemps,
          hasTemps: true,
          radiusX: 0,
          radiusY: 0
        },
        texts: {
          ...defaultConfig,
          rectConfig: {
            ...defaultConfig,
            width: 0,
            height: 0,
            strokeWidth: 1
          },
          text: '',
          fill: this.selectedUserColor,
          fontSize: 14,
          strokeWidth: 0
        },
        lines: {
          ...defaultConfig,
          idTemps: this.currentIdTemps,
          hasTemps: true,
          points: [0, 0],
          tension: 0,
          closed: false
        },
        polys: {
          ...defaultConfig,
          idTemps: this.currentIdTemps,
          hasTemps: true,
          points: [0, 0, 0, 0],
          tension: 0,
          closed: false
        }
      }

      return config[this.selectedUserTool]
    },
    getCurrentConfigDrawCanvas(event) {
      const cursorPos = this.getCursorPosition(event)

      const curX = (cursorPos.x - this.configKonva.x) / this.resizeRatioZoom
      const curY = (cursorPos.y - this.configKonva.y) / this.resizeRatioZoom

      const curDrawX =
        (this.currentDrawConfig.x - this.configKonva.x) / this.resizeRatioZoom
      const curDrawY =
        (this.currentDrawConfig.y - this.configKonva.y) / this.resizeRatioZoom

      const radiusXEllipse = (curX - curDrawX) / 2
      const radiusYEllipse = (curY - curDrawY) / 2
      const polyPoints =
        this.userDrawing.polys.length > 0 &&
        this.userDrawing.polys[this.currentDrawConfig.index] !== undefined
          ? [...this.userDrawing.polys[this.currentDrawConfig.index].points]
          : [0, 0, 0, 0]

      if (polyPoints.length > 3) {
        polyPoints[polyPoints.length - 2] = curX - curDrawX
        polyPoints[polyPoints.length - 1] = curY - curDrawY
      }

      const config = {
        rects: {
          width: curX - curDrawX,
          height: curY - curDrawY
        },
        arrows: {
          points: [0, 0, curX - curDrawX, curY - curDrawY]
        },
        ellipses: {
          x: curDrawX + radiusXEllipse,
          y: curDrawY + radiusYEllipse,
          radiusX: Math.abs(radiusXEllipse),
          radiusY: Math.abs(radiusYEllipse)
        },
        texts: {
          x: curX - curDrawX < 0 ? curX : curDrawX,
          y: curY - curDrawY < 0 ? curY : curDrawY,
          width: Math.abs(curX - curDrawX),
          height: Math.abs(curY - curDrawY)
        },
        lines: {
          points:
            this.userDrawing.lines.length > 0 &&
            this.userDrawing.lines[this.currentDrawConfig.index] !== undefined
              ? [
                  ...this.userDrawing.lines[this.currentDrawConfig.index]
                    .points,
                  curX - curDrawX,
                  curY - curDrawY
                ]
              : [0, 0, 0, 0]
        },
        polys: {
          points: polyPoints
        }
      }

      return config[this.selectedUserTool]
    },
    getCursorPosition(event) {
      return {
        x:
          event.currentTarget.pointerPos !== undefined &&
          event.currentTarget.pointerPos.x !== undefined
            ? event.currentTarget.pointerPos.x
            : event.evt.layerX,
        y:
          event.currentTarget.pointerPos !== undefined &&
          event.currentTarget.pointerPos.y !== undefined
            ? event.currentTarget.pointerPos.y
            : event.evt.layerY
      }
    },
    initCurrentDrawConfig(event) {
      const cursorPos = this.getCursorPosition(event)

      this.currentDrawConfig.index =
        this.userDrawing[this.selectedUserTool].length
      this.currentDrawConfig.x = cursorPos.x
      this.currentDrawConfig.y = cursorPos.y
    },
    async endCurrentDrawConfig(type, event) {
      if (type !== 'click' && type !== 'touchstart' && type !== 'touchend')
        return

      const currentDraw =
        this.userDrawing[this.selectedUserTool][this.currentDrawConfig.index]

      if (this.selectedUserTool === 'polys') {
        const cursorPos = this.getCursorPosition(event)
        const newPosX = cursorPos.x - this.currentDrawConfig.x
        const newPosY = cursorPos.y - this.currentDrawConfig.y

        if (
          currentDraw.points.length > 6 &&
          newPosX >= -10 &&
          newPosX <= 10 &&
          newPosY >= -10 &&
          newPosY <= 10
        ) {
          this.debounceSetTempsDrawing(
            this.selectedUserTool,
            this.currentDrawConfig.index
          )

          currentDraw.points[currentDraw.points.length - 2] = 0
          currentDraw.points[currentDraw.points.length - 1] = 0

          this.currentDrawConfig.index = -1
          return
        }

        currentDraw.points.push(newPosX)
        currentDraw.points.push(newPosY)

        return
      }
      if (this.selectedUserTool === 'lines') {
        this.debounceSetTempsDrawing(
          this.selectedUserTool,
          this.currentDrawConfig.index
        )

        currentDraw.points.push(0)
        currentDraw.points.push(0)
      }
      if (this.selectedUserTool === 'texts') {
        if (this.currentDrawConfig.editingText) {
          this.endEditingTextUserTools()
          return
        }
        this.currentDrawConfig.editingText = true
        await this.$nextTick()
        this.$refs['ref-text-user-draw'].$refs.input.focus()
      } else this.currentDrawConfig.index = -1
    },
    endEditingTextUserTools() {
      this.currentDrawConfig.index = -1
      this.currentDrawConfig.editingText = false
      this.textUserDraw = ''
    },
    changeDrawTool(type) {
      this.clearEditDrawConfig()

      if (this.currentDrawConfig.index >= 0) {
        delete this.userDrawing[this.selectedUserTool][
          this.currentDrawConfig.index
        ]
        this.currentDrawConfig.index = -1
      }

      if (type === 'trash') {
        if (!confirm(this.$t('AreYouSureYouWantToDeleteAllAnnotations'))) {
          return
        }
        this.userDrawing = {
          rects: [],
          arrows: [],
          ellipses: [],
          texts: [],
          lines: [],
          polys: []
        }
        this.clearCurrentDrawConfig()
        this.clearEditDrawConfig()
        return
      } else if (type === 'save') {
        this.saveRoi()
        this.clearEditDrawConfig()
        return
      }

      if (
        this.selectedUserTool.includes(type) &&
        this.selectedUserTool != 'texts' &&
        type != '' &&
        this.activateTexts
      ) {
        if (this.selectedUserTool.includes('_text')) {
          this.selectedUserTool = this.selectedUserTool.replace('_text', '')
        } else {
          this.selectedUserTool += '_text'
        }
      } else {
        this.selectedUserTool = type
      }
    },
    changeDrawSize(event) {
      const idxResize = this.editDrawConfig.indexResize
      const idxDraw = this.editDrawConfig.drawIndex
      const drawType = this.editDrawConfig.drawType
      const drawCopy = this.editDrawConfig.drawConfigCopy

      const currentDraw = this.userDrawing[drawType][idxDraw]

      const cursorPos = this.getCursorPosition(event)

      const curX = (cursorPos.x - this.configKonva.x) / this.resizeRatioZoom
      const curY = (cursorPos.y - this.configKonva.y) / this.resizeRatioZoom

      let editKey = []
      if (drawType === 'rects') {
        editKey = [
          ['x', 'y', 'width', 'height'],
          ['y', 'width', 'height'],
          ['x', 'width', 'height'],
          ['width', 'height']
        ]
      } else if (drawType === 'texts') {
        editKey = [
          ['x', 'y', 'width', 'height'],
          ['x', 'y', 'width', 'height'],
          ['x', 'y', 'width', 'height'],
          ['x', 'y', 'width', 'height']
        ]
      } else if (drawType === 'ellipses') {
        editKey = [
          ['x', 'y', 'radiusX', 'radiusY'],
          ['x', 'y', 'radiusX', 'radiusY'],
          ['x', 'y', 'radiusX', 'radiusY'],
          ['x', 'y', 'radiusX', 'radiusY']
        ]
      } else if (drawType === 'arrows') {
        editKey = [['x', 'y', 'points'], ['points']]
      }

      let valuesByKey = {}
      if (drawType === 'rects') {
        valuesByKey = {
          x: curX,
          y: curY,
          width:
            idxResize === 0 || idxResize === 2
              ? drawCopy.x - curX + drawCopy.width
              : curX - drawCopy.x,
          height:
            idxResize === 0 || idxResize === 1
              ? drawCopy.y - curY + drawCopy.height
              : curY - drawCopy.y
        }
      } else if (drawType === 'texts') {
        const textsWidth =
          idxResize === 0 || idxResize === 2
            ? drawCopy.x - curX + drawCopy.width
            : curX - drawCopy.x
        const textsHeight =
          idxResize === 0 || idxResize === 1
            ? drawCopy.y - curY + drawCopy.height
            : curY - drawCopy.y
        let textsX = textsWidth < 0 ? drawCopy.x + drawCopy.width : curX
        let textsY = textsHeight < 0 ? drawCopy.y + drawCopy.height : curY

        if (idxResize === 1 || idxResize === 3) {
          textsX = textsWidth < 0 ? drawCopy.x + textsWidth : drawCopy.x
        }
        if (idxResize === 2 || idxResize === 3) {
          textsY = textsHeight < 0 ? drawCopy.y + textsHeight : drawCopy.y
        }

        valuesByKey = {
          x: textsX,
          y: textsY,
          width: Math.abs(textsWidth),
          height: Math.abs(textsHeight)
        }
      } else if (drawType === 'ellipses') {
        const radiusXEllipse =
          idxResize === 0 || idxResize === 2
            ? (drawCopy.x - curX + drawCopy.radiusX) / 2
            : (curX - drawCopy.x + drawCopy.radiusX) / 2
        const radiusYEllipse =
          idxResize === 0 || idxResize === 1
            ? (drawCopy.y - curY + drawCopy.radiusY) / 2
            : (curY - drawCopy.y + drawCopy.radiusY) / 2
        valuesByKey = {
          x:
            idxResize === 0 || idxResize === 2
              ? drawCopy.x + (curX - drawCopy.x + drawCopy.radiusX) / 2
              : drawCopy.x - drawCopy.radiusX + radiusXEllipse,
          y:
            idxResize === 0 || idxResize === 1
              ? drawCopy.y + (curY - drawCopy.y + drawCopy.radiusY) / 2
              : drawCopy.y - drawCopy.radiusY + radiusYEllipse,
          radiusX: Math.abs(radiusXEllipse),
          radiusY: Math.abs(radiusYEllipse)
        }
      } else if (drawType === 'arrows') {
        valuesByKey = {
          x: drawCopy.x,
          y: drawCopy.y,
          points: [...drawCopy.points]
        }
        if (idxResize === 1) {
          valuesByKey.points[2] = curX - drawCopy.x
          valuesByKey.points[3] = curY - drawCopy.y
        } else if (idxResize === 0) {
          valuesByKey.x = curX
          valuesByKey.y = curY
          valuesByKey.points[2] = drawCopy.points[2] - curX + drawCopy.x
          valuesByKey.points[3] = drawCopy.points[3] - curY + drawCopy.y
        }
      }

      editKey[idxResize].forEach((key) => {
        currentDraw[key] = valuesByKey[key]

        if (drawType === 'texts') {
          currentDraw.rectConfig[key] = valuesByKey[key]
        }
      })

      this.debounceSetTempsDrawing(drawType, idxDraw)
    },
    moveDraw(event) {
      const drawType = this.editDrawConfig.drawType
      const idxDraw = this.editDrawConfig.drawIndex
      const currentDraw = this.userDrawing[drawType][idxDraw]
      const cursorPos = this.getCursorPosition(event)

      const moveKey = ['x', 'y']
      const valuesByKey = {
        x:
          (cursorPos.x - this.configKonva.x) / this.resizeRatioZoom +
          this.editDrawConfig.drawConfigCopy.posClickWidth,
        y:
          (cursorPos.y - this.configKonva.y) / this.resizeRatioZoom +
          this.editDrawConfig.drawConfigCopy.posClickHeight
      }

      moveKey.forEach((key) => {
        currentDraw[key] = valuesByKey[key]

        if (drawType === 'texts') {
          currentDraw.rectConfig[key] = valuesByKey[key]
        }
      })

      this.debounceSetTempsDrawing(drawType, idxDraw)

      if (drawType === 'lines' || drawType === 'polys')
        this.debounceSetTempsDrawing(drawType, idxDraw)
    },
    clearEditDrawConfig() {
      this.editDrawConfig.indexResize = -1
      this.editDrawConfig.drawConfigCopy = {}
      this.editDrawConfig.drawIndex = -1
      this.editDrawConfig.drawType = ''
      this.editDrawConfig.isMoving = false
      this.changeCursor('default')
    },
    clearCurrentDrawConfig() {
      this.currentDrawConfig = { x: -1, y: -1, index: -1, editingText: false }
    },
    selectDrawOnCanvas(event, type = 'click') {
      if (this.selectedUserTool === '') {
        const currentDraw = event.target.attrs.isTempsDraw
          ? event.target.parent.children[0].attrs
          : event.target.attrs

        if (
          currentDraw.extraData !== undefined &&
          !(
            this.editDrawConfig.drawIndex === currentDraw.extraData.drawIndex &&
            this.editDrawConfig.drawType === currentDraw.extraData.drawType
          ) &&
          (type === 'click' || type === 'touchstart')
        ) {
          this.changeCursor('move')
          this.editDrawConfig.drawConfigCopy = { ...currentDraw }

          this.editDrawConfig.drawIndex = currentDraw.extraData.drawIndex
          this.editDrawConfig.drawType = currentDraw.extraData.drawType

          return null
        }

        if (
          currentDraw !== undefined &&
          (currentDraw.drawType === 'lines' ||
            currentDraw.drawType === 'polys') &&
          currentDraw.text !== '' &&
          !(
            this.editDrawConfig.drawIndex === currentDraw.drawIndex &&
            this.editDrawConfig.drawType === currentDraw.drawType
          ) &&
          (type === 'click' || type === 'touchstart')
        ) {
          const drawLinesCopy = event.target.parent.children[0].attrs

          this.changeCursor('move')
          this.editDrawConfig.drawConfigCopy = { ...drawLinesCopy }

          this.editDrawConfig.drawIndex = currentDraw.drawIndex
          this.editDrawConfig.drawType = currentDraw.drawType

          return null
        }

        if (
          this.editDrawConfig.isMoving &&
          (type === 'click' || type === 'touchstart')
        ) {
          this.editDrawConfig.isMoving = false
          this.clearEditDrawConfig()
        }

        if (
          currentDraw.extraData !== undefined &&
          !this.editDrawConfig.isMoving &&
          this.editDrawConfig.drawIndex === currentDraw.extraData.drawIndex &&
          this.editDrawConfig.drawType === currentDraw.extraData.drawType &&
          (type === 'click' || type === 'touchstart')
        ) {
          const cursorPos = this.getCursorPosition(event)
          this.editDrawConfig.isMoving = true
          this.editDrawConfig.drawConfigCopy.posClickWidth =
            currentDraw.x -
            (cursorPos.x - this.configKonva.x) / this.resizeRatioZoom
          this.editDrawConfig.drawConfigCopy.posClickHeight =
            currentDraw.y -
            (cursorPos.y - this.configKonva.y) / this.resizeRatioZoom
        }

        if (
          currentDraw !== undefined &&
          (currentDraw.drawType === 'lines' ||
            currentDraw.drawType === 'polys') &&
          currentDraw.text !== '' &&
          !this.editDrawConfig.isMoving &&
          this.editDrawConfig.drawIndex === currentDraw.drawIndex &&
          this.editDrawConfig.drawType === currentDraw.drawType &&
          (type === 'click' || type === 'touchstart')
        ) {
          const cursorPos = this.getCursorPosition(event)
          const drawLinesCopy = event.target.parent.children[0].attrs
          this.editDrawConfig.isMoving = true
          this.editDrawConfig.drawConfigCopy.posClickWidth =
            drawLinesCopy.x -
            (cursorPos.x - this.configKonva.x) / this.resizeRatioZoom
          this.editDrawConfig.drawConfigCopy.posClickHeight =
            drawLinesCopy.y -
            (cursorPos.y - this.configKonva.y) / this.resizeRatioZoom
        }

        if (
          this.editDrawConfig.isMoving &&
          (type === 'mousemove' || type === 'touchmove')
        )
          this.moveDraw(event)

        if (
          currentDraw.pointResize !== undefined &&
          this.editDrawConfig.indexResize === -1 &&
          !this.editDrawConfig.isMoving &&
          (type === 'click' || type === 'touchstart')
        )
          return (this.editDrawConfig.indexResize = currentDraw.indexResize)

        if (
          this.editDrawConfig.indexResize >= 0 &&
          !this.editDrawConfig.isMoving
        ) {
          this.changeDrawSize(event)

          if (type === 'click' || type === 'touchend')
            this.clearEditDrawConfig()
        }
      }
    },
    changeCursor(val) {
      this.$refs.container.style.cursor = val
    },
    handlerMouseEnterMoveEditDraw(idx, type) {
      if (this.selectedUserTool === '') this.changeCursor('pointer')
      if (
        idx === this.editDrawConfig.drawIndex &&
        type === this.editDrawConfig.drawType
      )
        this.changeCursor('move')
    },
    drawOnCanvas(event, type) {
      if (
        (type === 'click' || type === 'touchstart') &&
        this.editDrawConfig.isEditingColor
      ) {
        this.editDrawConfig.isEditingColor = false
      }

      if (
        this.userDrawTools.includes(this.selectedUserTool) &&
        this.activeDrawings
      ) {
        if (
          this.currentDrawConfig.index === -1 &&
          (type === 'click' || type === 'touchstart')
        ) {
          this.initCurrentDrawConfig(event)

          if (
            ['rects', 'ellipses', 'lines', 'polys'].includes(
              this.selectedUserTool
            )
          ) {
            this.currentIdTemps++
          }

          this.userDrawing[this.selectedUserTool].push(
            this.getInitConfigDrawCanvas(event)
          )
        } else if (this.currentDrawConfig.index >= 0) {
          const configDraw = this.getCurrentConfigDrawCanvas(event)

          Object.keys(configDraw).forEach((keyConfig) => {
            if (!this.currentDrawConfig.editingText) {
              this.userDrawing[this.selectedUserTool][
                this.currentDrawConfig.index
              ][keyConfig] = configDraw[keyConfig]
            }

            if (
              this.selectedUserTool === 'texts' &&
              !this.currentDrawConfig.editingText
            ) {
              this.userDrawing[this.selectedUserTool][
                this.currentDrawConfig.index
              ].rectConfig[keyConfig] = configDraw[keyConfig]
            }
          })

          this.debounceSetTempsDrawing(
            this.selectedUserTool,
            this.currentDrawConfig.index
          )

          this.endCurrentDrawConfig(type, event)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lateral-roi-temperatures {
  position: absolute;
  left: 40px;
  top: 60px;
  z-index: 5;
  color: white;
  font-size: 10px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
}
.tools-user-drawings {
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 5;

  .btn-drawing {
    position: relative;
  }

  .selected-drawing {
    text-shadow: 0 0 15px rgba(200, 200, 200, 0.95),
      0 0 10px rgba(200, 200, 200, 0.95);
  }

  .v-icon {
    color: rgb(200, 200, 200);
    width: 36px;
    height: 36px;
  }

  .suffix-icon-text {
    position: absolute;
    right: -4px;
    bottom: -3px;
    width: 14px;
    height: 14px;
    font-size: 10px;
  }

  .div-text-user-draw {
    position: absolute;
    left: 35px;
    top: 10px;
    width: 200px;

    .v-text-field {
      background: rgb(200, 200, 200);
      padding: 10px;
    }
  }

  .selected-color-palette {
    position: absolute;
    left: 35px;
    top: 10px;
  }
}
</style>
