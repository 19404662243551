<template>
  <div
    class="player-card"
    :style="{
      filter: filterCard
    }"
  >
    <div :class="['player-card-top', hasActions ? 'has-actions' : '']">
      <div class="groups" v-if="groups.length > 0">
        <span
          class="label-group"
          v-for="(group, key) in groups"
          :key="`group-${key}`"
        >
          {{ group }}
        </span>
      </div>
      <!-- Deixe espaço para o degradê dourado no fundo -->
      <!-- <div class="player-rating">89</div> -->
      <!-- Alguma Informacao Relevante -->
      <!-- <div class="player-position">ATA</div> -->
      <!-- Posicao -->
      <img
        class="player-image"
        v-if="avatarUrl != ''"
        :src="avatarUrl"
        alt="Foto do jogador"
      />
      <img
        class="player-image"
        v-else
        src="../assets/avatar-kelvinv2.png"
        alt="Foto do jogador"
      />
      <h2 class="player-name">{{ namePlayer }}</h2>
      <!-- Nomes Grandes precisamos pensar-->
    </div>
    <!--<div class="player-card-info">
        <div class="player-icons">
        <img class="club-logo" src="../assets/peoplecard/palmeira_logo.png" alt="Escudo do clube" />
        <img class="league-logo" src="../assets/peoplecard/fpf_log.png" alt="Logo da liga" />
        <img class="country-flag" src="../assets/peoplecard/brasil.jpg" alt="Bandeira do país" />
      </div>
    </div>-->
    <div class="player-card-actions" v-if="hasActions">
      <button
        class="action-button"
        @click="hasNewThermo ? $emit('newThermo') : () => {}"
      >
        <v-icon>mdi-camera</v-icon>
      </button>
      <button
        class="action-button"
        :style="{ opacity: hasEditPeople ? 1 : 0.5 }"
        @click="hasEditPeople ? $emit('editPeople') : () => {}"
      >
        <v-icon>mdi-account-edit-outline</v-icon>
      </button>
      <button
        class="action-button"
        :style="{ opacity: hasEditThermo ? 1 : 0.5 }"
        @click="hasEditThermo ? $emit('editThermo') : () => {}"
      >
        <v-icon>mdi-numeric-5-box-outline</v-icon>
      </button>
      <button
        class="action-button"
        :style="{ opacity: hasNewData ? 1 : 0.5 }"
        @click="hasNewData ? $emit('newData') : () => {}"
      >
        <v-icon>mdi-database-arrow-up-outline</v-icon>
      </button>
      <!-- <button class="action-button" @click="$emit('editThermo')">
        <v-icon>mdi-text-box-edit-outline</v-icon>
      </button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeopleCardFigurePlayer',

  props: {
    hasActions: {
      type: Boolean,
      default: true
    },
    avatarUrl: {
      type: String,
      default: ''
    },
    namePlayer: String,
    playerRating: Number,
    playerPosition: String,
    countryFlag: String,
    clubLogo: String,
    leagueLogo: String,
    groups: {
      type: Array,
      default: () => []
    },
    hasEditPeople: {
      type: Boolean,
      default: false
    },
    hasNewThermo: {
      type: Boolean,
      default: false
    },
    hasEditThermo: {
      type: Boolean,
      default: false
    },
    hasNewData: {
      type: Boolean,
      default: false
    },
    isGray: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filterCard() {
      if (this.isWarning) {
        return 'sepia(1)'
      } else if (this.isGray) {
        return 'grayscale(1)'
      }
      return null
    }
  },
  methods: {
    enterData() {
      // Implemente a lógica para inserir dados
    }
  }
}
</script>

<style scoped>
.player-card {
  /* Precisa deixar um tamanho fixo padrao para nao ter quebra de image */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  max-width: 160px;
  height: auto;
  width: auto;
}

.player-card-top {
  background: radial-gradient(
    at 0% 70%,
    #ffd700 0%,
    #f9a602 25%,
    #ffd700 50%,
    #f9a602 100%
  ); /* ajuste das cores para um dourado mais realista */
  position: relative;
  height: 200px;
  padding-bottom: 38px;
  z-index: 0;
  text-align: center;
}

.player-rating,
.player-position {
  color: #783800;
  text-shadow: 2px 2px 2px rgb(255, 255, 255);
  font-size: 2em;
  position: absolute;
}

.player-rating {
  top: 10px;
  left: 10px;
}

.player-position {
  top: 10px;
  right: 10px;
}

.player-image {
  object-position: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10%;
  mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
}

.player-name {
  color: #783800;
  text-shadow: 2px 2px 2px white;
  font-size: 14px;
  position: absolute;
  bottom: 38px;
  left: 0;
  width: 100%;
}

.player-card-actions {
  padding: 5px;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  justify-content: space-around;
}

.action-button {
  border: none;
  border-radius: 10px;
  padding: 2px;
  height: 28px;
  width: 28px;
  background-color: #ee9d07;
  color: white;
  cursor: pointer;
}

.action-button:hover {
  background-color: #c77903;
}

.action-button .v-icon {
  font-size: 20px;
}

.groups {
  position: absolute;
  font-size: 10px;
  z-index: 2;
  left: 6px;
  top: 5px;
  width: calc(50% - 4px);
  text-align: left;
}

.groups .label-group {
  padding: 1px 6px;
  margin: 1px 0;
  display: inline-block;
  border-radius: 6px;
  color: white;
  background: #ee9d07;
  text-align: center;
}
</style>
