<template>
  <v-snackbar
    :timeout="defaultSnackBar.timeout"
    :color="snackBarColor"
    v-model="snackBar.model"
    fixed
    bottom
    right
  >
    {{ snackBar.text }}
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      defaultSnackBar: {
        timeout: 2000
      }
    }
  },

  computed: {
    snackBar() {
      return this.$store.getters.snackBar
    },
    snackBarColor() {
      let color = 'warning'
      if (this.snackBar?.type === 'success') {
        color = 'success'
      } else if (this.snackBar?.type === 'error') {
        color = 'red accent-2'
      }

      return color
    }
  }
}
</script>
