import { userService } from '@/services'

export default {
  state: {
    id: null,
    companyId: null,
    token: null,
    refreshToken: null,
    cpf: null,
    planType: undefined
  },
  mutations: {
    SET_ID(state, payload) {
      state.id = payload
    },
    SET_COMPANY_ID(state, payload) {
      state.companyId = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload
    },
    SET_CPF(state, payload) {
      state.cpf = payload
      if (state.cpf != null) {
        window.localStorage.setItem('cpf', state.cpf)
        userService.update({
          id: state.id,
          companyId: state.companyId,
          planType: state.planType,
          cpf: state.cpf
        })
      }
    },
    SET_PLAN_TYPE(state, payload) {
      state.planType = payload
    }
  },
  actions: {
    setCpf({ commit }, payload) {
      commit('SET_CPF', payload.cpf)
    },
    async setTokens({ commit }, payload) {
      commit('SET_TOKEN', payload.accessToken)
      commit('SET_REFRESH_TOKEN', payload.refreshToken)
      const user = await userService.get()
      commit('SET_ID', user?.data?.id)
      commit('SET_COMPANY_ID', user?.data?.companyId)
      commit('SET_CPF', user?.data?.cpf)
      commit('SET_PLAN_TYPE', user?.data?.planType)
    }
  },
  getters: {
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    cpf: (state) => state.cpf,
    planType: (state) => state.planType
  }
}
