import { reportTemplatesService } from '@/services'

export default {
  state: { reportTemplates: [] },
  mutations: {
    SET_REPORT_TEMPLATES(state, payload) {
      state.reportTemplates = payload
    }
  },
  actions: {
    async fetchReportTemplates({ commit }) {
      const response = await reportTemplatesService.index()
      if (response.data.length > 0) {
        commit('SET_REPORT_TEMPLATES', response.data)
      } else {
        commit('SET_REPORT_TEMPLATES', [])
      }
    },
    async saveReportTemplate({ commit, state }, payload) {
      const response = await reportTemplatesService.store(payload)
      commit('SET_REPORT_TEMPLATES', [...state.reportTemplates, response.data])
    },
    async updateReportTemplate({ commit, state }, payload) {
      const response = await reportTemplatesService.update(payload)
      commit(
        'SET_REPORT_TEMPLATES',
        state.reportTemplates.map((reportTemplate) =>
          reportTemplate.id === response.data.id
            ? response.data
            : reportTemplate
        )
      )
    },

    async removeReportTemplate({ commit, state }, payload) {
      await reportTemplatesService.destroy(payload)
      commit(
        'SET_REPORT_TEMPLATES',
        state.reportTemplates.filter(
          (reportTemplate) => reportTemplate.id !== payload
        )
      )
    }
  },
  getters: {
    reportTemplates: (state) => state.reportTemplates
  }
}
