<template>
  <v-col cols="12">
    <v-col cols="12" v-if="loadings.fetch">
      <v-row justify="center">
        <loading-screen />
      </v-row>
    </v-col>
    <v-card
      v-else-if="
        reponseDataStatus === 'PROCESSING' ||
        reponseDataStatus === 'CREATED' ||
        reponseDataStatus === 'QUEUE'
      "
    >
      <v-col>
        <v-col>
          <span
            >{{
              reponseDataStatus === 'QUEUE'
                ? $t('ProfileReprocessing')
                : $t('ProfileProcessing')
            }}...</span
          >
          <loading-screen-small />
        </v-col>
        <v-col class="pt-0 mt-n3">
          <span class="text-caption text--secondary"
            >{{ $t('ThisCanTakeSomeTime') }}...
          </span>
        </v-col>
      </v-col>
    </v-card>
    <v-col cols="12" v-else-if="errors.fetch">
      <span>{{ $t('ErrorFetchingProfile') }}</span>
    </v-col>
    <v-col cols="12" class="pa-0" v-else-if="view !== ''">
      <v-row>
        <v-col cols="12" id="infos-profile">
          <h2>{{ $t('Profile') }}: {{ nameProfile }}{{ vista }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" id="canvas-perfil">
          <profile-view
            :data="thermoImagesArr"
            :topLeftLabel="$t('PROFILE')"
            :metrics="profileMetrics"
            :contours="profileContours"
            :view="view"
            :onChangeSelection="onChangeSelection"
            :propComparType="chartsSelectedCompareType"
            :onChangeComparType="onChangeComparType"
            type="profile"
            :selectedContours="selectedContours"
            :maxHeight="300"
            :flipRegisters="profileFlipRegisters"
            :segments="profileSegments"
            :onClickOpenModal="() => handleToggleModal('profile')"
            showSemaforica
            :hideControls="true"
          />
        </v-col>
        <v-col cols="12" lg="6" id="canvas-thermos">
          <v-carousel
            v-model="carouselTermos"
            hide-delimiter-background
            height="300"
            class="carousel-profile-view"
          >
            <v-carousel-item
              v-for="image in images"
              :key="image.id"
              :onChangeSelected="() => onChangeSelection([])"
            >
              <div class="carousel-image">
                <profile-view
                  :data="image.thermoImage.data"
                  :topLeftLabel="
                    new Date(image.createdAt).toLocaleDateString().toString()
                  "
                  :metrics="image.metrics.data"
                  :contours="image.contours"
                  :view="image.view"
                  :maxHeight="300"
                  :type="'image'"
                  :srcImage="image.src"
                  :onChangeSelection="onChangeSelection"
                  :propComparType="chartsSelectedCompareType"
                  :onChangeComparType="onChangeComparType"
                  :selectedContours="selectedContours"
                  :flipRegisters="image.flip_registers"
                  :segments="image.segments"
                  showSemaforica
                  :defaultPaletteName="image.palette.replace('Palette', '')"
                  :thermoImageId="image.thermoImage.thermographyId"
                  :hideControls="true"
                />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row>
        <v-col cols="12" lg="6" id="canvas-delta-scorez">
          <v-carousel
            v-model="carouselSpecials"
            hide-delimiter-background
            height="300"
            class="carousel-profile-view"
          >
            <v-carousel-item>
              <div class="carousel-image">
                <profile-view
                  :topLeftLabel="$t('DELTA')"
                  :data="thermoImagesArr"
                  :metrics="metricsDelta"
                  :contours="profileContours"
                  :index="carouselTermos"
                  :view="view"
                  :temps="{
                    min: themeDeltaMinTemp,
                    max: themeDeltaMaxTemp
                  }"
                  defaultPaletteName="Artic"
                  :onChangeSelection="onChangeSelection"
                  :propComparType="chartsSelectedCompareType"
                  :onChangeComparType="onChangeComparType"
                  :selectedContours="selectedContours"
                  type="delta"
                  :maxHeight="300"
                  :removeAsymmetryTemps="true"
                  :hideControls="true"
                />
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="carousel-image">
                <profile-view
                  :topLeftLabel="$t('SCOREZ')"
                  :data="thermoImagesArr"
                  :metrics="metricsScoreZ"
                  :contours="profileContours"
                  :index="carouselTermos"
                  :view="view"
                  :temps="{
                    min: themeScoreZMinTemp,
                    max: themeScoreZMaxTemp
                  }"
                  defaultPaletteName="Artic"
                  :onChangeSelection="onChangeSelection"
                  :propComparType="chartsSelectedCompareType"
                  :onChangeComparType="onChangeComparType"
                  :selectedContours="selectedContours"
                  type="scoreZ"
                  :maxHeight="300"
                  :removeAsymmetryTemps="true"
                  :hideControls="true"
                />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" lg="6" id="infos-temperatures-profile">
          <v-row class="ma-0">
            <v-col cols="6">
              <v-select
                :items="dataTypes"
                v-model="chartsSelectedType"
                :label="$t('Type')"
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="compareTypes"
                v-model="chartsSelectedCompareType"
                outlined
                :label="$t('Comparative')"
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-card flat>
            <apexchart
              type="bar"
              height="200"
              :options="charts.chartData.options"
              :series="getChartSeries"
              :data="charts.chartData.data"
              v-if="chartsSelectedType === 'Chart'"
            />
            <v-simple-table
              fixed-header
              dense
              class="pa-0"
              v-if="chartsSelectedType === 'Table'"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="item in asymmetryItemsTable.headers"
                      :key="item"
                      class="text-left"
                      :title="translateItem(item, 'full')"
                    >
                      {{ translateItem(item, 'short') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      thermoItems, idxThermoItems
                    ) in asymmetryItemsTable.items"
                    :key="idxThermoItems"
                  >
                    <td
                      v-for="(asymetryInfo, idxAsymetryInfo) in thermoItems"
                      :key="idxAsymetryInfo"
                      :class="
                        idxThermoItems == 3
                          ? getAsymmetryClass(asymetryInfo)
                          : ''
                      "
                    >
                      {{
                        asymetryInfo && idxAsymetryInfo > 0
                          ? asymetryInfo.toFixed(2)
                          : asymetryInfo
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="showModal" width="900px">
      <profile-view
        :data="thermoImagesArr"
        :topLeftLabel="$t('PROFILE')"
        :metrics="profileMetrics"
        :contours="profileContours"
        :view="view"
        :onChangeSelection="onChangeSelection"
        :propComparType="chartsSelectedCompareType"
        :onChangeComparType="onChangeComparType"
        type="profile"
        :selectedContours="selectedContours"
        :maxHeight="600"
        :flipRegisters="profileFlipRegisters"
        :segments="profileSegments"
        :onClickCloseModal="() => handleToggleModal('profile')"
        activateEditDrawings
        showSemaforica
      />
    </v-dialog>
  </v-col>
</template>

<script>
import ProfileView from '../components/ProfileView.vue'
import dictSegmentation from '@/translations/dict.json'
import LoadingScreen from '../components/LoadingScreen.vue'
import LoadingScreenSmall from '../components/LoadingScreenSmall.vue'
import * as tf from '@tensorflow/tfjs'

export default {
  name: 'ProfileImagesFront',
  components: { ProfileView, LoadingScreen, LoadingScreenSmall },
  data: () => ({
    showModal: false,
    reponseDataStatus: '',
    nameProfile: '',
    carouselTermos: 0,
    carouselSpecials: 0,
    thermoImagesArr: [],
    view: '',
    profileContours: {},
    profileMetrics: {},
    selectedContours: [],
    chartsSelectedType: 'Chart',
    chartsSelectedCompareType: 'mean',
    charts: {
      chartData: {
        options: {
          legend: {
            show: false
          },
          bar: {
            distributed: true
          },
          dataLabels: {
            enabled: false
          },
          yaxis: {
            min: (min) => (min > 10 && min < 40 ? Math.floor(min) : 22),
            max: (max) => (max > 10 && max < 40 ? Math.ceil(max) : 36),
            tickAmount: 4
          },
          chart: {
            toolbar: {
              show: false
            },
            id: 'analyse-chart'
          }
        },
        series: []
      }
    },
    loadings: {
      fetch: false
    },
    errors: {
      fetch: false
    }
  }),

  props: {
    images: Array
  },

  methods: {
    handleToggleModal(type = 'profile') {
      if (type == 'profile') {
        this.showModal = !this.showModal
      }
    },
    pointInPolygon(polygon, point) {
      //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
      let odd = false
      //For each edge (In this case for each point of the polygon and the previous one)
      for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
        //If a line from the point into infinity crosses this edge
        if (
          polygon[i][1] > point[1] !== polygon[j][1] > point[1] && // One point needs to be above, one below our y coordinate
          // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
          point[0] <
            ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
              (polygon[j][1] - polygon[i][1]) +
              polygon[i][0]
        ) {
          // Invert odd
          odd = !odd
        }
        j = i
      }
      //If the number of crossings was odd, the point is in the polygon
      return odd
    },
    includesArray(data, arr) {
      return data.some(
        (e) => Array.isArray(e) && e.every((o, i) => Object.is(arr[i], o))
      )
    },
    getMinMaxXY(polygonContour) {
      let minX = 999999
      let maxX = 0
      let minY = 999999
      let maxY = 0
      for (let i = 0; i < polygonContour.length; i++) {
        const posX = polygonContour[i][0]
        const posY = polygonContour[i][1]
        minX = posX < minX ? posX : minX
        maxX = posX > maxX ? posX : maxX
        minY = posY < minY ? posY : minY
        maxY = posY > maxY ? posY : maxY
      }

      return {
        minX,
        minY,
        maxX,
        maxY
      }
    },
    getAsymmetryFromMetrics(metrics) {
      const asymmetry = {}

      Object.keys(metrics).forEach((keyMetric) => {
        if (keyMetric.includes('left')) {
          const keyMetricRight = keyMetric.replace('left', 'right')

          if (Object.keys(metrics).includes(keyMetricRight)) {
            asymmetry[keyMetric] = { ...metrics[keyMetric] }
            asymmetry[keyMetricRight] = { ...metrics[keyMetricRight] }

            Object.keys(asymmetry[keyMetric]).forEach((keyRange) => {
              asymmetry[keyMetric][keyRange] -=
                metrics[keyMetricRight][keyRange]
              asymmetry[keyMetricRight][keyRange] -=
                metrics[keyMetric][keyRange]
            })
          }
        }
      })

      return asymmetry
    },
    getAsymmetryClass(value) {
      let colorType = 'green_success'

      if (value > 2.59) colorType = 'red_danger'
      else if (value > 1.96) colorType = 'orange_danger'
      else if (value > 1.65) colorType = 'yellow_danger'
      else if (value < -2.59) colorType = 'purple_danger'
      else if (value < -1.96) colorType = 'blue_danger'
      else if (value < -1.65) colorType = 'gray_blue_danger'

      return colorType
    },
    async fetchAll() {
      await this.fetchProfile()
    },
    onChangeSelection(contours) {
      this.selectedContours = contours
    },
    onChangeComparType(val) {
      this.chartsSelectedCompareType = val
    },
    translateItem(item, type) {
      if (!dictSegmentation[item]) return item
      return dictSegmentation[item][type] || item
    },
    getLabelView(valueView) {
      let labelVista = valueView

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },
    removeZerosChunkImage(chunk) {
      let start = 0
      for (let i in chunk) {
        if (chunk[i][0] > 0) {
          start = i
          break
        }
      }
      return {
        chunk: chunk.filter((arr) => {
          return arr[0] > 0
        }),
        start
      }
    },
    async resizeChunkImage(
      chunkImageArray,
      heightInput,
      widthInput,
      heightOutput,
      widthOutput
    ) {
      const resizedChunkImageConstant = tf.image.resizeNearestNeighbor(
        [chunkImageArray, heightInput, widthInput, 1],
        [heightOutput, widthOutput]
      )
      const output = tf.gather(resizedChunkImageConstant, 0)
      const outputArray = await output.array()
      return outputArray
    },
    resizeChunkImageRow(chunk, profileChunk) {
      if (profileChunk.length == 0) {
        return []
      } else if (chunk.length == 0) {
        return [...profileChunk]
      }
      const newChunk = [...chunk]
      let difLength = profileChunk.length - newChunk.length

      if (difLength == 0) {
        return newChunk
      } else if (difLength > 0) {
        for (let i = 1; i <= difLength; i++) {
          const curLength = newChunk.length
          let pos = 0
          if (i % 2 == 0) {
            pos = Math.ceil(curLength / (2 + i / 2)) + Math.ceil(curLength / 2)
          } else {
            pos = Math.ceil(curLength / (2 + i / 2))
          }

          if (pos >= curLength) {
            pos = curLength - 1
          } else if (pos < 0) {
            pos = 0
          }

          newChunk.splice(pos, 0, newChunk[pos])
        }
      } else if (difLength < 0) {
        difLength = Math.abs(difLength)
        for (let i = 1; i <= difLength; i++) {
          const curLength = newChunk.length
          let pos = 0
          if (i % 2 == 0) {
            pos =
              Math.ceil(curLength / (2 + Math.ceil(i / 2))) +
              Math.ceil(curLength / 2)
          } else {
            pos = Math.ceil(curLength / (2 + Math.ceil(i / 2)))
          }
          newChunk.splice(pos, 1)
        }
        if (newChunk.length != profileChunk.length) {
          newChunk.length = profileChunk.length
        }
      }

      return newChunk
    },
    async fetchProfile() {
      this.loadings.fetch = true
      try {
        this.nameProfile = 'Profile'

        this.view = this.images[0].view

        this.profileContours = JSON.parse(
          JSON.stringify(this.images[0].contours)
        )
        Object.keys(this.profileContours.data).forEach((contour) => {
          const removeKeys = ['bg', 'vest', 'body', 'head']
          if (removeKeys.includes(contour)) {
            delete this.profileContours.data[contour]
          }
        })

        this.thermoImagesArr = []
        const imageArray = []
        const height = this.images[0].thermoImage.data.length
        const width = this.images[0].thermoImage.data[0].length
        for (let y = 0; y < height; y++) {
          imageArray[y] = []
          for (let x = 0; x < width; x++) {
            imageArray[y][x] = 0
          }
        }

        const polygonContourProfile = {}
        const minMaxXYProfile = {}
        const chunkImageProfileArray = {}
        for (let contour in this.profileContours.data) {
          polygonContourProfile[contour] = JSON.parse(
            JSON.stringify(this.images[0].contours.data[contour])
          )
          const polygonContourMinMaxXY = this.getMinMaxXY(
            polygonContourProfile[contour]
          )

          minMaxXYProfile[contour] = {
            ...polygonContourMinMaxXY
          }

          let iChunkPosY = 0
          let iChunkPosX = 0
          chunkImageProfileArray[contour] = []
          for (
            let posY = polygonContourMinMaxXY.minY;
            posY < polygonContourMinMaxXY.maxY;
            posY++
          ) {
            chunkImageProfileArray[contour][iChunkPosY] = []
            iChunkPosX = 0

            for (
              let posX = polygonContourMinMaxXY.minX;
              posX < polygonContourMinMaxXY.maxX;
              posX++
            ) {
              if (
                this.pointInPolygon(polygonContourProfile[contour], [
                  posX,
                  posY
                ])
              ) {
                chunkImageProfileArray[contour][iChunkPosY][iChunkPosX] = [
                  this.images[0].thermoImage.data[posY][posX],
                  0,
                  0
                ]
              } else {
                chunkImageProfileArray[contour][iChunkPosY][iChunkPosX] = [
                  0, 0, 0
                ]
              }
              iChunkPosX++
            }
            iChunkPosY++
          }
        }

        for (let keyImage in this.images) {
          const returImageArray = JSON.parse(JSON.stringify(imageArray))
          const resizedChunkPromise = []

          for (let contour in this.profileContours.data) {
            let chunkImageArray = []

            const polygonContour = JSON.parse(
              JSON.stringify(this.images[keyImage].contours.data[contour])
            )

            const { minX, minY, maxX, maxY } = minMaxXYProfile[contour]
            const difMinMaxX = maxX - minX
            const difMinMaxY = maxY - minY
            const minMaxCurPolyContour = this.getMinMaxXY(polygonContour)

            let iChunkPosY = 0
            let iChunkPosX = 0
            for (
              let posY = minMaxCurPolyContour.minY;
              posY < minMaxCurPolyContour.maxY;
              posY++
            ) {
              chunkImageArray[iChunkPosY] = []
              iChunkPosX = 0

              for (
                let posX = minMaxCurPolyContour.minX;
                posX < minMaxCurPolyContour.maxX;
                posX++
              ) {
                if (this.pointInPolygon(polygonContour, [posX, posY])) {
                  chunkImageArray[iChunkPosY][iChunkPosX] = [
                    this.images[keyImage].thermoImage.data[posY][posX],
                    0,
                    0
                  ]
                } else {
                  chunkImageArray[iChunkPosY][iChunkPosX] = [0, 0, 0]
                }
                iChunkPosX++
              }
              iChunkPosY++
            }

            resizedChunkPromise.push(
              this.resizeChunkImage(
                chunkImageArray,
                iChunkPosY,
                iChunkPosX,
                difMinMaxY,
                difMinMaxX
              )
            )
          }
          const resizedChunkArray = await Promise.all(resizedChunkPromise)
          let iResized = 0

          for (let contour in this.profileContours.data) {
            const resizedChunk = resizedChunkArray[iResized]
            const { minX, minY, maxX, maxY } = minMaxXYProfile[contour]
            const difMinMaxX = maxX - minX
            const difMinMaxY = maxY - minY

            for (let posY = 0; posY < difMinMaxY; posY++) {
              const chunkWithoutZeros = this.removeZerosChunkImage(
                resizedChunk[posY]
              )
              const chunkProfileWithoutZeros = this.removeZerosChunkImage(
                chunkImageProfileArray[contour][posY]
              )

              const resizedChunkWithoutZeros = this.resizeChunkImageRow(
                chunkWithoutZeros.chunk,
                chunkProfileWithoutZeros.chunk
              )

              let iChkPosX = 0
              for (
                let posX = chunkProfileWithoutZeros.start;
                posX < difMinMaxX;
                posX++
              ) {
                if (
                  this.pointInPolygon(polygonContourProfile[contour], [
                    minX + posX,
                    minY + posY
                  ])
                ) {
                  if (resizedChunkWithoutZeros[iChkPosX] != undefined) {
                    returImageArray[minY + posY][minX + posX] =
                      resizedChunkWithoutZeros[iChkPosX][0]
                    iChkPosX++
                  }
                }
              }
            }

            iResized++
          }
          this.thermoImagesArr.push(returImageArray)
        }

        this.errors.fetch = false

        this.profileMetrics = {}
        if (this.images.length > 0) {
          this.images.forEach((image) => {
            Object.keys(image.metrics.data).forEach((metric) => {
              if (
                this.profileMetrics[metric] != undefined &&
                image.metrics.data[metric] != undefined
              ) {
                this.profileMetrics[metric].min +=
                  image.metrics.data[metric].min
                this.profileMetrics[metric].max +=
                  image.metrics.data[metric].max
                this.profileMetrics[metric].mean +=
                  image.metrics.data[metric].mean
              }

              if (this.profileMetrics[metric] == undefined) {
                this.profileMetrics[metric] = {
                  ...image.metrics.data[metric]
                }
              }
            })
          })

          Object.keys(this.profileMetrics).forEach((metric) => {
            this.profileMetrics[metric].min /= this.images.length
            this.profileMetrics[metric].max /= this.images.length
            this.profileMetrics[metric].mean /= this.images.length
          })
        }
      } catch (error) {
        console.log('ERRO', error)
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    }
  },
  async mounted() {
    await this.fetchAll()
  },

  computed: {
    themeScoreZMinTemp() {
      return window.localStorage.getItem('themeScoreZMinTemp')
    },
    themeScoreZMaxTemp() {
      return window.localStorage.getItem('themeScoreZMaxTemp')
    },
    themeDeltaMinTemp() {
      return window.localStorage.getItem('themeDeltaMinTemp')
    },
    themeDeltaMaxTemp() {
      return window.localStorage.getItem('themeDeltaMaxTemp')
    },
    dataTypes() {
      return [
        { text: this.$t('Table'), value: 'Table' },
        { text: this.$t('Chart'), value: 'Chart' }
      ]
    },
    compareTypes() {
      return [
        { text: this.$t('Mean'), value: 'mean' },
        { text: this.$t('Minimum'), value: 'min' },
        { text: this.$t('Maximum'), value: 'max' }
      ]
    },
    vista() {
      let labelVista =
        this.images != undefined &&
        this.images.length > 0 &&
        this.images[0].view != undefined
          ? this.images[0].view
          : null

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = ' - ' + view.label
      })

      return labelVista
    },
    profileSegments() {
      if (this.images.length <= 0) return null
      if (this.images[0].segments === undefined) return null

      if (this.images.length) return null

      const segments = this.images[0].segments
      const tfSegData = tf.tensor(segments.data)

      this.images.forEach((img) => {
        tfSegData.add(tf.tensor(img.segments.data))
      })

      tfSegData.scalar(this.images.length)

      const taSegData = tfSegData.dataSync()
      const aSegData = Array.from(taSegData)

      segments.data = aSegData

      return segments
    },
    profileFlipRegisters() {
      if (this.images.length <= 0) return null
      if (this.images[0].flip_registers === undefined) return null

      if (this.images.length) return null

      const flipRegisters = this.images[0].flip_registers
      const tfFlipRegData = tf.tensor(flipRegisters.data)

      this.images.forEach((img) => {
        tfFlipRegData.add(tf.tensor(img.flip_registers.data))
      })

      tfFlipRegData.scalar(this.images.length)

      const taFlipRegData = tfFlipRegData.dataSync()
      const aFlipRegData = Array.from(taFlipRegData)

      flipRegisters.data = aFlipRegData

      return flipRegisters
    },
    metricsDelta() {
      const metricsDelta = {}
      Object.keys(this.profileMetrics).forEach((key) => {
        if (
          this.profileMetrics[key] != undefined &&
          this.images[this.carouselTermos].metrics.data[key] != undefined &&
          this.profileMetrics[key] != null &&
          this.images[this.carouselTermos].metrics.data[key] != null
        ) {
          metricsDelta[key] = {
            max:
              this.images[this.carouselTermos].metrics.data[key].max -
              this.profileMetrics[key].max,
            min:
              this.images[this.carouselTermos].metrics.data[key].min -
              this.profileMetrics[key].min,
            mean:
              this.images[this.carouselTermos].metrics.data[key].mean -
              this.profileMetrics[key].mean
          }
        }
      })
      return metricsDelta
    },
    metricsScoreZ() {
      const metricsScoreZ = {}
      Object.keys(this.metricsDelta).forEach((sKeyMetric) => {
        const curImg = this.images[this.carouselTermos].metrics.data[sKeyMetric]
        metricsScoreZ[sKeyMetric] = {}

        if (this.metricsDelta[sKeyMetric] != undefined && curImg != undefined) {
          Object.keys(curImg).forEach((type) => {
            let stdDeviation = 0
            const profile = this.profileMetrics[sKeyMetric][type]
            const curDelta = curImg[type] - profile

            if (curImg[type] !== 0) {
              this.images.forEach((val, keyImg) => {
                if (this.images[keyImg].metrics.data[sKeyMetric] !== null) {
                  const img = this.images[keyImg].metrics.data[sKeyMetric][type]
                  stdDeviation += Math.pow(img - profile, 2)
                }
              })

              stdDeviation = Math.sqrt(stdDeviation / this.images.length)

              metricsScoreZ[sKeyMetric][type] = curDelta / stdDeviation
            }
          })
        }
      })

      return metricsScoreZ
    },
    asymmetryItemsTable() {
      const returnData = {
        headers: [''],
        items: [
          [this.$t('Thermography') + ' (ºC)'],
          [this.$t('Profile') + ' (ºC)'],
          [this.$t('Delta') + ' (ºC)'],
          [this.$t('ScoreZ')]
        ]
      }

      const selectedContoursCopy = [...this.selectedContours]

      selectedContoursCopy.sort().forEach((contour) => {
        returnData.headers.push(contour)
        returnData.items[0].push(
          this.images[this.carouselTermos].metrics.data[contour][
            this.chartsSelectedCompareType
          ]
        )
        returnData.items[1].push(
          this.profileMetrics[contour][this.chartsSelectedCompareType]
        )
        returnData.items[2].push(
          this.metricsDelta[contour][this.chartsSelectedCompareType]
        )
        returnData.items[3].push(
          this.metricsScoreZ[contour][this.chartsSelectedCompareType]
        )
      })

      return returnData
    },
    profileItems() {
      return this.$store.getters.profiles.map((profile) => {
        return {
          text: profile.name,
          value: profile.id
        }
      })
    },
    getChartSeries() {
      let selectedContoursCopy = [...this.selectedContours]
      selectedContoursCopy = selectedContoursCopy.sort()

      const profile = selectedContoursCopy.map((contour) => {
        const fromProfile = parseFloat(
          this.profileMetrics[contour][this.chartsSelectedCompareType].toFixed(
            2
          )
        )
        return { y: fromProfile, x: this.translateItem(contour, 'full') }
      })
      const image = selectedContoursCopy.map((contour) => {
        const fromProfile = parseFloat(
          this.images[this.carouselTermos].metrics.data[contour][
            this.chartsSelectedCompareType
          ].toFixed(2)
        )
        return { y: fromProfile, x: this.translateItem(contour, 'full') }
      })
      return [
        {
          name: 'Profile',
          data: profile
        },
        {
          name: 'Image',
          data: image
        }
      ]
    }
  }
}
</script>

<style>
.carousel-profile-view .v-carousel__controls {
  width: calc(100% - 120px);
  margin-left: 60px;
}
</style>
<style scoped lang="scss">
.carousel-image {
  width: 100%;
  max-height: 300px;
  height: 100%;
}
.img-preview {
  width: 120px;
}
.selected-row {
  background: #ccc;
}
.red_danger {
  background: #fe4844;
  color: #fff;
}
.orange_danger {
  background: #f57b39;
  color: #fff;
}
.yellow_danger {
  background: #fbda68;
  color: #fff;
}
.gray_blue_danger {
  background: #b7c5ea;
  color: #fff;
}
.blue_danger {
  background: #3919ef;
  color: #fff;
}
.purple_danger {
  background: #7f2bb6;
  color: #fff;
}
</style>
