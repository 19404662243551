<template>
  <v-col v-if="error">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadStatistics') }} </v-col>
        <v-col class="py-0">
          <v-btn @click="getData" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col v-else-if="loading">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col v-else>
    <v-row class="ma-3" justify="center">
      <v-col cols="12" sm="6" lg="3" class="mb-8">
        <base-material-stats-card
          color="blue"
          icon="mdi-camera-account"
          :title="$t('Thermographs')"
          :value="stats.total_thermos.toString()"
          sub-icon="mdi-tag"
          :sub-text="$t('RegisteredThermographs')"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3" class="mb-8">
        <base-material-stats-card
          color="red"
          icon="mdi-camera-flip-outline"
          :title="$t('Reprocess')"
          :value="stats.error_thermos.toString()"
          sub-icon="mdi-tag"
          :sub-text="$t('ErrorThermographs')"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3" class="mb-8">
        <base-material-stats-card
          color="purple"
          icon="mdi-account-group"
          :title="$t('Groups')"
          :value="stats.groups.toString()"
          sub-icon="mdi-tag"
          :sub-text="$t('CreatedGroups')"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3" class="mb-8">
        <base-material-stats-card
          color="green"
          icon="mdi-account"
          :title="$t('People')"
          :value="stats.subjects.length.toString()"
          sub-icon="mdi-tag"
          :sub-text="$t('RegisteredPeople')"
        />
      </v-col>

      <!-- <v-col cols="12" class="pb-10" />-->

      <!-- <v-col cols="12" md="6" class="px-lg-16 px-md-8">
        <AssymetryList />
      </v-col> -->

      <v-col cols="12" md="10" class="px-lg-16 px-md-8">
        <PeopleSearchList
          class="playercard"
          customColsLg="3"
          customColsXl="2"
        />
      </v-col>

      <!--<v-col cols="12" md="6" class="px-lg-16 px-md-8">
        <v-data-table
          hide-default-footer
          :headers="headersAlerts"
          :items="subjectsTableAlerts"
          :sort-by="['date1']"
          :sort-desc="[true]"
          :loading="loadingSubjectsTableAlerts"
          must-sort
          outlined
          :items-per-page="-1"
          :no-data-text="`${$t('NoRecord')} :(`"
          mobile-breakpoint="1"
          id="table-data-people"
        >
          <template v-slot:item="{ headers, item }">
            <tr>
              <td v-for="itemKey in headers" :key="itemKey.value">
                <template
                  v-if="
                    itemKey.value === 'date1' ||
                    itemKey.value === 'date2' ||
                    itemKey.value === 'date3'
                  "
                >
                  {{
                    item[itemKey.value] != null
                      ? new Date(item[itemKey.value])
                          .toLocaleDateString()
                          .toString()
                      : null
                  }}
                </template>
                <template v-else>
                  {{ item[itemKey.value] }}
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>-->
    </v-row>
  </v-col>
</template>

<script>
// import AssymetryList from '../components/AsymetryList.vue'
import PeopleSearchList from '../components/PeopleSearchList.vue'
import LoadingScreen from '../components/LoadingScreen.vue'
//import { reportsService } from '@/services'
//import dictSegmentation from '@/translations/dict.json'

export default {
  components: { PeopleSearchList, LoadingScreen },
  data() {
    return {
      loading: false,
      loadingSubjectsTableAlerts: false,
      subjectsTableAlerts: [],
      error: false
    }
  },
  computed: {
    subjects() {
      return this.$store.getters.subjects.map((subject) => ({
        name: subject.name,
        createdAt: subject.createdAt,
        groups: subject.groups,
        thermographies: subject.thermographies
      }))
    },
    // headersAlerts() {
    //   return [
    //     { text: this.$t('Name'), value: 'name', width: '200px' },
    //     { text: this.$t('Date') + ' 1', value: 'date1', width: '100px' },
    //     { text: this.$t('Date') + ' 2', value: 'date2', width: '100px' },
    //     { text: this.$t('Date') + ' 3', value: 'date3', width: '100px' },
    //     { text: this.$t('Alerts'), value: 'alerts', width: '200px' }
    //   ]
    // },
    stats() {
      return this.$store.getters.stats
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        await Promise.all([
          this.$store.dispatch('fetchGeneralStats'),
          this.$store.dispatch('fetchSubjects'),
          this.$store.dispatch('fetchGroups'),
          this.$store.dispatch('fetchTags')
        ])
        //this.getSubjectsTableAlerts()
      } catch (error) {
        console.log('Error: ', error)
        this.error = error
      } finally {
        this.loading = false
      }
    },

    // async getSubjectsTableAlerts() {
    //   this.loadingSubjectsTableAlerts = true
    //   this.subjectsTableAlerts = this.subjects.filter((subject) => {
    //     const thermoLength = subject.thermographies.length
    //     let thermoProcessed = 0

    //     if (thermoLength <= 0) {
    //       return false
    //     }

    //     for (let index = 0; index < thermoLength; index++) {
    //       const thermo = subject.thermographies[index]
    //       if (thermo.status == 'PROCESSED') {
    //         thermoProcessed += 1
    //       }
    //     }

    //     if (thermoProcessed <= 0) {
    //       return false
    //     }

    //     return true
    //   })

    //   for (
    //     let iSubject = 0;
    //     iSubject < this.subjectsTableAlerts.length;
    //     iSubject++
    //   ) {
    //     const subject = this.subjectsTableAlerts[iSubject]

    //     this.subjectsTableAlerts[iSubject].thermographies =
    //       subject.thermographies.sort(function (v1, v2) {
    //         if (v1.createdAt > v2.createdAt) {
    //           return 1
    //         } else if (v1.createdAt < v2.createdAt) {
    //           return -1
    //         }
    //         return 0
    //       })

    //     this.subjectsTableAlerts[iSubject].cautionAsimmetryItems = []

    //     try {
    //       const response = await reportsService.getSingleThermo(
    //         this.subjectsTableAlerts[iSubject].thermographies[0].id
    //       )

    //       for (
    //         let iThermoImg = 0;
    //         iThermoImg < response.data.thermographyImages.length;
    //         iThermoImg++
    //       ) {
    //         const image = response.data.thermographyImages[iThermoImg]

    //         if (
    //           image.status === 'PROCESSED' &&
    //           image?.asymmetries != undefined
    //         ) {
    //           Object.keys(image?.asymmetries?.data).forEach((contour) => {
    //             if (Math.abs(image?.asymmetries?.data[contour]?.mean) > 0.99) {
    //               const contourName =
    //                 dictSegmentation?.[
    //                   contour.split('_left')[0].split('_right')[0].trim()
    //                 ]?.full
    //               if (
    //                 contourName != '' &&
    //                 contourName != null &&
    //                 !this.subjectsTableAlerts[
    //                   iSubject
    //                 ].cautionAsimmetryItems.includes(contourName)
    //               ) {
    //                 this.subjectsTableAlerts[
    //                   iSubject
    //                 ].cautionAsimmetryItems.push(contourName)
    //               }
    //             }
    //           })
    //         }
    //       }
    //     } catch (error) {
    //       console.log(error)
    //       this.$store.dispatch('setSnackBar', {
    //         text:
    //           error?.response?.data?.message != ''
    //             ? error.response.data.message
    //             : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
    //         type: 'error',
    //         model: true
    //       })
    //     }
    //   }

    //   this.subjectsTableAlerts = this.subjectsTableAlerts.map((subject) => ({
    //     name: subject.name,
    //     thermo1Id: subject.thermographies[0].id,
    //     date1: subject.thermographies[0].createdAt,
    //     date2:
    //       subject.thermographies[1] != undefined
    //         ? subject.thermographies[1].createdAt
    //         : null,
    //     date3:
    //       subject.thermographies[2] != undefined
    //         ? subject.thermographies[2].createdAt
    //         : null,
    //     alerts: subject.cautionAsimmetryItems.join(', ')
    //   }))
    //   this.loadingSubjectsTableAlerts = false
    // },
    onClickSubject(id) {
      this.$router.push({
        name: 'PeopleThermos',
        params: { peopleId: id }
      })
    }
  }
}
</script>

<style scoped>
.list-scroll {
  width: auto;
  overflow-y: auto;
  max-height: 400px;
}
.styled-table {
  border-collapse: collapse;
  margin: 20px 0;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 15px gray;
}
.styled-vcard {
  border-color: red;
  box-shadow: 0 0 10px gray;
}
</style>
