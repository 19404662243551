<template>
  <v-app-bar
    app
    clipped-left
    height="64"
    class="toolbar"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); z-index: 15"
    elevation="0"
    id="header-toolbar"
  >
    <v-row no-gutters class="align-center" justify="space-between">
      <v-col cols="3">
        <img
          class="logo-top d-none d-md-block"
          src="@/assets/logo-kelvin-white.png"
          alt=""
          v-if="theme == 'black'"
        />
        <img
          class="logo-top d-none d-md-block"
          src="@/assets/logo-kelvin.png"
          alt=""
          v-else
        />
        <img
          class="logo-top logo-mobile d-md-none"
          src="@/assets/logo-icon-kelvin-white.png"
          alt=""
        />
      </v-col>

      <!-- Colocar Logo da Empresa/Time 
      <v-row no-gutters class="align-center">
        <v-row align="center">
          <img
            class="company-logo-top"
            src="https://logodetimes.com/wp-content/uploads/palmeiras.png"
            alt=""
          />
          <span class="text-h5">Sociedade Esportiva Palmeiras</span>
        </v-row>
      </v-row>
      -->
      <v-col no-gutters class="align-center" cols="6">
        <v-row align="center" justify="center">
          <span class="text-h5">{{ company.name }}</span>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-menu
          :close-on-content-click="false"
          min-width="220px"
          max-width="100%"
          content-class="menu-perfil"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-row align="center" justify="end">
              <v-btn
                @click="changeTheme"
                class="mr-3"
                icon
                :color="theme == 'black' ? 'white' : 'gray'"
              >
                <v-icon> mdi-brightness-6 </v-icon>
              </v-btn>
              <v-avatar
                size="40"
                color="primary"
                class="mr-2"
                v-if="profile.picture"
                :src="profile.picture"
              >
                <img
                  v-if="profile.picture"
                  :src="profile.picture"
                  alt="Avatar"
                />
              </v-avatar>
              <span class="d-none d-md-inline">{{
                profile && profile.name
              }}</span>
              <v-btn dark icon v-bind="attrs" v-on="on" class="ma-0 pa-0">
                <v-icon color="primary">mdi-chevron-down</v-icon>
              </v-btn>
            </v-row>
          </template>
          <div>
            <v-list dense>
              <v-list-item><locale-changer /></v-list-item>
              <v-list-item dense @click="handleLogout">{{
                $t('Logout')
              }}</v-list-item>
            </v-list>
          </div>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import LocaleChanger from './LocaleChanger.vue'

export default {
  name: 'Toolbar',
  components: { LocaleChanger },
  methods: {
    handleLogout() {
      this.$auth.logout()
    },
    async getCompany() {
      await this.$store.dispatch('fetchCompany')
    },
    changeTheme() {
      const newTheme = this.theme == 'white' ? 'black' : 'white'
      this.$store.dispatch('setTheme', { theme: newTheme })
    }
  },
  async mounted() {
    const theme = window.localStorage.getItem('theme')
    if (theme != undefined) this.$store.dispatch('setTheme', { theme: theme })

    await this.getCompany()
  },
  computed: {
    profile() {
      return this.$auth.user
    },
    company() {
      return this.$store.getters.company
    },
    theme() {
      return this.$store.getters.theme
    }
  }
}
</script>

<style scoped>
.logo-top {
  width: 220px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}
.logo-mobile {
  width: auto;
  max-width: 100%;
  filter: brightness(0);
  height: 47px;
}
.company-logo-top {
  width: 110px;
}
.menu-perfil {
  width: 220px;
}
</style>
