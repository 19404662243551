import http from '@/infra/api'

let get = () => {
  return http.get('user/info')
}

let update = (data) => {
  return http.put(`user/${data.id}`, data)
}

export default {
  get,
  update
}
