<template>
  <default-template :handleClick="handleClick" />
</template>

<script>
import DefaultTemplate from './DefaultTemplate.vue'

export default {
  name: 'People',

  components: { DefaultTemplate },

  methods: {
    handleClick() {
      this.$store.dispatch('nextStep')

      if (this.step === 0) return

      let selectedEl = document.getElementById('header-toolbar')
      let tipPosition = 'bottom'

      let widthTextHighlight = 300
      let heightTextHighlight = 120

      if (this.step === 1) {
        tipPosition = 'bottom'
        selectedEl = document.getElementById('filters-people')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode filtrar as pessoas disponíveis ou criar uma nova pessoa no botão "NOVA PESSOA".'
        )
      } else if (this.step === 2) {
        tipPosition = 'top'
        selectedEl = document.getElementById('table-data-people')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode vizualizar todas as pessoas, e ao passar o mouse você verá que poderá editar ou excluir uma tag na coluna ações.'
        )
      } else if (this.step === 3) {
        tipPosition = 'top'
        selectedEl = document.getElementById('table-data-people')
        this.$store.dispatch(
          'setTextHighlight',
          'Algo importante a se ressaltar é que aqui você pode ver as termografias das pessoas ao clicar no icone de olho azul que fica na coluna Ações.'
        )
      }

      const offsetsSelectedEl = selectedEl.getBoundingClientRect()

      this.$store.dispatch('setHighlightCss', {
        top: `${offsetsSelectedEl.top}px`,
        left: `${offsetsSelectedEl.left}px`,
        width: `${selectedEl.offsetWidth}px`,
        height: `${selectedEl.offsetHeight}px`,
        border: '3px solid red'
      })
      this.$store.dispatch('setTextHighlightCss', {
        textHighlightCss: {
          top: 0,
          left: 0,
          width: `${widthTextHighlight}px`,
          height: `${heightTextHighlight}px`
        },
        positionInfos: {
          offsetsSelectedEl,
          selectedEl,
          widthTextHighlight,
          heightTextHighlight,
          tipPosition
        }
      })
    }
  },

  computed: {
    step() {
      return this.$store.getters.step
    }
  },

  mounted() {
    this.$store.dispatch('setMaxSteps', 3)
  }
}
</script>
