<template>
  <default-template :handleClick="handleClick" />
</template>

<script>
import DefaultTemplate from './DefaultTemplate.vue'

export default {
  name: 'Home',

  components: { DefaultTemplate },

  methods: {
    handleClick() {
      this.$store.dispatch('nextStep')

      let selectedEl = document.getElementById('header-toolbar')
      let tipPosition = 'bottom'

      let widthTextHighlight = 300
      let heightTextHighlight = 120

      if (this.step === 1) {
        tipPosition = 'bottom'
        selectedEl = document.getElementById('header-toolbar')
        this.$store.dispatch(
          'setTextHighlight',
          'Este é o cabeçalho do sistema, aqui fica a logo de sua empresa e suas informações pessoais.'
        )
      } else if (this.step === 2) {
        tipPosition = 'right'
        selectedEl = document.getElementById('sidebar-menu')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui é o menu do sistema, aqui você poderá escolher a ação que você deseja fazer no sistema. Explore a vontade!'
        )
      } else if (this.step === 3) {
        tipPosition = 'center'
        selectedEl = document.getElementById('app-content')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui é o conteúdo do sistema é aqui que será apresentado todas as ações que você pode fazer ao selecionar um item no menu.'
        )
      }

      const offsetsSelectedEl = selectedEl.getBoundingClientRect()

      this.$store.dispatch('setHighlightCss', {
        top: `${offsetsSelectedEl.top}px`,
        left: `${offsetsSelectedEl.left}px`,
        width: `${selectedEl.offsetWidth}px`,
        height: `${selectedEl.offsetHeight}px`,
        border: '3px solid red'
      })
      this.$store.dispatch('setTextHighlightCss', {
        textHighlightCss: {
          top: 0,
          left: 0,
          width: `${widthTextHighlight}px`,
          height: `${heightTextHighlight}px`
        },
        positionInfos: {
          offsetsSelectedEl,
          selectedEl,
          widthTextHighlight,
          heightTextHighlight,
          tipPosition
        }
      })
    }
  },

  computed: {
    step() {
      return this.$store.getters.step
    }
  },

  mounted() {
    this.$store.dispatch('setMaxSteps', 3)
  }
}
</script>
