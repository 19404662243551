import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import ThermalDiff from '../views/ThermalDiff.vue'
import StandardThermalDiff from '../views/StandardThermalDiff.vue'
import Groups from '../views/Groups.vue'
import People from '../views/People.vue'
import PeopleInactives from '../views/PeopleInactives.vue'
import PeopleThermos from '../views/PeopleThermos.vue'
import NewThermo from '../views/NewThermography.vue'
import Thermography from '../views/Thermography.vue'
import ThermoGraphOnly from '../views/ThermoGraphOnly.vue'
import ThermographyEdit from '../views/ThermographyEdit.vue'
import Tags from '../views/Tags.vue'
import Profile from '../views/Profile.vue'
import ProfileEdit from '../views/ProfileEdit.vue'
import NewProfile from '../views/NewProfile.vue'
import Profiles from '../views/Profiles.vue'
import ReportThermography from '../views/ReportThermography.vue'
import ReportThermographyEditor from '../views/ReportThermographyEditor.vue'
import NewTemplateReport from '../views/NewTemplateReport.vue'
import ReportTemplateEdit from '../views/ReportTemplateEdit.vue'
import ReportTemplates from '../views/ReportTemplates.vue'
import MenuConfig from '../views/MenuConfig.vue'
import DashboardLastThermos from '../views/DashboardLastThermos.vue'
import DashboardMap from '../views/DashboardMap.vue'
import TiraTeima from '../views/TiraTeima.vue'
import AlertsToday from '../views/AlertsToday.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/menu-config',
    name: 'MenuConfig',
    component: MenuConfig
  },
  {
    path: '/tira-teima/:peopleid?',
    name: 'TiraTeima',
    component: TiraTeima
  },
  {
    path: '/reports',
    name: 'ThermalDiff',
    component: ThermalDiff
  },
  {
    path: '/reports',
    name: 'StandardThermalDiff',
    component: StandardThermalDiff
  },
  {
    path: '/report-thermography-pdf',
    name: 'ReportThermography',
    component: ReportThermography
  },
  {
    path: '/report-thermography-editor/:reportTemplateId/:thermographyId',
    name: 'ReportThermographyEditor',
    component: ReportThermographyEditor
  },
  {
    path: '/report-thermography-editor/:thermographyId',
    name: 'ReportThermographyEditorWithoutTemplate',
    component: ReportThermographyEditor
  },
  {
    path: '/templates/new',
    name: 'NewTemplateReport',
    component: NewTemplateReport
  },
  {
    path: '/report-templates',
    name: 'ReportTemplates',
    component: ReportTemplates
  },
  {
    path: '/report-template/new',
    name: 'ReportTemplatesNew',
    component: ReportTemplateEdit
  },
  {
    path: '/report-template/edit/:id',
    name: 'ReportTemplatesEdit',
    component: ReportTemplateEdit
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups
  },
  {
    path: '/people',
    name: 'People',
    component: People
  },
  {
    path: '/people-inactives',
    name: 'PeopleInactives',
    component: PeopleInactives
  },
  {
    path: '/thermos/people/:peopleId',
    name: 'PeopleThermos',
    component: PeopleThermos
  },
  {
    path: '/new-thermography/:peopleId?',
    name: 'NewThermo',
    component: NewThermo
  },
  {
    path: '/thermos/new-profile',
    name: 'NewProfile',
    component: NewProfile
  },
  {
    path: '/thermos/profiles',
    name: 'Profiles',
    component: Profiles
  },
  {
    path: '/profile/:profileId',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/profile/edit/:profileId',
    name: 'ProfileEdit',
    component: ProfileEdit
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/thermography/:thermographyId',
    name: 'Thermography',
    component: Thermography
  },
  {
    path: '/thermos-graph-only/:thermographyId',
    name: 'ThermoGraphOnly',
    component: ThermoGraphOnly
  },
  {
    path: '/dashboard-last-thermos',
    name: 'DashboardLastThermos',
    component: DashboardLastThermos
  },
  {
    path: '/dashboard-map',
    name: 'DashboardMap',
    component: DashboardMap
  },
  {
    path: '/alerts-today',
    name: 'AlertsToday',
    component: AlertsToday
  },
  {
    path: '/thermography/edit/:thermographyId',
    name: 'ThermographyEdit',
    component: ThermographyEdit
  }
]

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')

const router = new VueRouter({
  base: locale.trim().length && locale != '/' ? '/' + locale : undefined,
  routes
})

export default router
