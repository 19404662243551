<template>
  <div id="app">
    <div @click="generatePDF()" class="np-btn">Generate PDF</div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="nightprogrammerpdf"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div style="margin: 12px 12px">
          <img src="https://picsum.photos/500/300" />
          <div>
            <p>
              <strong
                ><a href="https://www.nightprogrammer.com/" target="_blank"
                  >Nightprogrammer.com</a
                ></strong
              >: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus posuere, tellus lobortis posuere tempor, elit sem varius
              libero, ac vulputate ante orci vel odio. Nam sit amet tortor
              malesuada tellus rutrum varius vel a mauris. Integer volutpat
              neque mauris, vel imperdiet mi aliquet ac. Proin sed iaculis
              ipsum. Vivamus tincidunt egestas sapien, vitae faucibus velit
              ultricies eget. Donec mattis ante arcu, a pretium tortor
              scelerisque et. Morbi sed dui tempor, consectetur turpis sed,
              tristique arcu.
            </p>
            <p>
              Quisque nec ante faucibus, lobortis neque eget, placerat massa.
              Mauris tincidunt ante faucibus dui semper eleifend. Morbi sit amet
              velit lacus. Maecenas suscipit pulvinar lacus, ac feugiat erat
              venenatis porta. Donec at turpis ultrices, vehicula justo eu,
              laoreet magna. Ut consequat eleifend posuere. Quisque nec ante
              faucibus, lobortis neque eget, placerat massa. Mauris tincidunt
              ante faucibus dui semper eleifend. Morbi sit amet velit lacus.
              Maecenas suscipit pulvinar lacus, ac feugiat erat venenatis porta.
              Donec at turpis ultrices, vehicula justo eu, laoreet magna.
            </p>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <div>
      <div style="margin: 12px 12px">
        <img src="https://picsum.photos/500/300" />
        <div>
          <p>
            <strong
              ><a href="https://www.nightprogrammer.com/" target="_blank"
                >Nightprogrammer.com</a
              ></strong
            >: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Phasellus posuere, tellus lobortis posuere tempor, elit sem varius
            libero, ac vulputate ante orci vel odio. Nam sit amet tortor
            malesuada tellus rutrum varius vel a mauris. Integer volutpat neque
            mauris, vel imperdiet mi aliquet ac. Proin sed iaculis ipsum.
            Vivamus tincidunt egestas sapien, vitae faucibus velit ultricies
            eget. Donec mattis ante arcu, a pretium tortor scelerisque et. Morbi
            sed dui tempor, consectetur turpis sed, tristique arcu.
          </p>
          <p>
            Quisque nec ante faucibus, lobortis neque eget, placerat massa.
            Mauris tincidunt ante faucibus dui semper eleifend. Morbi sit amet
            velit lacus. Maecenas suscipit pulvinar lacus, ac feugiat erat
            venenatis porta. Donec at turpis ultrices, vehicula justo eu,
            laoreet magna. Ut consequat eleifend posuere. Quisque nec ante
            faucibus, lobortis neque eget, placerat massa. Mauris tincidunt ante
            faucibus dui semper eleifend. Morbi sit amet velit lacus. Maecenas
            suscipit pulvinar lacus, ac feugiat erat venenatis porta. Donec at
            turpis ultrices, vehicula justo eu, laoreet magna.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'App',
  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`)
    },
    hasGenerated() {
      alert('PDF generated successfully!')
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf()
    }
  },
  components: {
    VueHtml2pdf
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #da1010;
  width: 110px;
  background: #da1010;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}
</style>
