<template>
  <v-col>
    <v-row align="center" class="pa-3">
      <div class="icon-container mr-3">
        <v-icon color="primary" class="">mdi-camera</v-icon>
      </div>
      <span class="text-h5">{{ $t('Thermography') }}</span>
      <span class="caption ml-3 text--secondary"
        >| {{ $t('ViewThermography') }}</span
      >
    </v-row>
    <v-row class="mx-3" align="center" id="header-infos-thermography">
      <v-avatar color="primary" size="64">
        <img
          v-if="headerInfos.profilePicture !== ''"
          :src="headerInfos.profilePicture"
          alt="Profile picture"
        />
        <span v-else class="white--text text-body-1">
          {{ headerInfos.subject.charAt(0) }}
        </span>
      </v-avatar>
      <span class="ml-3">
        {{ headerInfos.subject }}
      </span>
      <v-divider vertical class="mx-2"></v-divider>
      <span class="text--secondary text-caption">{{ headerInfos.name }}</span>
      <v-divider vertical class="mx-2"></v-divider>
      <div>
        <v-chip
          color="primary"
          v-for="tag in headerInfos.tags"
          :key="tag"
          small
          class="ml-1"
          >{{ tag }}</v-chip
        >
      </div>
      <v-divider
        v-if="headerInfos.tags.length"
        vertical
        class="mx-2"
      ></v-divider>

      <div>
        <v-chip
          v-for="group in headerInfos.groups"
          :key="group.id"
          small
          class="ml-1"
          >{{ group.name }}</v-chip
        >
      </div>
      <v-divider
        v-if="headerInfos.groups.length"
        vertical
        class="mx-2"
      ></v-divider>

      <v-col align-self="center">
        <v-select
          :label="$t('Filter')"
          outlined
          dense
          v-model="filterTableType"
          hide-details
          :items="[
            { name: $t('Mean'), value: 'mean' },
            { name: $t('Min'), value: 'min' },
            { name: $t('Max'), value: 'max' }
          ]"
          item-text="name"
          item-value="value"
        >
        </v-select>
      </v-col>
      <v-col align-self="center">
        <v-select
          :label="$t('NumberOfHistoricalThermographs')"
          outlined
          dense
          v-model="qtyLastImages"
          hide-details
          :items="optionsQtyLastImages"
        />
      </v-col>

      <v-col align-self="center">
        <v-autocomplete
          style="font-weight: 300"
          outlined
          dense
          chips
          small-chips
          multiple
          hide-details
          v-model="selectedTag"
          :items="tagsOptions"
          :label="$t('Tags')"
        ></v-autocomplete>
      </v-col>

      <div v-if="metadata != null && metadata.report != null">
        <v-btn
          small
          color="primary"
          text
          :to="{
            name: 'ReportThermographyEditorWithoutTemplate',
            params: {
              thermographyId: this.$route.params.thermographyId
            }
          }"
        >
          <v-icon small>mdi-clipboard-text-search-outline</v-icon>
          {{ $t('Visualizar Relatório') }}
        </v-btn>
      </div>
      <div v-else>
        <v-btn small color="primary" text @click="reportTemplateModal = true">
          <v-icon small>mdi-download</v-icon>
          {{ $t('GENERATEREPORT') }}
        </v-btn>
      </div>
    </v-row>

    <!-- <v-row>
      <v-col cols="12" lg="4">
        <v-select
          label="Selecione outra termografia"
          outlined
          dense
          v-model="selectedThermo"
          :items="orderedThermos"
          item-text="label"
          item-value="id"
        />
      </v-col>
    </v-row> -->
    <v-row
      v-if="loadings.fetch && reponseDataStatus != 'PROCESSED'"
      class="pt-16"
    >
      <loading-screen style="height: 1vh" />
    </v-row>
    <v-card
      v-else-if="
        reponseDataStatus === 'PROCESSING' ||
        reponseDataStatus === 'CREATED' ||
        reponseDataStatus === 'QUEUE'
      "
    >
      <v-col>
        <v-col>
          <span
            >{{ $t('Imagem') }}
            {{
              reponseDataStatus === 'QUEUE'
                ? $t('Reprocessing')
                : $t('Processing')
            }}...</span
          >
          <loading-screen-small />
        </v-col>
        <v-col class="pt-0 mt-n3">
          <span class="text-caption text--secondary"
            >{{ $t('ThisCanTakeSomeTime') }}...
          </span>
        </v-col>
      </v-col>
    </v-card>
    <v-row
      class="flex-column-reverse flex-lg-row"
      v-else-if="reponseDataStatus == 'PROCESSED'"
    >
      <v-col class="col-content-thermography">
        <v-carousel
          hide-delimiter-background
          hide-delimiters
          height="auto"
          v-model="carousel"
          vertical
          :show-arrows="false"
          touchless
        >
          <v-carousel-item v-for="(image, keyImage) in images" :key="image.id">
            <v-card
              class="pa-4"
              v-if="
                image.status === 'PROCESSED' &&
                image.thermoImage != undefined &&
                image.thermoImage.data != undefined
              "
              outlined
            >
              <v-row>
                <!--<v-btn
                    small
                    color="primary"
                    @click="
                      showTypeThermos =
                        showTypeThermos === 'single' ? 'double' : 'single'
                    "
                  >
                    Exibir Termo
                    {{ showTypeThermos === 'single' ? 'dupla' : 'única' }}
                  </v-btn>-->
                <v-switch
                  md="4"
                  sm="4"
                  v-model="switchTermo"
                  @click="
                    showTypeThermos =
                      showTypeThermos === 'double' ? 'single' : 'double'
                  "
                  :label="$t('DualImage')"
                ></v-switch>

                <v-switch
                  style="padding-left: 30px"
                  v-model="switchGraphic"
                  :label="$t('Chart')"
                ></v-switch>
              </v-row>
              <v-row style="flex-wrap: nowrap">
                <v-row class="pa-3">
                  <v-col
                    cols="12"
                    :lg="colsLgThermographyImage"
                    v-if="image.status === 'PROCESSED'"
                    :id="`canvas-thermography-${keyImage}`"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        :lg="
                          images.length === 1 || showTypeThermos === 'single'
                            ? '12'
                            : '6'
                        "
                      >
                        <profile-view
                          :anamnese="anamnese"
                          :data="image.thermoImage.data"
                          :topLeftLabel="`${headerInfos.subject} - ${image.labelView}`"
                          :topLeftLabelB="
                            new Date(image.createdAt)
                              .toLocaleDateString()
                              .toString()
                          "
                          :metrics="image.metrics.data"
                          :contours="image.contours"
                          :view="image.view"
                          :maxHeight="switchGraphic ? 600 : 300"
                          :type="'image'"
                          :temps="{
                            min:
                              themeTypeTemps == 'dynamic'
                                ? image.minTemp
                                : themeMinTemp,
                            max:
                              themeTypeTemps == 'dynamic'
                                ? image.maxTemp
                                : themeMaxTemp
                          }"
                          :srcImage="image.src"
                          :onChangeSelection="onChangeSelected"
                          :selectedContours="curSelectedItems"
                          :onClickOpenModal="() => handleToggleModal(keyImage)"
                          :partsPainSelected="image.pain"
                          :propComparType="filterTableType"
                          :onChangeComparType="(val) => (filterTableType = val)"
                          :activeTempMetrics="activeTempMetrics"
                          :onChangeActiveTempMetrics="onChangeActiveTempMetrics"
                          :onChangeActiveTempMetricsObject="
                            onChangeActiveTempMetricsObject
                          "
                          :flipRegisters="image.flip_registers"
                          :segments="image.segments"
                          showSemaforica
                          :defaultPaletteName="themeDefaultPaletteName"
                          :thermoImageId="image.thermoImage.thermographyId"
                          :hideControls="true"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        lg="6"
                        v-if="images.length > 1 && showTypeThermos === 'double'"
                      >
                        <profile-view
                          :anamnese="anamnese"
                          :data="images[nextImgIdx].thermoImage.data"
                          :topLeftLabel="`${headerInfos.subject} - ${images[nextImgIdx].labelView}`"
                          :topLeftLabelB="
                            new Date(image.createdAt)
                              .toLocaleDateString()
                              .toString()
                          "
                          :metrics="images[nextImgIdx].metrics.data"
                          :contours="images[nextImgIdx].contours"
                          :view="images[nextImgIdx].view"
                          :maxHeight="switchGraphic ? 600 : 300"
                          :type="'image'"
                          :temps="{
                            min:
                              themeTypeTemps == 'dynamic'
                                ? images[nextImgIdx].minTemp
                                : themeMinTemp,
                            max:
                              themeTypeTemps == 'dynamic'
                                ? images[nextImgIdx].maxTemp
                                : themeMaxTemp
                          }"
                          :srcImage="images[nextImgIdx].src"
                          :onChangeSelection="onChangeSelectedNextItems"
                          :selectedContours="curSelectedNextItems"
                          :onClickOpenModal="
                            () => handleToggleModal(nextImgIdx, 'next')
                          "
                          :partsPainSelected="image.pain"
                          :propComparType="filterTableType"
                          :onChangeComparType="(val) => (filterTableType = val)"
                          :activeTempMetrics="activeTempMetrics"
                          :onChangeActiveTempMetrics="onChangeActiveTempMetrics"
                          :onChangeActiveTempMetricsObject="
                            onChangeActiveTempMetricsObject
                          "
                          :flipRegisters="images[nextImgIdx].flip_registers"
                          :segments="images[nextImgIdx].segments"
                          showSemaforica
                          :defaultPaletteName="themeDefaultPaletteName"
                          :thermoImageId="
                            images[nextImgIdx].thermoImage.thermographyId
                          "
                          :hideControls="true"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6" v-else>
                    <div style="max-width: 534px">
                      <img :src="activeImage.src" alt="" />
                    </div>
                    <span>{{ $t('PROCESSING') }}...</span>
                  </v-col>
                  <v-col
                    cols="12"
                    :lg="images.length === 1 ? '8' : '6'"
                    class="pa-3"
                    v-if="image.status === 'PROCESSED' && !switchGraphic"
                    :id="`chart-thermography-${keyImage}`"
                  >
                    <v-card v-if="selectedTempType === 'asymmetry'" flat>
                      <apexchart
                        type="line"
                        height="300"
                        :options="chartAsymmetries.options"
                        :series="getChartAsymmetries"
                        :data="chartAsymmetries.data"
                      />
                    </v-card>
                    <v-card v-else flat>
                      <apexchart
                        type="line"
                        height="300"
                        :options="chart.options"
                        :series="getChartSeries"
                        :data="chart.data"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-row>
              <v-divider class="mt-3"></v-divider>
              <v-row class="mt-n6">
                <v-col>
                  <v-row align="center">
                    <v-col cols="12" lg="6">
                      <v-switch
                        v-model="switchDataTable"
                        :label="`${$t('Asymmetry')} / ${$t('Temperature')}`"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="text-right">
                      <v-btn
                        @click="clickComparaTermos"
                        class="mr-2"
                        color="primary"
                        small
                      >
                        {{ $t('COMPARA TERMOS') }}
                      </v-btn>
                      <v-btn
                        @click="clickTiraTeima"
                        class="mr-2"
                        color="primary"
                        small
                      >
                        {{ $t('TIRA TEIMA') }}
                      </v-btn>
                      <v-btn
                        @click="clickShowPanoramica"
                        color="primary"
                        class="mr-2"
                        small
                        v-if="canGeneratePanoramica"
                      >
                        {{ $t('GENERATEPANORAMIC') }}
                      </v-btn>
                      <v-btn @click="clickShowProfile" color="primary" small>
                        {{ $t('VIEWPROFILE') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-card
                    outlined
                    :id="`data-table-asymmetry-thermography-${keyImage}`"
                  >
                    <v-row class="py-5 px-6">
                      <v-spacer></v-spacer>

                      <export-csv :exportData="exportData">
                        <template v-slot:button>
                          <v-btn small color="primary" text>
                            <v-icon small>mdi-download</v-icon>
                            {{ $t('EXPORT') }}
                          </v-btn>
                        </template>
                      </export-csv>
                    </v-row>
                    <v-data-table
                      hide-default-header
                      dense
                      class="pa-0 table-asymmetry"
                      :headers="asymmetryItems.headers"
                      :items="asymmetryItems.items"
                      :items-per-page="10"
                      :no-data-text="`${$t('NoRecord')} :(`"
                      mobile-breakpoint="1"
                    >
                      <template v-slot:header="{ props }">
                        <thead>
                          <tr>
                            <th
                              v-for="item in props.headers"
                              :key="item"
                              :class="[
                                'text-left',
                                getSelectedBorderClass(item)
                              ]"
                              :title="translateItem(item, 'full')"
                              @click="
                                !(item === 'Data' || item === 'Tags')
                                  ? toggleSelectedItems(item)
                                  : null
                              "
                            >
                              {{ translateItem(item, 'short') }}
                            </th>
                          </tr>
                        </thead>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td
                            width="500"
                            :class="getSelectedThermoClass(item.thermoId)"
                            @click="sendToThermo(item.thermoId)"
                          >
                            <span style="white-space: nowrap">
                              {{ formatDate(item.date) }}
                            </span>
                          </td>
                          <td @click="sendToThermo(item.thermoId)">
                            <v-chip
                              color="primary"
                              v-for="tag in item.tags"
                              :key="tag.id"
                              small
                              class="ml-1"
                              >{{ tag.name }}</v-chip
                            >
                          </td>
                          <td
                            :class="[
                              getAsymmetryClass(
                                item.data[key][filterTableType]
                              ),
                              getSelectedBorderClass(key)
                            ]"
                            v-for="key in Object.keys(item.data)"
                            :key="key"
                            @click="toggleSelectedItems(key)"
                          >
                            {{
                              item.data[key][filterTableType]
                                ? item.data[key][filterTableType].toFixed(2)
                                : item.data[key][filterTableType]
                            }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col class="carousel-thermos" id="carousel-thermos-thermography">
        <img
          :src="img.src"
          :key="img.id"
          v-for="(img, index) in images"
          class="img-preview"
          @click="handleSelectImage(index)"
        />
      </v-col>
    </v-row>
    <v-row class="pt-12" v-else>
      <v-col>
        <h3 class="pb-6">{{ $t('ErrorCouldntProcessImages') }}.</h3>
        <div class="pb-6">
          <v-btn
            depressed
            color="primary"
            :loading="isLoadingReprocess"
            :disabled="isLoadingReprocess"
            @click="handleReproccess"
            >{{ $t('REPROCESS') }}</v-btn
          >
        </div>
        <v-row align="center">
          <v-col
            cols="4"
            v-for="image in images"
            :key="image.id"
            align="center"
          >
            <v-img :src="image.src" max-height="300" contain />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="reponseDataStatus == 'PROCESSED'">
      <div v-for="(image, keyImage) in images" :key="image.id">
        <v-dialog v-model="image.showModal" width="900px">
          <profile-view
            :anamnese="anamnese"
            :data="image.thermoImage.data"
            :topLeftLabel="`${headerInfos.subject} - ${image.labelView}`"
            :topLeftLabelB="
              new Date(image.createdAt).toLocaleDateString().toString()
            "
            :metrics="image.metrics.data"
            :contours="image.contours"
            :view="image.view"
            :maxHeight="550"
            :type="'image'"
            :temps="{
              min: themeTypeTemps == 'dynamic' ? image.minTemp : themeMinTemp,
              max: themeTypeTemps == 'dynamic' ? image.maxTemp : themeMaxTemp
            }"
            :srcImage="image.src"
            :onChangeSelection="
              typeSelectedItems === 'current'
                ? onChangeSelected
                : onChangeSelectedNextItems
            "
            :selectedContours="
              typeSelectedItems === 'current'
                ? curSelectedItems
                : curSelectedNextItems
            "
            :onClickCloseModal="() => handleToggleModal()"
            activateEditDrawings
            :propComparType="filterTableType"
            :onChangeComparType="(val) => (filterTableType = val)"
            :activeTempMetrics="activeTempMetrics"
            :onChangeActiveTempMetrics="onChangeActiveTempMetrics"
            :onChangeActiveTempMetricsObject="onChangeActiveTempMetricsObject"
            :flipRegisters="image.flip_registers"
            :segments="image.segments"
            showSemaforica
            :defaultPaletteName="themeDefaultPaletteName"
            :id="`canvas-thermography-modal-${keyImage}`"
            :thermoImageId="image.thermoImage.thermographyId"
          />
        </v-dialog>
      </div>
    </div>

    <v-dialog v-model="reportTemplateModal" width="400px">
      <v-card elevation="8" class="pa-8">
        <v-select
          v-model="reportTemplateId"
          :items="reportTemplatesOptions"
          outlined
          dense
          single-line
          :label="$t('SelectATemplate')"
        />
        <v-btn
          color="primary"
          :to="{
            name: 'ReportThermographyEditor',
            params: {
              thermographyId: this.$route.params.thermographyId,
              reportTemplateId: this.reportTemplateId
            }
          }"
          :disabled="this.reportTemplateId == ''"
        >
          {{ $t('GENERATEREPORT') }}
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="profileCurImagesModal" width="1800px">
      <v-card elevation="8" class="pa-8">
        <loading-screen-small v-if="loadingProfilesImages" />
        <profile-images-front :images="profileImagesModal" v-else />
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPanoramica" width="500px">
      <v-card elevation="8" class="pa-8">
        <v-row v-if="loadingPanoramica">
          <v-col>
            <loading-screen-small />
          </v-col>
        </v-row>
        <v-row v-else-if="panoramicaErrorText">
          <v-col cols="12">
            {{ panoramicaErrorText }}
          </v-col>

          <v-col cols="12">
            <v-btn @click="clickBackPanoramica" color="primary" small>
              VOLTAR
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else-if="stepPanoramica == 'selection'">
          <v-col cols="12">
            Selecione as termografias para gerar a panoramica
          </v-col>
          <v-col cols="12">
            <v-carousel
              v-model="carouselImagePanoramica1"
              hide-delimiter-background
              height="250"
            >
              <v-carousel-item
                v-for="image in imagesPanoramica"
                :key="image.id"
                :onChangeSelected="() => onChangeSelection([])"
              >
                <div class="carousel-image">
                  <profile-view
                    :anamnese="anamnese"
                    :data="image.thermoImage.data"
                    :topLeftLabel="
                      new Date(image.createdAt).toLocaleDateString().toString()
                    "
                    :view="image.view"
                    :maxHeight="400"
                    :type="'image'"
                    :srcImage="image.src"
                    :defaultPaletteName="themeDefaultPaletteName"
                    :thermoImageId="image.thermoImage.thermographyId"
                    :temps="{
                      min:
                        themeTypeTemps == 'dynamic'
                          ? image.minTemp
                          : themeMinTemp,
                      max:
                        themeTypeTemps == 'dynamic'
                          ? image.maxTemp
                          : themeMaxTemp
                    }"
                    :hideControls="true"
                  />
                </div>
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="12">
            <v-carousel
              v-model="carouselImagePanoramica2"
              hide-delimiter-background
              height="250"
            >
              <v-carousel-item
                v-for="image in imagesPanoramica"
                :key="image.id"
                :onChangeSelected="() => onChangeSelection([])"
              >
                <div class="carousel-image">
                  <profile-view
                    :anamnese="anamnese"
                    :data="image.thermoImage.data"
                    :topLeftLabel="
                      new Date(image.createdAt).toLocaleDateString().toString()
                    "
                    :view="image.view"
                    :maxHeight="400"
                    :type="'image'"
                    :srcImage="image.src"
                    :defaultPaletteName="themeDefaultPaletteName"
                    :thermoImageId="image.thermoImage.thermographyId"
                    :temps="{
                      min:
                        themeTypeTemps == 'dynamic'
                          ? image.minTemp
                          : themeMinTemp,
                      max:
                        themeTypeTemps == 'dynamic'
                          ? image.maxTemp
                          : themeMaxTemp
                    }"
                    :hideControls="true"
                  />
                </div>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn @click="clickGeneratePanoramica" color="primary" small>
              GERAR PANORAMICA
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-else-if="stepPanoramica == 'generatedStitcher'">
          <v-col cols="12">
            <p>Panorama gerado:</p>
            <canvas ref="generatedPanorama"></canvas>
          </v-col>
          <v-col cols="12" class="text-left">
            <p>
              <v-btn
                @click="clickBackPanoramica"
                color="primary"
                small
                class="mr-2"
              >
                VOLTAR
              </v-btn>
              <v-btn @click="handleAddThermoImage" color="primary" small
                >Salvar</v-btn
              >
            </p>
          </v-col>
        </v-row>
        <v-row v-else-if="stepPanoramica == 'generated'">
          <v-col cols="12">{{
            imagePanoramica != null
              ? 'Panoramica gerada:'
              : 'Erro ao gerar panoramica'
          }}</v-col>

          <v-col cols="12">
            <canvas ref="generatedPanoramaStitcher"></canvas>
          </v-col>

          <v-col cols="12" class="text-right">
            <p>
              <v-btn
                @click="clickBackPanoramica"
                color="primary"
                small
                class="mr-2"
              >
                REFAZER
              </v-btn>
              <v-btn @click="handleAddThermoImage" color="primary" small
                >Salvar</v-btn
              >
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import {
  reportsService,
  thermoService,
  subjectsService,
  reportTemplatesService
} from '@/services'
import { format } from 'date-fns'
import ExportCsv from '../components/ExportCsv.vue'
import dictSegmentation from '@/translations/dict.json'
import ProfileView from '../components/ProfileView.vue'
import LoadingScreenSmall from '../components/LoadingScreenSmall.vue'
import LoadingScreen from '../components/LoadingScreen.vue'
import ProfileImagesFront from '../components/ProfileImagesFront.vue'
import cv from '../utils/opencv-helper'

export default {
  components: {
    ExportCsv,
    ProfileView,
    LoadingScreenSmall,
    ProfileImagesFront,
    LoadingScreen
  },
  data: () => ({
    anamnese: '',
    newThermo: {
      createdAt: '',
      anamnese: '',
      img: null,
      minTemp: '36',
      maxTemp: '26',
      paletteName: 'RainbowHC',
      view: 'others'
    },
    showPanoramica: false,
    loadingPanoramica: false,
    panoramicaErrorText: '',
    stepPanoramica: 'selection',
    canGeneratePanoramica: false,
    carouselImagePanoramica1: 0,
    carouselImagePanoramica2: 1,
    imagesPanoramica: [],
    imagePanoramica: null,
    qtyLastImages: 10,
    optionsQtyLastImages: [
      {
        text: '10',
        value: 10
      },
      {
        text: '25',
        value: 25
      },
      {
        text: '50',
        value: 50
      }
    ],
    loadingProfilesImages: true,
    profileImagesModal: [],
    profileCurImagesModal: false,
    metadata: null,
    switchDataTable: false,
    switchGraphic: false,
    reportTemplateModal: false,
    reportTemplateId: '',
    reportTemplatesOptions: [],
    showTypeThermos: 'double',
    switchTermo: true,
    selectedTempType: '',
    selectedThermo: '',
    peopleId: 0,
    thermos: [],
    isLoadingReprocess: false,
    showModal: false,
    reponseDataStatus: '',
    errors: {
      fetch: false
    },
    loadings: {
      fetch: false
    },
    images: [],
    carousel: 0,
    headerInfos: {
      subject: '',
      name: '',
      date: '',
      profilePicture: '',
      tags: [],
      groups: []
    },
    chart: {
      options: {
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        chart: {
          toolbar: {
            show: false
          },
          id: 'analyse-chart'
        },
        stroke: {
          show: true,
          curve: 'smooth',
          width: 2
        },
        markers: {
          size: 5
        },
        xaxis: {
          categories: [],
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          }
        },
        yaxis: {
          min: (min) => (min > 10 && min < 40 ? Math.floor(min) : 30),
          max: (max) => (max > 10 && max < 40 ? Math.ceil(max) : 36),
          tickAmount: 4
        }
      },
      series: []
    },
    chartAsymmetries: {
      options: {
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        chart: {
          toolbar: {
            show: false
          },
          id: 'analyse-chart'
        },
        stroke: {
          show: true,
          curve: 'smooth',
          width: 2
        },
        markers: {
          size: 5
        },
        xaxis: {
          categories: [],
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          }
        },
        yaxis: {
          min: (min) => (min > -10 && min < 10 ? Math.floor(min) : -4),
          max: (max) => (max > -10 && max < 10 ? Math.ceil(max) : 4),
          tickAmount: 10
        }
      },
      series: []
    },
    curSelectedItems: [],
    selectedItems: [],
    curSelectedNextItems: [],
    selectedNextItems: [],
    typeSelectedItems: 'current',
    filterTableType: 'mean',
    table: {
      items: []
    },
    idxModal: 0,
    activeTempMetrics: 0,
    currentTags: [],
    tagsOptions: [],
    selectedTag: []
  }),
  computed: {
    themeTypeTemps() {
      return window.localStorage.getItem('themeTypeTemps')
    },
    themeDefaultPaletteName() {
      return window.localStorage.getItem('themeDefaultPaletteName')
    },
    themeMinTemp() {
      return window.localStorage.getItem('themeMinTemp')
    },
    themeMaxTemp() {
      return window.localStorage.getItem('themeMaxTemp')
    },
    profileImagesThermoIds() {
      if (this.activeImage == null) return []
      let lastImages = JSON.parse(JSON.stringify(this.activeImage.lastImages))
      const uniqueIds = []
      lastImages = lastImages.sort(function (a, b) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      lastImages = lastImages
        .filter((image) => {
          const isDuplicate = uniqueIds.includes(image.id)

          if (!isDuplicate) {
            uniqueIds.push(image.id)
            return true
          }

          return false
        })
        .filter((image) => {
          if (this.selectedTag && this.selectedTag.length) {
            if (image.tags.length == 0) {
              return false
            }
            let hasTag = false
            for (let idxTag = 0; idxTag < image.tags.length; idxTag++) {
              if (this.selectedTag.includes(image.tags[idxTag].name)) {
                hasTag = true
              }
            }
            return hasTag
          }
          return true
        })
        .map((image) => {
          return {
            thermographyId: image.thermographyId,
            id: image.id
          }
        })
      return lastImages
    },
    colsLgThermographyImage() {
      if (this.switchGraphic) return '12'
      return this.images.length === 1 || this.showTypeThermos === 'single'
        ? '4'
        : '6'
    },
    orderedThermos() {
      let thermosReturn = this.thermos

      thermosReturn = thermosReturn.filter((thermo) => {
        return thermo.status === 'PROCESSED'
      })

      thermosReturn.sort((a, b) => {
        const iDateA = new Date(a.createdAt).getTime()
        const iDateB = new Date(b.createdAt).getTime()
        return iDateB < iDateA ? -1 : 1
      })

      thermosReturn.forEach((thermo) => {
        const createdAtFormatted = format(
          new Date(thermo.createdAt),
          'dd/MM/yyyy HH:mm'
        )
        thermo.label =
          thermo.name !== ''
            ? `${createdAtFormatted} - ${thermo.name}`
            : createdAtFormatted
      })

      return thermosReturn
    },
    nextImgIdx() {
      return this.images.length == this.carousel + 1 ? 0 : this.carousel + 1
    },
    activeImage() {
      return this.images[this.carousel]
    },
    asymmetryItems() {
      const asyMetric = this.switchDataTable ? 'metrics' : 'asymmetries'
      if (this.images.length === 0)
        return {
          headers: [],
          items: []
        }
      if (!this.activeImage[asyMetric].data)
        return {
          headers: [],
          items: []
        }

      const activeAssymetries = {}

      Object.keys(this.activeImage[asyMetric].data)
        .sort()
        .forEach((key) => {
          activeAssymetries[key] = { ...this.activeImage[asyMetric].data[key] }
        })

      const activeMetrics = {}

      Object.keys(this.activeImage.metrics.data)
        .sort()
        .forEach((key) => {
          activeMetrics[key] = { ...this.activeImage.metrics.data[key] }
        })

      const returnData = {
        headers: ['Data', 'Tags', ...Object.keys(activeAssymetries)],
        items: []
      }

      for (const image of this.activeImage.lastImages) {
        const imageAssymetriesData = {}
        Object.keys(image?.[asyMetric].data)
          .sort()
          .forEach((key) => {
            imageAssymetriesData[key] = { ...image?.[asyMetric].data[key] }
          })

        const imageMetricsData = {}
        Object.keys(image?.metrics.data)
          .sort()
          .forEach((key) => {
            imageMetricsData[key] = { ...image?.metrics.data[key] }
          })

        if (image.status === 'PROCESSED') {
          if (this.selectedTag && this.selectedTag.length) {
            for (let idxTag = 0; idxTag < image.tags.length; idxTag++) {
              if (this.selectedTag.includes(image.tags[idxTag].name)) {
                idxTag = image.tags.length
                returnData.items.push({
                  data: imageAssymetriesData || {},
                  tags: image.tags,
                  date: image.createdAt,
                  thermoId: image.thermographyId,
                  metrics: imageMetricsData || {}
                })
              }
            }
          } else {
            returnData.items.push({
              data: imageAssymetriesData || {},
              tags: image.tags,
              date: image.createdAt,
              thermoId: image.thermographyId,
              metrics: imageMetricsData || {}
            })
          }
        }
      }
      return returnData
    },
    imageItems() {
      if (this.images.length === 0) {
        return []
      }
      const data_return = Object.keys(this.images[this.carousel].metrics.data)
        .map((image) => {
          const data = this.images[this.carousel].metrics.data
          if (data[image]) {
            return {
              name: image,
              min: data[image].min,
              max: data[image].max,
              mean: data[image].mean
            }
          }
          return null
        })
        .filter((item) => !!item)
      return data_return
    },
    getChartSeries() {
      const data = this.curSelectedItems.map((selectedItem) => {
        let itemData = {
          y: this.activeImage?.metrics?.data?.[selectedItem]?.[
            this.filterTableType
          ].toFixed(2),
          x: new Date(this.headerInfos.date)
        }
        const lastItemData = this.activeImage.lastImages
          .map((image) => {
            return {
              y: image?.metrics?.data?.[selectedItem]?.[
                this.filterTableType
              ].toFixed(2),
              x: new Date(image.createdAt),
              tags: image.tags
            }
          })
          .filter((item) => {
            if (this.selectedTag && this.selectedTag.length) {
              if (item.tags.length == 0) {
                return false
              }
              let hasTag = false
              for (let idxTag = 0; idxTag < item.tags.length; idxTag++) {
                if (this.selectedTag.includes(item.tags[idxTag].name)) {
                  hasTag = true
                }
              }
              return hasTag
            }
            return !!item.y
          })
        const returnData = [itemData, ...lastItemData]
        return {
          name:
            this.activeImage.labelView +
            ' - ' +
            this.translateItem(selectedItem, 'full'),
          data: returnData
        }
      })

      if (this.showTypeThermos === 'single') return data

      const dataNext = this.curSelectedNextItems.map((selectedItem) => {
        let itemData = {
          y: this.images[this.nextImgIdx]?.metrics?.data?.[selectedItem]?.[
            this.filterTableType
          ].toFixed(2),
          x: new Date(this.headerInfos.date)
        }
        const lastItemData = this.images[this.nextImgIdx].lastImages
          .map((image) => {
            return {
              y: image?.metrics?.data?.[selectedItem]?.[
                this.filterTableType
              ].toFixed(2),
              x: new Date(image.createdAt),
              tags: image.tags
            }
          })
          .filter((item) => {
            if (this.selectedTag && this.selectedTag.length) {
              if (item.tags.length == 0) {
                return false
              }
              let hasTag = false
              for (let idxTag = 0; idxTag < item.tags.length; idxTag++) {
                if (this.selectedTag.includes(item.tags[idxTag].name)) {
                  hasTag = true
                }
              }
              return hasTag
            }
            return !!item.y
          })
        const returnData = [itemData, ...lastItemData]
        return {
          name:
            this.images[this.nextImgIdx].labelView +
            ' - ' +
            this.translateItem(selectedItem, 'full'),
          data: returnData
        }
      })
      return data.concat(dataNext)
    },
    getChartAsymmetries() {
      const data = this.curSelectedItems.map((selectedItem) => {
        let itemData = {
          y: this.activeImage?.asymmetries?.data?.[selectedItem]?.[
            this.filterTableType
          ].toFixed(2),
          x: new Date(this.headerInfos.date)
        }
        const lastItemData = this.activeImage.lastImages
          .map((image) => {
            return {
              y: image?.asymmetries?.data?.[selectedItem]?.[
                this.filterTableType
              ].toFixed(2),
              x: new Date(image.createdAt),
              tags: image.tags
            }
          })
          .filter((item) => {
            if (this.selectedTag && this.selectedTag.length) {
              if (item.tags.length == 0) {
                return false
              }
              let hasTag = false
              for (let idxTag = 0; idxTag < item.tags.length; idxTag++) {
                if (this.selectedTag.includes(item.tags[idxTag].name)) {
                  hasTag = true
                }
              }
              return hasTag
            }
            return !!item.y
          })
        const returnData = [itemData, ...lastItemData]
        return {
          name:
            this.activeImage.labelView +
            ' - ' +
            this.translateItem(selectedItem, 'full'),
          data: returnData
        }
      })
      const dataNext = this.curSelectedNextItems.map((selectedItem) => {
        let itemData = {
          y: this.images[this.nextImgIdx]?.asymmetries?.data?.[selectedItem]?.[
            this.filterTableType
          ].toFixed(2),
          x: new Date(this.headerInfos.date)
        }
        const lastItemData = this.images[this.nextImgIdx].lastImages
          .map((image) => {
            return {
              y: image?.asymmetries?.data?.[selectedItem]?.[
                this.filterTableType
              ].toFixed(2),
              x: new Date(image.createdAt),
              tags: image.tags
            }
          })
          .filter((item) => {
            if (this.selectedTag && this.selectedTag.length) {
              if (item.tags.length == 0) {
                return false
              }
              let hasTag = false
              for (let idxTag = 0; idxTag < item.tags.length; idxTag++) {
                if (this.selectedTag.includes(item.tags[idxTag].name)) {
                  hasTag = true
                }
              }
              return hasTag
            }
            return !!item.y
          })
        const returnData = [itemData, ...lastItemData]
        return {
          name:
            this.images[this.nextImgIdx].labelView +
            ' - ' +
            this.translateItem(selectedItem, 'full'),
          data: returnData
        }
      })
      return data.concat(dataNext)
    },
    exportData() {
      return this.asymmetryItems.items.map((item) => {
        const data_values = {}
        for (const key of Object.keys(item.data)) {
          data_values[this.translateItem(key, 'full')] =
            item.data[key][this.filterTableType] !== undefined
              ? item.data[key][this.filterTableType].toFixed(2)
              : 0
        }

        const metrics_values = {}
        for (const key of Object.keys(item.metrics)) {
          if (item.metrics[key]?.[this.filterTableType] != null) {
            metrics_values['Temperatura ' + this.translateItem(key, 'full')] =
              item.metrics[key]?.[this.filterTableType].toFixed(2)
          }
        }

        return {
          Nome: this.headerInfos.subject,
          Data: format(new Date(item.date), 'dd/MM/yyyy HH:mm'),
          Tags: this.headerInfos.tags.join('|'),
          ...data_values,
          ...metrics_values
        }
      })
    }
  },
  methods: {
    clickTiraTeima() {
      this.$router.push({
        name: 'TiraTeima',
        params: {
          peopleid: this.peopleId
        }
      })
    },
    clickComparaTermos() {
      this.$router.push({
        name: 'ThermoGraphOnly',
        params: {
          peopleid: this.thermographyId
        }
      })
    },
    async clickShowProfile() {
      this.profileCurImagesModal = true
      this.loadProfileImagesModal()
    },
    getImageArrayFromThermoImage(image) {
      const imageArray = []
      const height = image.thermoImage.data.length
      const width = image.thermoImage.data[0].length
      for (let y = 0; y < height; y++) {
        imageArray[y] = []

        for (let x = 0; x < width; x++) {
          imageArray[y][x] = image.thermoImage.data[y][x]
        }
      }

      return imageArray
    },
    removeZerosChunkImage(chunk) {
      const start = []
      const newChunk = []
      for (let i in chunk) {
        if (chunk[i] > 0) {
          start.push(parseInt(i))
          newChunk.push(parseInt(chunk[i]))
        }
      }
      return {
        chunk: newChunk,
        start
      }
    },
    resizeChunkImageRow(chunk, profileChunk) {
      if (profileChunk.length == 0) {
        return []
      } else if (chunk.length == 0) {
        return [...profileChunk]
      }
      const newChunk = [...chunk]
      let difLength = profileChunk.length - newChunk.length

      if (difLength == 0) {
        return newChunk
      } else if (difLength > 0) {
        for (let i = 1; i <= difLength; i++) {
          const curLength = newChunk.length
          let pos = 0
          if (i % 2 == 0) {
            pos = Math.ceil(curLength / (2 + i / 2)) + Math.ceil(curLength / 2)
          } else {
            pos = Math.ceil(curLength / (2 + i / 2))
          }

          if (pos >= curLength) {
            pos = curLength - 1
          } else if (pos < 0) {
            pos = 0
          }

          newChunk.splice(pos, 0, newChunk[pos])
        }
      } else if (difLength < 0) {
        difLength = Math.abs(difLength)
        for (let i = 1; i <= difLength; i++) {
          const curLength = newChunk.length
          let pos = 0
          if (i % 2 == 0) {
            pos =
              Math.ceil(curLength / (2 + Math.ceil(i / 2))) +
              Math.ceil(curLength / 2)
          } else {
            pos = Math.ceil(curLength / (2 + Math.ceil(i / 2)))
          }
          newChunk.splice(pos, 1)
        }
        if (newChunk.length != profileChunk.length) {
          newChunk.length = profileChunk.length
        }
      }

      return newChunk
    },
    clickShowPanoramica() {
      this.showPanoramica = true
      this.panoramicaErrorText = ''
      this.loadingPanoramica = false
      this.imagePanoramica = null
      this.stepPanoramica = 'selection'
    },
    getStartWidthAndHeightPanoramica(
      rowImage1WithoutZeros,
      dataImage2,
      heigthImage2
    ) {
      let startWidth = 0
      let startHeight = 0
      let iEqualsTotal = 0

      for (let y = 0; y < parseInt((heigthImage2 / 100) * 50); y++) {
        const curImage2WithouZeros = this.removeZerosChunkImage(dataImage2[y])

        for (
          let xStart = 0;
          xStart < parseInt(rowImage1WithoutZeros.chunk.length / 2);
          xStart++
        ) {
          let iEquals = 0
          for (let x = 0; x < rowImage1WithoutZeros.chunk.length; x++) {
            if (
              curImage2WithouZeros.chunk[x] != undefined &&
              rowImage1WithoutZeros.chunk[x + xStart] != undefined &&
              Math.abs(
                rowImage1WithoutZeros.chunk[x + xStart] -
                  curImage2WithouZeros.chunk[x]
              ) == 0
            ) {
              iEquals++
            }
          }

          if (iEqualsTotal < iEquals) {
            iEqualsTotal = iEquals
            startHeight = y
          }
        }
      }

      const curImage2WithouZeros = this.removeZerosChunkImage(
        dataImage2[startHeight]
      )
      const offsetLeftRight = {}
      for (
        let xStart = 0;
        xStart < parseInt(rowImage1WithoutZeros.chunk.length / 2);
        xStart++
      ) {
        for (let x = 0; x < rowImage1WithoutZeros.chunk.length; x++) {
          if (
            curImage2WithouZeros.chunk[x] != undefined &&
            rowImage1WithoutZeros.chunk[x + xStart] != undefined &&
            Math.abs(
              rowImage1WithoutZeros.chunk[x + xStart] -
                curImage2WithouZeros.chunk[x]
            ) == 0
          ) {
            if (
              offsetLeftRight[
                rowImage1WithoutZeros.start[x + xStart] -
                  curImage2WithouZeros.start[x]
              ] == undefined
            ) {
              offsetLeftRight[
                rowImage1WithoutZeros.start[x + xStart] -
                  curImage2WithouZeros.start[x]
              ] = 0
            }
            offsetLeftRight[
              rowImage1WithoutZeros.start[x + xStart] -
                curImage2WithouZeros.start[x]
            ] += 1
          }
        }
      }

      const keysSortedOffset = Object.keys(offsetLeftRight).sort(function (
        a,
        b
      ) {
        return offsetLeftRight[b] - offsetLeftRight[a]
      })

      startWidth = keysSortedOffset[0]

      return {
        startWidth,
        startHeight,
        iEqualsTotal
      }
    },
    async clickGeneratePanoramica() {
      this.loadingPanoramica = true
      this.panoramicaErrorText = ''
      await new Promise((r) => setTimeout(r, 500))
      this.generatePanoramica()
    },
    async generatePanoramicaOpenCv() {
      this.stepPanoramica = 'generatedStitcher'

      const imagePanoramica1 =
        this.imagesPanoramica[this.carouselImagePanoramica1]
      const imagePanoramica2 =
        this.imagesPanoramica[this.carouselImagePanoramica2]
      const imagesPanoramica = [
        imagePanoramica1.thermoImage.data,
        imagePanoramica2.thermoImage.data
      ]
      const usedTemps = {
        min: imagePanoramica1.minTemp,
        max: imagePanoramica1.maxTemp
      }
      const paletteName = imagePanoramica1.palette.replace('Palette', '')
      try {
        const imgDataPanoramica = cv.stitch(
          imagesPanoramica,
          paletteName,
          usedTemps
        )
        this.loadingPanoramica = false
        await new Promise((r) => setTimeout(r, 1000))

        const canvas = this.$refs.generatedPanorama
        canvas.width = imgDataPanoramica.width
        canvas.height = imgDataPanoramica.height
        const ctx = canvas.getContext('2d')
        await ctx.putImageData(imgDataPanoramica, 0, 0)
        let dataImgUrl = canvas.toDataURL('image/jpeg')
        dataImgUrl = await this.base64ToFile(dataImgUrl)
        this.newThermo = {
          createdAt: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
          img: dataImgUrl,
          minTemp: imagePanoramica1.minTemp,
          maxTemp: imagePanoramica1.maxTemp,
          paletteName,
          view: 'others'
        }
      } catch {
        this.loadingPanoramica = false
        this.panoramicaErrorText = 'Não foi possível gerar a panorâmica'
      }
    },
    async base64ToFile(url) {
      return new Promise((resolve) =>
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            resolve(
              new File([blob], 'Thermography File', { type: 'image/jpeg' })
            )
          })
      )
    },
    async handleAddThermoImage() {
      this.loadingPanoramica = true
      this.panoramicaErrorText = ''
      try {
        await this.$store.dispatch('addImage', {
          ...this.newThermo,
          thermoId: this.$route.params.thermographyId
        })
        await this.fetchImages(true)
        this.newThermo = {
          createdAt: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
          img: null,
          minTemp: this.newThermo.minTemp,
          maxTemp: this.newThermo.maxTemp,
          paletteName: this.newThermo.paletteName,
          view: ''
        }
        this.$store.dispatch('setSnackBar', {
          text: 'Panorama gerado com sucesso!',
          type: 'success',
          model: true
        })
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t(
                  'AnErrorOccurredWhileTryingToAddTheThermographicImage'
                )}!`,
          type: 'error',
          model: true
        })
      }
      this.showPanoramica = false
      this.loadingPanoramica = false
    },
    async generatePanoramica() {
      this.stepPanoramica = 'generated'
      this.imagePanoramica = JSON.parse(
        JSON.stringify(this.imagesPanoramica[0])
      )

      const dataImage1 = this.getImageArrayFromThermoImage(
        this.imagesPanoramica[this.carouselImagePanoramica1]
      )
      let dataImage2 = this.getImageArrayFromThermoImage(
        this.imagesPanoramica[this.carouselImagePanoramica2]
      )
      const resultDataImage = dataImage1

      const heigthImage1 = dataImage1.length
      const widthImage1 = dataImage1[0].length
      const heigthImage2 = dataImage2.length
      let iEqualsSelected = 0
      let startWidthSelected = 0
      let startHeightSelected = 0
      let newLengthResultHeight = heigthImage1

      for (let y = parseInt((heigthImage1 / 100) * 50); y < heigthImage1; y++) {
        const rowImage1 = dataImage1[y]
        const rowImage1WithoutZeros = this.removeZerosChunkImage(rowImage1)

        const { startWidth, startHeight, iEqualsTotal } =
          this.getStartWidthAndHeightPanoramica(
            rowImage1WithoutZeros,
            dataImage2,
            heigthImage2
          )

        if (iEqualsSelected < iEqualsTotal) {
          iEqualsSelected = iEqualsTotal
          startWidthSelected = startWidth
          startHeightSelected = startHeight
          newLengthResultHeight = y
        }
      }

      resultDataImage.splice(
        newLengthResultHeight,
        Math.abs(newLengthResultHeight - heigthImage1)
      )
      const heightResult = resultDataImage.length

      for (let y = startHeightSelected; y < heigthImage2; y++) {
        const yResult = y + heightResult - startHeightSelected
        resultDataImage[yResult] = []

        for (let x = startWidthSelected; x < widthImage1; x++) {
          if (dataImage2[y][x - startWidthSelected] != undefined) {
            resultDataImage[yResult][x] = dataImage2[y][x - startWidthSelected]
          } else {
            resultDataImage[yResult][x] = 0
          }
        }
      }

      const usedTemps = {
        min: this.imagesPanoramica[this.carouselImagePanoramica1].minTemp,
        max: this.imagesPanoramica[this.carouselImagePanoramica1].maxTemp
      }
      const paletteName = this.imagesPanoramica[
        this.carouselImagePanoramica1
      ].palette.replace('Palette', '')
      const imgDataPanoramica = await cv.getImageDataFromImageArray(
        resultDataImage,
        paletteName,
        usedTemps
      )

      this.loadingPanoramica = false
      await new Promise((r) => setTimeout(r, 1000))

      const canvas = this.$refs.generatedPanoramaStitcher
      canvas.width = imgDataPanoramica.width
      canvas.height = imgDataPanoramica.height
      const ctx = canvas.getContext('2d')
      await ctx.putImageData(imgDataPanoramica, 0, 0)
      let dataImgUrl = canvas.toDataURL('image/jpeg')
      dataImgUrl = await this.base64ToFile(dataImgUrl)

      this.newThermo = {
        createdAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16),
        img: dataImgUrl,
        minTemp: this.imagesPanoramica[this.carouselImagePanoramica1].minTemp,
        maxTemp: this.imagesPanoramica[this.carouselImagePanoramica1].maxTemp,
        paletteName: this.imagesPanoramica[
          this.carouselImagePanoramica1
        ].palette.replace('Palette', ''),
        view: 'others'
      }
    },
    clickBackPanoramica() {
      this.loadingPanoramica = false
      this.imagePanoramica = null
      this.panoramicaErrorText = ''
      this.stepPanoramica = 'selection'
    },
    async loadProfileImagesModal() {
      this.loadingProfilesImages = true
      this.profileImagesModal = []
      const fetchProfileImagesArray = []
      this.profileImagesThermoIds.forEach((image) => {
        fetchProfileImagesArray.push(
          this.fetchProfileImagesModal(image.thermographyId, image.id)
        )
      })

      await Promise.all(fetchProfileImagesArray)

      this.profileImagesModal = this.profileImagesModal.sort(function (a, b) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })

      this.loadingProfilesImages = false
    },
    async fetchProfileImagesModal(thermographyId, imageId) {
      try {
        const response = await reportsService.getSingleThermo(thermographyId, {
          qtyLastImages: 0
        })

        const data_images = response.data.thermographyImages
          .filter((image) => {
            if (image.id == imageId) {
              return true
            }
            return false
          })
          .map((image) => {
            const contours =
              typeof image?.contours?.data === typeof Object
                ? Object.keys(image.contours.data).filter(
                    (contour) =>
                      !image.contours.data[contour] ||
                      image.contours.data[contour] === 'bg'
                  )
                : []
            for (const contour of contours) {
              delete image.contours.data[contour]
            }
            const metrics =
              typeof image?.metrics?.data === typeof Object
                ? Object.keys(image.metrics.data).filter(
                    (metric) =>
                      !image.metrics.data[metric] ||
                      image.metrics.data[metric] === 'bg'
                  )
                : []
            for (const metric of metrics) {
              delete image.metrics.data[metric]
            }
            return image
          })

        if (data_images.length > 0) {
          this.profileImagesModal.push(data_images[0])
        }
      } catch (error) {
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
          type: 'error',
          model: true
        })
      }
    },
    async fetchReportTemplates() {
      try {
        const response = await reportTemplatesService.index()
        response.data.forEach((item) => {
          this.reportTemplatesOptions.push({
            text: item.name,
            value: item.id
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    async fetchPeople() {
      const { data } = await subjectsService.show(this.peopleId)
      this.thermos = data.thermographies
      this.tagsOptions = []
      data.thermographies.forEach((thermography) => {
        thermography.tags.forEach((tag) => {
          if (!this.tagsOptions.includes(tag.name)) {
            this.tagsOptions.push(tag.name)
          }
        })
      })
    },
    async handleReproccess() {
      this.isLoadingReprocess = true
      try {
        await thermoService.reprocess(this.$route.params.thermographyId)

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ThermographyIsBeingReprocessed')}!`,
          type: 'success',
          model: true
        })

        this.fetchImages()
      } catch (e) {
        console.log(e)

        this.$store.dispatch('setSnackBar', {
          text:
            e?.response?.data?.message != ''
              ? e.response.data.message
              : `${this.$t(
                  'AnErrorOccurredWhileTryingToReprocessTheThermography'
                )}!`,
          type: 'error',
          model: true
        })
      } finally {
        this.isLoadingReprocess = false
      }
    },
    sendToThermo(thermoId) {
      if (thermoId !== null)
        this.$router.push({
          name: 'Thermography',
          params: {
            thermographyId: thermoId
          }
        })
    },
    onChangeActiveTempMetrics(val) {
      this.activeTempMetrics = val
    },
    onChangeActiveTempMetricsObject(val) {
      this.selectedTempType = val.type
    },
    handleToggleModal(idxImage, type = 'current') {
      this.typeSelectedItems = type
      this.idxModal = idxImage !== undefined ? idxImage : this.idxModal
      this.images[this.idxModal].showModal =
        !this.images[this.idxModal].showModal
    },
    getAsymmetryClass(value) {
      if (this.switchDataTable) return ''
      let colorType = 'green_success'

      if (value < -0.99) colorType = 'red_white_danger'
      else if (value < -0.49) colorType = 'yellow_white_alert'
      else if (value > 0.99) colorType = 'red_danger'
      else if (value > 0.49) colorType = 'yellow_alert'

      return colorType
    },
    getSelectedBorderClass(item) {
      return this.curSelectedItems.includes(item)
        ? 'border-selected-items active'
        : 'border-selected-items'
    },
    getSelectedThermoClass(thermoId) {
      return thermoId === this.$route.params.thermographyId
        ? 'border-selected-items active'
        : 'border-selected-items'
    },
    formatDate(stringDate) {
      return format(new Date(stringDate), 'dd/MM/yyyy HH:mm')
    },
    async fetchImages(reloading = false) {
      if (this.images.length === 0) this.loadings.fetch = true
      this.selectedItems = []
      this.selectedNextItems = []

      if (reloading) {
        this.loadings.fetch = true
        this.reponseDataStatus = ''
      }
      try {
        const response = await reportsService.getSingleThermo(
          this.$route.params.thermographyId,
          {
            qtyLastImages: this.qtyLastImages
          }
        )
        this.headerInfos.subject = response.data.subject.name
        this.headerInfos.tags = response.data?.tags?.map((tag) => tag.name)
        this.currentTags = response.data?.tags
        this.headerInfos.name = response.data.name
        this.anamnese = response.data.anamnese
        this.headerInfos.groups = response.data.subject.groups
        this.headerInfos.date = response.data.createdAt
        this.headerInfos.profilePicture = response.data.subject.profilePicture
        this.peopleId = response.data.subject.id
        this.metadata = response.data.metadata
        let countViewOthers = 0
        this.imagesPanoramica = []

        const data_images = response.data.thermographyImages.map((image) => {
          if (image.status === 'PROCESSED') {
            const contours =
              typeof image?.contours?.data === typeof Object
                ? Object.keys(image.contours.data).filter(
                    (contour) =>
                      !image.contours.data[contour] ||
                      image.contours.data[contour] === 'bg'
                  )
                : []
            for (const contour of contours) {
              delete image.contours.data[contour]
            }
            const metrics =
              typeof image?.metrics?.data === typeof Object
                ? Object.keys(image.metrics.data).filter(
                    (metric) =>
                      !image.metrics.data[metric] ||
                      image.metrics.data[metric] === 'bg'
                  )
                : []
            for (const metric of metrics) {
              delete image.metrics.data[metric]
            }

            this.selectedItems.push([])
            this.selectedNextItems.push([])

            const returnDataImage = {
              id: image.id,
              src: image.src,
              pain: image.pain,
              status: image.status,
              asymmetries: image?.asymmetries || {},
              lastImages: image.lastImages,
              view: image.view,
              labelView: this.getLabelView(image.view),
              maxTemp: image.maxTemp,
              minTemp: image.minTemp,
              palette: image.palette,
              thermoImage: image.thermoImage,
              flip_registers: image?.flip_registers || {},
              segments: image?.segments || {},
              contours: {
                ...image.contours
              },
              metrics: image.metrics || {
                data: {}
              },
              createdAt: image.createdAt,
              showModal: false
            }
            if (image.view == 'others') {
              countViewOthers++
              this.imagesPanoramica.push(returnDataImage)
            }

            return returnDataImage
          }
          return image
        })
        if (countViewOthers > 1) {
          this.canGeneratePanoramica = true
        }
        this.images = data_images
        this.errors.fetch = false

        this.reponseDataStatus = response.data.status
        if (
          response.data.status === 'CREATED' ||
          response.data.status === 'PROCESSING' ||
          response.data.status === 'QUEUE'
        ) {
          setTimeout(() => {
            this.fetchImages()
          }, 5000)
        } else {
          this.$store.dispatch('setGlobalModal', {
            component: 'FormFeedbackThermography',
            showNextPage: true,
            model: false
          })
        }
      } catch (error) {
        this.errors.fetch = true
        this.reponseDataStatus = 'ERROR'

        console.log(error)

        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
          type: 'error',
          model: true
        })
      } finally {
        this.loadings.fetch = false
      }
    },
    getLabelView(valueView) {
      let labelVista = valueView

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },
    onClickChart(item) {
      this.$refs[`canvas-${this.activeImage.id}`][0].clickCanvas(item)
    },
    onChangeSelected(selected) {
      this.selectedItems[this.carousel] = selected
      this.curSelectedItems = selected
    },
    onChangeSelectedNextItems(selected) {
      this.selectedNextItems[this.carousel] = selected
      this.curSelectedNextItems = selected
    },
    toggleSelectedItems(selected) {
      const idxSelected = this.curSelectedItems.indexOf(selected)
      if (idxSelected === -1) this.curSelectedItems.push(selected)
      else this.curSelectedItems.splice(idxSelected, 1)
    },
    handleSelectImage(index) {
      this.curSelectedItems = this.selectedItems[index]
      this.curSelectedNextItems = this.selectedNextItems[index]
      this.carousel = index
    },
    translateItem(item, type) {
      if (!dictSegmentation[item]) return item
      return dictSegmentation[item][type] || item
    }
  },

  watch: {
    qtyLastImages() {
      this.fetchImages(true)
    },
    selectedThermo() {
      this.$router.push({
        name: 'Thermography',
        params: {
          thermographyId: this.selectedThermo
        }
      })
    },
    async '$route.params.thermographyId'() {
      this.loadings.fetch = true
      this.reponseDataStatus = 'PROCESSING'
      await this.fetchImages()
    }
  },

  async mounted() {
    await Promise.all([this.fetchReportTemplates(), this.fetchImages()])
    await this.fetchPeople()
    await this.loadProfileImagesModal()
  }
}
</script>

<style>
.apexcharts-tooltip.apexcharts-active {
  opacity: 0.5 !important;
}
</style>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.img-preview {
  width: 120px;
  max-width: 100%;
  height: auto;
  padding: 3px;
  display: inline-block;
}
.selected-row {
  background: #ccc;
}
.red_danger {
  background: red;
  color: #fff;
}
.red_white_danger {
  background: radial-gradient(red, #fff);
  color: #fff;
}
.yellow_alert {
  background: rgb(255, 157, 0);
  color: #fff;
}
.yellow_white_alert {
  background: radial-gradient(rgb(255, 157, 0), #fff);
  color: #fff;
}
.table-asymmetry {
  cursor: pointer;
  border-spacing: -1px;
}
.border-selected-items {
  border: 1px solid transparent;
  border-color: transparent;
}
.border-selected-items.active {
  border-color: blue !important;
}

.col-content-thermography {
  width: calc(100% - 154px);
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .carousel-thermos {
    width: 170px;
    max-width: 170px;
    height: calc(100vh - 184px);
    overflow-y: auto;
  }
}
@media #{map-get($display-breakpoints, 'md-and-down')} {
  .col-content-thermography {
    width: 100%;
  }
  .carousel-thermos {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    flex-basis: inherit !important;
  }
}
</style>
