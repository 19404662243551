import { subjectsService } from '@/services'

export default {
  state: { subjects: [] },
  mutations: {
    SET_SUBJECTS(state, payload) {
      state.subjects = payload
    }
  },
  actions: {
    async fetchSubjects({ commit }) {
      const response = await subjectsService.index()
      if (response.data.length > 0) {
        commit('SET_SUBJECTS', response.data)
      } else {
        commit('SET_SUBJECTS', [])
      }
    },
    async fetchSubjectsInactive({ commit }) {
      const response = await subjectsService.indexInactive()
      if (response.data.length > 0) {
        commit('SET_SUBJECTS', response.data)
      } else {
        commit('SET_SUBJECTS', [])
      }
    },
    async addSubject({ commit, state }, payload) {
      const data = new FormData()
      data.append('name', payload.name)
      data.append('cpf', payload.cpf)
      data.append('gender', payload.gender)
      data.append('birthDate', payload.birthDate)
      data.append('groups', JSON.stringify(payload.groups))
      data.append('metadata', JSON.stringify(payload.metadata))
      data.append('image', payload.file)

      const response = await subjectsService.store(data)
      if (response.data.id) {
        commit('SET_SUBJECTS', [...state.subjects, response.data])
      }
    },
    async updateSubject({ commit, state }, payload) {
      const data = new FormData()
      data.append('name', payload.name)
      data.append('cpf', payload.cpf)
      data.append('active', payload.active)
      data.append('gender', payload.gender)
      data.append('birthDate', payload.birthDate)
      data.append('groups', JSON.stringify(payload.groups))
      data.append('metadata', JSON.stringify({}))
      if (payload.file) {
        data.append('image', payload.file)
      }
      const response = await subjectsService.update(data, payload.id)
      if (response.data.id)
        commit(
          'SET_SUBJECTS',
          state.subjects.map((subject) =>
            subject.id === response.data.id ? { ...response.data } : subject
          )
        )
    },
    async removeSubject({ commit, state }, payload) {
      await subjectsService.destroy(payload)
      commit(
        'SET_SUBJECTS',
        state.subjects.filter((subject) => subject.id !== payload)
      )
    }
  },
  getters: {
    subjects: (state) => state.subjects
  }
}
