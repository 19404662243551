<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col cols="12" v-else-if="errors.fetch">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadingPeople') }}. </v-col>
        <v-col class="py-0">
          <v-btn @click="fetchAll" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <v-card outlined>
      <v-col>
        <v-row id="info-person-people-thermos" class="pa-3">
          <v-row align="center" class="ma-0">
            <v-avatar color="primary" size="86">
              <img
                v-if="people.profilePicture !== ''"
                :src="people.profilePicture"
                alt="Profile picture"
              />
              <span v-else class="white--text text-body-1">
                {{ people.name.charAt(0) }}
              </span>
            </v-avatar>
            <span class="ml-3">
              {{ people.name }}
            </span>
          </v-row>
          <v-row class="pa-3 ml-3">
            <v-chip
              label
              v-for="(item, index) in people.groups"
              :key="index"
              small
              class="mr-1"
            >
              {{ item }}
            </v-chip>
          </v-row>
        </v-row>
        <div id="filters-people-thermos">
          <v-row class="pa-3" justify="space-between">
            <span class="text-caption text--secondary">{{
              $t('Filters')
            }}</span>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-text-field
                outlined
                dense
                v-model="search"
                :placeholder="`${$t('SearchName')}...`"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                style="font-weight: 300"
                outlined
                dense
                chips
                small-chips
                multiple
                v-model="selectedStatus"
                :items="status"
                :label="$t('Status')"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                style="font-weight: 300"
                outlined
                dense
                chips
                small-chips
                multiple
                v-model="selectedTag"
                :items="tagsOptions"
                :label="$t('Tags')"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-card>
    <v-card outlined class="mt-2">
      <v-row>
        <v-col>
          <div class="pa-2">
            <v-btn
              icon
              small
              color="blue"
              @click="changeIdxVisualization()"
              id="btn-change-view-people-thermos"
            >
              <v-icon>mdi-{{ selectedVisualization }}</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div id="data-thermographs-prople-thermos">
        <v-row v-if="selectedVisualization === 'image'" class="px-6 mt-0">
          <v-col
            cols="12"
            lg="3"
            md="6"
            v-for="item in paginatedItems"
            :key="item.id"
          >
            <div class="h-full">
              <v-hover v-slot="{ hover }">
                <v-card
                  :class="['pb-4', 'thermo-card', hover ? 'on-hover' : null]"
                  :elevation="hover ? 12 : 6"
                  align="center"
                  @click="goToThermography(item)"
                >
                  <h5 class="pt-2">{{ item.name }}</h5>
                  <v-chip x-small label v-for="tag in item.tags" :key="tag.id">
                    {{ tag.name }}
                  </v-chip>
                  <h5>{{ `${item.status} - ${item.qtd_imagesThermos}` }}</h5>
                  <h5>{{ item.formatedDate }}</h5>
                </v-card>
              </v-hover>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="pa-3">
              <v-pagination
                v-model="page"
                :length="pageLength"
                circle
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          v-else-if="selectedVisualization === 'table'"
          :headers="headers"
          :items="filteredItems"
          :sort-by="['formatedDate']"
          :sort-desc="[true]"
          :custom-sort="customSort"
          must-sort
          outlined
          :items-per-page="10"
          :no-data-text="`${$t('NoRecord')} :(`"
          mobile-breakpoint="1"
        >
          <template v-slot:item="{ headers, item }">
            <v-hover v-slot="{ hover }">
              <tr>
                <!--<tr @dblclick="goToThermography(item)">-->
                <td
                  v-for="itemKey in headers"
                  :style="
                    itemKey.value === 'actions' ||
                    itemKey.value === 'inserted_at'
                      ? 'width: 150px'
                      : ''
                  "
                  :key="itemKey.value"
                >
                  <template v-if="itemKey.value === 'actions'">
                    <v-btn
                      icon
                      small
                      color="blue"
                      class=""
                      @click="goToThermography(item)"
                    >
                      <v-icon small>mdi-eye</v-icon></v-btn
                    >
                  </template>
                  <template v-if="itemKey.value === 'actions' && hover">
                    <v-btn
                      icon
                      small
                      color="primary"
                      class="ml-2"
                      @click="handleEditTermography(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      :loading="loadings.remove"
                      :disabled="loadings.remove"
                      icon
                      small
                      color="red"
                      class=""
                      @click="handleOpenRemoveModal(item)"
                    >
                      <v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </template>

                  <template v-else-if="itemKey.value === 'tags'">
                    <v-chip
                      x-small
                      label
                      v-for="tag in item.tags"
                      :key="tag.id"
                    >
                      {{ tag.name }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ item[itemKey.value] }}
                  </template>
                </td>
              </tr>
            </v-hover>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="showModal" max-width="400px">
      <RemoveFormModal
        :title="`${$t('RemoveThermography')}?`"
        :onCloseCallback="() => (showModal = false)"
        :onRemoveCallback="() => handleRemoveItem()"
        :isLoading="loadings.remove"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import { subjectsService } from '@/services'
import { format } from 'date-fns'
import RemoveFormModal from '../components/RemoveFormModal.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  name: 'PeopleThermos',
  components: {
    RemoveFormModal,
    LoadingScreen
  },
  data: function () {
    return {
      idxVisualization: 0,
      visualizations: ['table', 'image'],
      page: 1,
      thermosPerPage: 12,
      showModal: false,
      removeId: -1,
      search: '',
      people: {
        name: '',
        profilePicture: '',
        groups: []
      },
      loadings: {
        fetch: false,
        remove: false
      },
      selectedStatus: null,
      thermos: [],
      errors: {
        fetch: false
      },
      tagsOptions: [],
      selectedTag: []
    }
  },
  methods: {
    changeIdxVisualization() {
      if (this.visualizations.length - 1 > this.idxVisualization)
        this.idxVisualization++
      else this.idxVisualization = 0
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'formatedDate') {
          const iDateA = new Date(a.createdAt).getTime()
          const iDateB = new Date(b.createdAt).getTime()
          if (!isDesc[0]) {
            return iDateA < iDateB ? -1 : 1
          } else {
            return iDateB < iDateA ? -1 : 1
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
    async fetchPeople() {
      const { data } = await subjectsService.show(this.$route.params.peopleId)
      this.people.name = data.name
      this.people.profilePicture = data.profilePicture
      this.people.groups = data.groups.map(
        (group) => `${group.type} - ${group.name}`
      )
      this.thermos = data.thermographies
      this.tagsOptions = []
      data.thermographies.forEach((thermography) => {
        thermography.tags.forEach((tag) => {
          if (!this.tagsOptions.includes(tag.name)) {
            this.tagsOptions.push(tag.name)
          }
        })
      })
    },
    async fetchAll() {
      this.loadings.fetch = true
      try {
        await Promise.all([this.fetchPeople()])
        this.errors.fetch = false
      } catch (error) {
        this.errors.fetch = true
      }
      this.loadings.fetch = false
    },
    async handleRemoveItem() {
      this.loadings.remove = true
      try {
        await this.$store.dispatch('removeThermo', this.removeId)
        this.removeId = -1
        this.showModal = false

        this.$store.dispatch('setSnackBar', {
          text: this.$t('ThermographySuccessfullyRemoved'),
          type: 'success',
          model: true
        })

        this.fetchPeople()
      } catch (e) {
        this.$store.dispatch('setSnackBar', {
          text:
            e?.response?.data?.message != ''
              ? e.response.data.message
              : this.$t('ErrorTryingToRemoveThermography'),
          type: 'error',
          model: true
        })

        console.log(e)
      }
      this.loadings.remove = false
    },
    handleOpenRemoveModal(item) {
      this.removeId = item.id
      this.showModal = true
    },
    handleEditTermography(item) {
      this.$router.push({
        name: 'ThermographyEdit',
        params: {
          thermographyId: item.id
        }
      })
    },
    goToThermography(item) {
      this.$router.push({
        name: 'Thermography',
        params: {
          thermographyId: item.id
        }
      })
    }
  },
  async mounted() {
    await this.fetchAll()
  },
  computed: {
    headers() {
      return [
        { text: this.$t('Actions'), value: 'actions', width: '150px' },
        { text: this.$t('Description'), value: 'name', width: '250px' },
        { text: this.$t('Qty'), value: 'qtd_imagesThermos', width: '96px' },
        { text: this.$t('Tags'), value: 'tags', width: '250px' },
        { text: this.$t('Status'), value: 'status', width: '180px' },
        { text: this.$t('StudyDate'), value: 'formatedDate', width: '180px' }
      ]
    },
    status() {
      return [
        this.$t('CREATED'),
        this.$t('PROCESSED'),
        this.$t('PROCESSING'),
        this.$t('ERROR')
      ]
    },
    pageLength() {
      return Math.ceil(this.filteredItems.length / this.thermosPerPage)
    },
    paginatedItems() {
      return this.filteredItems.slice(
        (this.page - 1) * this.thermosPerPage,
        this.page * this.thermosPerPage
      )
    },
    selectedVisualization() {
      return this.visualizations[this.idxVisualization]
    },
    filteredItems() {
      return this.items.filter((item) => {
        if (this.selectedStatus && this.selectedStatus.length) {
          if (!this.selectedStatus.includes(item.status)) {
            return false
          }
        }
        if (this.selectedTag && this.selectedTag.length) {
          if (item.tags.length == 0) {
            return false
          }
          let hasTag = false
          for (let idxTag = 0; idxTag < item.tags.length; idxTag++) {
            if (this.selectedTag.includes(item.tags[idxTag].name)) {
              hasTag = true
            }
          }
          return hasTag
        }
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    items() {
      return this.thermos.map((item) => ({
        ...item,
        status: this.$t(item.status),
        qtd_imagesThermos: item.thermographyImages.length,
        formatedDate: format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')
      }))
    }
  }
}
</script>

<style scoped>
.h-full {
  height: 100%;
}
.thermo-card {
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  top: 0;
  height: 100%;
}
.thermo-card.on-hover {
  top: -5px;
}
</style>
