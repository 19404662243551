<template>
  <div class="d-flex">
    <v-slider
      min="-5"
      max="5"
      dense
      v-model="sliders[key]"
      v-for="(slider, key) in sliders"
      :key="`slider-${key}`"
    />
  </div>
</template>

<script>
export default {
  name: 'RangeSliderMultiColor',

  data() {
    return {
      sliders: [-5, -2.5, 2.5, 5]
    }
  }
}
</script>
