import http from '@/infra/api'

let index = () => {
  return http.get('tags')
}

let show = (id) => {
  return http.get(`tags/${id}`)
}

let store = (data) => {
  return http.post('tags', data)
}

let update = (data) => {
  return http.put(`tags/${data.id}`, data)
}

let destroy = (id) => {
  return http.delete(`tags/${id}`)
}

export default {
  index,
  show,
  store,
  update,
  destroy
}
