<template>
  <default-template :handleClick="handleClick" />
</template>

<script>
import DefaultTemplate from './DefaultTemplate.vue'

export default {
  name: 'Profiles',

  components: { DefaultTemplate },

  methods: {
    async handleClick() {
      this.$store.dispatch('nextStep')

      if (this.step === 0) return

      let selectedEl = document.getElementById('header-toolbar')
      let tipPosition = 'bottom'

      let widthTextHighlight = 300
      let heightTextHighlight = 120

      if (this.step === 1) {
        tipPosition = 'bottom'
        selectedEl = document.getElementById('select-view-new-profile')
        this.clickElByTag(selectedEl, 'input')
        await this.sleep(300)
        const vAutoCompleteContent = this.getElByClass(
          document,
          'v-autocomplete__content'
        )
        this.clickElByClass(vAutoCompleteContent, 'v-list-item')
        await this.sleep(300)
        this.$store.dispatch(
          'setTextHighlight',
          'Para criar um novo perfil termográfico primeiro você deve selecionar uma view.'
        )
      } else if (this.step === 2) {
        tipPosition = 'bottom'
        selectedEl = document.getElementById('button-select-view-new-profile')
        this.$store.dispatch(
          'setTextHighlight',
          'Após selecionar clique em COMEÇAR.'
        )
      } else if (this.step === 3) {
        tipPosition = 'bottom'
        document.getElementById('button-select-view-new-profile').click()
        await this.sleep(1500)
        selectedEl = document.getElementById('filter-new-profile')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode filtrar as termografias para facilitar a sua seleção.'
        )
      } else if (this.step === 4) {
        tipPosition = 'top'
        selectedEl = document.getElementById('all-thermos-new-profile')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode selecionar as termografias que você deseja para formar o perfil termográfico.'
        )
      }

      const offsetsSelectedEl = selectedEl.getBoundingClientRect()

      this.$store.dispatch('setHighlightCss', {
        top: `${offsetsSelectedEl.top}px`,
        left: `${offsetsSelectedEl.left}px`,
        width: `${selectedEl.offsetWidth}px`,
        height: `${selectedEl.offsetHeight}px`,
        border: '3px solid red'
      })
      this.$store.dispatch('setTextHighlightCss', {
        textHighlightCss: {
          top: 0,
          left: 0,
          width: `${widthTextHighlight}px`,
          height: `${heightTextHighlight}px`
        },
        positionInfos: {
          offsetsSelectedEl,
          selectedEl,
          widthTextHighlight,
          heightTextHighlight,
          tipPosition
        }
      })
    },
    clickElByTag(selectedEl, className) {
      let zoomInEl = selectedEl.getElementsByTagName(className)
      if (zoomInEl.length > 0) {
        zoomInEl[0].click()
      }
    },
    clickElByClass(selectedEl, className) {
      let zoomInEl = selectedEl.getElementsByClassName(className)
      if (zoomInEl.length > 0) {
        zoomInEl[0].click()
      }
    },
    getElByClass(selectedEl, className) {
      let newEl = selectedEl.getElementsByClassName(className)
      if (newEl.length > 0) return newEl[0]
      return selectedEl
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    }
  },

  computed: {
    step() {
      return this.$store.getters.step
    }
  },

  mounted() {
    this.$store.dispatch('setMaxSteps', 4)
  }
}
</script>
