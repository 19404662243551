<template>
  <v-col cols="12">
    <v-row justify="center" align="center">
      <div>
        <v-row align="center" justify="center">
          <img src="../assets/loading_kelvin.gif" style="height: 100px" />
        </v-row>
      </div>
    </v-row>
  </v-col>
</template>

<script>
export default {}
</script>

<style></style>
