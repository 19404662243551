export default {
  state: {
    theme: 'white',
    themeTypeTemps: 'dynamic',
    themeDefaultPaletteName: 'RainbowLC',
    themeMinTemp: 22,
    themeMaxTemp: 37,
    themeScoreZMinTemp: -1.96,
    themeScoreZMaxTemp: 1.96,
    themeDeltaMinTemp: -3,
    themeDeltaMaxTemp: 3,
    themeNewThermoMinTemp: 0,
    themeNewThermoMaxTemp: 0,
    themeRoiTempMinPerc: 10,
    themeRoiTempMaxPerc: 90,
    themeRoiTempMeanPerc: 100
  },
  mutations: {
    SET_THEME(state, payload) {
      state.theme = payload
      window.localStorage.setItem('theme', state.theme)
    },
    SET_THEME_TYPE_TEMPS(state, payload) {
      state.themeTypeTemps = payload
      window.localStorage.setItem('themeTypeTemps', state.themeTypeTemps)
    },
    SET_THEME_DEFAULT_PALETTE_NAME(state, payload) {
      state.themeDefaultPaletteName = payload
      window.localStorage.setItem(
        'themeDefaultPaletteName',
        state.themeDefaultPaletteName
      )
    },
    SET_THEME_MIN_TEMP(state, payload) {
      state.themeMinTemp = payload
      window.localStorage.setItem('themeMinTemp', state.themeMinTemp)
    },
    SET_THEME_MAX_TEMP(state, payload) {
      state.themeMaxTemp = payload
      window.localStorage.setItem('themeMaxTemp', state.themeMaxTemp)
    },
    SET_THEME_SCOREZ_MIN_TEMP(state, payload) {
      state.themeScoreZMinTemp = payload
      window.localStorage.setItem(
        'themeScoreZMinTemp',
        state.themeScoreZMinTemp
      )
    },
    SET_THEME_SCOREZ_MAX_TEMP(state, payload) {
      state.themeScoreZMaxTemp = payload
      window.localStorage.setItem(
        'themeScoreZMaxTemp',
        state.themeScoreZMaxTemp
      )
    },
    SET_THEME_DELTA_MIN_TEMP(state, payload) {
      state.themeDeltaMinTemp = payload
      window.localStorage.setItem('themeDeltaMinTemp', state.themeDeltaMinTemp)
    },
    SET_THEME_DELTA_MAX_TEMP(state, payload) {
      state.themeDeltaMaxTemp = payload
      window.localStorage.setItem('themeDeltaMaxTemp', state.themeDeltaMaxTemp)
    },
    SET_THEME_NEW_THERMO_MIN_TEMP(state, payload) {
      state.themeNewThermoMinTemp = payload
      window.localStorage.setItem(
        'themeNewThermoMinTemp',
        state.themeNewThermoMinTemp
      )
    },
    SET_THEME_NEW_THERMO_MAX_TEMP(state, payload) {
      state.themeNewThermoMaxTemp = payload
      window.localStorage.setItem(
        'themeNewThermoMaxTemp',
        state.themeNewThermoMaxTemp
      )
    },
    SET_THEME_ROI_TEMP_MIN_PERC(state, payload) {
      state.themeRoiTempMinPerc = payload
      window.localStorage.setItem(
        'themeRoiTempMinPerc',
        state.themeRoiTempMinPerc
      )
    },
    SET_THEME_ROI_TEMP_MAX_PERC(state, payload) {
      state.themeRoiTempMaxPerc = payload
      window.localStorage.setItem(
        'themeRoiTempMaxPerc',
        state.themeRoiTempMaxPerc
      )
    },
    SET_THEME_ROI_TEMP_MEAN_PERC(state, payload) {
      state.themeRoiTempMeanPerc = payload
      window.localStorage.setItem(
        'themeRoiTempMeanPerc',
        state.themeRoiTempMeanPerc
      )
    }
  },
  actions: {
    setTheme({ commit }, payload) {
      commit('SET_THEME', payload.theme)
    },
    setThemeTypeTemps({ commit }, payload) {
      commit('SET_THEME_TYPE_TEMPS', payload.themeTypeTemps)
    },
    setThemeDefaultPaletteName({ commit }, payload) {
      commit('SET_THEME_DEFAULT_PALETTE_NAME', payload.themeDefaultPaletteName)
    },
    setThemeMinTemp({ commit }, payload) {
      commit('SET_THEME_MIN_TEMP', payload.themeMinTemp)
    },
    setThemeMaxTemp({ commit }, payload) {
      commit('SET_THEME_MAX_TEMP', payload.themeMaxTemp)
    },
    setThemeScoreZMinTemp({ commit }, payload) {
      commit('SET_THEME_SCOREZ_MIN_TEMP', payload.themeScoreZMinTemp)
    },
    setThemeScoreZMaxTemp({ commit }, payload) {
      commit('SET_THEME_SCOREZ_MAX_TEMP', payload.themeScoreZMaxTemp)
    },
    setThemeDeltaMinTemp({ commit }, payload) {
      commit('SET_THEME_DELTA_MIN_TEMP', payload.themeDeltaMinTemp)
    },
    setThemeDeltaMaxTemp({ commit }, payload) {
      commit('SET_THEME_DELTA_MAX_TEMP', payload.themeDeltaMaxTemp)
    },
    setThemeNewThermoMinTemp({ commit }, payload) {
      commit('SET_THEME_NEW_THERMO_MIN_TEMP', payload.themeNewThermoMinTemp)
    },
    setThemeNewThermoMaxTemp({ commit }, payload) {
      commit('SET_THEME_NEW_THERMO_MAX_TEMP', payload.themeNewThermoMaxTemp)
    },
    setThemeRoiTempMinPerc({ commit }, payload) {
      commit('SET_THEME_ROI_TEMP_MIN_PERC', payload.themeRoiTempMinPerc)
    },
    setThemeRoiTempMaxPerc({ commit }, payload) {
      commit('SET_THEME_ROI_TEMP_MAX_PERC', payload.themeRoiTempMaxPerc)
    },
    setThemeRoiTempMeanPerc({ commit }, payload) {
      commit('SET_THEME_ROI_TEMP_MEAN_PERC', payload.themeRoiTempMeanPerc)
    }
  },
  getters: {
    theme: (state) => state.theme,
    themeTypeTemps: (state) => state.themeTypeTemps,
    themeDefaultPaletteName: (state) => state.themeDefaultPaletteName,
    themeMinTemp: (state) => state.themeMinTemp,
    themeMaxTemp: (state) => state.themeMaxTemp,
    themeScoreZMinTemp: (state) => state.themeScoreZMinTemp,
    themeScoreZMaxTemp: (state) => state.themeScoreZMaxTemp,
    themeDeltaMinTemp: (state) => state.themeDeltaMinTemp,
    themeDeltaMaxTemp: (state) => state.themeDeltaMaxTemp,
    themeNewThermoMinTemp: (state) => state.themeNewThermoMinTemp,
    themeNewThermoMaxTemp: (state) => state.themeNewThermoMaxTemp,
    themeRoiTempMinPerc: (state) => state.themeRoiTempMinPerc,
    themeRoiTempMaxPerc: (state) => state.themeRoiTempMaxPerc,
    themeRoiTempMeanPerc: (state) => state.themeRoiTempMeanPerc
  }
}
