<template>
  <v-col v-if="error">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadStatistics') }} </v-col>
        <v-col class="py-0">
          <v-btn @click="getData" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col v-else-if="loading">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col v-else>
    <v-row class="ma-3" justify="center" align="end">
      <v-col cols="4" style="border-right: 1px solid gray">
        <label>{{ $t('Filter') }}</label>
        <v-autocomplete
          v-if="groups.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedGroups"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="groups"
          :label="$t('Groups')"
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-if="tags.length"
          style="font-weight: 300"
          outlined
          dense
          clearable
          v-model="selectedTags"
          auto-select-first
          chips
          small-chips
          multiple
          hide-details
          :items="tags"
          :label="$t('Tags')"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="thermoDate"
          type="datetime-local"
          :label="$t('Date')"
          hide-details
          dense
          outlined
          class="append-date-thermo"
        >
          <template v-slot:prepend>
            <v-switch
              v-model="activateThermoDate"
              color="primary"
              :value="true"
              hide-details
            ></v-switch>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" class="px-lg-16 px-md-8 pt-8">
        <PeopleSearchList
          showSubjectWithoutThermo
          :enableFilters="false"
          :autoColumns="0"
          showOnlytThermoOnModal
          :tempMax="tempMax"
          :tempMin="tempMin"
          :selectedTagsOutside="selectedTags"
          :selectedGroupsOutside="selectedGroups"
          :thermoDate="activateThermoDate ? thermoDate : null"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
// import AssymetryList from '../components/AsymetryList.vue'
import PeopleSearchList from '../components/PeopleSearchList.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  components: { PeopleSearchList, LoadingScreen },
  data() {
    return {
      loading: false,
      selectedGroups: [],
      selectedTags: [],
      thermoDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16),
      activateThermoDate: true,
      tempMin: null,
      tempMax: null,
      error: false
    }
  },
  computed: {
    autoColumns() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 2
        case 'sm':
          return 3
        case 'md':
          return 4
        case 'lg':
          return 5
        case 'xl':
          return 7
      }
      return 2
    },
    groups() {
      return this.$store.getters.groups.map((item) => {
        return {
          text: `${item.type}: ${item.name}`,
          value: item.id
        }
      })
    },
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    themeNewThermoMinTemp() {
      return this.$store.getters.themeNewThermoMinTemp
    },
    themeNewThermoMaxTemp() {
      return this.$store.getters.themeNewThermoMaxTemp
    },
    itemsAlerts() {
      return []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      console.log('this.themeNewThermoMinTemp', this.themeNewThermoMinTemp)
      if (this.themeNewThermoMinTemp != 0) {
        this.tempMin = this.themeNewThermoMinTemp
      }
      if (this.themeNewThermoMaxTemp != 0) {
        this.tempMax = this.themeNewThermoMaxTemp
      }
      try {
        await Promise.all([
          this.$store.dispatch('fetchSubjects'),
          this.$store.dispatch('fetchGroups'),
          this.$store.dispatch('fetchTags')
        ])
      } catch (error) {
        console.log('Error: ', error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    onClickSubject(id) {
      this.$router.push({
        name: 'PeopleThermos',
        params: { peopleId: id }
      })
    }
  }
}
</script>

<style scoped>
.list-scroll {
  width: auto;
  overflow-y: auto;
  max-height: 400px;
}
.styled-table {
  border-collapse: collapse;
  margin: 20px 0;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 15px gray;
}
.styled-vcard {
  border-color: red;
  box-shadow: 0 0 10px gray;
}
.append-date-thermo .v-input {
  margin-top: 0;
  padding-top: 0;
}
</style>
