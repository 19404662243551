<template>
  <div>
    <v-row v-if="enableFilters">
      <v-col>
        <v-btn large icon color="primary" @click="showFilters = !showFilters">
          <v-icon>mdi-filter{{ showFilters ? '-off' : '' }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="subjects.length <= 0">
      <v-col cols="12"> {{ $t('PersonNotFound') }} </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if="showFilters" cols="12">
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filter.name"
              :placeholder="`${$t('SearchName')}...`"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-if="groups.length"
              style="font-weight: 300"
              outlined
              dense
              clearable
              v-model="filter.selectedGroups"
              auto-select-first
              chips
              small-chips
              multiple
              hide-details
              :items="groups"
              :label="$t('Groups')"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-if="tags.length"
              style="font-weight: 300"
              outlined
              dense
              clearable
              v-model="filter.selectedTags"
              auto-select-first
              chips
              small-chips
              multiple
              hide-details
              :items="tags"
              :label="$t('Tags')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="ageFirst"
              type="number"
              :placeholder="`${$t('AgeFrom')}...`"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="ageLast"
              type="number"
              :placeholder="`${$t('To')}...`"
            />
          </v-col>
          <v-col cols="12" class="d-none">
            <v-menu
              ref="menuRangeDates"
              v-model="menuRangeDates"
              :close-on-content-click="false"
              :return-value.sync="rangeDates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Intervalo de Datas"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="rangeDates"
                no-title
                scrollable
                range
                locale="pt-BR"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="rangeDates = []">
                  Limpar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuRangeDates.save(rangeDates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="lastDays"
              :items="lastDaysOptions"
              outlined
              dense
              single-line
              label="Pesquise por mais recentes"
            />
          </v-col>
          <v-col cols="12" class="d-none">
            <range-slider-multi-color />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row v-if="filteredSubjects.length <= 0">
          <v-col cols="12"> {{ $t('PersonNotFoundWithThisFilter') }} </v-col>
        </v-row>
        <v-row v-if="autoColumns == 0">
          <v-col
            cols="12"
            :sm="customColsSm"
            :lg="customColsLg"
            :xl="customColsXl"
            class="pb-8"
            v-for="subject in filteredSubjects"
            :key="subject.id"
          >
            <PeopleCardFigurePlayer
              :isGray="!subject.hasThermo && !subject.hasProcessingThermo"
              :isWarning="!subject.hasThermo && subject.hasProcessingThermo"
              :avatarUrl="subject.avatarUrl"
              :groups="subject.groups.map((group) => group.name)"
              :namePlayer="subject.name"
              :thermographies="subject.thermographies"
              :hasEditPeople="true"
              @editPeople="onClickEditPeople(subject)"
              :hasNewThermo="true"
              @newThermo="onClickPeople(subject)"
              :hasEditThermo="subject.hasThermo"
              @editThermo="onClickGoToThermoGraphOnly(subject)"
            />
          </v-col>
        </v-row>
        <div v-else-if="autoColumns > 0">
          <v-row
            class="pb-8"
            v-for="n in Math.ceil(filteredSubjects.length / autoColumns)"
            :key="n"
          >
            <v-col
              v-for="nSub in Math.ceil(filteredSubjects.length / autoColumns) ==
                n && Math.ceil(filteredSubjects.length / autoColumns) > 1
                ? Math.ceil(filteredSubjects.length % autoColumns)
                : Math.ceil(filteredSubjects.length / autoColumns) == 1
                ? filteredSubjects.length
                : autoColumns"
              :key="filteredSubjects[(n - 1) * autoColumns + nSub - 1].id"
            >
              <PeopleCardFigurePlayer
                :isGray="
                  !filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                    .hasThermo &&
                  !filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                    .hasProcessingThermo
                "
                :isWarning="
                  !filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                    .hasThermo &&
                  filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                    .hasProcessingThermo
                "
                :avatarUrl="
                  filteredSubjects[(n - 1) * autoColumns + nSub - 1].avatarUrl
                "
                :groups="
                  filteredSubjects[(n - 1) * autoColumns + nSub - 1].groups.map(
                    (group) => group.name
                  )
                "
                :namePlayer="
                  filteredSubjects[(n - 1) * autoColumns + nSub - 1].name
                "
                :thermographies="
                  filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                    .thermographies
                "
                :hasEditPeople="true"
                @editPeople="
                  onClickEditPeople(
                    filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                  )
                "
                :hasNewThermo="true"
                @newThermo="
                  onClickPeople(
                    filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                  )
                "
                :hasEditThermo="
                  filteredSubjects[(n - 1) * autoColumns + nSub - 1].hasThermo
                "
                @editThermo="
                  onClickGoToThermoGraphOnly(
                    filteredSubjects[(n - 1) * autoColumns + nSub - 1]
                  )
                "
              />
            </v-col>
            <v-col
              v-for="nSubSpacer in Math.ceil(
                filteredSubjects.length / autoColumns
              ) == n && Math.ceil(filteredSubjects.length / autoColumns) > 1
                ? autoColumns - Math.ceil(filteredSubjects.length % autoColumns)
                : 0"
              :key="nSubSpacer"
            ></v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="modalAnamnese" :width="500">
      <div class="pa-6 white text-right">
        <v-textarea
          v-model="updatePeople.anamnese"
          outlined
          dense
          :label="$t('Anamnese')"
        />
        <v-btn text color="primary" @click="modalAnamnese = false">
          Salvar
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
      v-model="modalPeople"
      :width="showLastThermo ? 1400 : 500"
      persistent
    >
      <div class="pa-6 white" v-if="selectedPeople != null">
        <v-row v-if="loadingThermo">
          <v-col>
            <loading-screen-small />
          </v-col>
        </v-row>
        <v-row v-else-if="selectedPeople.hasThermo">
          <v-col v-show="showLastThermo" cols="12">
            <v-row>
              <v-col cols="2" class="d-flex justify-center align-center">
                <PeopleCardFigurePlayer
                  :avatarUrl="selectedPeople.avatarUrl"
                  :groups="selectedPeople.groups.map((group) => group.name)"
                  :namePlayer="selectedPeople.name"
                  :thermographies="selectedPeople.thermographies"
                  :dateLastThermo="
                    new Date(dateLastThermo).toLocaleDateString()
                  "
                  :statusLastThermo="statusLastThermo"
                  :tagsLastThermo="tagsLastThermo"
                  :hasActions="false"
                  @expandThermo="showLastThermo = !showLastThermo"
                  :isExpanded="showLastThermo"
                  hasAdditionalInfo
                />
              </v-col>

              <v-col cols="7">
                <v-card flat>
                  <span>Analise do Último Exame de Termográfia</span>
                  <apexchart
                    type="line"
                    height="200"
                    :options="chartAsymmetries.options"
                    :series="getChartAsymmetries"
                    :data="chartAsymmetries.data"
                    @click="clickChartAsymmetry"
                  />
                </v-card>
              </v-col>
              <v-col cols="3" align-self="center">
                <span class="labelDateThermo"> {{ $t('InjuryAlert') }} </span>
                <apexchart
                  type="donut"
                  :series="asymmetriesDonutChart.series"
                  :options="asymmetriesDonutChart.options"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-carousel
                  v-model="idxCarousel"
                  hide-delimiter-background
                  :show-arrows="false"
                  :hide-delimiters="true"
                  height="350"
                  class="carousel-thermos"
                >
                  <v-carousel-item
                    v-for="image in images"
                    :key="'thermo-' + image.id"
                  >
                    <div class="carousel-image">
                      <profile-view
                        :anamnese="anamnese"
                        v-if="image"
                        :data="image.thermoImage.data"
                        :topLeftLabel="
                          `${image.labelView}` +
                          '  :: Data: ' +
                          new Date(image.createdAt)
                            .toLocaleDateString()
                            .toString()
                        "
                        :metrics="image.metrics.data"
                        :contours="image.contours"
                        :view="image.view"
                        :maxHeight="350"
                        :type="'image'"
                        :temps="{
                          min:
                            themeTypeTemps == 'dynamic'
                              ? image.minTemp
                              : themeMinTemp,
                          max:
                            themeTypeTemps == 'dynamic'
                              ? image.maxTemp
                              : themeMaxTemp
                        }"
                        :srcImage="image.src"
                        :selectedContours="selectedItems[idxCarousel]"
                        :onChangeSelection="onChangeSelected"
                        :flipRegisters="image.flip_registers"
                        :segments="image.segments"
                        :defaultPaletteName="themeDefaultPaletteName"
                        :activeTempMetrics="1"
                        :thermoImageId="image.thermoImage.thermographyId"
                        :hideControls="true"
                        showSemaforica
                      />
                    </div>
                  </v-carousel-item>
                </v-carousel>

                <div class="caution-asymmetries-chips mt-1">
                  <v-chip
                    color="red"
                    text-color="white"
                    small
                    class="mb-1 mr-1"
                    v-for="(contour, key) in cautionAsimmetryItems[idxCarousel]"
                    :key="`asimmetry-chip-${key}`"
                  >
                    {{ translateItem(contour, 'full').replace('Esquerdo', '') }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="6">
                <v-carousel
                  v-model="idxCarouselNext"
                  hide-delimiter-background
                  :show-arrows="false"
                  :hide-delimiters="true"
                  height="350"
                  class="carousel-thermos"
                >
                  <v-carousel-item
                    v-for="image in images"
                    :key="'thermo-' + image.id"
                  >
                    <div class="carousel-image">
                      <profile-view
                        :anamnese="anamnese"
                        v-if="image"
                        :data="image.thermoImage.data"
                        :topLeftLabel="
                          `${image.labelView}` +
                          '  :: Data: ' +
                          new Date(image.createdAt)
                            .toLocaleDateString()
                            .toString()
                        "
                        :metrics="image.metrics.data"
                        :contours="image.contours"
                        :view="image.view"
                        :maxHeight="350"
                        :type="'image'"
                        :temps="{
                          min:
                            themeTypeTemps == 'dynamic'
                              ? image.minTemp
                              : themeMinTemp,
                          max:
                            themeTypeTemps == 'dynamic'
                              ? image.maxTemp
                              : themeMaxTemp
                        }"
                        :srcImage="image.src"
                        :selectedContours="selectedItems[idxCarouselNext]"
                        :onChangeSelection="onChangeSelectedNextItems"
                        :flipRegisters="image.flip_registers"
                        :segments="image.segments"
                        :defaultPaletteName="themeDefaultPaletteName"
                        :activeTempMetrics="1"
                        :thermoImageId="image.thermoImage.thermographyId"
                        :hideControls="true"
                        showSemaforica
                      />
                    </div>
                  </v-carousel-item>
                </v-carousel>

                <div class="caution-asymmetries-chips mt-2">
                  <v-chip
                    color="red"
                    text-color="white"
                    small
                    class="mb-1 mr-1"
                    v-for="(contour, key) in cautionAsimmetryItems[
                      idxCarouselNext
                    ]"
                    :key="`asimmetry-chip-${key}`"
                  >
                    {{ translateItem(contour, 'full').replace('Esquerdo', '') }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="danger"
                  @click="modalPeople = false"
                  class="mr-3"
                  >{{ $t('Cancel') }}</v-btn
                >
                <v-btn @click="moreThermo" color="primary"
                  >Acessar Termografia</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-else-if="showOnlytThermoOnModal">
          <v-col align="right" justify="center" cols="2">
            <PeopleCardFigurePlayer
              style="width: 200px; height: 170px; align-items: right"
              :avatarUrl="selectedPeople.avatarUrl"
              :groups="selectedPeople.groups.map((group) => group.name)"
              :namePlayer="selectedPeople.name"
              :tagsLastThermo="tagsLastThermo"
              :hasActions="false"
            />
            <!--<h2 class="text-overlay">Nome: {{updatePeople.name}}</h2>-->
          </v-col>
          <v-col cols="5">
            <h3 class="text-overlay">
              Novo Exame de Termografia :: Mapa de Trabalho
            </h3>
            <v-text-field
              v-model="updatePeople.description"
              outlined
              dense
              :label="$t('Description')"
            />
            <v-autocomplete
              v-if="tags.length"
              style="font-weight: 300"
              outlined
              dense
              clearable
              v-model="updatePeople.tags"
              auto-select-first
              chips
              small-chips
              multiple
              hide-details
              :items="tags"
              :label="$t('Tags')"
            />
          </v-col>
          <v-col class="text-center" style="padding-right: 10px" cols="5">
            <h3 class="text-overlay">Informações Adicionais</h3>
            <!--<v-text-field
              v-model="updatePeople.weight"
              outlined
              dense
              :label="$t('Weight')"
              disabled
            />-->
            <!-- usar protocolo TISEM para novos dados -->
            <v-icon
              class="icon-add-info"
              @click="modalAnamnese = !modalAnamnese"
              >mdi-account-injury</v-icon
            >
            <v-icon class="icon-add-info" disabled>mdi-weight-kilogram</v-icon>
            <v-icon class="icon-add-info" disabled>mdi-air-humidifier</v-icon>
            <v-icon class="icon-add-info" disabled
              >mdi-thermometer-check</v-icon
            >
            <v-icon class="icon-add-info" disabled
              >mdi-temperature-kelvin</v-icon
            >
          </v-col>
          <v-col
            class="windowThermal-1"
            cols="6"
            v-if="!selectedPeople.hasThermo"
          >
            <v-row>
              <v-col cols="5">
                <v-select
                  prepend-inner-icon="mdi-account-eye"
                  :label="$t('Protocol')"
                  v-model="updatePeople.imgs[0].view"
                  dense
                  :items="availableViews"
                  item-text="label"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="palettesKeys"
                  v-model="updatePeople.imgs[0].paletteName"
                  outlined
                  :label="$t('Palette')"
                  color="primary"
                  dense
                ></v-select>
              </v-col>
              <v-col style="font-size: 10px" cols="2">
                <v-text-field
                  v-model="updatePeople.imgs[0].minTemp"
                  outlined
                  dense
                  type="number"
                  :placeholder="`${'Min'}`"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="updatePeople.imgs[0].maxTemp"
                  outlined
                  dense
                  type="number"
                  :placeholder="`${'Max'}`"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  possibleViewsPain.includes(updatePeople.imgs[0].view) ? 6 : 12
                "
              >
                <input
                  type="file"
                  @change="handleFileChange0"
                  ref="inputFile0"
                  style="display: none"
                />
                <v-card v-if="liveCapture[0]"> </v-card>
                <v-card v-if="liveCapture[0]">
                  <live-capture :onChangeImage="handleLiveCapture0" />
                </v-card>
                <v-card
                  v-else
                  :class="[
                    isDroppingFile0 == true ? 'blue dropping-file' : null,
                    'v-card-height-file'
                  ]"
                  @click="handleInputFile('click', 0)"
                  @dragover.prevent="dragOverFile0"
                  @dragleave.prevent="dragLeaveFile0"
                  @drop.prevent="handleInputFile('drop', 0)"
                  height="400"
                >
                  <img
                    class="current-image"
                    :src="updatePeople.imgs[0].imgPreview"
                    v-if="updatePeople.imgs[0].img"
                  />
                  <v-row
                    v-else
                    style="height: 100%"
                    justify="center"
                    align="center"
                  >
                    <v-col align="center">
                      <p><v-icon>mdi-camera-plus</v-icon></p>
                      <p>{{ $t('DragAndDropTheImageOrClickHere') }}.</p>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row justify="center" class="pa-1">
                  <v-switch
                    label="Live capture"
                    v-model="liveCapture[0]"
                  ></v-switch>
                </v-row>
              </v-col>
              <v-col
                class="scale-eva"
                cols="6"
                v-if="possibleViewsPain.includes(updatePeople.imgs[0].view)"
              >
                <h2 class="text-center">Intensidade da Dor</h2>
                <h5 class="text-center mb-5">Protocolo EVA</h5>
                <div class="p-relative p-r-25px p-l-25px card-pain-body">
                  <div class="paleta-cores">
                    <button
                      v-for="(pain, key) in painColors"
                      :key="`cor-${(key - 10) * -1}`"
                      :class="painSelected0 == (key - 10) * -1 ? 'active' : ''"
                      :style="{
                        background: painColors[(key - 10) * -1]
                      }"
                      @click="painSelected0 = (key - 10) * -1"
                    >
                      {{ (key - 10) * -1 }}
                    </button>
                  </div>
                  <vue-full-body-part-selector
                    :contoursType="true"
                    :selection.sync="painPartSelected0"
                    :disabled="false"
                    :multiple="false"
                    :parts-colour="partsColourFullBody0"
                    :body-colour="painColors[0]"
                    :selection-colour="painColors[0]"
                    :viewType="updatePeople.imgs[0].view"
                    :contourJson="contoursJsonPain[updatePeople.imgs[0].view]"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="windowThermal-1"
            cols="6"
            v-if="!selectedPeople.hasThermo"
          >
            <v-row>
              <v-col cols="5">
                <v-select
                  prepend-inner-icon="mdi-account-eye"
                  :label="$t('Protocol')"
                  v-model="updatePeople.imgs[1].view"
                  dense
                  :items="availableViews"
                  item-text="label"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="palettesKeys"
                  v-model="updatePeople.imgs[1].paletteName"
                  outlined
                  :label="$t('Palette')"
                  color="primary"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="updatePeople.imgs[1].minTemp"
                  outlined
                  dense
                  type="number"
                  :placeholder="`${'Min'}`"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="updatePeople.imgs[1].maxTemp"
                  outlined
                  dense
                  type="number"
                  :placeholder="`${'Max'}`"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  possibleViewsPain.includes(updatePeople.imgs[1].view) ? 6 : 12
                "
              >
                <input
                  type="file"
                  @change="handleFileChange1"
                  ref="inputFile1"
                  style="display: none"
                />
                <v-card v-if="liveCapture[1]"> </v-card>
                <v-card v-if="liveCapture[1]">
                  <live-capture :onChangeImage="handleLiveCapture1" />
                </v-card>
                <v-card
                  v-else
                  :class="[
                    isDroppingFile1 == true ? 'blue dropping-file' : null,
                    'v-card-height-file'
                  ]"
                  @click="handleInputFile('click', 1)"
                  @dragover.prevent="dragOverFile1"
                  @dragleave.prevent="dragLeaveFile1"
                  @drop.prevent="handleInputFile('drop', 1)"
                  height="400"
                >
                  <img
                    class="current-image"
                    :src="updatePeople.imgs[1].imgPreview"
                    v-if="updatePeople.imgs[1].img"
                  />
                  <v-row
                    v-else
                    style="height: 100%"
                    justify="center"
                    align="center"
                  >
                    <v-col align="center">
                      <p><v-icon>mdi-camera-plus</v-icon></p>
                      <p>{{ $t('DragAndDropTheImageOrClickHere') }}.</p>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row justify="center" class="pa-1">
                  <v-switch
                    label="Live capture"
                    v-model="liveCapture[1]"
                  ></v-switch>
                </v-row>
              </v-col>
              <v-col
                class="scale-eva"
                cols="6"
                v-if="possibleViewsPain.includes(updatePeople.imgs[1].view)"
              >
                <h2 class="text-center">Intensidade da Dor</h2>
                <h5 class="text-center mb-5">Protocolo EVA</h5>
                <div class="p-relative p-r-25px p-l-25px card-pain-body">
                  <div class="paleta-cores">
                    <button
                      v-for="(pain, key) in painColors"
                      :key="`cor-${(key - 10) * -1}`"
                      :class="painSelected1 == (key - 10) * -1 ? 'active' : ''"
                      :style="{
                        background: painColors[(key - 10) * -1]
                      }"
                      @click="painSelected1 = (key - 10) * -1"
                    >
                      {{ (key - 10) * -1 }}
                    </button>
                  </div>
                  <vue-full-body-part-selector
                    :contoursType="true"
                    :selection.sync="painPartSelected1"
                    :disabled="false"
                    :multiple="false"
                    :parts-colour="partsColourFullBody1"
                    :body-colour="painColors[0]"
                    :selection-colour="painColors[0]"
                    :viewType="updatePeople.imgs[1].view"
                    :contourJson="contoursJsonPain[updatePeople.imgs[1].view]"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" v-if="selectedPeople.hasThermo">
            <v-carousel
              v-model="idxCarousel"
              hide-delimiter-background
              height="400"
              class="carousel-thermos"
            >
              <v-carousel-item
                v-for="image in images"
                :key="'thermo-' + image.id"
              >
                <div class="carousel-image">
                  <profile-view
                    :anamnese="anamnese"
                    v-if="image"
                    :data="image.thermoImage.data"
                    :topLeftLabel="`${image.labelView}`"
                    :topLeftLabelB="
                      new Date(image.createdAt).toLocaleDateString().toString()
                    "
                    :metrics="image.metrics.data"
                    :contours="image.contours"
                    :view="image.view"
                    :maxHeight="400"
                    :type="'image'"
                    :temps="{
                      min: image.minTemp,
                      max: image.maxTemp
                    }"
                    :srcImage="image.src"
                    :selectedContours="selectedItems[idxCarousel]"
                    :onChangeSelection="onChangeSelected"
                    :flipRegisters="image.flip_registers"
                    :segments="image.segments"
                    :defaultPaletteName="image.palette.replace('Palette', '')"
                    :activeTempMetrics="1"
                    :thermoImageId="image.thermoImage.thermographyId"
                    :hideControls="true"
                    showSemaforica
                  />
                </div>
              </v-carousel-item>
            </v-carousel>

            <div class="caution-asymmetries-chips mt-2">
              <v-chip
                color="red"
                text-color="white"
                small
                class="mb-1 mr-1"
                v-for="(contour, key) in cautionAsimmetryItems[idxCarousel]"
                :key="`asimmetry-chip-${key}`"
              >
                {{ translateItem(contour, 'full').replace('Esquerdo', '') }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="6" v-if="selectedPeople.hasThermo">
            <v-carousel
              v-model="idxCarouselNext"
              hide-delimiter-background
              :show-arrows="false"
              :hide-delimiters="true"
              height="400"
              class="carousel-thermos"
            >
              <v-carousel-item
                v-for="image in images"
                :key="'thermo-' + image.id"
              >
                <div class="carousel-image">
                  <profile-view
                    :anamnese="anamnese"
                    v-if="image"
                    :data="image.thermoImage.data"
                    :topLeftLabel="`${image.labelView}`"
                    :topLeftLabelB="
                      new Date(image.createdAt).toLocaleDateString().toString()
                    "
                    :metrics="image.metrics.data"
                    :contours="image.contours"
                    :view="image.view"
                    :maxHeight="400"
                    :type="'image'"
                    :temps="{
                      min: image.minTemp,
                      max: image.maxTemp
                    }"
                    :srcImage="image.src"
                    :selectedContours="selectedItems[idxCarouselNext]"
                    :onChangeSelection="onChangeSelectedNextItems"
                    :flipRegisters="image.flip_registers"
                    :segments="image.segments"
                    :defaultPaletteName="image.palette.replace('Palette', '')"
                    :activeTempMetrics="1"
                    :thermoImageId="image.thermoImage.thermographyId"
                    :hideControls="true"
                    showSemaforica
                  />
                </div>
              </v-carousel-item>
            </v-carousel>

            <div class="caution-asymmetries-chips mt-2">
              <v-chip
                color="red"
                text-color="white"
                small
                class="mb-1 mr-1"
                v-for="(contour, key) in cautionAsimmetryItems[idxCarouselNext]"
                :key="`asimmetry-chip-${key}`"
              >
                {{ translateItem(contour, 'full').replace('Esquerdo', '') }}
              </v-chip>
            </div>
            <v-row v-if="selectedPeople.hasThermo">
              <v-col class="text-right">
                <v-btn
                  color="danger"
                  @click="modalPeople = false"
                  class="mr-3"
                  >{{ $t('Cancel') }}</v-btn
                >
                <v-btn @click="moreThermo" color="primary"
                  >Acessar Termografia</v-btn
                >
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="!selectedPeople.hasThermo">
            <v-row class="pa-3" justify="end">
              <v-btn color="danger" @click="modalPeople = false" class="mr-3">{{
                $t('Cancel')
              }}</v-btn>
              <v-btn
                color="primary"
                style="width: 150px"
                @click="handleSendThermo"
                :loading="loadings.sendThermo"
                :disabled="
                  (updatePeople.imgs[0].img == null &&
                    updatePeople.imgs[1].img == null) ||
                  loadings.sendThermo
                "
                id="btn-send-new-thermo"
                >{{ $t('Send') }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <v-dialog v-model="createPeopleModal" max-width="550px">
      <CreatePeopleFormModal
        :closeModalCallback="() => (createPeopleModal = false)"
        :type="typeCreatePeopleModal"
        :peopleInfo="editPeople"
        :onErrorCallback="handleOnErrorCreatePeople"
        :onSuccessCallback="handleOnSuccessCreatePeople"
        v-if="createPeopleModal"
      />
    </v-dialog>
  </div>
</template>

<script>
// import PeopleCardFigure from './PeopleCardFigure.vue'
import PeopleCardFigurePlayer from './PeopleCardFigurePlayer.vue'
import CreatePeopleFormModal from '../components/CreatePeopleFormModal.vue'
import LiveCapture from '../components/LiveCapture.vue'
import ProfileView from '../components/ProfileView.vue'
import { reportsService } from '@/services'
import LoadingScreenSmall from '../components/LoadingScreenSmall.vue'
import RangeSliderMultiColor from '../components/RangeSliderMultiColor.vue'
import dictSegmentation from '@/translations/dict.json'
import palettes from '@/assets/palettes.json'
import VueFullBodyPartSelector from '../components/VueFullBodyPartSelector.vue'
import ContourSuperiorPosterior from '@/assets/contour-superiorPosterior.json'
import ContourSuperiorAnterior from '@/assets/contour-superiorAnterior.json'
import ContourInferiorPosterior from '@/assets/contour-inferiorPosterior.json'
import ContourInferiorAnterior from '@/assets/contour-inferiorAnterior.json'
import ContourLateralEsquerda from '@/assets/contour-lateralEsquerda.json'
import ContourLateralDireita from '@/assets/contour-lateralDireita.json'
import ContourSuperiorInferiorPosterior from '@/assets/contour-superiorInferiorPosterior.json'
import ContourSuperiorInferiorAnterior from '@/assets/contour-superiorInferiorAnterior.json'

export default {
  components: {
    VueFullBodyPartSelector,
    LiveCapture,
    // PeopleCardFigure,
    PeopleCardFigurePlayer,
    LoadingScreenSmall,
    ProfileView,
    CreatePeopleFormModal,
    RangeSliderMultiColor
  },
  name: 'PeopleSearchList',

  props: {
    autoColumns: {
      type: Number,
      default: 0
    },
    showSubjectWithoutThermo: {
      type: Boolean,
      default: false
    },
    enableFilters: {
      type: Boolean,
      default: true
    },
    showOnlytThermoOnModal: {
      type: Boolean,
      default: false
    },
    tempMin: {
      type: Number,
      default: null
    },
    tempMax: {
      type: Number,
      default: null
    },
    thermoDate: {
      type: String,
      default: null
    },
    selectedTagsOutside: {
      type: Array,
      default: null
    },
    selectedGroupsOutside: {
      type: Array,
      default: null
    },
    customColsSm: {
      type: String,
      default: '6'
    },
    customColsLg: {
      type: String,
      default: '3'
    },
    customColsXl: {
      type: String,
      default: '2'
    }
  },

  data() {
    return {
      createPeopleModal: false,
      typeCreatePeopleModal: 'edit',
      editPeople: {},
      modalPainSelection: false,
      modalAnamnese: false,
      painPartSelected0: null,
      painPartSelected1: null,
      selectedIdxPain: 0,
      contoursJsonPain: {
        superiorPosterior: ContourSuperiorPosterior,
        superiorAnterior: ContourSuperiorAnterior,
        inferiorPosterior: ContourInferiorPosterior,
        inferiorAnterior: ContourInferiorAnterior,
        lateral_left: ContourLateralEsquerda,
        lateral_right: ContourLateralDireita,
        others: {
          superiorInferiorPosterior: ContourSuperiorInferiorPosterior,
          superiorInferiorAnterior: ContourSuperiorInferiorAnterior
        }
      },
      possibleViewsPain: [
        'superiorPosterior',
        'superiorAnterior',
        'inferiorPosterior',
        'inferiorAnterior',
        'lateral_left',
        'lateral_right',
        'others'
      ],
      painColors: {
        0: '#4DC81F',
        1: '#67C621',
        2: '#7CCA09',
        3: '#C1CA06',
        4: '#FFCC00',
        5: '#FEB400',
        6: '#FF9800',
        7: '#FA8009',
        8: '#FA6815',
        9: '#F84A15',
        10: '#F22A22'
      },
      painSelected0: 0,
      painSelected1: 0,
      partsPainSelected: [{}, {}],
      loadings: {
        sendThermo: false
      },
      liveCapture: [false, false],
      isDroppingFile0: false,
      isDroppingFile1: false,
      updatePeople: {
        createdAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16),
        imgs: [
          {
            view: 'inferiorAnterior',
            paletteName: 'RainbowLC',
            img: null,
            minTemp: '',
            maxTemp: '',
            imgPreview: null
          },
          {
            view: 'inferiorPosterior',
            paletteName: 'RainbowLC',
            img: null,
            minTemp: '',
            maxTemp: '',
            imgPreview: null
          }
        ],
        name: '',
        description: '',
        anamnese: '',
        weight: '',
        tags: []
      },
      anamnese: '',
      lastDays: '',
      modalPeople: false,
      showFilters: false,
      menuRangeDates: false,
      rangeDates: [],
      ageFirst: '',
      ageLast: '',
      showLastThermo: true,
      selectedPeople: null,
      selectedThermographyId: '',
      loadingThermo: true,
      images: [],
      selectedItems: [],
      curSelectedItems: [],
      curSelectedNextItems: [],
      cautionAsimmetryItems: [],
      dateLastThermo: {
        type: String,
        default: ''
      },
      tagsLastThermo: [],
      statusLastThermo: '',
      idxCarousel: 0,
      selectedTempType: '',
      filterTableType: 'mean',
      filter: {
        name: '',
        date: '',
        selectedGroups: [],
        selectedTags: []
      },

      chartAsymmetries: {
        options: {
          legend: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          chart: {
            toolbar: {
              show: false
            },
            id: 'analyse-chart'
          },
          stroke: {
            show: true,
            curve: 'smooth',
            width: 2
          },
          markers: {
            size: 5
          },
          xaxis: {
            categories: [],
            type: 'datetime',
            labels: {
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm'
              }
            }
          },
          yaxis: {
            min: (min) => (min > -10 && min < 10 ? Math.floor(min) : -4),
            max: (max) => (max > -10 && max < 10 ? Math.ceil(max) : 4),
            tickAmount: 10
          }
        },
        series: []
      }
    }
  },

  watch: {
    modalPeople(val) {
      if (val == false) {
        this.clearNewThermo()
      }
    },
    painSelected0() {
      const partsPainSelected = {
        ...this.partsPainSelected[0]
      }
      if (this.painPartSelected0 == null) {
        return
      }
      partsPainSelected[this.painPartSelected0] = this.painSelected0
      this.partsPainSelected[0] = partsPainSelected
    },
    painSelected1() {
      const partsPainSelected = {
        ...this.partsPainSelected[1]
      }
      if (this.painPartSelected1 == null) {
        return
      }
      partsPainSelected[this.painPartSelected1] = this.painSelected1
      this.partsPainSelected[1] = partsPainSelected
    },
    painPartSelected0() {
      if (this.partsPainSelected[0][this.painPartSelected0] != undefined) {
        this.painSelected0 = this.partsPainSelected[0][this.painPartSelected0]
      } else {
        this.painSelected0 = 0
      }
    },
    painPartSelected1() {
      if (this.partsPainSelected[1][this.painPartSelected1] != undefined) {
        this.painSelected1 = this.partsPainSelected[1][this.painPartSelected1]
      } else {
        this.painSelected1 = 0
      }
    },
    tempMin(val) {
      this.updatePeople.minTemp = val
    },
    tempMax(val) {
      this.updatePeople.maxTemp = val
    },
    selectedGroupsOutside(val) {
      if (val != null) {
        this.filter.selectedGroups = val
      }
    },
    idxCarousel(val) {
      this.curSelectedItems = this.selectedItems[val]
      this.curSelectedNextItems = this.selectedItems[this.idxCarouselNext]
    }
  },

  computed: {
    partsColourFullBody0() {
      const partsColour = {}
      this.painSelected0 // to update this computed variable it needs to listen to this variable
      if (this.updatePeople.imgs[0].view == null) return partsColour
      let contourJson =
        this.contoursJsonPain[this.updatePeople.imgs[0].view].data

      if (this.updatePeople.imgs[0].view == 'others') {
        contourJson = {
          ...this.contoursJsonPain[this.updatePeople.imgs[0].view]
            .superiorInferiorPosterior.data,
          ...this.contoursJsonPain[this.updatePeople.imgs[0].view]
            .superiorInferiorAnterior.data
        }
      }
      for (const [partName] of Object.entries(contourJson)) {
        partsColour[partName] =
          this.painColors[this.partsPainSelected[0][partName]]
      }
      return partsColour
    },
    partsColourFullBody1() {
      const partsColour = {}
      this.painSelected1 // to update this computed variable it needs to listen to this variable
      if (this.updatePeople.imgs[1].view == null) return partsColour
      let contourJson =
        this.contoursJsonPain[this.updatePeople.imgs[1].view].data

      if (this.updatePeople.imgs[1].view == 'others') {
        contourJson = {
          ...this.contoursJsonPain[this.updatePeople.imgs[1].view]
            .superiorInferiorPosterior.data,
          ...this.contoursJsonPain[this.updatePeople.imgs[1].view]
            .superiorInferiorAnterior.data
        }
      }
      for (const [partName] of Object.entries(contourJson)) {
        partsColour[partName] =
          this.painColors[this.partsPainSelected[1][partName]]
      }
      return partsColour
    },
    getCurrentSelectedView() {
      let selectedView = this.updatePeople.imgs[0].view
      this.availableViews.forEach((view) => {
        if (view.value == selectedView) {
          selectedView = view.label
        }
      })
      return selectedView
    },
    availableViews() {
      return this.allViews.filter(
        (view) =>
          !this.$store.getters.thermoImages.find((img) => {
            return img.view !== 'others' && img.view === view.value
          })
      )
    },
    allViews() {
      return this.$t('viewsOptsArr')
    },
    palettesKeys() {
      return Object.keys(palettes)
    },
    themeTypeTemps() {
      return window.localStorage.getItem('themeTypeTemps')
    },
    themeDefaultPaletteName() {
      return window.localStorage.getItem('themeDefaultPaletteName')
    },
    themeMinTemp() {
      return window.localStorage.getItem('themeMinTemp')
    },
    themeMaxTemp() {
      return window.localStorage.getItem('themeMaxTemp')
    },
    lastDaysOptions() {
      return [
        {
          text: this.$t('All'),
          value: -1
        },
        {
          text: this.$t('Last7Days'),
          value: 7
        },
        {
          text: this.$t('Last14Days'),
          value: 14
        },
        {
          text: this.$t('Last30Days'),
          value: 30
        }
      ]
    },
    dateRangeText() {
      if (this.rangeDates.length <= 0) return ''
      let dates = [...this.rangeDates]
      dates.sort()
      dates = dates.map((date) => {
        return new Date(date + ' 00:00:00').toLocaleDateString()
      })
      return dates.join(' até ')
    },
    filteredSubjects() {
      let dates = [...this.rangeDates]
      dates.sort()
      if (dates.length > 0) {
        dates[1] = dates[dates.length - 1]
      }
      return this.subjects.filter((subject) => {
        if (
          !this.showSubjectWithoutThermo &&
          subject.thermographies.length <= 0
        ) {
          return false
        }

        const thermographies = subject.thermographies
        let haveThermograph = subject.hasThermo

        if (!this.showSubjectWithoutThermo && !haveThermograph) {
          return false
        }

        if (dates.length > 0) {
          let inRangeDate = false
          const curDate = new Date(
            new Date(subject.createdAt).getTime() -
              new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16)
          if (curDate >= dates[0] && curDate <= dates[1]) inRangeDate = true

          if (!inRangeDate) return false
        }
        if (this.lastDays > 0) {
          const curDate = new Date(
            new Date(subject.createdAt).getTime() -
              new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16)
          const dateNow = new Date().toISOString().split('T')[0]
          let lastDaysDate = new Date()
          lastDaysDate = this.dateAddDays(lastDaysDate, -this.lastDays)
          lastDaysDate = lastDaysDate.toISOString().split('T')[0]
          if (!(curDate >= lastDaysDate && curDate <= dateNow)) return false
        }
        if (this.ageFirst != '') {
          if (!(subject.age >= this.ageFirst)) return false
        }
        if (this.ageLast != '') {
          if (!(subject.age <= this.ageLast)) return false
        }
        if (this.filter.selectedGroups) {
          const existent = this.filter.selectedGroups.find((group) => {
            return !subject.groups.find((itemGroup) => itemGroup.id === group)
          })
          if (existent) return false
        }
        if (this.filter.selectedTags) {
          const existent = this.filter.selectedTags.find((tag) => {
            let haveTag = false
            for (let iThermo = 0; iThermo < thermographies.length; iThermo++) {
              const thermo = thermographies[iThermo]
              if (thermo.tags.find((itemTag) => itemTag.id === tag)) {
                haveTag = true
              }
            }
            return !haveTag
          })
          if (existent) return false
        }

        if (this.filter.date != '') {
          let correctDate = false
          for (let iThermo = 0; iThermo < thermographies.length; iThermo++) {
            const thermo = thermographies[iThermo]
            if (
              new Date(
                new Date(thermo.createdAt).getTime() -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .slice(0, 16) == this.filter.date.split('T')[0]
            ) {
              correctDate = true
            }
          }
          return correctDate
        }

        return subject.name
          .toLowerCase()
          .includes(this.filter.name.toLowerCase())
      })
    },
    subjects() {
      // Mention this variable to update subjects
      this.thermoDate
      if (
        this.$store.getters.subjects != undefined &&
        this.$store.getters.subjects.length > 0
      ) {
        return this.$store.getters.subjects.map((subject) => {
          const thermographies = subject.thermographies
          let haveThermograph = false
          let haveProcessingThermograph = false
          let hasThermoId = ''

          if (thermographies != undefined) {
            for (let iThermo = 0; iThermo < thermographies.length; iThermo++) {
              const thermo = thermographies[iThermo]
              if (thermo.status == 'PROCESSED' && this.thermoDate == null) {
                haveThermograph = true
                hasThermoId = thermo.id
              } else if (
                thermo.status == 'PROCESSED' &&
                this.thermoDate.slice(0, 10) ==
                  new Date(
                    new Date(thermo.createdAt).getTime() -
                      new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .slice(0, 10)
              ) {
                haveThermograph = true
                hasThermoId = thermo.id
              }

              if (
                (thermo.status === 'CREATED' ||
                  thermo.status === 'PROCESSING' ||
                  thermo.status === 'QUEUE') &&
                this.thermoDate == null
              ) {
                haveProcessingThermograph = true
              } else if (
                (thermo.status === 'CREATED' ||
                  thermo.status === 'PROCESSING' ||
                  thermo.status === 'QUEUE') &&
                this.thermoDate.slice(0, 10) ==
                  new Date(
                    new Date(thermo.createdAt).getTime() -
                      new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .slice(0, 10)
              ) {
                haveProcessingThermograph = true
              }
            }
          }

          return {
            id: subject.id,
            active: subject.active,
            birthDate: subject.birthDate,
            profilePicture: subject.profilePicture,
            cpf: subject.cpf,
            gender: subject.gender,
            hasThermo: haveThermograph,
            hasProcessingThermo: haveProcessingThermograph,
            hasThermoId: hasThermoId,
            name: subject.name,
            createdAt: subject.createdAt,
            age: this.getAge(subject.birthDate),
            avatarUrl: subject.profilePicture,
            groups: subject.groups,
            thermographies: subject.thermographies
          }
        })
      }

      return []
    },
    groups() {
      return this.$store.getters.groups.map((item) => {
        return {
          text: `${item.type}: ${item.name}`,
          value: item.id
        }
      })
    },
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },

    activeImage() {
      return this.images[this.idxCarousel]
    },
    idxCarouselNext() {
      return this.images.length - 1 == this.idxCarousel
        ? 0
        : this.idxCarousel + 1
    },

    getChartAsymmetries() {
      const data = this.curSelectedItems.map((selectedItem) => {
        let itemData = {
          y: this.activeImage?.asymmetries?.data[selectedItem]?.[
            this.filterTableType
          ].toFixed(2),
          x: new Date(this.dateLastThermo),
          thermographyId: this.activeImage.thermoImage.thermographyId
        }
        const lastItemData = this.activeImage.lastImages
          .map((image) => {
            return {
              y: image?.asymmetries?.data[selectedItem]?.[
                this.filterTableType
              ].toFixed(2),
              x: new Date(image.createdAt),
              tags: image.tags,
              thermographyId: image.thermographyId
            }
          })
          .filter((item) => {
            if (this.selectedTag && this.selectedTag.length) {
              if (item.tags.length == 0) {
                return false
              }
              let hasTag = false
              for (let idxTag = 0; idxTag < item.tags.length; idxTag++) {
                if (this.selectedTag.includes(item.tags[idxTag].name)) {
                  hasTag = true
                }
              }
              return hasTag
            }
            return !!item.y
          })
        const returnData = [itemData, ...lastItemData]
        return {
          name:
            this.activeImage.labelView +
            ' - ' +
            this.translateItem(selectedItem, 'full'),
          data: returnData
        }
      })
      const dataNext = this.curSelectedNextItems.map((selectedItem) => {
        let itemData = {
          y: this.images[this.idxCarouselNext]?.asymmetries?.data[
            selectedItem
          ]?.[this.filterTableType].toFixed(2),
          x: new Date(this.dateLastThermo),
          thermographyId:
            this.images[this.idxCarouselNext].thermoImage.thermographyId
        }
        const lastItemData = this.images[this.idxCarouselNext].lastImages
          .map((image) => {
            return {
              y: image?.asymmetries?.data[selectedItem]?.[
                this.filterTableType
              ].toFixed(2),
              x: new Date(image.createdAt),
              tags: image.tags,
              thermographyId: image.thermographyId
            }
          })
          .filter((item) => {
            if (this.selectedTag && this.selectedTag.length) {
              if (item.tags.length == 0) {
                return false
              }
              let hasTag = false
              for (let idxTag = 0; idxTag < item.tags.length; idxTag++) {
                if (this.selectedTag.includes(item.tags[idxTag].name)) {
                  hasTag = true
                }
              }
              return hasTag
            }
            return !!item.y
          })
        const returnData = [itemData, ...lastItemData]
        return {
          name:
            this.images[this.idxCarouselNext].labelView +
            ' - ' +
            this.translateItem(selectedItem, 'full'),
          data: returnData
        }
      })
      return data.concat(dataNext)
    },

    asymmetriesDonutChart() {
      const data = {
        red: 0,
        yellow: 0,
        green: 0
      }

      this.images.forEach((image) => {
        const percentages = this.getAssymetriesPercentages(image)

        if (percentages.red >= 20) {
          data.red++
        } else if (percentages.yellow >= 50) {
          data.yellow++
        } else {
          data.green++
        }

        image.lastImages.forEach((lastImage) => {
          const percentages = this.getAssymetriesPercentages(lastImage)

          if (percentages.red >= 20) {
            data.red++
          } else if (percentages.yellow >= 50) {
            data.yellow++
          } else {
            data.green++
          }
        })
      })

      return {
        series: [data.red, data.yellow, data.green],
        options: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    fontSize: '22px',
                    color: '#000000',
                    offsetY: -10,
                    formatter: () => data.red + data.green + data.yellow
                  }
                }
              }
            }
          },
          dataLabels: {
            textAnchor: 'start',
            style: {
              fontSize: '12px',
              colors: ['#333']
            },
            offsetX: 150,
            dropShadow: {
              enabled: false
            },
            background: {
              enabled: true,
              opacity: 0.3,
              foreColor: '#fff',
              borderRadius: 1,
              padding: 4
            },
            position: 'top'
          },
          colors: ['#B00020', '#FB8C00', '#4CAF50'],
          labels: [this.$t('Alert'), this.$t('Attention'), this.$t('Normal')]
        }
      }
    }
  },

  mounted() {
    this.$store.dispatch('clearThermo')
    this.updatePeople.imgs[0].minTemp = this.tempMin
    this.updatePeople.imgs[0].maxTemp = this.tempMax
    this.updatePeople.imgs[1].minTemp = this.tempMin
    this.updatePeople.imgs[1].maxTemp = this.tempMax
  },

  methods: {
    handleEditPeople(item) {
      this.editPeople = {
        ...item,
        profilePictureBase64: item.profilePicture,
        birthDate: item.birthDate?.substr(0, 10),
        groups: item.groups?.map((g) => g.id)
      }
      this.typeCreatePeopleModal = 'edit'
      this.createPeopleModal = true
    },
    async handleOnSuccessCreatePeople() {
      const createPeopleSuccess = this.$t('SuccessfullyUpdatedPerson')

      await this.$store.dispatch('fetchSubjects')

      this.createPeopleModal = false

      this.$store.dispatch('setSnackBar', {
        text: createPeopleSuccess,
        type: 'success',
        model: true
      })
    },
    handleOnErrorCreatePeople() {
      const createPeopleError = this.$t('ErrorTryingToUpdatePerson')

      this.$store.dispatch('setSnackBar', {
        text: createPeopleError,
        type: 'error',
        model: true
      })
    },
    handleLiveCapture0(img) {
      this.updatePeople.imgs[0].img = img
      this.updatePeople.imgs[0].imgPreview = URL.createObjectURL(img)
    },
    handleLiveCapture1(img) {
      this.updatePeople.imgs[1].img = img
      this.updatePeople.imgs[1].imgPreview = URL.createObjectURL(img)
    },
    dragOverFile0() {
      this.isDroppingFile0 = true
    },
    dragLeaveFile0() {
      this.isDroppingFile0 = false
    },
    dragOverFile1() {
      this.isDroppingFile1 = true
    },
    dragLeaveFile1() {
      this.isDroppingFile1 = false
    },
    handleInputFile(type, idx) {
      if (type == 'drop') {
        if (idx == 0) {
          this.isDroppingFile0 = false
          this.handleFileChange0(event)
        } else if (idx == 1) {
          this.isDroppingFile1 = false
          this.handleFileChange1(event)
        }
      } else if (type == 'click') {
        if (idx == 0) {
          this.$refs.inputFile0.click()
        } else if (idx == 1) {
          this.$refs.inputFile1.click()
        }
      }
    },
    handleFileChange0(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.updatePeople.imgs[0].img = files[0]
      this.updatePeople.imgs[0].imgPreview = URL.createObjectURL(files[0])
    },
    handleFileChange1(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.updatePeople.imgs[1].img = files[0]
      this.updatePeople.imgs[1].imgPreview = URL.createObjectURL(files[0])
    },
    getViewName(key) {
      return this.allViews.find((item) => item.value === key).label
    },
    removeThermoImage(idx) {
      this.$store.dispatch('removeThermoImage', idx)
    },
    async handleAddThermoImage() {
      let createdAt = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 16)
      if (this.thermoDate != null) {
        createdAt = new Date(
          new Date(this.thermoDate).getTime() -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16)
      }
      if (this.updatePeople.imgs[0].img != null) {
        await this.$store.dispatch('addThermoImage', {
          createdAt: createdAt,
          img: this.updatePeople.imgs[0].img,
          imgPreview: this.updatePeople.imgs[0].imgPreview,
          minTemp: this.updatePeople.imgs[0].minTemp,
          maxTemp: this.updatePeople.imgs[0].maxTemp,
          paletteName: this.updatePeople.imgs[0].paletteName,
          pain: this.partsPainSelected[0],
          view: this.updatePeople.imgs[0].view
        })
      }
      if (this.updatePeople.imgs[1].img != null) {
        await this.$store.dispatch('addThermoImage', {
          createdAt: createdAt,
          img: this.updatePeople.imgs[1].img,
          imgPreview: this.updatePeople.imgs[1].imgPreview,
          minTemp: this.updatePeople.imgs[1].minTemp,
          maxTemp: this.updatePeople.imgs[1].maxTemp,
          paletteName: this.updatePeople.imgs[1].paletteName,
          pain: this.partsPainSelected[1],
          view: this.updatePeople.imgs[1].view
        })
      }
    },
    clearNewThermo() {
      let createdAt = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 16)
      if (this.thermoDate != null) {
        createdAt = new Date(
          new Date(this.thermoDate).getTime() -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16)
      }
      this.partsPainSelected = [{}, {}]
      this.updatePeople = {
        createdAt: createdAt,
        imgs: [
          {
            view: 'inferiorAnterior',
            paletteName: 'RainbowLC',
            img: null,
            minTemp: this.tempMin,
            maxTemp: this.tempMax,
            tags: [],
            imgPreview: null
          },
          {
            view: 'inferiorPosterior',
            paletteName: 'RainbowLC',
            img: null,
            minTemp: this.tempMin,
            maxTemp: this.tempMax,
            tags: [],
            imgPreview: null
          }
        ],
        name: '',
        description: '',
        anamnese: '',
        weight: '',
        tags: []
      }
      this.painPartSelected0 = null
      this.painPartSelected1 = null
      this.painSelected0 = 0
      this.painSelected1 = 0
    },
    async handleSendThermo() {
      let createdAt = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 16)
      if (this.thermoDate != null) {
        createdAt = new Date(
          new Date(this.thermoDate).getTime() -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16)
      }
      if (!this.updatePeople.imgs[0].img && !this.updatePeople.imgs[1].img) {
        this.$store.dispatch('setSnackBar', {
          text: 'Você deve informar ao menos uma imagem.',
          type: 'error',
          model: true
        })
        return false
      }
      if (
        !this.updatePeople.imgs[0].minTemp &&
        !this.updatePeople.imgs[1].minTemp
      ) {
        this.$store.dispatch('setSnackBar', {
          text: 'Você deve informar a temperatura mínima.',
          type: 'error',
          model: true
        })
        return false
      }
      if (
        !this.updatePeople.imgs[0].maxTemp &&
        !this.updatePeople.imgs[1].maxTemp
      ) {
        this.$store.dispatch('setSnackBar', {
          text: 'Você deve informar a temperatura máxima.',
          type: 'error',
          model: true
        })
        return false
      }
      this.loadings.sendThermo = true
      try {
        await this.handleAddThermoImage()
        await this.$store.dispatch('updateThermoCreatedAt', createdAt)
        await this.$store.dispatch(
          'updateThermoName',
          this.updatePeople.description
        )
        await this.$store.dispatch(
          'updateThermoAnamnese',
          this.updatePeople.anamnese
        )
        await this.$store.dispatch('updateThermoTags', this.updatePeople.tags)
        await this.$store.dispatch(
          'updateThermoSubjectId',
          this.selectedPeople.id
        )
        const thermo = await this.$store.dispatch('analyseThermo')
        let status = 'CREATED'

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ThermographySuccessfullyRegistered')}!`,
          type: 'success',
          model: true
        })

        await this.$store.dispatch('fetchSubjects')

        this.clearNewThermo()

        this.modalPeople = false
        this.loadings.sendThermo = false

        while (
          status === 'CREATED' ||
          status === 'PROCESSING' ||
          status === 'QUEUE'
        ) {
          const responseThermo = await reportsService.getSingleThermo(thermo.id)
          await new Promise((resolve) => setTimeout(resolve, 2000))
          status = responseThermo.data.status
        }

        await this.$store.dispatch('fetchSubjects')
      } catch (error) {
        console.log(error)
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('AnErrorOccurredWhileTryingToAddThermography')}!`,
          type: 'error',
          model: true
        })

        // this.errors.sendThermo = true
      }
      this.loadings.sendThermo = false
    },
    dateAddDays(date, days) {
      date.setDate(date.getDate() + days)
      return date
    },
    clickChartAsymmetry(e, chartContext, config) {
      const idxSeries = config.dataPointIndex
      this.selectedThermographyId =
        this.getChartAsymmetries[0].data[idxSeries].thermographyId
      this.fetchImages()
    },
    getAge(dateString) {
      var today = new Date()
      var birthDate = new Date(dateString)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    getAssymetriesPercentages(image) {
      const lengthAsymmetries = Object.keys(image?.asymmetries?.data).length
      let red = 0
      let yellow = 0

      Object.keys(image?.asymmetries?.data).forEach((contour) => {
        if (
          Math.abs(image?.asymmetries?.data[contour]?.[this.filterTableType]) >
          0.99
        ) {
          red++
          yellow++
        } else if (
          Math.abs(image?.asymmetries?.data[contour]?.[this.filterTableType]) >
          0.49
        ) {
          yellow++
        }
      })

      return {
        red: (red / lengthAsymmetries) * 100,
        yellow: (yellow / lengthAsymmetries) * 100
      }
    },
    onClickPeople(subject) {
      this.modalPeople = true
      this.selectedPeople = subject
      this.updatePeople.name = subject.name
      if (
        this.selectedTagsOutside != undefined &&
        this.selectedTagsOutside.length > 0
      ) {
        this.updatePeople.tags = this.selectedTagsOutside
      }
      this.updatePeople.anamnese = subject.anamnese

      if (subject.hasThermo) {
        this.selectedThermographyId = subject.hasThermoId
        this.fetchImages()
      } else {
        this.loadingThermo = false
      }
    },
    onClickEditPeople(subject) {
      this.handleEditPeople(subject)
    },
    onClickGoToThermoGraphOnly(subject) {
      if (subject.hasThermo) {
        this.$router.push({
          name: 'ThermoGraphOnly',
          params: {
            thermographyId: subject.hasThermoId
          }
        })
      }
    },
    translateItem(item, type) {
      if (!dictSegmentation[item]) return item
      return dictSegmentation[item][type] || item
    },
    onChangeSelected(selected) {
      this.selectedItems[this.idxCarousel] = selected
      this.curSelectedItems = selected
    },
    onChangeSelectedNextItems(selected) {
      this.selectedItems[this.idxCarouselNext] = selected
      this.curSelectedNextItems = selected
    },
    editThermo() {
      if (this.selectedThermographyId != '') {
        this.$router.push({
          name: 'Thermography',
          params: {
            thermographyId: this.selectedThermographyId
          }
        })
      }
    },
    newThermo() {
      this.$router.push({
        name: 'NewThermo',
        params: {
          peopleId: this.selectedPeople.id
        }
      })
    },
    newData() {
      this.$router.push({
        name: 'NewThermo',
        params: {
          peopleId: this.selectedPeople.id
        }
      })
    },
    moreThermo() {
      if (this.selectedThermographyId != '') {
        this.$router.push({
          name: 'Thermography',
          params: {
            thermographyId: this.selectedThermographyId
          }
        })
      }
    },
    getLabelView(valueView) {
      let labelVista = valueView

      this.$t('viewsOptsArr').forEach((view) => {
        if (labelVista === view.value) labelVista = view.label
      })

      return labelVista
    },
    async fetchImages() {
      this.loadingThermo = true
      this.selectedItems = []
      this.cautionAsimmetryItems = []
      this.curSelectedItems = []
      let updateCurSelectedItems = true

      try {
        const response = await reportsService.getSingleThermo(
          this.selectedThermographyId
        )
        this.tagsLastThermo = response.data?.tags
        this.dateLastThermo = response.data.createdAt
        this.anamnese = response.data.anamnese

        const data_images = response.data.thermographyImages.map((image) => {
          if (image.status === 'PROCESSED') {
            this.selectedItems.push([])
            this.cautionAsimmetryItems.push([])

            Object.keys(image?.asymmetries?.data).forEach((contour) => {
              if (
                Math.abs(
                  image?.asymmetries?.data[contour]?.[this.filterTableType]
                ) > 0.99
              ) {
                this.selectedItems[this.selectedItems.length - 1].push(contour)
                const contourNormalized = contour.replace('_right', '_left')
                if (
                  !this.cautionAsimmetryItems[
                    this.cautionAsimmetryItems.length - 1
                  ].includes(contourNormalized)
                ) {
                  this.cautionAsimmetryItems[
                    this.cautionAsimmetryItems.length - 1
                  ].push(contourNormalized)
                }
                if (updateCurSelectedItems) {
                  this.curSelectedItems.push(contour)
                }
              }
            })

            const contours =
              typeof image?.contours?.data === typeof Object
                ? Object.keys(image.contours.data).filter(
                    (contour) =>
                      !image.contours.data[contour] ||
                      image.contours.data[contour] === 'bg'
                  )
                : []

            for (const contour of contours) {
              delete image.contours.data[contour]
            }
            const metrics =
              typeof image?.metrics?.data === typeof Object
                ? Object.keys(image.metrics.data).filter(
                    (metric) =>
                      !image.metrics.data[metric] ||
                      image.metrics.data[metric] === 'bg'
                  )
                : []
            for (const metric of metrics) {
              delete image.metrics.data[metric]
            }

            updateCurSelectedItems = false

            return {
              id: image.id,
              src: image.src,
              status: image.status,
              asymmetries: image?.asymmetries || {},
              lastImages: image.lastImages,
              view: image.view,
              labelView: this.getLabelView(image.view),
              maxTemp: image.maxTemp,
              minTemp: image.minTemp,
              palette: image.palette,
              thermoImage: image.thermoImage,
              flip_registers: image?.flip_registers || {},
              segments: image?.segments || {},
              contours: {
                ...image.contours
              },
              metrics: image.metrics || {
                data: {}
              },
              createdAt: image.createdAt,
              showModal: false
            }
          }
          return image
        })
        this.images = data_images

        this.statusLastThermo = this.$t(response.data.status)
      } catch (error) {
        console.log(error)
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
          type: 'error',
          model: true
        })
      } finally {
        this.loadingThermo = false
      }
    }
  }
}
</script>
<style>
.card-pain-body svg {
  height: 340px;
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .card-pain-body svg {
    height: 240px !important;
  }
}
</style>
<style lang="scss" scoped>
.labelDateThermo {
  font-size: 14px !important;
  align-content: center;
  text-align: center;
}

.p-r-25px {
  padding-right: 25px;
}
.p-l-25px {
  padding-left: 25px;
}
.card-pain-body {
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-relative {
  position: relative;
}
.paleta-cores {
  position: absolute;
  right: 0;
  top: 0;
  top: calc(50% - 176px);
  width: 26px;
  height: 352px;
  padding: 0 6px;

  button {
    color: white;
    font-size: 16px;
    display: flex;
    width: 26px;
    margin-bottom: 6px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 9000px;
  }

  button.active {
    text-shadow: 0px 0px 6px rgba(0, 0, 128, 1);
    color: #1c1c1c;
  }
}
.icon-container {
  background-color: #f6ebea;
  padding: 4px;
  border-radius: 3px;
}
.card-hover {
  cursor: pointer;
  transition: background 0.5s;
  &:hover {
    background: #f6ebea;
  }
}
.current-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.view-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropping-file {
  opacity: 0.6;
}

.text-center,
.text-overlay {
  color: #666; /* Texto menos proeminente */
  letter-spacing: 0.4px;
}
.text-overlay {
  padding-bottom: 20px;
}
.scale-eva,
.information-player,
.windowThermal-1 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
.icon-add-info {
  transition: color 0.3s ease, box-shadow 0.3s ease;
  margin: 5px;
  font-size: 50px;
  padding: 10px;
  box-shadow: 0 2px 4px lightgray;
  cursor: pointer;
  color: grey; /* Exemplo de cor para ícone desativado */
  opacity: 0.5; /* Torna o ícone semitransparente */
}
.icon-add-info:hover {
  color: gray; /* Altera a cor no hover */
  box-shadow: 0 4px 8px gray; /* Sombra mais pronunciada no hover */
}
.icon-add-info:disabled {
  box-shadow: 0 2px 4px lightgray;
  color: grey; /* Exemplo de cor para ícone desativado */
  cursor: default;
}
@media screen and (max-width: 1600px) {
  .card-pain-body svg {
    height: 240px;
  }

  .card-pain-body {
    height: 240px;
  }
  .v-card-height-file {
    height: 270px !important;
  }

  .paleta-cores {
    width: 18px;

    button {
      font-size: 12px;
      width: 18px;
      height: 18px;
    }
  }
}
</style>
