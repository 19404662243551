<template>
  <v-col v-if="error">
    <v-row justify="center" class="mt-10">
      <div>
        <v-col> {{ $t('ErrorLoadStatistics') }} </v-col>
        <v-col class="py-0">
          <v-btn @click="getData" rounded color="primary">{{
            $t('TRYAGAIN')
          }}</v-btn>
        </v-col>
      </div>
    </v-row>
  </v-col>
  <v-col v-else-if="loading">
    <v-row justify="center" class="mt-10">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col v-else>
    <v-row>
      <v-col cols="12" class="px-lg-16 px-md-8">
        <v-data-table
          hide-default-footer
          :headers="headersAlerts"
          :items="subjectsTableAlerts"
          :sort-by="['date1']"
          :sort-desc="[true]"
          :loading="loadingSubjectsTableAlerts"
          must-sort
          outlined
          :items-per-page="-1"
          :no-data-text="`${$t('NoRecord')} :(`"
          mobile-breakpoint="1"
          id="table-data-people"
        >
          <template v-slot:item="{ headers, item }">
            <tr @click="goToThermo(item.thermo1Id)" style="cursor: pointer">
              <td v-for="itemKey in headers" :key="itemKey.value">
                <template
                  v-if="
                    itemKey.value === 'date1' ||
                    itemKey.value === 'date2' ||
                    itemKey.value === 'date3'
                  "
                >
                  {{
                    item[itemKey.value] != null
                      ? new Date(item[itemKey.value])
                          .toLocaleDateString()
                          .toString()
                      : null
                  }}
                </template>
                <template v-else>
                  {{ item[itemKey.value] }}
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import LoadingScreen from '../components/LoadingScreen.vue'
import { reportsService } from '@/services'
import dictSegmentation from '@/translations/dict.json'

export default {
  components: { LoadingScreen },
  data() {
    return {
      loading: false,
      loadingSubjectsTableAlerts: false,
      subjectsTableAlerts: [],
      error: false
    }
  },
  computed: {
    subjects() {
      return this.$store.getters.subjects.map((subject) => ({
        name: subject.name,
        createdAt: subject.createdAt,
        groups: subject.groups,
        thermographies: subject.thermographies
      }))
    },
    headersAlerts() {
      return [
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('Date'), value: 'date1' },
        { text: this.$t('Alerts'), value: 'alerts' }
      ]
    },
    stats() {
      return this.$store.getters.stats
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    goToThermo(thermoId) {
      if (thermoId != null) {
        this.$router.push({
          name: 'Thermography',
          params: {
            thermographyId: thermoId
          }
        })
      }
    },
    async getData() {
      this.loading = true
      try {
        await this.getSubjectsTableAlerts()
      } catch (error) {
        console.log('Error: ', error)
        this.error = error
      } finally {
        this.loading = false
      }
    },

    async getSubjectsTableAlerts() {
      this.loadingSubjectsTableAlerts = true

      try {
        const subjects = await reportsService.getLastAsymmetries()
        this.subjectsTableAlerts = subjects.data

        for (
          let iSubject = 0;
          iSubject < this.subjectsTableAlerts.length;
          iSubject++
        ) {
          this.subjectsTableAlerts[iSubject].cautionAsimmetryItems = []

          const lengthThermoImgs =
            this.subjectsTableAlerts[iSubject].thermography != undefined
              ? this.subjectsTableAlerts[iSubject].thermography
                  .thermographyImages.length
              : 0

          for (
            let iThermoImg = 0;
            iThermoImg < lengthThermoImgs;
            iThermoImg++
          ) {
            const image =
              this.subjectsTableAlerts[iSubject].thermography
                .thermographyImages[iThermoImg]

            if (
              image.status === 'PROCESSED' &&
              image?.asymmetries != undefined
            ) {
              Object.keys(image?.asymmetries?.data).forEach((contour) => {
                if (Math.abs(image?.asymmetries?.data[contour]?.mean) > 0.99) {
                  const contourName =
                    dictSegmentation?.[
                      contour.split('_left')[0].split('_right')[0].trim()
                    ]?.full
                  if (
                    contourName != '' &&
                    contourName != null &&
                    !this.subjectsTableAlerts[
                      iSubject
                    ].cautionAsimmetryItems.includes(contourName)
                  ) {
                    this.subjectsTableAlerts[
                      iSubject
                    ].cautionAsimmetryItems.push(contourName)
                  }
                }
              })
            }
          }
        }

        this.subjectsTableAlerts = this.subjectsTableAlerts.map((subject) => ({
          name: subject.name,
          thermo1Id:
            subject.thermography != undefined ? subject.thermography.id : null,
          date1:
            subject.thermography != undefined
              ? subject.thermography.createdAt
              : null,
          date2: null,
          date3: null,
          alerts: subject.cautionAsimmetryItems.join(', ')
        }))
      } catch (error) {
        console.log(error)
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('UnexpectedErrorWhileProcessingThermography')}!`,
          type: 'error',
          model: true
        })
      }
      this.loadingSubjectsTableAlerts = false
    },
    onClickSubject(id) {
      this.$router.push({
        name: 'PeopleThermos',
        params: { peopleId: id }
      })
    }
  }
}
</script>

<style scoped>
.list-scroll {
  width: auto;
  overflow-y: auto;
  max-height: 400px;
}
.styled-table {
  border-collapse: collapse;
  margin: 20px 0;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 15px gray;
}
.styled-vcard {
  border-color: red;
  box-shadow: 0 0 10px gray;
}
</style>
