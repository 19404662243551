<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-toolbar-title class="text--subtitle">
        <v-icon color="primary" style="padding-bottom: 2px">mdi-plus</v-icon>
        {{ $t('NewPerson') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon @click="handleCloseAddModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-col class="px-4">
      <v-text-field
        v-model="createPeople.name"
        outlined
        dense
        :label="$t('Name')"
      >
      </v-text-field>
      <v-select
        v-model="createPeople.active"
        v-if="type == 'edit'"
        :items="optionsActive"
        outlined
        dense
        :label="$t('Active') + '?'"
      />
      <v-text-field
        v-model="createPeople.cpf"
        :rules="rulesCpf"
        v-mask="'###.###.###-##'"
        outlined
        dense
        :label="$t('CPF')"
      />
      <v-autocomplete
        style="font-weight: 300"
        outlined
        dense
        v-model="createPeople.groups"
        auto-select-first
        chips
        small-chips
        multiple
        :items="groups"
        :label="$t('Groups')"
      >
      </v-autocomplete>
      <v-select
        v-model="createPeople.gender"
        :items="genders"
        outlined
        dense
        :label="$t('Gender')"
      />
      <v-text-field
        v-model="createPeople.birthDate"
        type="date"
        outlined
        dense
        :label="$t('BirthDate')"
      />
    </v-col>
    <v-col>
      <v-row class="mx-1" align="center">
        <v-avatar color="primary" size="86">
          <img
            v-if="createPeople.profilePictureBase64"
            :src="createPeople.profilePictureBase64"
            alt="Profile picture"
          />
          <span v-else class="white--text text-body-1">
            {{
              createPeople.name !== ''
                ? createPeople.name.charAt(0).toUpperCase()
                : ''
            }}
          </span>
        </v-avatar>
        <v-file-input
          class="mb-n6"
          @change="onFileChange($event)"
          accept="image/*"
          :label="$t('ProfilePicture')"
          dense
          outlined
        ></v-file-input>
      </v-row>
    </v-col>

    <v-card-actions class="pb-6 mt-4">
      <v-spacer></v-spacer>
      <v-btn text class="px-10" @click="handleCloseAddModal">
        <span class="mx-3">{{ $t('Cancel') }}</span>
      </v-btn>
      <v-btn
        @click="handleSave"
        color="primary"
        :loading="createPeople.loading"
        :disabled="createPeople.loading || !validaCpf(createPeople.cpf)"
        class="px-4"
      >
        <span class="mx-3">{{ $t('SAVE') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validaCpf from '../utils/validaCpf'

export default {
  name: 'CreatePeopleFormModal',
  props: {
    closeModalCallback: Function,
    onSuccessCallback: Function,
    onErrorCallback: Function,
    peopleInfo: Object,
    type: String
  },
  data: function () {
    return {
      rulesCpf: [(value) => validaCpf(value) || 'CPF inválido'],
      snackBar: {
        model: false,
        color: 'default',
        timeout: 2000,
        text: ''
      },
      createPeople: {
        id: -1,
        loading: false,
        errors: false,
        birthDate: '',
        gender: '',
        name: '',
        cpf: '',
        file: null,
        profilePictureBase64: null,
        active: 1,
        groups: []
      }
    }
  },
  methods: {
    validaCpf(cpf) {
      return validaCpf(cpf)
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async onFileChange(file) {
      this.createPeople.file = file
      this.createPeople.profilePictureBase64 = file
        ? await this.toBase64(file)
        : null
    },
    async handleSave() {
      this.createPeople.loading = true
      const dispatchStr = this.type === 'add' ? 'addSubject' : 'updateSubject'

      try {
        await this.$store.dispatch(dispatchStr, this.createPeople)
        this.createPeople.errors = false
        this.createPeople.loading = false
        this.handleCloseAddModal()

        await this.onSuccessCallback()
      } catch (error) {
        this.onErrorCallback()
        this.createPeople.errors = true
      }
      this.createPeople.loading = false
    },
    async handleCloseAddModal() {
      this.createPeople = {
        loading: false,
        errors: false,
        birthDate: '',
        gender: '',
        name: '',
        cpf: '',
        metadata: [
          {
            key: '',
            value: ''
          }
        ],
        file: null,
        profilePictureBase64: null,
        active: 1,
        groups: []
      }
      this.closeModalCallback()
    }
  },
  computed: {
    optionsActive() {
      return [
        {
          text: this.$t('Yes'),
          value: 1
        },
        {
          text: this.$t('No'),
          value: 0
        }
      ]
    },
    genders() {
      return [
        {
          text: this.$t('Male'),
          value: 'Masculino'
        },
        {
          text: this.$t('Female'),
          value: 'Feminino'
        }
      ]
    },
    groups() {
      return this.$store.getters.groups.map((item) => {
        return {
          text: `${item.type}: ${item.name}`,
          value: item.id
        }
      })
    }
  },
  created() {
    if (this.type === 'edit') {
      this.createPeople.id = this.peopleInfo.id
      this.createPeople.name = this.peopleInfo.name
      this.createPeople.cpf = this.peopleInfo.cpf
      this.createPeople.gender = this.peopleInfo.gender
      this.createPeople.profilePictureBase64 =
        this.peopleInfo.profilePictureBase64
      this.createPeople.birthDate = this.peopleInfo.birthDate
      this.createPeople.groups = this.peopleInfo.groups
      this.createPeople.active = this.peopleInfo.active ? 1 : 0
    }
  }
}
</script>
