import http from '@/infra/api'

let all = () => {
  return http.get('company')
}

let info = () => {
  return http.get('info')
}

export default {
  all,
  info
}
