import http from '@/infra/api'

let createThermo = async (data) => {
  return http.post('thermo', data)
}

let updateThermo = async (id, data) => {
  return http.put(`/thermo/${id}`, data)
}

let destroy = (id) => {
  return http.delete(`thermo/${id}`)
}

let addImage = async (data) => {
  return http.post(`thermo/image`, data)
}

let deleteImage = async (id) => {
  return http.delete(`thermo/image/${id}`)
}

let getThermoImages = async (payload) => {
  return http.get(
    '/thermo/images?view=' + payload.view + '&subjectIds=' + payload.subjectIds
  )
}

let reprocess = (id) => {
  return http.post(`/thermo/reprocess/${id}`)
}

export default {
  createThermo,
  updateThermo,
  destroy,
  addImage,
  deleteImage,
  getThermoImages,
  reprocess
}
