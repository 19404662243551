<template>
  <div @click="exportCsv">
    <slot name="button"></slot>
  </div>
</template>

<script>
export default {
  props: {
    exportData: {
      type: Array,
      required: true
    }
  },
  methods: {
    exportCsv() {
      let template = Object.keys(this.exportData[0]).join(',') + '\n'
      for (let i = 0; i < this.exportData.length; i++) {
        template += Object.values(this.exportData[i]).join(',') + '\n'
      }
      const anchor = document.createElement('a')
      anchor.href = 'data:text/template;base64,' + btoa(template)
      anchor.target = '_blank'
      anchor.download = 'export_data.csv'
      anchor.click()
    }
  }
}
</script>

<style></style>
