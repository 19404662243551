import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import theme from './theme'
import groups from './groups'
import subjects from './subjects'
import thermo from './thermo'
import tags from './tags'
import stats from './stats'
import profile from './profile'
import thermoImages from './thermo-images'
import snackBar from './snack-bar'
import globalModal from './global-modal'
import tutorial from './tutorial'
import company from './company'
import reportTemplates from './report-templates'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    theme,
    groups,
    subjects,
    thermo,
    tags,
    stats,
    thermoImages,
    profile,
    tutorial,
    globalModal,
    company,
    snackBar,
    reportTemplates
  }
})
