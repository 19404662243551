import { companyService } from '@/services'

export default {
  state: {
    companies: [],
    company: {}
  },
  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload
    },
    SET_COMPANY(state, payload) {
      state.company = payload
    }
  },
  actions: {
    async fetchCompanies({ commit }) {
      const response = await companyService.all()
      if (response.data.length > 0) {
        commit('SET_COMPANIES', response.data)
      } else {
        commit('SET_COMPANIES', [])
      }
    },
    async fetchCompany({ commit }) {
      const response = await companyService.info()
      if (response.data.name !== '') {
        commit('SET_COMPANY', response.data)
      } else {
        commit('SET_COMPANY', {})
      }
    }
  },
  getters: {
    companies: (state) => state.companies,
    company: (state) => state.company
  }
}
