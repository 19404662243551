var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading || _vm.isLoadingContent)?_c('loading-screen'):(_vm.images.length <= 0)?_c('h3',{staticClass:"text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$t('UnexpectedErrorWhileProcessingThermography'))+" ")]):_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"report-tiny-editor"},[_c('tiny-editor',{attrs:{"content":_vm.content,"onChangeContent":_vm.onChangeContent,"addContent":_vm.addContent,"height":"calc(100vh - 65px)"}})],1),_c('div',{staticClass:"menu-report-tiny-editor"},[_c('v-list',[_c('v-list-item-group',_vm._l((_vm.menuTools),function(tool,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.handleMenuToolClick(tool)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(tool.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(tool.text))}})],1)],1)}),1)],1)],1)]),_c('v-dialog',{attrs:{"width":_vm.toolAction == 'addThermographyDelta' ||
      _vm.toolAction == 'addThermographiesWithDelta'
        ? '1200px'
        : '600px'},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"12"}},[(
          _vm.thermography &&
          (_vm.toolAction == 'addThermographyDelta' ||
            _vm.toolAction == 'addThermographiesWithDelta')
        )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Selecione duas termografias com mesma vista para formar o Delta.")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.optionsImages,"label":"Selecione o periodo"},model:{value:(_vm.thermographyId),callback:function ($$v) {_vm.thermographyId=$$v},expression:"thermographyId"}}),(_vm.isLoadingThermographies)?_c('div',[_c('loading-screen-small')],1):(
                _vm.thermography &&
                !_vm.isLoadingThermographies &&
                _vm.stepDelta == 'selectThermos'
              )?_c('div',[_c('v-carousel',{staticClass:"carousel-profile-view",attrs:{"hide-delimiter-background":"","height":"400"},model:{value:(_vm.idxThermoDelta1),callback:function ($$v) {_vm.idxThermoDelta1=$$v},expression:"idxThermoDelta1"}},_vm._l((_vm.images),function(image){return _c('v-carousel-item',{key:'thermo-to-delta-modal-1-' + image.id,attrs:{"onChangeSelected":function () { return _vm.onChangeSelection([]); }}},[_c('div',{staticClass:"carousel-image"},[(image)?_c('profile-view',{attrs:{"refCanvasKelvin":("canvasKelvinReportDelta" + (image.id)),"printCanvas":_vm.isAddingThermographyImage,"data":image.thermoImage.data,"topLeftLabel":("" + (image.labelView)),"metrics":image.metrics.data,"contours":image.contours,"view":image.view,"maxHeight":400,"type":'image',"temps":{
                        min:
                          _vm.themeTypeTemps == 'dynamic'
                            ? image.minTemp
                            : _vm.themeMinTemp,
                        max:
                          _vm.themeTypeTemps == 'dynamic'
                            ? image.maxTemp
                            : _vm.themeMaxTemp
                      },"srcImage":image.src,"activateEditDrawings":"","flipRegisters":image.flip_registers,"segments":image.segments,"showSemaforica":"","defaultPaletteName":_vm.themeDefaultPaletteName,"thermoImageId":image.thermoImage.thermographyId,"hideControls":true}}):_vm._e()],1)])}),1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.optionsImages,"label":"Selecione o periodo"},model:{value:(_vm.thermographyIdDelta2),callback:function ($$v) {_vm.thermographyIdDelta2=$$v},expression:"thermographyIdDelta2"}}),(_vm.isLoadingThermographiesDelta2)?_c('div',[_c('loading-screen-small')],1):(
                _vm.thermography &&
                !_vm.isLoadingThermographiesDelta2 &&
                _vm.stepDelta == 'selectThermos'
              )?_c('div',[_c('v-carousel',{staticClass:"carousel-profile-view",attrs:{"hide-delimiter-background":"","height":"400"},model:{value:(_vm.idxThermoDelta2),callback:function ($$v) {_vm.idxThermoDelta2=$$v},expression:"idxThermoDelta2"}},_vm._l((_vm.imagesDelta2),function(image){return _c('v-carousel-item',{key:'thermo-to-delta-modal-2-' + image.id,attrs:{"onChangeSelected":function () { return _vm.onChangeSelection([]); }}},[_c('div',{staticClass:"carousel-image"},[(image)?_c('profile-view',{attrs:{"refCanvasKelvin":("canvasKelvinReportDelta" + (image.id)),"printCanvas":_vm.isAddingThermographyImage,"data":image.thermoImage.data,"topLeftLabel":("" + (image.labelView)),"metrics":image.metrics.data,"contours":image.contours,"view":image.view,"maxHeight":400,"type":'image',"temps":{
                        min:
                          _vm.themeTypeTemps == 'dynamic'
                            ? image.minTemp
                            : _vm.themeMinTemp,
                        max:
                          _vm.themeTypeTemps == 'dynamic'
                            ? image.maxTemp
                            : _vm.themeMaxTemp
                      },"srcImage":image.src,"activateEditDrawings":"","flipRegisters":image.flip_registers,"segments":image.segments,"showSemaforica":"","defaultPaletteName":_vm.themeDefaultPaletteName,"thermoImageId":image.thermoImage.thermographyId,"hideControls":true}}):_vm._e()],1)])}),1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.imagesDelta2.length > 0 && _vm.stepDelta == 'viewDelta')?_c('div',[_c('div',{staticClass:"carousel-image"},[_c('profile-view',{attrs:{"refCanvasKelvin":"canvasKelvinReportDelta","topLeftLabel":((_vm.$t('DELTA')) + " - " + (_vm.getLabelView(
                    _vm.images[_vm.idxThermoDelta1].view
                  ))),"data":_vm.thermoDeltaDatasArr,"metrics":_vm.metricsDelta,"contours":_vm.images[_vm.idxThermoDelta1].contours,"index":0,"indexTwo":1,"view":_vm.images[_vm.idxThermoDelta1].view,"temps":{
                    min: _vm.themeDeltaMinTemp,
                    max: _vm.themeDeltaMaxTemp
                  },"defaultPaletteName":"Artic","type":"deltaTwoThermos","maxHeight":400,"removeAsymmetryTemps":true,"hideControls":true}})],1)]):_vm._e()]),_c('v-col',[(
                _vm.stepDelta == 'selectThermos' &&
                !(_vm.toolAction == 'addThermographiesWithDelta')
              )?_c('v-btn',{staticClass:"mb-4 mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeStepDelta('viewDelta')}}},[_vm._v("Avançar")]):_vm._e(),(
                _vm.stepDelta == 'viewDelta' &&
                !(_vm.toolAction == 'addThermographiesWithDelta')
              )?_c('v-btn',{staticClass:"mb-4 mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeStepDelta('selectThermos')}}},[_vm._v("Voltar")]):_vm._e(),(
                _vm.stepDelta == 'viewDelta' ||
                _vm.toolAction == 'addThermographiesWithDelta'
              )?_c('v-btn',{staticClass:"mb-4 mr-4",attrs:{"color":"primary"},on:{"click":_vm.addThermographyDeltaToContent}},[_vm._v("Adicionar")]):_vm._e()],1)],1)],1):(
          _vm.thermography &&
          (_vm.toolAction == 'addThermography' ||
            _vm.toolAction == 'addThermographyImage')
        )?_c('div',[_c('v-select',{attrs:{"items":_vm.optionsImages,"label":"Selecione o periodo"},model:{value:(_vm.thermographyId),callback:function ($$v) {_vm.thermographyId=$$v},expression:"thermographyId"}}),(_vm.isLoadingThermographies)?_c('div',[_c('loading-screen-small')],1):(_vm.thermography && !_vm.isLoadingThermographies)?_c('div',[_c('v-carousel',{staticClass:"carousel-profile-view",attrs:{"hide-delimiter-background":"","height":"400"},model:{value:(_vm.idxThermo),callback:function ($$v) {_vm.idxThermo=$$v},expression:"idxThermo"}},_vm._l((_vm.images),function(image){return _c('v-carousel-item',{key:'thermo-modal-' + image.id,attrs:{"onChangeSelected":function () { return _vm.onChangeSelection([]); }}},[_c('div',{staticClass:"carousel-image"},[(image)?_c('profile-view',{attrs:{"refCanvasKelvin":("canvasKelvinReport" + (image.id)),"printCanvas":_vm.isAddingThermographyImage,"data":image.thermoImage.data,"topLeftLabel":("" + (image.labelView)),"metrics":image.metrics.data,"contours":image.contours,"view":image.view,"maxHeight":400,"type":'image',"temps":{
                    min:
                      _vm.themeTypeTemps == 'dynamic'
                        ? image.minTemp
                        : _vm.themeMinTemp,
                    max:
                      _vm.themeTypeTemps == 'dynamic'
                        ? image.maxTemp
                        : _vm.themeMaxTemp
                  },"srcImage":image.src,"activateEditDrawings":"","flipRegisters":image.flip_registers,"segments":image.segments,"showSemaforica":"","defaultPaletteName":_vm.themeDefaultPaletteName,"thermoImageId":image.thermoImage.thermographyId,"hideControls":true}}):_vm._e()],1)])}),1)],1):_vm._e(),_c('v-btn',{staticClass:"my-4",attrs:{"color":"primary"},on:{"click":_vm.addThermographyToContent}},[_vm._v("Adicionar")])],1):_vm._e()])],1),_c('div',{style:({
      height: 0,
      width: 0,
      opacity: 0,
      position: 'fixed',
      right: 0,
      bottom: 0
    })},_vm._l((_vm.images),function(image){return _c('profile-view',{key:image.id,attrs:{"refCanvasKelvin":("canvasKelvinReportHidden" + (image.id)),"printCanvas":true,"data":image.thermoImage.data,"topLeftLabel":("" + (image.labelView)),"metrics":image.metrics.data,"contours":image.contours,"view":image.view,"maxHeight":700,"type":'image',"temps":{
        min: _vm.themeTypeTemps == 'dynamic' ? image.minTemp : _vm.themeMinTemp,
        max: _vm.themeTypeTemps == 'dynamic' ? image.maxTemp : _vm.themeMaxTemp
      },"srcImage":image.src,"activateEditDrawings":"","flipRegisters":image.flip_registers,"segments":image.segments,"showSemaforica":"","defaultPaletteName":image.palette.replace('Palette', ''),"thermoImageId":image.thermoImage.thermographyId,"hideControls":true}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }