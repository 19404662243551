import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import authConfig from './config/auth'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
import { VueMaskDirective } from 'v-mask'
import './plugins/base'

import { Auth0Plugin } from './auth'
import i18n from './i18n'

Vue.directive('mask', VueMaskDirective)

// require styles

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(Auth0Plugin, {
  domain: authConfig.domain,
  clientId: authConfig.clientId,
  audience: authConfig.audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
