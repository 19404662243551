<template>
  <v-app
    v-if="
      canLoad &&
      $auth &&
      !$auth.loading &&
      $auth.isAuthenticated &&
      planType != undefined &&
      planType != 'TRIAL-CANCELED'
    "
    :class="`custom-theme-${theme}`"
  >
    <v-main>
      <toolbar />
      <sidebar />
      <router-view id="app-content" />
      <snack-bar-fixed />
      <global-modal v-if="activeGlobalModal" />
      <tutorial />
    </v-main>
  </v-app>
  <v-app
    v-else-if="planType == 'TRIAL-CANCELED'"
    :class="`custom-theme-${theme}`"
  >
    <toolbar />
    <v-row class="mt-10 pt-10 text-center mb-0">
      <v-col>
        <h2>Você está com problemas de acesso!</h2>
        <p>
          Entre em contato com nossa equipe para mais informações:
          <a href="mailto:contato@rad2.ai">contato@rad2.ai</a>
        </p>
      </v-col>
    </v-row>
  </v-app>
  <v-app :class="`custom-theme-${theme}`" v-else>
    <loading-screen />
  </v-app>
</template>

<script>
import LoadingScreen from './components/LoadingScreen.vue'
import Sidebar from './components/Sidebar.vue'
import Toolbar from './components/Toolbar.vue'
import SnackBarFixed from './components/SnackBarFixed.vue'
import GlobalModal from './components/GlobalModal.vue'
import Tutorial from './components/Tutorial/Tutorial.vue'

export default {
  components: {
    LoadingScreen,
    Toolbar,
    Sidebar,
    SnackBarFixed,
    GlobalModal,
    Tutorial
  },
  name: 'App',

  data: function () {
    return {
      activeGlobalModal: false,
      loading: this.$auth.loading,
      canLoad: false
    }
  },
  watch: {
    loading: async function (val) {
      if (!val) {
        await this.verifyLogin()
      }
    },
    $route() {
      if (this.globalModal.showNextPage && this.globalModal.component !== '')
        this.$store.dispatch('setGlobalModal', {
          component: this.globalModal.component,
          showNextPage: false,
          model: true
        })
    }
  },
  methods: {
    async verifyLogin() {
      if (!this.$auth.loading) {
        if (this.$auth.isAuthenticated) {
          const token = await this.$auth.getTokenSilently()
          this.$store.dispatch('setTokens', { accessToken: token })
          this.startUpConfigs()
        } else {
          this.$auth.loginWithRedirect()
        }
        this.canLoad = true
      } else {
        setTimeout(this.verifyLogin, 1000)
      }
    },
    startUpConfigs() {
      const theme = window.localStorage.getItem('theme')
      if (theme != undefined) this.$store.dispatch('setTheme', { theme: theme })
      else {
        window.localStorage.setItem('theme', this.$store.getters.theme)
      }
      const themeTypeTemps = window.localStorage.getItem('themeTypeTemps')
      if (themeTypeTemps != undefined)
        this.$store.dispatch('setThemeTypeTemps', {
          themeTypeTemps: themeTypeTemps
        })
      else {
        window.localStorage.setItem(
          'themeTypeTemps',
          this.$store.getters.themeTypeTemps
        )
      }
      const themeDefaultPaletteName = window.localStorage.getItem(
        'themeDefaultPaletteName'
      )
      if (themeDefaultPaletteName != undefined)
        this.$store.dispatch('setThemeDefaultPaletteName', {
          themeDefaultPaletteName: themeDefaultPaletteName
        })
      else {
        window.localStorage.setItem(
          'themeDefaultPaletteName',
          this.$store.getters.themeDefaultPaletteName
        )
      }
      const themeMinTemp = window.localStorage.getItem('themeMinTemp')
      if (themeMinTemp != undefined)
        this.$store.dispatch('setThemeMinTemp', { themeMinTemp: themeMinTemp })
      else {
        window.localStorage.setItem(
          'themeMinTemp',
          this.$store.getters.themeMinTemp
        )
      }
      const themeMaxTemp = window.localStorage.getItem('themeMaxTemp')
      if (themeMaxTemp != undefined)
        this.$store.dispatch('setThemeMaxTemp', { themeMaxTemp: themeMaxTemp })
      else {
        window.localStorage.setItem(
          'themeMaxTemp',
          this.$store.getters.themeMaxTemp
        )
      }

      const themeScoreZMinTemp =
        window.localStorage.getItem('themeScoreZMinTemp')
      if (themeScoreZMinTemp != undefined)
        this.$store.dispatch('setThemeScoreZMinTemp', {
          themeScoreZMinTemp: themeScoreZMinTemp
        })
      else {
        window.localStorage.setItem(
          'themeScoreZMinTemp',
          this.$store.getters.themeScoreZMinTemp
        )
      }
      const themeScoreZMaxTemp =
        window.localStorage.getItem('themeScoreZMaxTemp')
      if (themeScoreZMaxTemp != undefined)
        this.$store.dispatch('setThemeScoreZMaxTemp', {
          themeScoreZMaxTemp: themeScoreZMaxTemp
        })
      else {
        window.localStorage.setItem(
          'themeScoreZMaxTemp',
          this.$store.getters.themeScoreZMaxTemp
        )
      }

      const themeDeltaMinTemp = window.localStorage.getItem('themeDeltaMinTemp')
      if (themeDeltaMinTemp != undefined)
        this.$store.dispatch('setThemeDeltaMinTemp', {
          themeDeltaMinTemp: themeDeltaMinTemp
        })
      else {
        window.localStorage.setItem(
          'themeDeltaMinTemp',
          this.$store.getters.themeDeltaMinTemp
        )
      }
      const themeDeltaMaxTemp = window.localStorage.getItem('themeDeltaMaxTemp')
      if (themeDeltaMaxTemp != undefined)
        this.$store.dispatch('setThemeDeltaMaxTemp', {
          themeDeltaMaxTemp: themeDeltaMaxTemp
        })
      else {
        window.localStorage.setItem(
          'themeDeltaMaxTemp',
          this.$store.getters.themeDeltaMaxTemp
        )
      }

      const themeNewThermoMinTemp = window.localStorage.getItem(
        'themeNewThermoMinTemp'
      )
      if (themeNewThermoMinTemp != undefined)
        this.$store.dispatch('setThemeNewThermoMinTemp', {
          themeNewThermoMinTemp: themeNewThermoMinTemp
        })
      else {
        window.localStorage.setItem(
          'themeNewThermoMinTemp',
          this.$store.getters.themeNewThermoMinTemp
        )
      }
      const themeNewThermoMaxTemp = window.localStorage.getItem(
        'themeNewThermoMaxTemp'
      )
      if (themeNewThermoMaxTemp != undefined)
        this.$store.dispatch('setThemeNewThermoMaxTemp', {
          themeNewThermoMaxTemp: themeNewThermoMaxTemp
        })
      else {
        window.localStorage.setItem(
          'themeNewThermoMaxTemp',
          this.$store.getters.themeNewThermoMaxTemp
        )
      }

      const themeRoiTempMinPerc = window.localStorage.getItem(
        'themeRoiTempMinPerc'
      )
      if (themeRoiTempMinPerc != undefined)
        this.$store.dispatch('setThemeRoiTempMinPerc', {
          themeRoiTempMinPerc: themeRoiTempMinPerc
        })
      else {
        window.localStorage.setItem(
          'themeRoiTempMinPerc',
          this.$store.getters.themeRoiTempMinPerc
        )
      }
      const themeRoiTempMaxPerc = window.localStorage.getItem(
        'themeRoiTempMaxPerc'
      )
      if (themeRoiTempMaxPerc != undefined)
        this.$store.dispatch('setThemeRoiTempMaxPerc', {
          themeRoiTempMaxPerc: themeRoiTempMaxPerc
        })
      else {
        window.localStorage.setItem(
          'themeRoiTempMaxPerc',
          this.$store.getters.themeRoiTempMaxPerc
        )
      }
      const themeRoiTempMeanPerc = window.localStorage.getItem(
        'themeRoiTempMeanPerc'
      )
      if (themeRoiTempMeanPerc != undefined)
        this.$store.dispatch('setThemeRoiTempMeanPerc', {
          themeRoiTempMeanPerc: themeRoiTempMeanPerc
        })
      else {
        window.localStorage.setItem(
          'themeRoiTempMeanPerc',
          this.$store.getters.themeRoiTempMeanPerc
        )
      }
    }
  },
  async mounted() {
    await this.verifyLogin()
  },
  computed: {
    globalModal() {
      return this.$store.getters.globalModal
    },
    planType() {
      return this.$store.getters.planType
    },
    theme() {
      return this.$store.getters.theme
    }
  }
}
</script>

<style lang="scss">
.v-main {
  padding-left: 60px !important;
}
.v-list-item,
.v-list-item__title {
  font-weight: 400 !important;
}
.custom-theme-black {
  --main-bg-color: #292929;
  --main-bg-color-darker: #232323;
  --main-bg-color-hover: #666666;
  --main-text-color: #cfcfcf;

  .v-toolbar__content,
  .v-navigation-drawer {
    background-color: var(--main-bg-color-darker) !important;
    color: var(--main-text-color) !important;
    .v-list {
      background-color: var(--main-bg-color-darker) !important;
    }
  }
  #app-content,
  .v-card--material,
  .v-main__wrap,
  .v-card,
  .v-data-table {
    background-color: var(--main-bg-color) !important;
    color: var(--main-text-color) !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--main-bg-color-hover) !important;
  }
  .v-data-table th {
    color: var(--main-text-color) !important;
  }

  .apexcharts-text,
  .apexcharts-legend-text {
    color: var(--main-text-color) !important;
    fill: var(--main-text-color) !important;
  }

  .content-text {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      var(--main-bg-color) 30%,
      var(--main-bg-color) 100%
    ) !important;
  }
  .card-people-item {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      var(--main-bg-color) 100%
    ) !important;
  }

  #app-content {
    .display-2,
    .v-icon,
    .text--secondary,
    .v-data-table-header span {
      color: var(--main-text-color) !important;
    }
  }
  .v-btn.theme--light:not(.primary) {
    background-color: var(--main-bg-color) !important;
    color: var(--main-text-color) !important;
  }

  .name-player {
    color: var(--main-text-color) !important;
  }
  .name-player {
    filter: none !important;
  }
  .v-data-table span.v-chip__content,
  .v-data-table span.v-chip,
  .label-group,
  .label-thermographies-number {
    color: var(--main-bg-color) !important;
    background-color: var(--main-text-color) !important;
  }

  .v-input input::placeholder,
  .v-input input,
  .v-input fieldset {
    background-color: var(--main-bg-color) !important;
    color: var(--main-text-color) !important;
    border-color: var(--main-text-color) !important;
  }
  .v-input * {
    color: var(--main-text-color) !important;
    caret-color: var(--main-text-color) !important;
  }
  .v-input .v-label {
    color: var(--main-text-color) !important;
  }
  .v-input {
    .v-chip {
      background-color: var(--v-primary-base) !important;
      color: var(--main-text-color) !important;
    }
  }
  .v-list {
    background-color: var(--main-bg-color) !important;
    color: var(--main-text-color) !important;
    .v-icon {
      color: var(--main-text-color) !important;
    }
  }

  .v-list-item {
    color: var(--main-text-color) !important;
  }

  .v-dialog .white {
    background-color: var(--main-bg-color) !important;
    color: var(--main-text-color) !important;
  }
  .v-dialog .options {
    background-color: var(--main-bg-color) !important;
    color: var(--main-text-color) !important;
  }
}

.v-carousel__controls {
  bottom: 30px !important;
}
</style>
