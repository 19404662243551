import { thermoService } from '@/services'

export default {
  state: { thermoImages: [] },
  mutations: {
    SET_THERMO_IMAGES(state, payload) {
      state.thermoImages = payload
    }
  },
  actions: {
    async fetchThermoImages({ commit }, payload) {
      const response = await thermoService.getThermoImages(payload)
      commit('SET_THERMO_IMAGES', response.data)
    }
  },
  getters: {
    thermoImagesGet: (state) => state.thermoImages
  }
}
