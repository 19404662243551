<template>
  <div
    class="container-delta"
    @mouseover="hideControls ? (hideControlsInside = false) : null"
    @mouseleave="hideControls ? (hideControlsInside = true) : null"
  >
    <label
      class="top-left-label z-index-5"
      :style="{ fontSize: this.$vuetify.breakpoint.lgAndUp ? '14px' : null }"
      v-if="topLeftLabel != ''"
    >
      {{ topLeftLabel }}
    </label>
    <label
      class="top-left-label-b z-index-5"
      :style="{ fontSize: this.$vuetify.breakpoint.lgAndUp ? '14px' : null }"
      v-if="topLeftLabelB != ''"
    >
      {{ topLeftLabelB }}
    </label>
    <div
      class="left-bar-gray-shadow z-index-1"
      v-if="!hideControlsInside || optionsOpen"
    ></div>
    <div
      class="top-bar-gray-shadow z-index-1"
      v-if="!hideControlsInside || optionsOpen"
    ></div>
    <div
      class="right-bar-gray-shadow z-index-1"
      v-if="!hideControlsInside || optionsOpen"
    ></div>
    <div
      class="down-bar-gray-shadow z-index-1"
      v-if="!hideControlsInside || optionsOpen"
    ></div>
    <div
      class="down-tools z-index-5"
      style="justify-content: center; display: flex"
      v-if="!hideControlsInside || optionsOpen"
    >
      <v-btn
        icon
        color="primary"
        @click="modalPainSelection = true"
        class="modal-pain-body"
        v-if="(!hideControlsInside || optionsOpen) && partsPainSelected != null"
      >
        <v-icon>mdi-account-heart</v-icon>
      </v-btn>

      <v-btn icon color="primary" @click="changeSemaforico">
        <v-icon>mdi-flashlight</v-icon>
      </v-btn>
      <v-btn icon color="primary" @click="optionsOpen = !optionsOpen">
        <v-icon>mdi-palette</v-icon>
      </v-btn>
      <v-btn icon color="primary" @click="resetProfileView">
        <v-icon>mdi-lock-reset</v-icon>
      </v-btn>
      <v-btn icon color="primary" @click="changeImage">
        <v-icon>mdi-camera-front-variant</v-icon>
      </v-btn>

      <v-btn
        v-if="anamnese != null"
        icon
        color="primary"
        @click="modalAnamnese = !modalAnamnese"
      >
        <v-icon>mdi-account-injury</v-icon>
      </v-btn>
    </div>
    <div
      :class="{ options: true, hidden: !optionsOpen }"
      v-if="!hideControlsInside || optionsOpen"
    >
      <v-select
        :items="paletteImages"
        v-model="paletteName"
        outlined
        hide-details
        :label="$t('Palette')"
        color="primary"
        @change="changePalette"
        dense
      ></v-select>
    </div>
    <div
      v-if="onClickOpenModal != undefined && !hideControlsInside"
      class="zoom-canvas"
    >
      <v-btn
        icon
        color="primary"
        @click="onClickOpenModal()"
        class="zoom-in-kelvin-canvas"
      >
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
    </div>
    <div
      v-if="onClickCloseModal != undefined && !hideControlsInside"
      class="zoom-canvas"
    >
      <v-btn
        icon
        color="primary"
        @click="onClickCloseModal()"
        class="zoom-out-kelvin-canvas"
      >
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
    </div>

    <canvas style="display: none" id="display"></canvas>

    <v-row v-if="loading" justify="center" class="ma-0 mt-3 z-index-5">
      <loading-screen-small />
    </v-row>
    <v-row justify="center" class="ma-0">
      <kelvin-canvas
        v-show="viewType === 'Processada'"
        :key="`thermo${keyKelvinCanvas}`"
        :usedTemps="usedTemps"
        :ref="`kelvinCanvas${refCanvasKelvin}`"
        :refCanvasKelvin="refCanvasKelvin"
        :activateEditDrawings="activateEditDrawings"
        :comparType="selectedComparType"
        :imageUrl="blobUrl"
        :view="view"
        canResize
        :maxHeight="this.maxHeight - 72"
        :meta="contours.meta"
        :selectedContours="selectedContours"
        :data="contours.data || {}"
        :dataArray="dataArray"
        :metrics="metrics"
        :onChangeSelected="onChangeSelection"
        :activeTempMetrics="activeTempMetrics"
        :onChangeActiveTempMetrics="onChangeActiveTempMetrics"
        :onChangeActiveTempMetricsObject="onChangeActiveTempMetricsObject"
        :removeAsymmetryTemps="removeAsymmetryTemps"
        :hideControls="hideControlsInside"
        :printCanvas="printCanvas"
        :topLeftLabel="topLeftLabel"
        :topLeftLabelB="topLeftLabelB"
        :thermoImageId="thermoImageId"
      />
      <kelvin-canvas
        v-show="viewType === 'Semafórica'"
        :key="`semaforica${keyKelvinCanvas}`"
        :usedTemps="usedTemps"
        :refCanvasKelvin="refCanvasKelvin"
        :dataArray="dataArray"
        :activateEditDrawings="activateEditDrawings"
        :imageUrl="blobUrlSemaforico"
        :view="view"
        :meta="contours.meta"
        canResize
        :maxHeight="this.maxHeight - 72"
        :showContours="false"
        :removeAsymmetryTemps="removeAsymmetryTemps"
        :hideControls="hideControlsInside"
        :printCanvas="printCanvas"
        :topLeftLabel="topLeftLabel"
        :topLeftLabelB="topLeftLabelB"
        :thermoImageId="thermoImageId"
      />
      <kelvin-canvas
        v-show="viewType === 'Original'"
        :key="`default${keyKelvinCanvas}`"
        :usedTemps="usedTemps"
        :refCanvasKelvin="refCanvasKelvin"
        :activateEditDrawings="activateEditDrawings"
        :comparType="selectedComparType"
        :imageUrl="srcImage"
        :originalImageUrlBlob="blobUrl"
        :view="view"
        canResize
        :maxHeight="this.maxHeight - 72"
        :showContours="false"
        :meta="contours.meta"
        :selectedContours="selectedContours"
        :data="contours.data || {}"
        :dataArray="dataArray"
        :metrics="metrics"
        :onChangeSelected="onChangeSelection"
        :activeTempMetrics="activeTempMetrics"
        :onChangeActiveTempMetrics="onChangeActiveTempMetrics"
        :onChangeActiveTempMetricsObject="onChangeActiveTempMetricsObject"
        :removeAsymmetryTemps="removeAsymmetryTemps"
        :hideControls="hideControlsInside"
        :printCanvas="printCanvas"
        :topLeftLabel="topLeftLabel"
        :topLeftLabelB="topLeftLabelB"
        :thermoImageId="thermoImageId"
      />
    </v-row>
    <div
      class="range-input range-input-profile-view"
      v-show="(viewType === 'Processada' && !hideControlsInside) || optionsOpen"
    >
      <div>
        <v-text-field
          type="number"
          class="temp-range-input"
          outlined
          @blur="displayCanvas(paletteName)"
          dense
          color="white"
          @keydown.enter="displayCanvas(paletteName)"
          v-model="usedTemps.max"
          dark
          hide-details
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          type="number"
          class="temp-range-input"
          outlined
          dense
          @blur="displayCanvas(paletteName)"
          @keydown.enter="displayCanvas(paletteName)"
          color="white"
          v-model="usedTemps.min"
          dark
          hide-details
        ></v-text-field>
      </div>
      <div class="div-range-slider">
        <v-range-slider
          v-model="rangeModel"
          :min="rangeMin"
          :max="rangeMax"
          :step="0.01"
          @end="() => this.displayCanvas(this.paletteName)"
          color="#C8C8C8"
          vertical
          hide-details
          class="range-slider"
        />
      </div>
    </div>
    <div class="watermark-canvas">
      <img src="@/assets/logo-icon-kelvin-white.png" alt="" />
    </div>

    <v-dialog
      v-model="modalPainSelection"
      max-width="300px"
      v-if="partsPainSelected != null"
    >
      <v-card class="px-8 py-6">
        <v-row>
          <v-col cols="12">
            <label>Dores relatadas</label>
          </v-col>
          <v-col cols="12">
            <div class="p-relative p-r-40px">
              <vue-full-body-part-selector
                :disabled="true"
                :contoursType="true"
                :multiple="false"
                :parts-colour="partsColourFullBody"
                :body-colour="painColors[0]"
                :selection-colour="painColors[0]"
                :viewType="view"
                :contourJson="contoursJsonPain[view]"
              />
              <div class="paleta-cores">
                <label
                  v-for="(pain, key) in painColors"
                  :key="`cor-${(key - 10) * -1}`"
                  :style="{
                    background: painColors[(key - 10) * -1]
                  }"
                >
                  {{ (key - 10) * -1 }}
                </label>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="text-right mb-0 pb-0">
            <v-btn
              color="primary"
              style="width: 150px"
              @click="modalPainSelection = false"
              >{{ $t('Fechar') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalAnamnese" :width="500" v-if="anamnese != null">
      <div class="pa-6 white">
        <p>{{ $t('Anamnese') }}:</p>
        <p>{{ anamnese }}</p>
        <div class="text-right">
          <v-btn text color="primary" @click="modalAnamnese = false">
            Fechar
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import * as tf from '@tensorflow/tfjs'
import palettes from '@/assets/palettes.json'
import KelvinCanvas from './KelvinCanvas.vue'
import LoadingScreenSmall from './LoadingScreenSmall.vue'
import VueFullBodyPartSelector from '../components/VueFullBodyPartSelector.vue'
import ContourSuperiorPosterior from '@/assets/contour-superiorPosterior.json'
import ContourSuperiorAnterior from '@/assets/contour-superiorAnterior.json'
import ContourInferiorPosterior from '@/assets/contour-inferiorPosterior.json'
import ContourInferiorAnterior from '@/assets/contour-inferiorAnterior.json'
import ContourLateralEsquerda from '@/assets/contour-lateralEsquerda.json'
import ContourLateralDireita from '@/assets/contour-lateralDireita.json'
import FullBodyPosterior from '@/assets/full-body-posterior.json'
import FullBodyAnterior from '@/assets/full-body-anterior.json'
import ContourSuperiorInferiorPosterior from '@/assets/contour-superiorInferiorPosterior.json'
import ContourSuperiorInferiorAnterior from '@/assets/contour-superiorInferiorAnterior.json'
const keys = Object.keys(palettes)
export default {
  components: { KelvinCanvas, LoadingScreenSmall, VueFullBodyPartSelector },
  data: () => ({
    modalAnamnese: false,
    hideControlsInside: false,
    contoursJsonPain: {
      superiorPosterior: ContourSuperiorPosterior,
      superiorAnterior: ContourSuperiorAnterior,
      inferiorPosterior: ContourInferiorPosterior,
      inferiorAnterior: ContourInferiorAnterior,
      lateral_left: ContourLateralEsquerda,
      lateral_right: ContourLateralDireita,
      others: {
        superiorInferiorPosterior: ContourSuperiorInferiorPosterior,
        superiorInferiorAnterior: ContourSuperiorInferiorAnterior
      }
    },
    painColors: {
      0: '#4DC81F',
      1: '#67C621',
      2: '#7CCA09',
      3: '#C1CA06',
      4: '#FFCC00',
      5: '#FEB400',
      6: '#FF9800',
      7: '#FA8009',
      8: '#FA6815',
      9: '#F84A15',
      10: '#F22A22'
    },
    contoursBodyPain: {
      superiorAnterior: ContourSuperiorAnterior,
      superiorPosterior: ContourSuperiorPosterior,
      lateral_right: ContourLateralDireita,
      lateral_left: ContourLateralEsquerda,
      inferiorAnterior: ContourInferiorAnterior,
      inferiorPosterior: ContourInferiorPosterior
    },
    fullBody: {
      fullBodyPosterior: FullBodyPosterior,
      fullBodyAnterior: FullBodyAnterior
    },
    modalPainSelection: false,
    dataArray: [],
    keyKelvinCanvas: 0,
    base64Image: '',
    paletteName: 'RainbowLC',
    blobUrl: '',
    blobUrlSemaforico: '',
    usedTemps: {
      max: 36,
      min: 22
    },
    loading: false,
    optionsOpen: false,
    selectedComparType: 'mean',
    viewType: 'Processada',
    itemsViewType: ['Processada', 'Semafórica', 'Original'],
    defaultsProfileView: {
      paletteName: '',
      usedTemps: '',
      viewType: ''
    },
    rangeModel: [20, 40],
    rangeMin: 20,
    rangeMax: 40
  }),
  props: {
    partsPainSelected: {
      type: Object,
      default: null
    },
    printCanvas: {
      type: Boolean,
      deafult: false
    },
    refCanvasKelvin: {
      type: String,
      required: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    showSemaforica: {
      type: Boolean,
      default: false
    },
    flipRegisters: {
      type: Object
    },
    segments: {
      type: Object
    },
    removeAsymmetryTemps: {
      type: Boolean,
      default: false
    },
    activeTempMetrics: {
      type: Number,
      default: 0
    },
    onChangeActiveTempMetrics: {
      type: Function,
      default() {
        return () => null
      }
    },
    onChangeActiveTempMetricsObject: {
      type: Function,
      default() {
        return () => null
      }
    },
    activateEditDrawings: {
      type: Boolean,
      default: false
    },
    propComparType: {
      type: String,
      default: 'mean'
    },
    topLeftLabel: {
      type: String,
      default: ''
    },
    topLeftLabelB: {
      type: String,
      default: ''
    },
    onClickOpenModal: Function,
    onClickCloseModal: Function,
    defaultPaletteName: String,
    data: {
      type: Array,
      default() {
        return []
      }
    },
    contours: {
      type: Object,
      default() {
        return {}
      }
    },
    metrics: {
      type: Object,
      default() {
        return {}
      }
    },
    view: {
      type: String,
      required: true
    },
    temps: {
      type: Object,
      required: false
    },
    index: {
      type: Number,
      default: 0
    },
    indexTwo: {
      type: Number,
      default: 0
    },
    selectedContours: {
      type: Array,
      default() {
        return []
      }
    },
    onChangeSelection: {
      type: Function,
      default() {
        return () => {}
      }
    },
    onChangeComparType: {
      type: Function,
      default() {
        return () => {}
      }
    },
    type: {
      type: String,
      required: true
    },
    srcImage: {
      type: String,
      required: false
    },
    maxHeight: {
      type: Number,
      default: 448
    },
    anamnese: {
      type: String,
      required: false
    },
    thermoImageId: {
      type: String,
      required: false
    }
  },
  async mounted() {
    this.hideControlsInside = this.hideControls
    this.dataArray = this.data
    if (this.srcImage === undefined) this.itemsViewType.splice(2, 1)
    if (!this.showSemaforica) this.itemsViewType.splice(1, 1)
    this.selectedComparType = this.propComparType
    this.loading = true
    if (this.temps) {
      this.usedTemps = this.temps
      this.rangeMin = this.temps.min
      this.rangeMax = this.temps.max
      this.rangeModel = [this.temps.min, this.temps.max]
    }
    this.paletteName = this.defaultPaletteName || this.paletteName
    await this.displayCanvas(this.paletteName)
    this.loading = false

    this.defaultsProfileView.paletteName = this.paletteName
    this.defaultsProfileView.viewType = this.viewType
    this.defaultsProfileView.usedTemps = { ...this.usedTemps }

    if (this.showSemaforica) await this.displayCanvasSemaforico()
    if (this.showSemaforica && this.blobUrlSemaforico === '')
      this.itemsViewType.splice(1, 1)
  },
  computed: {
    partsColourFullBody() {
      const partsColour = {}
      this.painSelected // to update this computed variable it needs to listen to this variable
      if (this.view == '' || this.view == 'face') return partsColour
      let contourJson = this.contoursJsonPain[this.view].data

      if (this.view == 'others') {
        contourJson = {
          ...this.contoursJsonPain[this.view].superiorInferiorPosterior.data,
          ...this.contoursJsonPain[this.view].superiorInferiorAnterior.data
        }
      }
      for (const [partName] of Object.entries(contourJson)) {
        partsColour[partName] =
          this.painColors[this.partsPainSelected[partName]]
      }
      return partsColour
    },
    selectedContourBodyPain() {
      return this.contoursBodyPain[this.view]
    },
    partsColour() {
      const partsColour = {}
      Object.keys(this.partsPainSelected).forEach((part) => {
        partsColour[part] = this.painColors[this.partsPainSelected[part]]
      })
      return partsColour
    },
    paletteImages() {
      return keys
    }
  },
  watch: {
    maxHeight() {
      this.keyKelvinCanvas += 1
    },
    index() {
      this.displayCanvas(this.paletteName)
    },
    selectedComparType(val) {
      this.onChangeComparType(val)
    },
    propComparType(val) {
      this.selectedComparType = val
    },
    rangeModel(val) {
      this.usedTemps = {
        min: val[0],
        max: val[1]
      }
    },
    contours() {
      this.displayCanvas(this.paletteName)
      this.displayCanvasSemaforico()
    }
  },
  methods: {
    changeSemaforico() {
      this.viewType =
        this.viewType == 'Processada' ? 'Semafórica' : 'Processada'
    },
    changeImage() {
      this.viewType = this.viewType == 'Processada' ? 'Original' : 'Processada'
    },
    resetProfileView() {
      this.paletteName = this.defaultsProfileView.paletteName
      this.viewType = this.defaultsProfileView.viewType
      this.usedTemps = { ...this.defaultsProfileView.usedTemps }
      this.selectedComparType = 'mean'
      this.displayCanvas(this.paletteName)
    },
    async changePalette(key) {
      await this.displayCanvas(key)
    },
    async b64toBlob(b64) {
      const b64Response = await fetch(b64)
      const blob = await b64Response.blob()
      const blobUrl = URL.createObjectURL(blob)
      return blobUrl
    },

    ignoreRegion(image, seg, region, value) {
      // Create balack image with same sahpe as image
      const zeros = tf.zerosLike(image).add(value)
      const mask = seg.notEqual(region)
      return image.where(mask, zeros)
    },
    createCustomPalette() {
      const red = tf.tensor([[255, 0, 0]])
      const yellow = tf.tensor([[255, 255, 0]])
      const green = tf.tensor([[0, 255, 0]])

      const customPalette = tf.concat([green, yellow.tile([50, 1]), red], 0)

      return customPalette
    },
    removeBG(image, seg) {
      // Create balack image with same sahpe as image
      const black = tf.concat(
        [
          tf.zeros(image.shape.slice(0, -1)).expandDims(2).tile([1, 1, 3]),
          tf.zeros(image.shape.slice(0, -1)).add(240).expandDims(2)
        ],
        2
      )

      const BG = 0
      const mask = seg.notEqual(BG).expandDims(2).tile([1, 1, 4])
      return image.where(mask, black)
    },
    async displayCanvasSemaforico() {
      if (
        this.flipRegisters === undefined ||
        this.flipRegisters === null ||
        this.segments === undefined ||
        this.segments === null ||
        this.data === null ||
        this.data === undefined
      )
        return null

      const palette = tf.tensor(palettes.StopLight)
      const customPalette = this.createCustomPalette()

      const curData = tf.tensor(this.data)
      const flipReg = tf.tensor(this.flipRegisters.data)
      const seg = tf.tensor(this.segments.data)

      let display = curData.sub(flipReg)
      display = display.clipByValue(0, display.max().dataSync()[0])
      display = this.ignoreRegion(display, seg, 1, 0)

      let transformed = this.applyPalette(display, customPalette, {
        tempMin: 0.4,
        tempMax: 0.9
      })

      transformed = this.removeBG(transformed, seg)

      const rgbaPalette = this.createPaletteRGBA(palette, [
        Math.ceil(transformed.shape[0] * 0.85),
        Math.ceil(transformed.shape[1] * 0.03)
      ])

      await this.putTensorOnCanvas(
        transformed,
        rgbaPalette,
        'blobUrlSemaforico'
      )
    },
    async displayCanvas(key) {
      if (this.data === undefined || this.data === null) return null

      const palette = tf.tensor(palettes[key])
      let stacked = tf.tensor(this.data)
      const temps = {
        max: parseFloat(`${this.usedTemps.max}`),
        min: parseFloat(`${this.usedTemps.min}`)
      }
      let transformed = null

      const getTfByType = {}
      getTfByType.image = (tf) => tf
      getTfByType.profile = (tf) => tf.mean(0)
      getTfByType.delta = (tf) =>
        tf.gather(this.index).sub(getTfByType.profile(tf))
      getTfByType.deltaTwoThermos = (tf) =>
        tf.gather(this.index).sub(tf.gather(this.indexTwo))
      getTfByType.stdDeviation = (tf) =>
        tf.sub(getTfByType.profile(tf)).square(tf).mean(0).sqrt(tf)
      getTfByType.scoreZ = (tf) =>
        tf
          .gather(this.index)
          .sub(getTfByType.profile(tf))
          .div(getTfByType.stdDeviation(tf))

      if (Object.keys(getTfByType).includes(this.type))
        stacked = getTfByType[this.type](stacked)

      this.dataArray = await stacked.array()
      transformed = this.applyPalette(stacked, palette, {
        tempMin: temps.min,
        tempMax: temps.max
      })

      const rgbaPalette = this.createPaletteRGBA(palette, [
        Math.ceil(transformed.shape[0] * 0.85),
        Math.ceil(transformed.shape[1] * 0.03)
      ])

      await this.putTensorOnCanvas(transformed, rgbaPalette)
    },
    createPaletteRGBA(palette, shape) {
      palette = palette.reverse(0).expandDims(1)
      palette = tf.image.resizeBilinear(palette, shape) // Problema here MAYBE?

      // Add transparency channel
      const a_channel = tf
        .zeros([palette.shape[0], palette.shape[1], 1])
        .add(255)
      palette = tf.concat([palette, a_channel], 2)

      return palette
    },
    applyPalette(temps, palette, params = {}) {
      // Get temperature ranges
      const tempMin = params.tempMin ? tf.tensor(params.tempMin) : temps.min()
      const tempMax = params.tempMax ? tf.tensor(params.tempMax) : temps.max()
      const NUM_STEPS = palette.shape[0] - 1
      //Normalize
      temps = temps.sub(tempMin)
      temps = temps.div(tempMax.sub(tempMin))
      temps = temps.mul(NUM_STEPS)
      temps = temps.clipByValue(0, NUM_STEPS)
      temps = tf.cast(temps, 'int32')

      // Extract RGB colors from palette
      temps = palette.gather(temps)

      // Add transparency channel
      const a_channel = tf.zeros([temps.shape[0], temps.shape[1], 1]).add(255)

      temps = tf.concat([temps, a_channel], 2)

      return temps
    },
    async putTensorOnCanvas(tensor, palette, blobUrl = 'blobUrl') {
      const canvas = document.querySelector('#display')
      const ctx = canvas.getContext('2d')

      canvas.height = tensor.shape[0]
      canvas.width = tensor.shape[1]

      const imgData = new ImageData(
        Uint8ClampedArray.from(tensor.dataSync()),
        canvas.width,
        canvas.height
      )
      const paletteData = new ImageData(
        Uint8ClampedArray.from(palette.dataSync()),
        palette.shape[1],
        palette.shape[0]
      )

      ctx.putImageData(imgData, 0, 0)
      ctx.putImageData(
        paletteData,
        Math.ceil(canvas.width * 0.9),
        Math.ceil(canvas.height * 0.075)
      )
      this.base64Image = canvas.toDataURL()
      this[blobUrl] = await this.b64toBlob(canvas.toDataURL())
    }
  }
}
</script>

<style lang="scss">
.z-index-5 {
  z-index: 5;
}
.z-index-1 {
  z-index: 1;
}
.left-bar-gray-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  background-color: #00000050;
}
.top-bar-gray-shadow {
  position: absolute;
  left: 40px;
  top: 0;
  width: calc(100% - 90px);
  height: 40px;
  background-color: #00000050;
}
.right-bar-gray-shadow {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  background-color: #00000050;
}
.down-bar-gray-shadow {
  position: absolute;
  left: 40px;
  bottom: 0;
  width: calc(100% - 90px);
  height: 30px;
  background-color: #00000050;
}
.down-tools {
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 30px;
  color: rgb(200, 200, 200) !important;
  font-size: 24px !important;
}
.down-tools .v-icon,
.down-tools .v-btn,
div-btn-pain-body .v-btn,
div-btn-pain-body .v-icon {
  color: rgb(200, 200, 200) !important;
  font-size: 24px !important;
  margin: 0 5px;
}
.down-tools .v-btn,
.div-btn-pain-body .v-btn {
  width: 26px !important;
  height: 26px !important;
  padding-left: 10px;
}
.p-r-40px {
  padding-right: 40px;
}
.p-relative {
  position: relative;
}
.paleta-cores {
  position: absolute;
  right: 0;
  top: 0;
  top: calc(50% - 176px);
  width: 26px;
  height: 352px;
  padding: 0 6px;

  label {
    color: white;
    font-size: 16px;
    display: flex;
    width: 26px;
    margin-bottom: 6px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 9000px;
  }
}
.temp-range-input {
  background: rgba(0, 0, 0, 0.6);
  width: 32px;
  font-size: 12px;
  color: white;
  .v-input__slot {
    padding: 0 4px !important;
    height: 30px !important;
    min-height: 30px !important;
  }
  .v-text-field__slot {
    height: 30px !important;
  }
  input {
    padding: 0 !important;
    text-align: center !important;
  }
  * {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.div-range-slider {
  position: absolute;
  height: calc(100% - 100px);
  right: 0;
  width: 100%;
  top: 50px;

  .range-slider,
  .range-slider .v-slider,
  .range-slider .v-input__control,
  .range-slider .v-input__slot {
    height: 100%;
  }
}
</style>

<style scoped lang="scss">
.options {
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  bottom: 30px;
  left: 40px;
  width: 170px;
  background: #fff;
  z-index: 11;
}
.hidden {
  transform: translateX(-220px);
}
.delta-image {
  width: 100%;
  height: calc(100% - 48px);
  object-fit: contain;
  overflow: hidden;
}
.container-delta {
  width: 100%;
  height: 100%;
  padding: 40px 50px 30px 40px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background-color: #000344;
}
.controls {
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 4;
}
.controls,
.controls .v-icon,
.controls .v-btn {
  color: rgb(200, 200, 200) !important;
}
.range-input {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 5px 0;
  right: 5px;
  z-index: 4;
}
.zoom-canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
}
.zoom-canvas .v-icon {
  color: rgb(200, 200, 200);
}
.top-left-label,
.top-left-label-b {
  position: absolute;
  top: 0;
  font-size: 24px;
  color: white;
}
.top-left-label-b {
  top: 1rem;
}
.watermark-canvas {
  position: absolute;
  left: 50px;
  bottom: 35px;
  display: flex;

  img {
    width: 60px;
    height: auto;
    filter: brightness(0) invert(1);
  }
}
</style>
