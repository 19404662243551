<template>
  <default-template :handleClick="handleClick" />
</template>

<script>
import DefaultTemplate from './DefaultTemplate.vue'

export default {
  name: 'PeopleThermos',

  components: { DefaultTemplate },

  methods: {
    handleClick() {
      this.$store.dispatch('nextStep')

      if (this.step === 0) return

      let selectedEl = document.getElementById('header-toolbar')
      let tipPosition = 'bottom'

      let widthTextHighlight = 300
      let heightTextHighlight = 120

      if (this.step === 1) {
        tipPosition = 'bottom'
        selectedEl = document.getElementById('info-person-people-thermos')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui é exibido as informações da pessoa selecionada.'
        )
      } else if (this.step === 2) {
        tipPosition = 'bottom'
        selectedEl = document.getElementById('filters-people-thermos')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode utilizar os filtros para pesquisar as termografias da pessoa selecionada.'
        )
      } else if (this.step === 3) {
        tipPosition = 'right'
        selectedEl = document.getElementById('btn-change-view-people-thermos')
        this.$store.dispatch(
          'setTextHighlight',
          'Ao clicar nesse botão você altera a visualização dos registros de termografia da pessoa selecionada entre tabela e cards.'
        )
      } else if (this.step === 4) {
        tipPosition = 'top'
        selectedEl = document.getElementById('data-thermographs-prople-thermos')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode vizualizar todas as termografias da pessoa selecionada e vizualisar, editar ou excluir a termografia, basta clicar em seu ícone correspondente.'
        )
      }

      const offsetsSelectedEl = selectedEl.getBoundingClientRect()

      this.$store.dispatch('setHighlightCss', {
        top: `${offsetsSelectedEl.top}px`,
        left: `${offsetsSelectedEl.left}px`,
        width: `${selectedEl.offsetWidth}px`,
        height: `${selectedEl.offsetHeight}px`,
        border: '3px solid red'
      })
      this.$store.dispatch('setTextHighlightCss', {
        textHighlightCss: {
          top: 0,
          left: 0,
          width: `${widthTextHighlight}px`,
          height: `${heightTextHighlight}px`
        },
        positionInfos: {
          offsetsSelectedEl,
          selectedEl,
          widthTextHighlight,
          heightTextHighlight,
          tipPosition
        }
      })
    }
  },

  computed: {
    step() {
      return this.$store.getters.step
    }
  },

  mounted() {
    this.$store.dispatch('setMaxSteps', 4)
  }
}
</script>
