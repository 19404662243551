<template>
  <div class="background-page-editor">
    <v-card elevation="8" class="content-page-editor">
      <empty-row-template v-if="rows.length == 0" />

      <v-hover
        v-slot="hoverRow"
        v-for="(row, idxRow) in rows"
        :key="`row-${idxRow}`"
      >
        <v-row
          :class="{
            'hover-element': hoverRow.hover
          }"
          v-bind:style="{
            margin: 0,
            ...row.configs,
            minHeight: '1px',
            position: 'relative'
          }"
        >
          <empty-col-template v-if="row.cols.length == 0" />

          <move-modulo-template
            v-if="isMovingNewModulo && hoverRow.hover && row.cols.length == 0"
            text="Módulo"
          />

          <edit-modulo-tools
            :hover="hoverRow.hover"
            @onClickMove="onMove"
            @onClickConfig="onConfig(idxRow)"
            @onClickCopy="onCopy(idxRow)"
            @onClickDelete="onDelete(idxRow)"
          />

          <v-hover
            v-slot="hoverCol"
            v-for="(col, idxCol) in row.cols"
            :key="`col-${idxCol}`"
          >
            <v-col
              :class="{ 'hover-element': hoverCol.hover }"
              v-bind:style="{
                ...col.configs,
                minHeight: '1px',
                position: 'relative'
              }"
            >
              <empty-modulo-template v-if="col.modulos.length == 0" />

              <move-modulo-template
                v-if="
                  isMovingNewModulo && hoverCol.hover && col.modulos.length == 0
                "
                text="Módulo"
              />

              <edit-modulo-tools
                :hover="hoverCol.hover"
                @onClickMove="onMove"
                @onClickConfig="onConfig(idxRow, idxCol)"
                @onClickCopy="onCopy(idxRow, idxCol)"
                @onClickDelete="onDelete(idxRow, idxCol)"
              />

              <v-hover
                v-slot="hoverModulo"
                v-for="(modulo, idxModulo) in col.modulos"
                :key="`modulo-${idxModulo}`"
              >
                <div
                  :class="{ 'hover-element': hoverModulo.hover }"
                  v-bind:style="{
                    ...modulo.configs,
                    minHeight: '1px',
                    width: '100%',
                    position: 'relative'
                  }"
                >
                  <edit-modulo-tools
                    :hover="hoverModulo.hover"
                    @onClickMove="onMove"
                    @onClickConfig="onConfig(idxRow, idxCol, idxModulo)"
                    @onClickCopy="onCopy(idxRow, idxCol, idxModulo)"
                    @onClickDelete="onDelete(idxRow, idxCol, idxModulo)"
                  />
                  {{ modulo.value }}

                  <move-modulo-template
                    v-if="isMovingNewModulo && hoverModulo.hover"
                    text="Módulo"
                  />
                </div>
              </v-hover>
            </v-col>
          </v-hover>
        </v-row>
      </v-hover>
    </v-card>

    <v-card elevation="8" class="menu-page-editor">
      <menu-tools-editor @onMouseDown="mouseDownSelectTool" />
    </v-card>

    <v-dialog v-model="dialogConfigs" max-width="550px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="text--subtitle">
            <v-icon color="primary" style="padding-bottom: 2px"
              >mdi-hammer-wrench</v-icon
            >
            {{ textModulo }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialogConfigs = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-col>
          <edit-modulo-form :modulo="selectedModulo" />
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EditModuloTools from './EditModuloTools.vue'
import EditModuloForm from './EditModuloForm.vue'
import EmptyRowTemplate from './EmptyRowTemplate.vue'
import EmptyColTemplate from './EmptyColTemplate.vue'
import EmptyModuloTemplate from './EmptyModuloTemplate.vue'
import MoveModuloTemplate from './MoveModuloTemplate.vue'
import MenuToolsEditor from './MenuToolsEditor.vue'

export default {
  name: 'PageEditor',

  components: {
    EditModuloTools,
    EditModuloForm,
    EmptyRowTemplate,
    EmptyColTemplate,
    EmptyModuloTemplate,
    MoveModuloTemplate,
    MenuToolsEditor
  },

  data() {
    return {
      isMovingNewModulo: false,
      isMovingColuna: false,
      typeModulo: '',
      dialogConfigs: false,

      newIdxMove: {
        row: -1,
        col: -1,
        modulos: -1
      },

      selectedIdx: {
        row: -1,
        col: -1,
        modulos: -1
      },

      rows: [
        {
          cols: [
            {
              modulos: [
                {
                  value: 'Olá!',
                  type: 'text',
                  configs: {
                    fontSize: '18px',
                    textAlign: 'center',
                    color: 'white',
                    padding: '8px',
                    background: 'red'
                  }
                }
              ],
              configs: {
                fontSize: '18px',
                textAlign: 'center',
                padding: '8px',
                background: 'yellow'
              }
            },
            {
              modulos: [
                {
                  value: 'Olá!',
                  type: 'text',
                  configs: {
                    fontSize: '18px',
                    textAlign: 'center',
                    color: 'white',
                    padding: '8px',
                    background: 'red'
                  }
                },
                {
                  value: 'Olá!',
                  type: 'text',
                  configs: {
                    fontSize: '18px',
                    textAlign: 'center',
                    color: 'white',
                    padding: '8px',
                    background: 'red'
                  }
                }
              ],
              configs: {
                fontSize: '18px',
                textAlign: 'center',
                padding: '8px',
                background: 'yellow'
              }
            }
          ],
          configs: {
            fontSize: '18px',
            textAlign: 'center',
            padding: '8px',
            background: 'blue'
          }
        },
        {
          cols: [
            {
              modulos: [
                {
                  value: 'Olá!',
                  type: 'text',
                  configs: {
                    fontSize: '18px',
                    textAlign: 'center',
                    color: 'white',
                    padding: '8px',
                    background: 'red'
                  }
                }
              ],
              configs: {
                fontSize: '18px',
                textAlign: 'center',
                padding: '8px',
                background: 'yellow'
              }
            }
          ],
          configs: {
            fontSize: '18px',
            textAlign: 'center',
            padding: '8px',
            background: 'blue'
          }
        },
        {
          cols: [],
          configs: {
            fontSize: '18px',
            textAlign: 'center',
            padding: '8px',
            background: 'blue'
          }
        },
        {
          cols: [
            {
              modulos: [],
              configs: {
                fontSize: '18px',
                textAlign: 'center',
                padding: '8px',
                background: 'yellow'
              }
            }
          ],
          configs: {
            fontSize: '18px',
            textAlign: 'center',
            padding: '8px',
            background: 'blue'
          }
        }
      ]
    }
  },

  computed: {
    selectedModulo: function () {
      const idxRow = this.selectedIdx.row
      const idxModulo = this.selectedIdx.modulos
      const idxCol = this.selectedIdx.col

      if (idxRow >= 0 && idxModulo >= 0 && idxCol >= 0) {
        return this.rows[idxRow].cols[idxCol].modulos[idxModulo]
      } else if (idxRow >= 0 && idxCol >= 0) {
        return this.rows[idxRow].cols[idxCol]
      } else if (idxRow >= 0) {
        return this.rows[idxRow]
      }

      return {}
    },
    textModulo: function () {
      const idxRow = this.selectedIdx.row
      const idxModulo = this.selectedIdx.modulos
      const idxCol = this.selectedIdx.col

      if (idxRow >= 0 && idxModulo >= 0 && idxCol >= 0) {
        return 'Módulo'
      } else if (idxRow >= 0 && idxCol >= 0) {
        return 'Coluna'
      } else if (idxRow >= 0) {
        return 'Linha'
      }

      return 'Módulo'
    }
  },

  methods: {
    onMove: function () {},
    onConfig: function (idxRow = -1, idxCol = -1, idxModulo = -1) {
      this.dialogConfigs = true
      this.selectedIdx.modulos = idxModulo >= 0 ? idxModulo : -1
      this.selectedIdx.col = idxCol >= 0 ? idxCol : -1
      this.selectedIdx.row = idxRow >= 0 ? idxRow : -1
    },
    onCopy: function (idxRow, idxCol = -1, idxModulo = -1) {
      if (idxRow >= 0 && idxModulo >= 0 && idxCol >= 0) {
        const copyContent = JSON.parse(
          JSON.stringify(this.rows[idxRow].cols[idxCol].modulos[idxModulo])
        )
        this.rows[idxRow].cols[idxCol].modulos.splice(idxModulo, 0, copyContent)
      } else if (idxRow >= 0 && idxCol >= 0) {
        const copyContent = JSON.parse(
          JSON.stringify(this.rows[idxRow].cols[idxCol])
        )
        this.rows[idxRow].cols.splice(idxCol, 0, copyContent)
      } else if (idxRow >= 0) {
        const copyContent = JSON.parse(JSON.stringify(this.rows[idxRow]))
        this.rows.splice(idxRow, 0, copyContent)
      }
    },
    onDelete: function (idxRow, idxCol = -1, idxModulo = -1) {
      if (idxRow >= 0 && idxModulo >= 0 && idxCol >= 0) {
        this.rows[idxRow].cols[idxCol].modulos.splice(idxModulo, 1)
      } else if (idxRow >= 0 && idxCol >= 0) {
        this.rows[idxRow].cols.splice(idxCol, 1)
      } else if (idxRow >= 0) {
        this.rows.splice(idxRow, 1)
      }
    },
    mouseDownSelectTool(type) {
      this.isMovingNewModulo = true
      this.typeModulo = type
      console.log('mouseDown', this.typeModulo)
      setTimeout(
        () => document.addEventListener('mouseup', this.clearSelectTool),
        this.mouseUpSelectTool
      )
    },
    clearSelectTool(event) {
      console.log(event)
      this.isMovingNewModulo = false
      this.typeModulo = ''
      console.log('mouseUp', this.typeModulo)
      document.removeEventListener('mouseup', this.clearSelectTool)
    }
  }
}
</script>

<style scoped>
.background-page-editor {
  background: rgb(225, 225, 225);
  padding: 15px;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.content-page-editor {
  max-width: 595px;
  height: 842px;
  overflow: auto;
  width: 100%;
  margin: auto;
}
.menu-page-editor {
  position: fixed;
  top: 85px;
  right: 15px;
  width: 200px;
  height: calc(100vh - 105px);
}

.hover-element {
  box-shadow: inset 0px 0px 0px 2px gray;
  cursor: pointer;
}
</style>
