<template>
  <v-col cols="12" v-if="loadings.fetch">
    <v-row justify="center" class="mt-6">
      <loading-screen />
    </v-row>
  </v-col>
  <v-col class="pa-5" v-else>
    <v-row align="center" class="pa-3 mx-0">
      <div class="icon-container mr-3">
        <v-icon color="primary" class="">mdi-camera</v-icon>
      </div>
      <span class="text-h5">{{ $t('NewAnalysis') }}</span>
      <span class="caption ml-3 text--secondary"
        >:: {{ $t('SubmitNewThermographyForAnalysis') }}</span
      >
    </v-row>
    <v-col>
      <v-row align="center" class="pa-2"> </v-row>
      <v-row class="mt-3">
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-autocomplete
            prepend-inner-icon="mdi-account"
            outlined
            dense
            v-model="subject"
            :items="subjects"
            item-text="label"
            item-value="value"
            autofocus
            :label="$t('Person')"
            :append-outer-icon="'mdi-plus-circle'"
            @click:append-outer="addPeopleModal = true"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
          id="select-tags-new-thermos"
        >
          <v-autocomplete
            prepend-inner-icon="mdi-tag"
            dense
            multiple
            chips
            v-model="selectedTags"
            :items="tags"
            auto-select-first
            small-chips
            outlined
            :label="$t('Tags')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="py-0" id="description-new-thermo">
          <v-text-field
            v-model="name"
            :label="$t('Description')"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          v-if="createdAtEditable && !liveCapture"
          cols="12"
          md="6"
          lg="3"
          class="py-0"
          id="date-creation-new-thermo"
        >
          <v-text-field
            v-model="createdAt"
            type="datetime-local"
            :label="$t('DateCreation')"
            dense
            outlined
            :append-outer-icon="'mdi-notebook-heart-outline'"
            @click:append-outer="modalAnamnese = true"
          ></v-text-field>
        </v-col>
      </v-row>

      <template v-if="subject">
        <v-row class="py-3 pt-8">
          <v-col cols="12" sm="6" id="select-image-new-thermo">
            <input
              type="file"
              @change="handleFileChange"
              ref="inputFile"
              style="display: none"
            />

            <v-card v-if="liveCapture">
              <live-capture :onChangeImage="handleLiveCapture" />
            </v-card>
            <v-card
              v-else
              :class="[isDroppingFile ? 'blue dropping-file' : null]"
              @click="handleInputFile('click')"
              @dragover.prevent="dragOverFile"
              @dragleave.prevent="dragLeaveFile"
              @drop.prevent="handleInputFile('drop')"
              height="300"
            >
              <img
                class="current-image"
                :src="newThermo.imgPreview"
                v-if="newThermo.img"
              />
              <v-row
                v-else
                style="height: 100%"
                justify="center"
                align="center"
              >
                <v-col align="center">
                  <p><v-icon>mdi-camera-plus</v-icon></p>
                  <p>{{ $t('DragAndDropTheImageOrClickHere') }}.</p>
                </v-col>
              </v-row>
            </v-card>

            <v-row class="pa-3">
              <v-spacer></v-spacer>
              <v-switch label="Live Capture" v-model="liveCapture"></v-switch>
            </v-row>
          </v-col>
          <v-col cols="6" sm="6">
            <span class="text-caption text--secondary"
              >{{ $t('TemperatureImage') }}:</span
            >
            <v-row class="pa-3" id="inputs-min-max-new-thermo">
              <v-text-field
                dense
                style="max-width: calc(20% - 10px)"
                outlined
                prepend-inner-icon="mdi-thermometer-minus"
                :label="$t('Min')"
                type="number"
                v-model="newThermo.minTemp"
              ></v-text-field>
              <v-text-field
                dense
                class="ml-5"
                prepend-inner-icon="mdi-thermometer-plus"
                style="max-width: calc(20% - 10px)"
                outlined
                type="number"
                v-model="newThermo.maxTemp"
                :label="$t('Max')"
              ></v-text-field>

              <v-select
                :items="palettesKeys"
                class="ml-5"
                style="max-width: calc(35% - 10px)"
                v-model="paletteName"
                outlined
                :label="$t('Palette')"
                color="primary"
                dense
              ></v-select>
            </v-row>
            <v-row class="pa-2" id="inputs-protocol-pain" d-flex>
              <div class="ml-1" style="width: 250px">
                <v-select
                  prepend-inner-icon="mdi-account-eye"
                  :label="$t('Protocol')"
                  v-model="newThermo.view"
                  dense
                  style="max-width: 250"
                  :items="avalibleViews"
                  item-text="label"
                  item-value="value"
                  outlined
                ></v-select>
              </div>
              <div class="ml-5">
                <v-btn
                  outlined
                  style="max-width: 100%"
                  color="primary"
                  :disabled="newThermo.view == '' || newThermo.view == 'face'"
                  @click="modalPainSelection = !modalPainSelection"
                >
                  + DOR
                </v-btn>
              </div>
            </v-row>
            <!-- <v-text-field
              v-if="createdAtEditable"
              v-model="newThermo.createdAt"
              type="datetime-local"
              label="Data Imagem"
              dense
              outlined
            ></v-text-field> -->

            <v-row class="pa-5" justify="end">
              <v-btn
                outlined
                color="primary"
                :disabled="!canAddImage"
                @click="handleAddThermoImage"
                id="add-btn-new-thermo"
                >{{ $t('Add') }} Imagem</v-btn
              >
            </v-row>

            <v-row class="pa-6" justify="end">
              <v-btn
                class="mr-3"
                text
                @click="$router.push({ name: 'People' })"
                >{{ $t('Cancel') }}</v-btn
              >
              <v-btn
                color="primary"
                style="width: 150px"
                @click="handleSendThermo"
                :loading="loadings.sendThermo"
                :disabled="!canSend || loadings.sendThermo"
                id="btn-send-new-thermo"
                >{{ $t('Send') }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row align="center" class="pa-3">
          <span class="text--secondary text-caption pr-2">{{
            $t('Thermographs')
          }}</span>
        </v-row>

        <v-row class="px-3">
          <v-card
            width="120"
            height="160"
            class="mr-2"
            v-for="(thermoItem, idx) in $store.getters.thermoImages"
            :key="thermoItem.view"
            style="position: relative"
          >
            <img class="current-image" :src="thermoItem.imgPreview" />
            <div class="view-overlay">
              <span class="text-body-2 white--text">{{
                getViewName(thermoItem.view)
              }}</span>
              <v-btn icon small
                ><v-icon @click="removeThermoImage(idx)" color="white" small
                  >mdi-delete</v-icon
                ></v-btn
              >
            </div>
          </v-card>
        </v-row>
      </template>
    </v-col>
    <v-dialog v-model="addPeopleModal" max-width="550px">
      <CreatePeopleFormModal
        :closeModalCallback="() => (addPeopleModal = false)"
        type="add"
        :onErrorCallback="handleOnErrorCreatePeople"
        :onSuccessCallback="handleOnSuccessCreatePeople"
      />
    </v-dialog>
    <v-dialog
      v-model="modalPainSelection"
      max-width="550px"
      v-if="newThermo.view != null && newThermo.view != 'face'"
    >
      <v-card class="px-8 py-6">
        <v-row>
          <v-col cols="12">
            <h3>
              <b>{{ getCurrentSelectedView }}</b>
            </h3>
            <label>Selecione uma parte do corpo e a intensidade da dor</label>
          </v-col>
          <v-col cols="12">
            <div class="p-relative p-r-40px pain-parts">
              <div class="paleta-cores">
                <button
                  v-for="(pain, key) in painColors"
                  :key="`cor-${(key - 10) * -1}`"
                  :class="painSelected == (key - 10) * -1 ? 'active' : ''"
                  :style="{
                    background: painColors[(key - 10) * -1]
                  }"
                  @click="painSelected = (key - 10) * -1"
                >
                  {{ (key - 10) * -1 }}
                </button>
              </div>
              <!-- vue-body-part-selector
                :selection.sync="painPartSelected"
                :disabled="false"
                :multiple="false"
                :parts-colour="partsColour"
                :body-colour="painColors[0]"
                :selection-colour="painColors[0]"
                :contourJson="selectedContourBodyPain"
              />
              <br / -->
              <vue-full-body-part-selector
                :contoursType="true"
                :selection.sync="painPartSelected"
                :disabled="false"
                :multiple="false"
                :parts-colour="partsColourFullBody"
                :body-colour="painColors[0]"
                :selection-colour="painColors[0]"
                :viewType="newThermo.view"
                :contourJson="contoursJsonPain[newThermo.view]"
              />
            </div>
          </v-col>
          <!-- v-col cols="12" style="font-size: 12px; font-weight: bold">
            <label>Legenda escala de dor:</label>
            <p
              v-for="(color, intensity) in painColors"
              :key="`scale-pain-${intensity}`"
              class="mb-1"
            >
              <v-icon :color="color" class="mr-2" small> mdi-circle </v-icon>
              <label>
                {{ intensity }} - <span v-if="intensity == 0">Nenhuma</span>
                <span v-else-if="intensity >= 1 && intensity <= 2">Leve</span>
                <span v-else-if="intensity >= 3 && intensity <= 7">
                  Moderada
                </span>
                <span v-else-if="intensity >= 8 && intensity <= 10">
                  Intensa
                </span>
              </label>
            </p>
          </v-col -->
          <v-col cols="12" class="text-right mb-0 pb-0">
            <v-btn
              color="primary"
              style="width: 150px"
              @click="modalPainSelection = false"
              >{{ $t('Salvar') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalAnamnese" :width="500">
      <div class="pa-6 white text-right">
        <v-textarea v-model="anamnese" outlined dense :label="$t('Anamnese')" />
        <v-btn text color="primary" @click="modalAnamnese = false">
          Salvar
        </v-btn>
      </div>
    </v-dialog>
  </v-col>
</template>

<script>
import 'vue-media-recorder/src/assets/scss/main.scss'
import LiveCapture from '../components/LiveCapture.vue'
import CreatePeopleFormModal from '../components/CreatePeopleFormModal.vue'
import LoadingScreen from '../components/LoadingScreen.vue'
import palettes from '@/assets/palettes.json'
// import VueBodyPartSelector from '../components/VueBodyPartSelector.vue'
import VueFullBodyPartSelector from '../components/VueFullBodyPartSelector.vue'
import ContourSuperiorPosterior from '@/assets/contour-superiorPosterior.json'
import ContourSuperiorAnterior from '@/assets/contour-superiorAnterior.json'
import ContourInferiorPosterior from '@/assets/contour-inferiorPosterior.json'
import ContourInferiorAnterior from '@/assets/contour-inferiorAnterior.json'
import ContourLateralEsquerda from '@/assets/contour-lateralEsquerda.json'
import ContourLateralDireita from '@/assets/contour-lateralDireita.json'
import FullBodyPosterior from '@/assets/full-body-posterior.json'
import FullBodyAnterior from '@/assets/full-body-anterior.json'
import ContourSuperiorInferiorPosterior from '@/assets/contour-superiorInferiorPosterior.json'
import ContourSuperiorInferiorAnterior from '@/assets/contour-superiorInferiorAnterior.json'

export default {
  components: {
    LiveCapture,
    CreatePeopleFormModal,
    // VueBodyPartSelector,
    VueFullBodyPartSelector,
    LoadingScreen
  },
  data() {
    return {
      modalAnamnese: false,
      contoursJsonPain: {
        superiorPosterior: ContourSuperiorPosterior,
        superiorAnterior: ContourSuperiorAnterior,
        inferiorPosterior: ContourInferiorPosterior,
        inferiorAnterior: ContourInferiorAnterior,
        lateral_left: ContourLateralEsquerda,
        lateral_right: ContourLateralDireita,
        others: {
          superiorInferiorPosterior: ContourSuperiorInferiorPosterior,
          superiorInferiorAnterior: ContourSuperiorInferiorAnterior
        }
      },
      modalPainSelection: false,
      painPartSelected: null,
      contours: {
        superiorAnterior: ContourSuperiorAnterior,
        superiorPosterior: ContourSuperiorPosterior,
        lateral_right: ContourLateralDireita,
        lateral_left: ContourLateralEsquerda,
        inferiorAnterior: ContourInferiorAnterior,
        inferiorPosterior: ContourInferiorPosterior
      },
      fullBody: {
        fullBodyPosterior: FullBodyPosterior,
        fullBodyAnterior: FullBodyAnterior
      },
      painColors: {
        0: '#4DC81F',
        1: '#67C621',
        2: '#7CCA09',
        3: '#C1CA06',
        4: '#FFCC00',
        5: '#FEB400',
        6: '#FF9800',
        7: '#FA8009',
        8: '#FA6815',
        9: '#F84A15',
        10: '#F22A22'
      },
      painSelected: 0,
      partsPainSelected: {},
      paletteName: 'RainbowLC',
      isDroppingFile: false,
      createdAtEditable: true,
      addPeopleModal: false,
      subject: null,
      newThermo: {
        createdAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16),
        img: null,
        imgPreview: null,
        minTemp: '',
        maxTemp: '',
        paletteName: 'RainbowLC',
        pain: {},
        view: ''
      },
      name: '',
      anamnese: '',
      createdAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16),
      liveCapture: false,
      selectedTags: [],
      camCapture: null,
      errors: {
        sendThermo: false,
        fetch: false
      },
      loadings: {
        sendThermo: false,
        fetch: false
      }
    }
  },
  methods: {
    handleLiveCapture(img) {
      this.newThermo.img = img
      this.newThermo.imgPreview = URL.createObjectURL(img)
    },
    dragOverFile() {
      this.isDroppingFile = true
    },
    dragLeaveFile() {
      this.isDroppingFile = false
    },
    handleInputFile(type) {
      if (type == 'drop') {
        this.isDroppingFile = false
        this.handleFileChange(event)
      } else if (type == 'click') {
        this.$refs.inputFile.click()
      }
    },
    handleFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.newThermo.img = files[0]
      this.newThermo.imgPreview = URL.createObjectURL(files[0])
    },
    getViewName(key) {
      return this.allViews.find((item) => item.value === key).label
    },
    removeThermoImage(idx) {
      this.$store.dispatch('removeThermoImage', idx)
    },
    handleAddThermoImage() {
      this.$store.dispatch('addThermoImage', this.newThermo)
      this.newThermo = {
        createdAt: this.createdAt,
        img: null,
        imgPreview: null,
        minTemp: this.newThermo.minTemp,
        maxTemp: this.newThermo.maxTemp,
        paletteName: this.newThermo.paletteName,
        pain: {},
        anamnese: '',
        view: this.newThermo.view
      }
      this.partsPainSelected = {}
      this.painSelected = 0
      this.painPartSelected = null
    },
    async handleSendThermo() {
      this.loadings.sendThermo = true
      try {
        this.$store.dispatch('updateThermoCreatedAt', this.createdAt)
        this.$store.dispatch('updateThermoName', this.name)
        this.$store.dispatch('updateThermoAnamnese', this.anamnese)
        this.$store.dispatch('updateThermoTags', this.selectedTags)
        await this.$store.dispatch('updateThermoSubjectId', this.subject)
        const thermo = await this.$store.dispatch('analyseThermo')

        this.$router.push(`/thermography/${thermo.id}`)

        this.$store.dispatch('setSnackBar', {
          text: `${this.$t('ThermographySuccessfullyRegistered')}!`,
          type: 'success',
          model: true
        })
      } catch (error) {
        console.log(error)
        this.$store.dispatch('setSnackBar', {
          text:
            error?.response?.data?.message != ''
              ? error.response.data.message
              : `${this.$t('AnErrorOccurredWhileTryingToAddThermography')}!`,
          type: 'error',
          model: true
        })

        this.errors.sendThermo = true
      }
      this.loadings.sendThermo = false
    },
    handleOnSuccessCreatePeople() {
      this.$store.dispatch('setSnackBar', {
        text: `${this.$t('SuccessfullyRegisteredPerson')}!`,
        type: 'success',
        model: true
      })
    },
    handleOnErrorCreatePeople() {
      this.$store.dispatch('setSnackBar', {
        text: `${this.$t('ErrorTryingToRegisterThePerson')}!`,
        type: 'error',
        model: true
      })
    }
  },
  computed: {
    getCurrentSelectedView() {
      let selectedView = this.newThermo.view
      this.avalibleViews.forEach((view) => {
        if (view.value == selectedView) {
          selectedView = view.label
        }
      })
      return selectedView
    },
    selectedContourBodyPain() {
      return this.contours[this.newThermo.view]
    },
    partsColour() {
      const partsColour = {}
      Object.keys(this.partsPainSelected).forEach((part) => {
        partsColour[part] = this.painColors[this.partsPainSelected[part]]
      })
      return partsColour
    },
    partsColourFullBody() {
      const partsColour = {}
      this.painSelected // to update this computed variable it needs to listen to this variable
      if (this.newThermo.view == '' || this.newThermo.view == 'face')
        return partsColour
      let contourJson = this.contoursJsonPain[this.newThermo.view].data

      if (this.newThermo.view == 'others') {
        contourJson = {
          ...this.contoursJsonPain[this.newThermo.view]
            .superiorInferiorPosterior.data,
          ...this.contoursJsonPain[this.newThermo.view].superiorInferiorAnterior
            .data
        }
      }
      for (const [partName] of Object.entries(contourJson)) {
        partsColour[partName] =
          this.painColors[this.partsPainSelected[partName]]
      }
      return partsColour
    },
    palettesKeys() {
      return Object.keys(palettes)
    },
    allViews() {
      return this.$t('viewsOptsArr')
    },
    subjects() {
      return this.$store.getters.subjects.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },
    canAddImage() {
      return (
        !!this.newThermo.img &&
        !!this.newThermo.minTemp &&
        !!this.newThermo.maxTemp &&
        !!this.newThermo.imgPreview &&
        this.newThermo.view !== ''
      )
    },
    canSend() {
      return this.$store.getters.thermoImages.length > 0
    },
    tags() {
      return this.$store.getters.tags.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    avalibleViews() {
      return this.allViews.filter(
        (view) =>
          !this.$store.getters.thermoImages.find((img) => {
            return img.view !== 'others' && img.view === view.value
          })
      )
    },
    themeNewThermoMinTemp() {
      return this.$store.getters.themeNewThermoMinTemp
    },
    themeNewThermoMaxTemp() {
      return this.$store.getters.themeNewThermoMaxTemp
    }
  },
  async mounted() {
    this.loadings.fetch = true
    try {
      await Promise.all([
        this.$store.dispatch('fetchSubjects'),
        this.$store.dispatch('fetchGroups'),
        this.$store.dispatch('fetchTags')
      ])
      this.errors.fetch = false
    } catch (error) {
      this.errors.fetch = true
    }
    this.subject =
      this.$route.params.peopleId != undefined
        ? this.$route.params.peopleId
        : null
    this.loadings.fetch = false

    this.$store.dispatch('clearThermo')

    if (this.themeNewThermoMinTemp != 0) {
      this.newThermo.minTemp = this.themeNewThermoMinTemp
    }
    if (this.themeNewThermoMaxTemp != 0) {
      this.newThermo.maxTemp = this.themeNewThermoMaxTemp
    }
  },
  watch: {
    painSelected() {
      const partsPainSelected = { ...this.partsPainSelected }
      if (this.painPartSelected == null) {
        return
      }
      partsPainSelected[this.painPartSelected] = this.painSelected
      this.partsPainSelected = partsPainSelected
      this.newThermo.pain = partsPainSelected
    },
    painPartSelected() {
      if (this.partsPainSelected[this.painPartSelected] != undefined) {
        this.painSelected = this.partsPainSelected[this.painPartSelected]
      } else {
        this.painSelected = 0
      }
    },
    createdAt() {
      this.newThermo.createdAt = this.createdAt
    },
    paletteName() {
      this.newThermo.paletteName = this.paletteName
    }
  }
}
</script>

<style>
.pain-parts svg {
  width: 100%;
  height: 400px;
}
</style>

<style lang="scss" scoped>
.p-r-40px {
  padding-right: 40px;
}
.p-relative {
  position: relative;
}
.paleta-cores {
  position: absolute;
  right: 0;
  top: 0;
  top: calc(50% - 176px);
  width: 26px;
  height: 352px;
  padding: 0 6px;

  button {
    color: white;
    font-size: 16px;
    display: flex;
    width: 26px;
    margin-bottom: 6px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 9000px;
  }

  button.active {
    text-shadow: 0px 0px 6px rgba(0, 0, 128, 1);
    color: #1c1c1c;
  }
}
.icon-container {
  background-color: #f6ebea;
  padding: 4px;
  border-radius: 3px;
}
.card-hover {
  cursor: pointer;
  transition: background 0.5s;
  &:hover {
    background: #f6ebea;
  }
}
.current-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.view-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropping-file {
  opacity: 0.6;
}
</style>
