<template>
  <default-template :handleClick="handleClick" />
</template>

<script>
import DefaultTemplate from './DefaultTemplate.vue'

export default {
  name: 'People',

  components: { DefaultTemplate },

  methods: {
    async handleClick() {
      this.$store.dispatch('nextStep')

      if (this.step === 0) return

      let selectedEl = document.getElementById('header-toolbar')
      let tipPosition = 'bottom'

      let widthTextHighlight = 300
      let heightTextHighlight = 120

      if (this.step === 1) {
        tipPosition = 'bottom'
        selectedEl = document.getElementById('header-infos-thermography')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui fica as informações da pessoa da termografia atual e também pode ser alterado o tipo da temperatura que será exibido na termografia abaixo.'
        )
      } else if (this.step === 2) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui é exibido a termografia onde você pode ver as informações de temperaturas através da imagem e também através da seleção dos contornos.'
        )
      } else if (this.step === 3) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        selectedEl = this.getElByClass(selectedEl, 'arrow-right-profile-view')
        this.$store.dispatch(
          'setTextHighlight',
          'Ao clicar na seta você poderá alterar os filtros da imagem para facilitar sua visualização ou ainda vizualizar a imagem original ou semafórica.'
        )
      } else if (this.step === 4) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        this.clickElByClass(selectedEl, 'arrow-right-profile-view')
        await this.sleep(300)
        selectedEl = this.getElByClass(
          selectedEl,
          'select-palette-profile-view'
        )
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode alterar a paleta da imagem termográfica.'
        )
      } else if (this.step === 5) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        selectedEl = this.getElByClass(
          selectedEl,
          'select-type-image-profile-view'
        )
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode alterar o tipo de exibição da imagem termográfica. Existem 3 opções, Processada, Semafória e Original.'
        )
      } else if (this.step === 6) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        selectedEl = this.getElByClass(
          selectedEl,
          'select-compare-type-profile-view'
        )
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode alterar a exibição da temperatura dos contornos entre a média das temperaturas, a temperatura máxima do contorno ou mínima.'
        )
      } else if (this.step === 7) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        this.clickElByClass(selectedEl, 'arrow-left-profile-view')
        selectedEl = this.getElByClass(selectedEl, 'btns-thermo-kelvin-canvas')
        this.$store.dispatch(
          'setTextHighlight',
          'Ao clicar no ícone de temperatura você pode alterar a exibição da temperatura dos contornos para normal, assimétrica ou desativada.'
        )
      } else if (this.step === 8) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        selectedEl = this.getElByClass(selectedEl, 'range-input-profile-view')
        this.$store.dispatch(
          'setTextHighlight',
          'Você também poderá alterar a temperatura máxima e mínima exibida na imagem termográfica ao editar os valores a direita ou arrastar o slider.'
        )
      } else if (this.step === 9) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        selectedEl = this.getElByClass(selectedEl, 'zoom-in-kelvin-canvas')
        this.$store.dispatch(
          'setTextHighlight',
          'Ao clicar na lupa você aumentará a termografia e terá a sua disposição um conjunto de ferramentas para ajudar na sua análise.'
        )
      } else if (this.step === 10) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-0')
        this.clickElByClass(selectedEl, 'zoom-in-kelvin-canvas')
        await this.sleep(300)
        selectedEl = document.getElementById('canvas-thermography-modal-0')
        this.clickElByClass(selectedEl, 'btn-edit-tools-kelvin-canvas')
        await this.sleep(300)
        selectedEl = this.getElByClass(selectedEl, 'tools-user-drawings')
        this.$store.dispatch(
          'setTextHighlight',
          'Com as ferramentas disponíveis você pode selecionar partes da termografia para exibir suas informações termográficas e fazer anotações e destacá-las.'
        )
      } else if (this.step === 11) {
        tipPosition = 'top'
        selectedEl = document.getElementById('canvas-thermography-modal-0')
        this.clickElByClass(selectedEl, 'zoom-out-kelvin-canvas')
        selectedEl = document.getElementById('chart-thermography-0')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você tem a sua disposição informações assimétricas da seleção dos contornos da série de termografias.'
        )
      } else if (this.step === 12) {
        tipPosition = 'top'
        selectedEl = document.getElementById(
          'data-table-asymmetry-thermography-0'
        )
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você tem a sua disposição informações assimétricas da série dessa termografia.'
        )
      } else if (this.step === 13) {
        tipPosition = 'top'
        selectedEl = document.getElementById('carousel-thermos-thermography')
        this.$store.dispatch(
          'setTextHighlight',
          'Aqui você pode selecionar a termografia que você deseja destacar para fazer a sua análise.'
        )
      }

      const offsetsSelectedEl = selectedEl.getBoundingClientRect()

      this.$store.dispatch('setHighlightCss', {
        top: `${offsetsSelectedEl.top}px`,
        left: `${offsetsSelectedEl.left}px`,
        width: `${selectedEl.offsetWidth}px`,
        height: `${selectedEl.offsetHeight}px`,
        border: '3px solid red'
      })
      this.$store.dispatch('setTextHighlightCss', {
        textHighlightCss: {
          top: 0,
          left: 0,
          width: `${widthTextHighlight}px`,
          height: `${heightTextHighlight}px`
        },
        positionInfos: {
          offsetsSelectedEl,
          selectedEl,
          widthTextHighlight,
          heightTextHighlight,
          tipPosition
        }
      })
    },
    clickElByClass(selectedEl, className) {
      let zoomInEl = selectedEl.getElementsByClassName(className)
      if (zoomInEl.length > 0) {
        zoomInEl[0].click()
      }
    },
    getElByClass(selectedEl, className) {
      let newEl = selectedEl.getElementsByClassName(className)
      if (newEl.length > 0) return newEl[0]
      return selectedEl
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    }
  },

  computed: {
    step() {
      return this.$store.getters.step
    }
  },

  mounted() {
    this.$store.dispatch('setMaxSteps', 13)
  }
}
</script>
