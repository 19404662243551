export default {
  state: {
    globalModal: {
      component: '',
      showNextPage: false,
      model: false
    }
  },
  mutations: {
    SET_GLOBAL_MODAL(state, payload) {
      state.globalModal = payload
    }
  },
  actions: {
    setGlobalModal({ commit }, payload) {
      commit('SET_GLOBAL_MODAL', payload)
    }
  },
  getters: {
    globalModal: (state) => state.globalModal
  }
}
