var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadings.fetch)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('loading-screen')],1)],1):(_vm.errors.fetch)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('div',[_c('v-col',[_vm._v(_vm._s(_vm.$t('ErrorLoadingReportTemplates'))+".")]),_c('v-col',{staticClass:"py-0"},[_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.fetchAll}},[_vm._v(_vm._s(_vm.$t('TRYAGAIN')))])],1)],1)])],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"id":"filters-report-templates","outlined":""}},[_c('v-col',[_c('v-row',{staticClass:"pa-3",attrs:{"justify":"space-between"}},[_c('span',{staticClass:"text-caption text--secondary"},[_vm._v(_vm._s(_vm.$t('Filters')))]),_c('v-btn',{attrs:{"to":{ name: 'ReportTemplatesNew' },"rounded":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('NEWREPORTTEMPLATE')))],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":((_vm.$t('SearchName')) + "...")},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.filteredItems,"must-sort":"","outlined":"","items-per-page":_vm.items.length,"no-data-text":((_vm.$t('NoRecord')) + " :("),"mobile-breakpoint":"1","id":"table-data-report-templates"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',_vm._l((headers),function(itemKey){return _c('td',{key:itemKey.value,style:(itemKey.value === 'actions' || itemKey.value === 'inserted_at'
                  ? 'width: 150px'
                  : '')},[(itemKey.value === 'actions')?[(hover)?[_c('v-btn',{attrs:{"icon":"","small":"","color":"primary","to":{
                      name: 'ReportTemplatesEdit',
                      params: { id: item.id }
                    }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","loading":_vm.loadings.remove,"disabled":_vm.loadings.remove,"color":"red"},on:{"click":function($event){return _vm.handleOpenRemoveModal(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]:_vm._e()]:[_vm._v(" "+_vm._s(item[itemKey.value])+" ")]],2)}),0)]}}],null,true)})]}}])}),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.removeModal.open),callback:function ($$v) {_vm.$set(_vm.removeModal, "open", $$v)},expression:"removeModal.open"}},[_c('RemoveFormModal',{attrs:{"title":((_vm.$t('RemoveReportTemplate')) + "?"),"onCloseCallback":function () { return (_vm.removeModal.open = false); },"onRemoveCallback":function () { return _vm.handleRemoveItem(_vm.removeModal.item); },"isLoading":_vm.loadings.remove}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }