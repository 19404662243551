<template>
  <div class="locale-changer">
    <v-select
      v-model="$i18n.locale"
      :items="langs"
      outlined
      dense
      single-line
      label=""
      hide-details
    />
  </div>
</template>

<script>
export default {
  name: 'LocaleChanger',
  computed: {
    langs() {
      return [
        {
          value: 'pt_BR',
          text: this.$t('Portuguese')
        },
        {
          value: 'en_US',
          text: this.$t('English')
        },
        {
          value: 'es_ES',
          text: this.$t('Spanish')
        }
      ]
    }
  },
  watch: {
    '$i18n.locale'(val) {
      const locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
      const newLocale = val != 'pt_BR' ? val : ''
      let pathName = window.location.pathname

      if (val === 'pt_BR') {
        pathName = pathName.replace(locale + '/', '')
      } else if (locale === '/') {
        pathName = pathName.split('/')
        pathName[3] = newLocale + '/'
        pathName = pathName.join('')
      } else pathName = pathName.replace(locale, newLocale)

      window.history.replaceState(
        {},
        document.title,
        pathName + window.location.hash
      )
    }
  },
  mounted() {
    let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
    if (locale === '/') locale = 'pt_BR'
    this.$i18n.locale = locale
  }
}
</script>
